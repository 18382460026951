(function($) {
    "use strict";
    /* object js */
    var EL_Frontend = {
        init: function() {
            // $('.content, #tabs').tabs();
            this.ticket_info_according();
            this.el_tippy();
            // CART
            this.cart_ticket_event();


            this.cart_discount();
            this.cart_change_ticket_info();
            this.cart_change_customer_info();
            this.cart_edit_button();
            this.cart_payment_according();

            // detail
            this.event_calendar();
            this.comment_rating();
            this.slide_event_related();
            this.slide_event_single_gallery();
            this.banner_event_single();
            this.el_show_more_desc();
            this.image_profile();
            this.update_profile();
            this.update_role();
            this.add_social();
            this.save_social();
            this.remove_social();
            this.repair_key_social();
            this.check_password();
            this.change_password();
            this.view_password();
            this.select_event();
            this.el_pending_post();
            this.el_publish_post();
            this.el_trash_post();
            this.el_duplicate_post();
            this.el_delete_post();
            this.el_bulk_action();

            // Edit post
            this.edit_lat_lng();
            this.cut_string_cat();
            this.image_feature();
            this.add_image_gallery();
            this.sort_image_gallery();
            this.remove_image_gallery();
            this.el_load_location();
            this.el_save_edit_event();

            // Checkout
            this.process_checkout();
            // this.cart_next_step();
            this.el_cart_optimize();
            this.countdown_checkout();

            

            // package
            this.add_package();

            // Wishlist
            this.update_wishlist();
            this.remove_wishlist();

            // Update Payout Method
            this.update_payout_method();

            // Search
            this.el_load_venue_search();
            this.datepicket_search();
            this.el_load_location_search();
            this.el_search_map();

            // Event Detail Map 
            this.event_map();

            //send mail
            this.el_single_send_mail_vendor();

            //send mail report
            this.el_single_send_mail_report();

            this.el_single_click_book();
            this.el_single_gallery();

            //menu vendor 
            this.el_menu_mobile();

            // cancel booking
            this.el_enable_cancellation_booking();

            // Cancel
            this.el_cancel_booking();

            // event type
            this.el_choose_event_type();

            // link ticket
            this.el_choose_link_ticket();

            this.el_update_ticket_status();

            // Choose Payment method
            this.el_choose_payout_method();

            // Withdraw form show hide
            this.withdraw_modal();

            // Withdraw form validate
            this.validateForm_withdraw();

            /*** update ticket in manage ticket***/
            this.el_update_ticket_max();

            /*** edit ticket in manage ticket***/
            this.el_edit_ticket_calendar();

            /*** choose calendar in manage ticket***/
            this.el_choose_calendar();

            /*  check date search ticket*/
            this.el_check_date_search_ticket();

            // Show Chart
            this.el_show_chart();

            // User Profile

            this.ova_upload_file();

            // Validate price ticket

            this.el_ticket_list();

            this.el_ticket_received_download();

            this.el_vendor_event_listing();

            this.el_show_hide_password_create_event();

            this.el_create_event_tab();

            this.el_profile_tab();

            // Ticket Manager
            this.el_ticket_manager.init();

            this.el_show_password_field();

            this.el_view_seating_map();

            this.el_show_data();

            this.el_show_column_tickets();


            // Export booking

            //export csv booking
            this.export_csv();

            //export csv ticket
            this.export_csv_ticket();
        },


        el_close_popup: function(){
            $('.el_close_popup').off().on('click', function(e){
                e.preventDefault();
                var targetId = $(this).attr('data-id');
                $('body').find('#'+targetId).remove();
            });
        },

        el_tippy: function(){
            tippy('[data-tippy-content]');
        },

        el_show_column_tickets: function(){
            $('.el_show_column_tickets').off().on('click', function(e){
                e.preventDefault();
                if ( $(this).hasClass('is_showing') ) {
                    $(this).html('<i class="fas fa-eye-slash"></i>');
                    $(this).removeClass('is_showing');
                    $('.manage_tickets .column-tickets').html('');
                } else {
                    $(this).html('<i class="fas fa-eye"></i>');
                    $(this).addClass('is_showing');
                    const id_event = $(this).attr('data-id-event');

                    const data = {
                        'action': 'el_show_column_tickets',
                        'nonce': ajax_object.nonce,
                        'id_event': id_event,
                    };

                    $('.manage_tickets').block({
                        message: null,
                        overlayCSS:  { 
                            backgroundColor: '#fff', 
                            opacity: 0.3, 
                            cursor: 'wait' 
                        },
                    });

                    $.ajax({
                        url: ajax_object.ajax_url,
                        complete: function( jqXHR, textStatus){
                            $('.manage_tickets').unblock();
                        },
                        data: data,
                        method: 'POST',
                        type: 'POST',
                        error: function( jqXHR, textStatus, errorThrown){
                            console.error( errorThrown );
                        },
                        success: function(data){
                            $('.manage_tickets .column-tickets').html(data);
                        }
                    });
                }
            });
        },

        el_show_data: function(){
            const that = this;
            $('.el_show_data').off().on('click', function(e){
                e.preventDefault();
                const id_event = $('.info-sales').attr('data-id-event');
                const thisBox = $(this).closest('li');
                const name = $(this).attr('data-name');
                const data = {
                    'action': 'el_show_data_booking',
                    'nonce': ajax_object.nonce,
                    'id_event': id_event,
                    'name': name,
                };

                thisBox.block({
                    message: null,
                    overlayCSS:  { 
                        backgroundColor: '#fff', 
                        opacity: 0.3, 
                        cursor: 'wait' 
                    },
                });

                $.ajax({
                    url: ajax_object.ajax_url,
                    complete: function( jqXHR, textStatus){
                        thisBox.unblock();
                    },
                    data: data,
                    method: 'POST',
                    type: 'POST',
                    error: function( jqXHR, textStatus, errorThrown){
                        console.error( errorThrown );
                    },
                    success: function(data){
                        if ( data?.name ) {
                            $('.info-sales .el_show_data[data-name="'+data?.name+'"]').html( data?.result );
                        }
                    }
                });
            });
        },

        el_view_seating_map: function(){

            $('.seating_map').zoom();

            $(".el_view_seating_map").off().on("click",function(){
                const icon = $(this).find('.icon');
                const seatingMap = $('.seating_map');
                if ( $(this).hasClass("is-hidden") ) {
                    $(this).removeClass("is-hidden");
                    seatingMap.slideDown();
                    icon.html('<i class="fas fa-minus"></i>');
                } else {
                    $(this).addClass("is-hidden");
                    seatingMap.slideUp();
                    icon.html('<i class="fas fa-plus"></i>');
                }
            });
        },

        el_ticket_manager: {
            init: function(){
                this.download_ticket();
                this.send_ticket();
                this.download_all_ticket();
                this.create_tickets();
                this.save_tickets();
                this.select_all_ticket();
                this.cancel_check_in_ticket();
            },

            cancel_check_in_ticket: function(){
                $(".el_cancel_check_in").off().on("click",function(e){
                    e.preventDefault();
                    var $this = $(this);
                    var nonce = $this.attr("data-nonce");
                    var ticketID = $this.attr("data-ticket-id");


                    $(".table-list-ticket").block({
                        message: null,
                        overlayCSS:  { 
                            backgroundColor: '#fff', 
                            opacity: 0.3, 
                            cursor: 'wait' 
                        },
                    });

                    var data = {
                        'action': 'el_cancel_check_in',
                        'nonce': nonce,
                        'ticket_id': ticketID,
                    };

                    $.post( ajax_object.ajax_url, data, function(res){

                        res = JSON.parse(res);

                        if ( res.status === "error" ) {
                            alert(res.mess);
                        } else {
                            alert(res.mess);
                            window.location.reload( true );
                        }

                        $(".table-list-ticket").unblock();
                    } );
                });
            },

            cart: {
                extra_service: [],
                get_storage_key: function(){
                    var storageKey  = null;
                    var eventID     = $("#el_create_tickets_select_event").val();
                    var calendarID  = $("#el_create_tickets_calendar_event").val();
                    if ( eventID !== "" && eventID !== null && calendarID !== "" && calendarID !== null ) {
                        storageKey  = "_"+eventID+"_"+calendarID;
                    }
                    return storageKey;
                },
                init: function(){
                    EL_Frontend.el_ticket_manager.cart.set_storage_data();
                    EL_Frontend.el_ticket_manager.cart.set_data_extra_service();
                },
                clear_cart: function(){
                    var storageKey = EL_Frontend.el_ticket_manager.cart.get_storage_key();
                    sessionStorage.removeItem(storageKey);
                },
                get_data: function(){
                    var data = [];
                    var storageKey = EL_Frontend.el_ticket_manager.cart.get_storage_key();
                    
                    if ( storageKey ) {
                        var dataStorage = JSON.parse( sessionStorage.getItem( storageKey ) );
                        if ( dataStorage ) {
                            data = dataStorage;
                        }
                    }
                    return data;
                },
                get_array_column: function( $column, $array ){
                    var $arrayColumn = [];
                    if ( $.isArray( $array ) && $array.length > 0 ) {
                        for (var i = 0; i < $array.length; i++) {
                            var item = $array[i];
                            $arrayColumn.push( item[$column] );
                        }
                    }

                    return $arrayColumn;
                },
                get_total_qty_ticket: function(){
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    var totalQty = 0;
                    var seatOption = EL_Frontend.el_ticket_manager.get_seat_option();

                    if ( seatOption !== "map" ) {
                        if ( $.isArray(data) && data.length > 0 ) {
                            for (var i = 0; i < data.length; i++) {
                                var cartItem = data[i];
                                var qty = parseInt( cartItem['qty'] );
                                if ( isNaN(qty) ) {
                                    qty = 0;
                                }
                                totalQty += qty;
                            }
                        }
                    } else {
                        if ( $.isArray( data ) && data.length > 0 ) {

                            for (var i = 0; i < data.length; i++) {
                                var cartItem = data[i];
                                var data_person = cartItem.data_person ? cartItem.data_person : [];

                                if ( $.isArray( data_person ) && data_person.length > 0 ) {
                                    for (var j = 0; j < data_person.length; j++) {
                                        var personItem = data_person[j];
                                        var qty = parseInt( personItem['qty'] );
                                        if ( isNaN( qty ) ) {
                                            qty = 0;
                                        }
                                        totalQty += qty;
                                    }
                                } else {
                                    var qty = parseInt( cartItem['qty'] );
                                    if ( isNaN( qty ) ) {
                                        qty = 1;
                                    }
                                    totalQty += qty;
                                }
                                
                            }
                        }
                    }

                    return totalQty;
                },
                get_index_cart_item_by_ticket_id( ticketID ){
                    var foundIndex = -1;
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    var $arrayColumn = EL_Frontend.el_ticket_manager.cart.get_array_column( 'id' ,data );
                    if ( $.isArray( $arrayColumn ) && $arrayColumn.length > 0 ) {
                        foundIndex = $arrayColumn.indexOf( ticketID );
                    }
                    return foundIndex;
                },
                set_storage_data: function( data = [] ){
                    var storageKey = EL_Frontend.el_ticket_manager.cart.get_storage_key();
                    if ( storageKey ) {
                        sessionStorage.setItem( storageKey, JSON.stringify( data ) );
                    }
                },
                get_person_type_seat: function(){
                    const personType = JSON.parse( $(".el_seats_container").attr("data-person-type-seat") );
                    return personType;
                },
                add_to_cart: function( ticketID, ticketName, currentQty ){
                    var data            = EL_Frontend.el_ticket_manager.cart.get_data();
                    var dataService     = EL_Frontend.el_ticket_manager.cart.extra_service;
                    var extra_service   = [];
                    var checkItem       = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( ticketID );
                    var seat_option     = EL_Frontend.el_ticket_manager.get_seat_option();

                    if ( $.isArray( dataService ) && dataService.length > 0 ) {
                        extra_service = $.map( dataService, function(item){
                            item['price'] = 0;
                            item['qty'] = 0;
                            return item;
                        } );
                    }

                    if ( checkItem !== null ) {
                        var foundIndex = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                        var curExtraSV = checkItem['extra_service'];
                        checkItem['qty'] = currentQty;

                        if ( $.isArray( curExtraSV ) && curExtraSV.length > 0 ) {
                            checkItem['extra_service'] = checkItem['extra_service'].concat( [extra_service] );
                        }
                        if ( seat_option === "simple" ) {
                            checkItem['seat'] = checkItem['seat'].concat( [""] );
                        }
                        
                        if ( foundIndex !== -1 ) {
                            data[foundIndex] = checkItem;
                        }
                        
                    } else {

                        var newItem = {
                            id: ticketID,
                            name: ticketName,
                            price: 0,
                            qty: currentQty,
                            extra_service: [],
                            seat: [],
                        };

                        if ( seat_option === "simple" ) {
                            newItem['seat'] = [""];
                        }

                        if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                            newItem['extra_service'] = [ extra_service ];
                        }
                       
                        data = data.concat( [newItem] );
                        
                    }
            
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },
                update_person_type_seat: function( ticketID, personType ){
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    var checkItem = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( ticketID );
                    
                    if ( typeof checkItem !== null ) {
                        var foundIndex = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                        checkItem['person_type'] = personType;
                        data[foundIndex] = checkItem;
                    }

                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },
                add_qty_area: function( ticketID, currentQty ){
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    var dataService     = EL_Frontend.el_ticket_manager.cart.extra_service;
                    var extra_service   = [];
                    var checkItem       = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( ticketID );

                    if ( $.isArray( dataService ) && dataService.length > 0 ) {
                        extra_service = $.map( dataService, function(item){
                            item['price'] = 0;
                            item['qty'] = 0;
                            return item;
                        } );
                    }

                    if ( checkItem !== null ) {
                        var foundIndex = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                        checkItem['qty'] = currentQty;
                        if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                            checkItem['extra_service'] = checkItem['extra_service'].concat( [extra_service] );
                        }
                        
                        if ( foundIndex !== -1 ) {
                            data[foundIndex] = checkItem;
                        }
                    }
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },
                add_qty_area_person: function( ticketID, personIndex, currentQty ){
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    var dataService     = EL_Frontend.el_ticket_manager.cart.extra_service;
                    var extra_service   = [];
                    var checkItem       = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( ticketID );

                    if ( $.isArray( dataService ) && dataService.length > 0 ) {
                        extra_service = $.map( dataService, function(item){
                            item['price'] = 0;
                            item['qty'] = 0;
                            return item;
                        } );
                    }

                    if ( checkItem !== null && personIndex !== -1 ) {
                        var foundIndex = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                        checkItem['data_person'][personIndex]['qty'] = currentQty;
                        if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                            checkItem['data_person'][personIndex]['extra_service'] = checkItem['data_person'][personIndex]['extra_service'].concat( [extra_service] );
                        }
                        
                        if ( foundIndex !== -1 ) {
                            data[foundIndex] = checkItem;
                        }
                    }
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },
                remove_qty_area_person: function( ticketID, personIndex, currentQty ){
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    var checkItem = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( ticketID );

                    if ( checkItem !== null && personIndex !== -1 ) {
                        var foundIndex = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                        var extra_service = checkItem['data_person'][personIndex]['extra_service'];
                        if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                            extra_service.splice(-1);
                            checkItem['data_person'][personIndex]['extra_service'] = extra_service;
                        }
                        
                        checkItem['data_person'][personIndex]['qty'] = currentQty;
                        
                        if ( foundIndex !== -1 ) {
                            data[foundIndex] = checkItem;
                            EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                            var checkQty = EL_Frontend.el_ticket_manager.cart.get_total_qty_area_person( ticketID );
                            if ( checkQty < 1 ) {
                                data.splice(foundIndex, 1);
                                EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                                $('.el_areas input[name="area"]').each(function(i,el){
                                    if ( $(el).val() === ticketID ) {
                                        $(el).prop("checked", false);
                                    }
                                });
                            }
                        }

                    }

                },
                remove_qty_area: function( ticketID, currentQty ){
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();

                    var checkItem = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( ticketID );

                    if ( checkItem !== null ) {
                        var foundIndex = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                        var extra_service = checkItem['extra_service'];
                
                        checkItem['qty'] = currentQty;
                        if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                            extra_service.splice(-1);
                            checkItem['extra_service'] = extra_service;
                        }
                        if ( currentQty < 1 ) {
                            data.splice( foundIndex, 1 );
                            $('.el_areas input[name="area"]').each(function(i,el){
                                
                                if ( $(el).val() === ticketID ) {
                                    $(el).prop('checked', false);
                                }
                            });
                        } else {
                            data[foundIndex] = checkItem;
                        }

                    }
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },
                add_seat_map: function( ticketID ){
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    var dataService         = EL_Frontend.el_ticket_manager.cart.extra_service;
                    var extra_service       = [];
                    var checkItem           = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( ticketID );
                    const personTypeSeat    = EL_Frontend.el_ticket_manager.cart.get_person_type_seat();

                    if ( $.isArray( dataService ) && dataService.length > 0 ) {
                        var extra_item = $.map( dataService, function(item){
                            item['price'] = 0;
                            item['qty'] = 0;
                            return item;
                        } );
                        extra_service = extra_service.concat( [extra_item] );
                    }

                    var newItem = {
                        id: ticketID,
                        price: 0,
                        extra_service: extra_service,
                    };
                    // Add person type
                    if ( ! $.isEmptyObject( personTypeSeat ) ) {
                        let i = 0;
                        for(const prop in personTypeSeat ){
                            if ( i == 0 ) {
                                newItem['person_type'] = personTypeSeat[prop];
                                break;
                            }
                        }
                    }
                   
                    data = data.concat( [newItem] );
                        
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );

                },
                check_object_empty: function( obj ){
                    for (const prop in obj) {
                        if (Object.hasOwn(obj, prop)) {
                            return false;
                        }
                    }

                    return true;
                },
                add_area_map: function( ticketID ){
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    var dataService     = EL_Frontend.el_ticket_manager.cart.extra_service;
                    var extra_service   = [];
                    var checkItem       = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( ticketID );
                    var dataPersonType  = JSON.parse( $(".el_seats_container").attr("data-person-type") );

                    if ( $.isArray( dataService ) && dataService.length > 0 ) {
                        extra_service = $.map( dataService, function(item){
                            item['price'] = 0;
                            item['qty'] = 0;
                            return item;
                        } );
                    }

                    // For Person Type
                    if ( EL_Frontend.el_ticket_manager.cart.check_object_empty( dataPersonType ) === false ) {
                        var data_person = [];
                        
                        for( const prop in dataPersonType ){
                            var item = {
                                id: prop,
                                name: dataPersonType[prop],
                                price: 0,
                                qty: 0,
                                extra_service: []
                            };
                            data_person = data_person.concat( [item] );
                        }

                        var newItem = {
                            id: ticketID,
                            data_person: data_person,
                        };
                        data = data.concat( [newItem] );
                    } else {

                        var newItem = {
                            id: ticketID,
                            price: 0,
                            qty: 1,
                            extra_service: [],
                        };

                        if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                            newItem['extra_service'] = [ extra_service ];
                        }

                        data = data.concat( [newItem] );
                    }
                        
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },
                update_qty_ticket: function( ticketID, currentQty ){
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();

                    var checkItem = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( ticketID );

                    if ( checkItem !== null ) {
                        var foundIndex = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                        var extra_service = checkItem['extra_service'];
                        var seats = checkItem['seat'];
                        checkItem['qty'] = currentQty;
                        seats.splice(-1);
                        checkItem['seat'] = seats;
                        if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                            extra_service.splice(-1);
                            checkItem['extra_service'] = extra_service;
                        }
                        if ( currentQty < 1 ) {
                            data.splice( foundIndex, 1 );
                        } else {
                            data[foundIndex] = checkItem;
                        }
                        
                    }
           
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },
                remove_seat_map: function( ticketID ){
                    var data        = EL_Frontend.el_ticket_manager.cart.get_data();
                    var checkItem   = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( ticketID );
                    if ( checkItem ) {
                        var foundIndex = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                        if ( foundIndex !== -1 ) {
                            data.splice(foundIndex, 1);
                        }
                    }
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },
                get_cart_item_by_ticket_id( ticketID = null ){
                    var result = null;
                    if ( ticketID === null ) {
                        return result;
                    }
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    if ( data.length > 0 && $.isArray( data ) ) {
                        for (var i = 0; i < data.length; i++) {
                            var cartItem = data[i];
                            if ( cartItem.id == ticketID ) {
                                return cartItem;
                            }
                        }
                    } else {
                        return result;
                    }
                    return result;
                },
                get_all_area: function(){
                    var data        = EL_Frontend.el_ticket_manager.cart.get_data();
                    var dataArea    = JSON.parse( $(".el_seats_container").attr("data-area") );
                    var result      = [];
                    if ( $.isArray( data ) && data.length > 0 && $.isArray( dataArea ) && dataArea.length > 0 ) {
                        for (var i = 0; i < data.length; i++) {
                            var cartItem = data[i];
                            if ( dataArea.includes( cartItem.id ) ) {
                                const data_person = cartItem['data_person'] ? cartItem['data_person'] : [];
                                var newItem = {
                                    id: cartItem.id,
                                    qty: cartItem.qty,
                                    data_person: data_person,
                                };
                                result = result.concat( [ newItem ] );
                            }
                        }
                    }
                    return result;
                },
                get_total_qty_area_person: function( ticketID ){
                    var areaInCart = EL_Frontend.el_ticket_manager.cart.get_all_area();
                    var totalQty = 0;
                    if ( $.isArray( areaInCart ) && areaInCart.length > 0 ) {
                        for (var i = 0; i < areaInCart.length; i++) {
                            var areaItem = areaInCart[i];

                            if ( areaItem.id == ticketID ) {
                                var dataPerson = areaItem.data_person;
                                if ( $.isArray( dataPerson ) && dataPerson.length > 0 ) {
                                    for (var j = 0; j < dataPerson.length; j++) {
                                        var personItem = dataPerson[j];
                                        var qty = parseInt( personItem['qty'] );
                                        if ( isNaN( qty ) ) {
                                            qty = 0;
                                        }
                                        totalQty += qty;
                                    }
                                }
                            }
                            
                        }
                    }
                    return totalQty;
                },

                get_total_qty_service_by_id: function( serviceID = null ){
                    var totalQtyService = 0;
                    if ( serviceID === null ) {
                        return 0;
                    }

                    var data = EL_Frontend.el_ticket_manager.cart.get_data();

                    if ( $.isArray( data ) && data.length > 0 ) {
                        for (var i = 0; i < data.length; i++) {
                            var cartItem = data[i];
                            var extra_service = cartItem.extra_service;
                            if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                for (var j = 0; j < extra_service.length; j++) {
                                    var serviceItems = extra_service[j];
                                    for (var k = 0; k < serviceItems.length; k++) {
                                        var serviceItem = serviceItems[k];
                                        if ( serviceItem.id == serviceID ) {
                                            totalQtyService += parseInt( serviceItem.qty );
                                        }
                                    }
                                }
                            }
                        }
                    }

                    return totalQtyService;
                },
                get_total_qty_service_by_id_seat_map: function( serviceID = null ){
                    var totalQtyService = 0;
                    if ( serviceID === null ) {
                        return 0;
                    }
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    var dataArea = JSON.parse( $(".el_seats_container").attr("data-area") );

                    if ( $.isArray( data ) && data.length > 0 ) {
                        for (var i = 0; i < data.length; i++) {
                            var cartItem = data[i];
                            
                            // For Area
                            if ( $.isArray( dataArea ) && dataArea.length && dataArea.includes( cartItem.id ) ) {
                                var data_person = cartItem.data_person ? cartItem.data_person : [];
                                // For Person Type
                                if ( $.isArray( data_person ) && data_person.length > 0 ) {

                                    for (var j = 0; j < data_person.length; j++) {
                                        var personItem = data_person[j];
                                        var serviceItems = personItem['extra_service'];
                                        if ( $.isArray( serviceItems ) && serviceItems.length > 0 ) {
                                            for (var k = 0; k < serviceItems.length; k++) {
                                                var serviceItem = serviceItems[k];
                                                if ( serviceItem.id == serviceID ) {
                                                    totalQtyService += parseInt( serviceItem.qty );
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    var extra_service = cartItem.extra_service;
                                    for (var j = 0; j < extra_service.length; j++) {
                                        var serviceItems = extra_service[j];
                                        if ( $.isArray( serviceItems ) && serviceItems.length > 0 ) {
                                            for (var k = 0; k < serviceItems.length; k++) {
                                                var serviceItem = serviceItems[k];
                                                if ( serviceItem.id == serviceID ) {
                                                    totalQtyService += parseInt( serviceItem.qty );
                                                }
                                            }
                                        }
                                        
                                    }
                                }

                            } else {
                                var extra_service = cartItem.extra_service;
                                if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                    for (var j = 0; j < extra_service.length; j++) {
                                        var serviceItem = extra_service[j];
                                        if ( serviceItem.id == serviceID ) {
                                            totalQtyService += parseInt( serviceItem.qty );
                                        }
                                    }
                                }
                            }

                            
                        }
                    }

                    return totalQtyService;
                },
                update_service_qty: function( ticketID, ticketIndex, serviceIndex, currentQty ){
                    var indexItem   = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                    var data        = EL_Frontend.el_ticket_manager.cart.get_data();
                    
                    if ( indexItem !== -1 ) {
                        data[indexItem]['extra_service'][ticketIndex][serviceIndex]['qty'] = currentQty;
                    }
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },
                update_service_qty_area_per: function( ticketID, personIndex, ticketIndex, serviceIndex, currentQty ){
                    var indexItem   = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                    var data        = EL_Frontend.el_ticket_manager.cart.get_data();
                    if ( indexItem !== -1 ) {
                        data[indexItem]['data_person'][personIndex]['extra_service'][ticketIndex][serviceIndex]['qty'] = currentQty;
                    }
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },

                set_data_extra_service: function(){
                    var dataService = $(".el_extra_services").attr("data-services");
                    var $service_arr = [];
                    if ( dataService ) {
                        $service_arr = JSON.parse( dataService );
                    }
                    EL_Frontend.el_ticket_manager.cart.extra_service = $service_arr;
                },
                update_seat: function( ticketID, seat, indexSeat ){
                    var indexCart   = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                    var data        = EL_Frontend.el_ticket_manager.cart.get_data();

                    if ( indexCart !== -1 ) {
                        data[indexCart]['seat'][indexSeat] = seat;
                    }
                    
                    EL_Frontend.el_ticket_manager.cart.set_storage_data( data );
                },
                get_seats_by_ticket_id: function( ticketID ){
                    var indexCart   = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                    var data        = EL_Frontend.el_ticket_manager.cart.get_data();
                    var seats       = [];
                    if ( indexCart !== -1 ) {
                        seats = data[indexCart]['seat'];
                    }
                    seats = seats.filter( (seat) => seat !== "" && seat !== null );
                    return seats;
                },
                get_seat_by_index_and_ticket_id: function( indexSeat, ticketID ){
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();
                    var seat = "";
                    var cartIndex = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );

                    if ( indexSeat && cartIndex !== -1 ) {
                        seat = data[cartIndex]['seat'][indexSeat];
                    }

                    return seat;
                },
                check_seat_valid: function(){
                    var $flag = true;
                    var data = EL_Frontend.el_ticket_manager.cart.get_data();

                    if ( $.isArray(data) && data.length > 0 ) {
                        for (var i = 0; i < data.length; i++) {
                            var cartItem    = data[i];
                            var seats       = cartItem['seat'];
                            var ticketID    = cartItem['id'];
                            var ticketItem  = EL_Frontend.el_ticket_manager.get_data_ticket_item( ticketID );

                            if ( ticketItem !== null && ticketItem.setup_seat === "yes" ) {
                                var checkSeats = seats.every( function(currentValue){
                                    return currentValue !== null && currentValue !== "";
                                } );
                                var check_duplicate = arr => arr.filter((item, index) => arr.indexOf(item) !== index);

                                if ( checkSeats === false || check_duplicate( seats ).length > 0 ) {
                                    $flag = false;
                                    break;
                                }
                            }
                        }

                    }

                    return $flag;
                },
                check_area_data_person: function(){
                    var areaInCart = EL_Frontend.el_ticket_manager.cart.get_all_area();
                    var $result = true;
                    if ( $.isArray( areaInCart ) && areaInCart.length > 0 ) {
                        for (var i = 0; i < areaInCart.length; i++) {
                            var cartItem = areaInCart[i];
                            var data_person = cartItem['data_person'] ? cartItem['data_person'] : [];
                            var totalQty = 0;
                            if ( $.isArray( data_person ) && data_person.length > 0 ) {
                                for (var j = 0; j < data_person.length; j++) {
                                    var personItem = data_person[j];
                                    var qty = parseInt( personItem['qty'] );
                                    if ( isNaN( qty ) ) {
                                        qty = 0;
                                    }
                                    totalQty += qty;
                                }
                                if ( totalQty < 1 ) {
                                    $result = false;
                                    break;
                                }
                            }
                            
                        }
                    }

                    return $result;
                },
                get_all_seat: function(){
                    var data        = EL_Frontend.el_ticket_manager.cart.get_data();
                    var dataSeat    = JSON.parse( $(".el_seats_container").attr("data-seat") );
                    var $result     = [];
                    if ( $.isArray( data ) && data.length > 0 && $.isArray( dataSeat ) && dataSeat.length > 0 ) {
                        for (var i = 0; i < data.length; i++) {
                            var cartItem = data[i];
                            if ( dataSeat.includes( cartItem.id ) ) {
                                $result.push( cartItem.id );
                            }
                        }
                    }

                    return $result;
                },
            },
            get_data_person_type: function(){
                var dataPersonType = JSON.parse( $(".el_seats_container").attr("data-person-type") );
                return dataPersonType;
            },
            get_data_tickets: function(){
                var dataTickets = JSON.parse( $(".el_tickets_container").attr("data-tickets") );
                return dataTickets;
            },
            get_seat_option:function(){
                var seat_option = $(".el_calendar_event_content").attr("data-seat-option");
                return seat_option;
            },
            get_data_ticket_item: function( ticketID ){
                var dataTickets = EL_Frontend.el_ticket_manager.get_data_tickets();
                var result = null;
                if ( $.isArray( dataTickets ) && dataTickets.length > 0 ) {
                    for (var i = 0; i < dataTickets.length; i++) {
                        var ticketItem = dataTickets[i];
                        if ( ticketItem.ticket_id == ticketID ) {
                            return ticketItem;
                        }
                    }
                }
                return result;
            },
            change_qty_service: function(){
                $(".el_sv_btn_plus").off().on("click",function(e){
                    e.preventDefault();

                    var $this           = $(this);
                    var $wrapper        = $this.closest(".service_item");
                    var $service_items  = $wrapper.closest(".service_items");
                    var $service_group  = $service_items.closest(".service_group");
                    var ticketID        = $service_group.attr("data-ticket-id");
                    var ticketIndex     = parseInt( $service_items.attr("data-ticket-index") );
                    var serviceIndex    = parseInt( $wrapper.attr("data-service-index") );
                    var maxQty          = parseInt( $wrapper.find(".el_service_qty").attr("data-max-qty") );
                    var restQtyService  = parseInt( $wrapper.find(".el_service_qty").attr("data-rest-qty") );
                    var serviceID       = $wrapper.find('input[name="service_id"]').val();
                    var totalQtyService = EL_Frontend.el_ticket_manager.cart.get_total_qty_service_by_id( serviceID );
                    var restQty         = restQtyService - totalQtyService;
                    var currentQty      = parseInt( $wrapper.find('input[name="quantity"]').val() );

                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }
                    currentQty += 1;

                    if ( maxQty - restQty > 0 ) {
                        maxQty = restQty;
                    }

                    if ( currentQty <= maxQty ) {
                        $wrapper.find('input[name="quantity"]').val( currentQty );
                        EL_Frontend.el_ticket_manager.cart.update_service_qty( ticketID, ticketIndex, serviceIndex, currentQty );
                    }

                });

                $(".el_sv_btn_minus").off().on("click",function(e){
                    e.preventDefault();
                    var $this           = $(this);
                    var $wrapper        = $this.closest(".service_item");
                    var $service_items  = $wrapper.closest(".service_items");
                    var $service_group  = $service_items.closest(".service_group");
                    var ticketID        = $service_group.attr("data-ticket-id");
                    var ticketIndex     = parseInt( $service_items.attr("data-ticket-index") );
                    var serviceIndex    = parseInt( $wrapper.attr("data-service-index") );
                    var maxQty          = parseInt( $wrapper.find(".el_service_qty").attr("data-max-qty") );
                    var restQtyService  = parseInt( $wrapper.find(".el_service_qty").attr("data-rest-qty") );
                    var serviceID       = $wrapper.find('input[name="service_id"]').val();
                    var totalQtyService = EL_Frontend.el_ticket_manager.cart.get_total_qty_service_by_id( serviceID );
                    var restQty         = restQtyService - totalQtyService;
                    var currentQty      = parseInt( $wrapper.find('input[name="quantity"]').val() );

                    
                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }

                    currentQty -= 1;
                    if ( currentQty >= 0 ) {
                        $wrapper.find('input[name="quantity"]').val( currentQty );
                        EL_Frontend.el_ticket_manager.cart.update_service_qty( ticketID, ticketIndex, serviceIndex, currentQty );
                    }

                });
            },
            change_qty_service_map: function(){
  
                $(".el_sv_area_btn_plus").off().on("click",function(e){
                    e.preventDefault();

                    var $this           = $(this);
                    var $wrapper        = $this.closest(".service_item");
                    var $service_items  = $wrapper.closest(".service_items");
                    var $service_group  = $service_items.closest(".service_group");
                    var ticketID        = $service_group.attr("data-ticket-id");
                    var ticketIndex     = parseInt( $service_items.attr("data-ticket-index") );
                    var serviceIndex    = parseInt( $wrapper.attr("data-service-index") );
                    var maxQty          = parseInt( $wrapper.find(".el_service_qty").attr("data-max-qty") );
                    var restQtyService  = parseInt( $wrapper.find(".el_service_qty").attr("data-rest-qty") );
                    var serviceID       = $wrapper.find('input[name="service_id"]').val();
                    var totalQtyService = EL_Frontend.el_ticket_manager.cart.get_total_qty_service_by_id( serviceID );
                    var restQty         = restQtyService - totalQtyService;
                    var currentQty      = parseInt( $wrapper.find('input[name="quantity"]').val() );

                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }
                    currentQty += 1;

                    if ( maxQty - restQty > 0 ) {
                        maxQty = restQty;
                    }

                    if ( currentQty <= maxQty ) {
                        $wrapper.find('input[name="quantity"]').val( currentQty );
                        EL_Frontend.el_ticket_manager.cart.update_service_qty( ticketID, ticketIndex, serviceIndex, currentQty );
                    }

                });

                $(".el_sv_area_btn_minus").off().on("click",function(e){
                    e.preventDefault();
                    var $this           = $(this);
                    var $wrapper        = $this.closest(".service_item");
                    var $service_items  = $wrapper.closest(".service_items");
                    var $service_group  = $service_items.closest(".service_group");
                    var ticketID        = $service_group.attr("data-ticket-id");
                    var ticketIndex     = parseInt( $service_items.attr("data-ticket-index") );
                    var serviceIndex    = parseInt( $wrapper.attr("data-service-index") );
                    var maxQty          = parseInt( $wrapper.find(".el_service_qty").attr("data-max-qty") );
                    var restQtyService  = parseInt( $wrapper.find(".el_service_qty").attr("data-rest-qty") );
                    var serviceID       = $wrapper.find('input[name="service_id"]').val();
                    var totalQtyService = EL_Frontend.el_ticket_manager.cart.get_total_qty_service_by_id( serviceID );
                    var restQty         = restQtyService - totalQtyService;
                    var currentQty      = parseInt( $wrapper.find('input[name="quantity"]').val() );

                    
                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }

                    currentQty -= 1;
                    if ( currentQty >= 0 ) {
                        $wrapper.find('input[name="quantity"]').val( currentQty );
                        EL_Frontend.el_ticket_manager.cart.update_service_qty( ticketID, ticketIndex, serviceIndex, currentQty );
                    }

                });

                $(".el_sv_area_per_btn_plus").off().on("click",function(e){
                    e.preventDefault();
                    var $this           = $(this);
                    var $wrapper        = $this.closest(".service_item");
                    var $service_group  = $wrapper.closest(".service_group");
                    var $service_items  = $wrapper.closest(".service_items");
                    var ticketID        = $service_group.attr("data-ticket-id");
                    var personIndex     = parseInt( $service_group.attr("data-person-index") );
                    var ticketIndex     = parseInt( $service_items.attr("data-ticket-index") );
                    var serviceIndex    = parseInt( $wrapper.attr("data-service-index") );
                    var maxQty          = parseInt( $wrapper.find(".el_service_qty").attr("data-max-qty") );
                    var restQtyService  = parseInt( $wrapper.find(".el_service_qty").attr("data-rest-qty") );
                    var serviceID       = $wrapper.find('input[name="service_id"]').val();
                    var totalQtyService = EL_Frontend.el_ticket_manager.cart.get_total_qty_service_by_id_seat_map( serviceID );
                    var restQty         = restQtyService - totalQtyService;
                    var currentQty      = parseInt( $wrapper.find('input[name="quantity"]').val() );

                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }
                    currentQty += 1;

                    if ( maxQty - restQty > 0 ) {
                        maxQty = restQty;
                    }

                    if ( currentQty <= maxQty ) {
                        $wrapper.find('input[name="quantity"]').val( currentQty );
                        EL_Frontend.el_ticket_manager.cart.update_service_qty_area_per( ticketID, personIndex, ticketIndex, serviceIndex, currentQty );
                    }
                });

                $(".el_sv_area_per_btn_minus").off().on("click",function(e){
                    e.preventDefault();
                    var $this           = $(this);
                    var $wrapper        = $this.closest(".service_item");
                    var $service_group  = $wrapper.closest(".service_group");
                    var $service_items  = $wrapper.closest(".service_items");
                    var ticketID        = $service_group.attr("data-ticket-id");
                    var personIndex     = parseInt( $service_group.attr("data-person-index") );
                    var ticketIndex     = parseInt( $service_items.attr("data-ticket-index") );
                    var serviceIndex    = parseInt( $wrapper.attr("data-service-index") );
                    var currentQty      = parseInt( $wrapper.find('input[name="quantity"]').val() );

                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }

                    currentQty -= 1;
                    if ( currentQty >= 0 ) {
                        $wrapper.find('input[name="quantity"]').val( currentQty );
                        EL_Frontend.el_ticket_manager.cart.update_service_qty_area_per( ticketID, personIndex, ticketIndex, serviceIndex, currentQty );
                    }

                });

            },
            show_areas: function(){
                var data            = EL_Frontend.el_ticket_manager.cart.get_data();
                var areaInCart      = EL_Frontend.el_ticket_manager.cart.get_all_area();
                var dataAreaRest    = JSON.parse( $(".el_seats_container").attr("data-area-rest") );
                var dataPersonType  = EL_Frontend.el_ticket_manager.get_data_person_type();
                var $html = '';
                $(".el_show_area").html( "" );
                // For Person Type
                if ( EL_Frontend.el_ticket_manager.cart.check_object_empty( dataPersonType ) === false ) {
                    if ( $.isArray( areaInCart ) && areaInCart.length > 0 ) {
                        for (var i = 0; i < areaInCart.length; i++) {
                            var areaItem = areaInCart[i];
                            var data_person = areaItem.data_person;
                            $html += '<div class="area_item">';
                                $html += '<div class="area_info">';
                                    $html += '<span class="area_name">'+areaItem.id+'</span>';
                                    $html += '<input type="hidden" name="area_id" value="'+areaItem.id+'" />';
                                $html += '</div>';
                                $html += '<div class="person_type">';
                                for (var j = 0; j < data_person.length; j++) {
                                    var personItem = data_person[j];
                                    $html += '<div class="person_item" data-person-index="'+j+'">';
                                        $html += '<div class="person_name">'+personItem.name+'</div>';
                                        $html += '<div class="el_qty_area" data-max-qty="'+dataAreaRest[areaItem.id]+'">';
                                            $html += '<a href="#" class="el_btn_qty el_btn_area_minus"><i class="fa fa-minus" aria-hidden="true"></i></a>';
                                            $html += '<input type="text" readonly class="form-control" min="1" name="quantity" value="'+personItem.qty+'" placeholder="">';
                                            $html += '<a href="#" class="el_btn_qty el_btn_area_plus"><i class="fa fa-plus" aria-hidden="true"></i></a>';
                                        $html += '</div>';
                                    $html += '</div>';
                                }
                                $html += '</div>';
                            $html += '</div>';
                        }
                    }
                } else {

                    if ( $.isArray( areaInCart ) && areaInCart.length > 0 ) {
                    
                        for (var i = 0; i < areaInCart.length; i++) {
                            var areaItem = areaInCart[i];
                            $html += '<div class="area_item">';
                                $html += '<div class="area_info">';
                                    $html += '<span class="area_name">'+areaItem.id+'</span>';
                                    $html += '<input type="hidden" name="area_id" value="'+areaItem.id+'" />';
                                $html += '</div>';
                                $html += '<div class="el_qty_area" data-max-qty="'+dataAreaRest[areaItem.id]+'">';
                                    $html += '<a href="#" class="el_btn_qty el_btn_minus"><i class="fa fa-minus" aria-hidden="true"></i></a>';
                                    $html += '<input type="text" readonly class="form-control" min="1" name="quantity" value="'+areaItem.qty+'" placeholder="">';
                                    $html += '<a href="#" class="el_btn_qty el_btn_plus"><i class="fa fa-plus" aria-hidden="true"></i></a>';
                                $html += '</div>';
                            $html += '</div>';
                        }

                    }
                }
                
                $(".el_show_area").html( $html );
            },
            show_seats: function( currentQty, ticketItem ){
                var ticketID = ticketItem.find('input[name="ticket_id"]').val();
                var indexCart = EL_Frontend.el_ticket_manager.cart.get_index_cart_item_by_ticket_id( ticketID );
                var listSeatRest = JSON.parse( ticketItem.find('input[name="list_seat_rest"]').val() );
                var data = EL_Frontend.el_ticket_manager.cart.get_data();
                var seats = $.isArray( data ) && data.length > 0 && data[indexCart] && data[indexCart]['seat'] ? data[indexCart]['seat'] : [];
                var ticketSeatLabel = ticketItem.find(".ticket_seats").attr("data-placeholder");
                var $html = '';

                ticketItem.find(".ticket_seats").html("");
                var seatsSelected = EL_Frontend.el_ticket_manager.cart.get_seats_by_ticket_id( ticketID );

                if ( $.isArray( seats ) && seats.length > 0 ) {
                    for (var i = 0; i < seats.length; i++) {
                        var seatVal = seats[i];
                        $html += '<div class="seat_item" data-index="'+i+'">';
                            $html += '<select name="ticket_seat" style="width: 100%">';
                            $html += '<option value="">'+ticketSeatLabel+'</option>';
                            if ( $.isArray( listSeatRest ) && listSeatRest.length > 0 ) {
                                for (var j = 0; j < listSeatRest.length; j++) {
                                    var seat = listSeatRest[j];
                                    var seatSelected = seatVal === seat ? 'selected' : '';
                                    var seatDisabled = seatsSelected.includes( seat ) ? 'disabled' : '';
                                    $html += '<option value="'+seat+'" '+seatSelected+' '+seatDisabled+'>'+seat+'</option>';
                                }
                            }
                            $html += '</select>';
                        $html += '</div>';
                    }
                }

                ticketItem.find(".ticket_seats").html( $html );

                var isRtl = $("body").hasClass("rtl") ? "rtl": "ltr";

                var seatSelect = ticketItem.find('.ticket_seats select').select2({
                    placeholder: ticketSeatLabel,
                    allowClear: true,
                    dir: isRtl,
                }).on("change",function(){

                    var $this       = $(this);
                    var seatItem    = $this.closest(".seat_item");
                    var ticketItem  = $this.closest(".ticket_item");
                    var ticketID    = ticketItem.find('input[name="ticket_id"]').val();
                    var indexSeat   = seatItem.attr("data-index");
                    var seat        = $this.val();

                    EL_Frontend.el_ticket_manager.cart.update_seat( ticketID, seat, indexSeat );
                });

            },
            show_seat_map: function(){

            },
            select_seat_map: function(){

                $('.el_seats input[name="seat"]').on("change",function(){
                    var $this = $(this);
                    var ticketID = $this.val();
                    const personTypeSeat = EL_Frontend.el_ticket_manager.cart.get_person_type_seat();

                    if (this.checked) {
                        EL_Frontend.el_ticket_manager.cart.add_seat_map( ticketID );
                    } else {
                        EL_Frontend.el_ticket_manager.cart.remove_seat_map( ticketID );
                    }
                    EL_Frontend.el_ticket_manager.show_extra_service_seat_map();
                    const seatInCart = EL_Frontend.el_ticket_manager.cart.get_all_seat();
                    $(".el_show_seat").html("");

                    // Show seat item html
                    let html = '';
                    if ( $.isArray( seatInCart ) && seatInCart.length > 0 ) {
                        for (var i = 0; i < seatInCart.length; i++) {
                            const seat = seatInCart[i];
                            const cartItem = EL_Frontend.el_ticket_manager.cart.get_cart_item_by_ticket_id( seat );
                            html += '<div class="seat_item seat-'+seat+'" data-id="'+seat+'">';
                            html += '<div class="seat_info">';
                            html += '<span class="seat_name">'+seat+'</span>';
                            html += '</div>';
                            if ( ! $.isEmptyObject( personTypeSeat ) && ! $.isEmptyObject( cartItem ) ) {
                                html += '<div class="person_type">';
                                html += '<select class="select_person_type" width="100%">';
                                let j = 0;

                                const currentPersonType = cartItem['person_type'];
                                for( const prop in personTypeSeat ){
                                    let $selected = ( currentPersonType == personTypeSeat[prop] ) ? 'selected="selected"' : '';
                                    let $value = personTypeSeat[prop];
                                    html += '<option value="'+$value+'" '+$selected+'>'+$value+'</option>';
                                }
                                html += '</select>';
                                html += '</div>';
                            }
                            
                            html +='</div>';
                        }
                    }

                    $(".el_show_seat").append(html);
                    // init select2
                    $(".select_person_type").select2();
                    EL_Frontend.el_ticket_manager.change_person_type_seat();
                });


                $('.el_areas input[name="area"]').on("change",function(){
                    var $this = $(this);
                    var ticketID = $this.val();
                    if ( this.checked ) {
                        EL_Frontend.el_ticket_manager.cart.add_area_map( ticketID );
                    } else {
                        EL_Frontend.el_ticket_manager.cart.remove_seat_map( ticketID );
                    }
                    EL_Frontend.el_ticket_manager.show_areas();
                    EL_Frontend.el_ticket_manager.change_qty_area();
                    EL_Frontend.el_ticket_manager.show_extra_service_seat_map();
                });

            },
            change_person_type_seat: function(){
                $(".select_person_type").on("change",function(){
                    const personTypeSeleted = $(this).val();
                    const seatItem  = $(this).closest(".seat_item");
                    const seat      = seatItem.attr("data-id");
                    // seat
                    // person type
                    EL_Frontend.el_ticket_manager.cart.update_person_type_seat( seat, personTypeSeleted );
                });
            },
            change_qty_area: function(){
                $(".el_qty_area .el_btn_minus").off().on("click",function(e){
                    e.preventDefault();
                    var $this       = $(this);
                    var $wrapper    = $this.closest(".el_qty_area");
                    var currentQty  = parseInt( $wrapper.find('input[name="quantity"]').val() );
                    var areaItem    = $this.closest(".area_item");
                    var ticketID    = areaItem.find('input[name="area_id"]').val();

                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }
                    currentQty -= 1;
                    if ( currentQty < 0 ) {
                        currentQty = 0;
                    }
                    // set quantity
                    $wrapper.find('input[name="quantity"]').val( currentQty );

                    EL_Frontend.el_ticket_manager.cart.remove_qty_area( ticketID, currentQty );
                    EL_Frontend.el_ticket_manager.show_areas();
                    EL_Frontend.el_ticket_manager.change_qty_area();
                    EL_Frontend.el_ticket_manager.show_extra_service_seat_map();
                });


                $(".el_qty_area .el_btn_plus").off().on("click", function(e){
                    e.preventDefault();
                    var $this       = $(this);
                    var $wrapper    = $this.closest(".el_qty_area");
                    var currentQty  = parseInt( $wrapper.find('input[name="quantity"]').val() );
                    var maxQty      = parseInt( $wrapper.attr("data-max-qty") );
                    var areaItem    = $this.closest(".area_item");
                    var ticketID    = areaItem.find('input[name="area_id"]').val();

                    if ( isNaN( maxQty ) ) {
                        maxQty = 0;
                    }

                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }
                    currentQty += 1;

                    if ( currentQty <= maxQty ) {
                        $wrapper.find('input[name="quantity"]').val( currentQty );
                        // add to cart
                        EL_Frontend.el_ticket_manager.cart.add_qty_area( ticketID, currentQty );
                        EL_Frontend.el_ticket_manager.show_extra_service_seat_map();
                    }
                });


                $(".el_qty_area .el_btn_area_minus").off().on("click",function(e){
                    e.preventDefault();
                    var $this       = $(this);
                    var $wrapper    = $this.closest(".el_qty_area");
                    var currentQty  = parseInt( $wrapper.find('input[name="quantity"]').val() );
                    var areaItem    = $this.closest(".area_item");
                    var ticketID    = areaItem.find('input[name="area_id"]').val();
                    var personItem  = $this.closest(".person_item");
                    var personIndex = parseInt( personItem.attr("data-person-index") );

                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }
                    currentQty -= 1;
                    if ( currentQty < 0 ) {
                        currentQty = 0;
                    }
                    // set quantity
                    $wrapper.find('input[name="quantity"]').val( currentQty );
                    EL_Frontend.el_ticket_manager.cart.remove_qty_area_person( ticketID, personIndex, currentQty );
                    EL_Frontend.el_ticket_manager.show_areas();
                    EL_Frontend.el_ticket_manager.change_qty_area();
                    EL_Frontend.el_ticket_manager.show_extra_service_seat_map();
                });

                $(".el_qty_area .el_btn_area_plus").off().on("click",function(e){
                    e.preventDefault();
                    var $this = $(this);
                    var $wrapper    = $this.closest(".el_qty_area");
                    var currentQty  = parseInt( $wrapper.find('input[name="quantity"]').val() );
                    var maxQty      = parseInt( $wrapper.attr("data-max-qty") );
                    var areaItem    = $this.closest(".area_item");
                    var ticketID    = areaItem.find('input[name="area_id"]').val();
                    var personItem  = $this.closest(".person_item");
                    var personIndex = parseInt( personItem.attr("data-person-index") );
                    var totalQty    = EL_Frontend.el_ticket_manager.cart.get_total_qty_area_person( ticketID );
                    if ( isNaN( maxQty ) ) {
                        maxQty = 0;
                    }
                    var restQty = maxQty - ( totalQty + 1 );

                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }
                    currentQty += 1;
                    
                    if ( restQty >= 0 ) {
                        $wrapper.find('input[name="quantity"]').val( currentQty );
                        EL_Frontend.el_ticket_manager.cart.add_qty_area_person( ticketID, personIndex, currentQty );
                        EL_Frontend.el_ticket_manager.show_extra_service_seat_map();
                    }

                });

            },
            show_extra_service: function(){
         
                var cartData    = EL_Frontend.el_ticket_manager.cart.get_data();
                var ticketLabel = $(".el_extra_services").attr("data-ticket");

                $(".el_extra_services").html("");

                if ( $.isArray( cartData ) && cartData.length > 0 ) {
                    for (var i = 0; i < cartData.length; i++) {
                        var cartItem        = cartData[i];
                        var ticketName      = cartItem.name;
                        var extra_service   = cartItem.extra_service;
                        var $html = '';
                        
                        if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                            $html += '<div class="service_group" data-ticket-id="'+cartItem.id+'">';
                                $html += '<span class="ticket_name">'+ticketName+'</span>';
                                for (var j = 0; j < extra_service.length; j++) {
                                    var serviceItems = extra_service[j];
                                    var ticketNum = j + 1;
                                    $html += '<div class="service_items" data-ticket-index="'+j+'">';
                                        $html += '<span class="ticket_mark">'+ticketLabel+ticketNum+'</span>';
                                        for (var k = 0; k < serviceItems.length; k++) {
                                            var serviceItem = serviceItems[k];
                                            $html += '<div class="service_item" data-service-index="'+k+'">';

                                                $html += '<input type="hidden" name="service_name" value="'+serviceItem.name+'" />';
                                                $html += '<input type="hidden" name="service_id" value="'+serviceItem.id+'" />';

                                                $html += '<div class="content">';
                                                    $html += '<div class="service_name">'+ serviceItem.name+'</div>';

                                                    $html += '<div class="el_service_qty" data-max-qty="'+serviceItem.max_qty+'" data-rest-qty="'+serviceItem.rest_qty+'">';

                                                    $html += '<a href="#" class="el_btn_qty el_sv_btn_minus">';
                                                        $html += '<i class="fa fa-minus" aria-hidden="true"></i>';
                                                    $html += '</a>';

                                                    $html += '<input type="text" readonly class="form-control" min="0" name="quantity" value="'+serviceItem.qty+'" placeholder="">';

                                                    $html += '<a href="#" class="el_btn_qty el_sv_btn_plus">';
                                                        $html += '<i class="fa fa-plus" aria-hidden="true"></i>';
                                                    $html += '</a>';

                                                    $html += '</div>';

                                                $html += '</div>';
                                            $html += '</div>';
                                        }
                                        
                                    $html += '</div>';
                                }
                            $html += '</div>';
                            $(".el_extra_services").append( $html );
                        }
                    }
                }

                EL_Frontend.el_ticket_manager.change_qty_service();
            },

            show_extra_service_seat_map: function(){
                var cartData    = EL_Frontend.el_ticket_manager.cart.get_data();
                var ticketLabel = $(".el_extra_services").attr("data-ticket");
                var dataArea    = JSON.parse( $(".el_seats_container").attr("data-area") );
                var $html = '';
                $(".el_extra_services").html("");

                if ( $.isArray( cartData ) && cartData.length > 0 ) {
                    for (var i = 0; i < cartData.length; i++) {
                        var cartItem        = cartData[i];
                        var extra_service   = cartItem.extra_service;
                        var data_person     = cartItem.data_person ? cartItem.data_person : [];
                        
                        if ( dataArea.includes( cartItem.id ) ) {
                            // For Person Type
                            if ( $.isArray( data_person ) && data_person.length > 0 ) {
                                for (var m = 0; m < data_person.length; m++) {
                                    var personItem = data_person[m];
                                    var extra_service = personItem['extra_service'];

                                    if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                        $html += '<div class="service_group" data-ticket-id="'+cartItem.id+'" data-person-index="'+m+'">';
                                       
                                            for (var j = 0; j < extra_service.length; j++) {
                                                var serviceItems = extra_service[j];
                                                var ticketNum = j + 1;
                                                $html += '<span class="ticket_name">'+cartItem.id+' - '+personItem.name+'</span>';
                                                $html += '<div class="service_items" data-ticket-index="'+j+'">';
                                                    $html += '<span class="ticket_mark">'+ticketLabel+ticketNum+'</span>';
                                                    for (var k = 0; k < serviceItems.length; k++) {
                                                        var serviceItem = serviceItems[k];
                                                        $html += '<div class="service_item" data-service-index="'+k+'">';

                                                            $html += '<input type="hidden" name="service_name" value="'+serviceItem.name+'" />';
                                                            $html += '<input type="hidden" name="service_id" value="'+serviceItem.id+'" />';

                                                            $html += '<div class="content">';
                                                                $html += '<div class="service_name">'+ serviceItem.name+'</div>';

                                                                $html += '<div class="el_service_qty" data-max-qty="'+serviceItem.max_qty+'" data-rest-qty="'+serviceItem.rest_qty+'">';

                                                                $html += '<a href="#" class="el_btn_qty el_sv_area_per_btn_minus">';
                                                                    $html += '<i class="fa fa-minus" aria-hidden="true"></i>';
                                                                $html += '</a>';

                                                                $html += '<input type="text" readonly class="form-control" min="0" name="quantity" value="'+serviceItem.qty+'" placeholder="">';

                                                                $html += '<a href="#" class="el_btn_qty el_sv_area_per_btn_plus">';
                                                                    $html += '<i class="fa fa-plus" aria-hidden="true"></i>';
                                                                $html += '</a>';

                                                                $html += '</div>';

                                                            $html += '</div>';
                                                        $html += '</div>';
                                                    }
                                                    
                                                $html += '</div>';
                                            }
                                        $html += '</div>';
                                    }
                                }
                            } else {
                                if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                    $html += '<div class="service_group" data-ticket-id="'+cartItem.id+'">';
                                    $html += '<span class="ticket_name">'+cartItem.id+'</span>';
                                        for (var j = 0; j < extra_service.length; j++) {
                                            var serviceItems = extra_service[j];
                                            var ticketNum = j + 1;
                                            $html += '<div class="service_items" data-ticket-index="'+j+'">';
                                                $html += '<span class="ticket_mark">'+ticketLabel+ticketNum+'</span>';
                                                for (var k = 0; k < serviceItems.length; k++) {
                                                    var serviceItem = serviceItems[k];
                                                    $html += '<div class="service_item" data-service-index="'+k+'">';

                                                        $html += '<input type="hidden" name="service_name" value="'+serviceItem.name+'" />';
                                                        $html += '<input type="hidden" name="service_id" value="'+serviceItem.id+'" />';

                                                        $html += '<div class="content">';
                                                            $html += '<div class="service_name">'+ serviceItem.name+'</div>';

                                                            $html += '<div class="el_service_qty" data-max-qty="'+serviceItem.max_qty+'" data-rest-qty="'+serviceItem.rest_qty+'">';

                                                            $html += '<a href="#" class="el_btn_qty el_sv_area_btn_minus">';
                                                                $html += '<i class="fa fa-minus" aria-hidden="true"></i>';
                                                            $html += '</a>';

                                                            $html += '<input type="text" readonly class="form-control" min="0" name="quantity" value="'+serviceItem.qty+'" placeholder="">';

                                                            $html += '<a href="#" class="el_btn_qty el_sv_area_btn_plus">';
                                                                $html += '<i class="fa fa-plus" aria-hidden="true"></i>';
                                                            $html += '</a>';

                                                            $html += '</div>';

                                                        $html += '</div>';
                                                    $html += '</div>';
                                                }
                                                
                                            $html += '</div>';
                                        }
                                    $html += '</div>';
                                    
                                }
                            }
                            
                        } else {
                            if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                $html += '<div class="service_group" data-ticket-id="'+cartItem.id+'">';
                                $html += '<span class="ticket_name">'+cartItem.id+'</span>';
                                    for (var j = 0; j < extra_service.length; j++) {
                                        var serviceItems = extra_service[j];
                                        var ticketNum = j + 1;
                                        $html += '<div class="service_items" data-ticket-index="'+j+'">';
                                            for (var k = 0; k < serviceItems.length; k++) {
                                                var serviceItem = serviceItems[k];
                                                $html += '<div class="service_item" data-service-index="'+k+'">';

                                                    $html += '<input type="hidden" name="service_name" value="'+serviceItem.name+'" />';
                                                    $html += '<input type="hidden" name="service_id" value="'+serviceItem.id+'" />';

                                                    $html += '<div class="content">';
                                                        $html += '<div class="service_name">'+ serviceItem.name+'</div>';

                                                        $html += '<div class="el_service_qty" data-max-qty="'+serviceItem.max_qty+'" data-rest-qty="'+serviceItem.rest_qty+'">';

                                                        $html += '<a href="#" class="el_btn_qty el_sv_area_btn_minus">';
                                                            $html += '<i class="fa fa-minus" aria-hidden="true"></i>';
                                                        $html += '</a>';

                                                        $html += '<input type="text" readonly class="form-control" min="0" name="quantity" value="'+serviceItem.qty+'" placeholder="">';

                                                        $html += '<a href="#" class="el_btn_qty el_sv_area_btn_plus">';
                                                            $html += '<i class="fa fa-plus" aria-hidden="true"></i>';
                                                        $html += '</a>';

                                                        $html += '</div>';

                                                    $html += '</div>';
                                                $html += '</div>';
                                            }
                                            
                                        $html += '</div>';
                                    }
                                $html += '</div>';
                                
                            }
                        }
                    }
                }
                $(".el_extra_services").append( $html );

                EL_Frontend.el_ticket_manager.change_qty_service_map();
            },

            change_qty_tickets: function(){

                $(".el_qty_ticket .el_btn_minus").off().on("click",function(e){
                    e.preventDefault();

                    var $this       = $(this);
                    var $wrapper    = $this.closest(".el_qty_ticket");
                    var currentQty  = parseInt( $wrapper.find('input[name="quantity"]').val() );
                    var ticketItem  = $this.closest(".ticket_item");
                    var ticketID    = ticketItem.find('input[name="ticket_id"]').val();
                    var ticketName  = ticketItem.find('input[name="ticket_name"]').val();
                    var setupSeat   = $wrapper.attr("data-setup-seat");

                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }
                    currentQty -= 1;
                    if ( currentQty < 0 ) {
                        currentQty = 0;
                    }
                    // set quantity
                    $wrapper.find('input[name="quantity"]').val( currentQty );

                    EL_Frontend.el_ticket_manager.cart.update_qty_ticket( ticketID, currentQty );
                    if ( setupSeat === 'yes' ) {
                        EL_Frontend.el_ticket_manager.show_seats( currentQty, ticketItem );
                    }
                    EL_Frontend.el_ticket_manager.show_extra_service();
                });


                $(".el_qty_ticket .el_btn_plus").off().on("click",function(e){
                    e.preventDefault();

                    var $this       = $(this);
                    var $wrapper    = $this.closest(".el_qty_ticket");
                    var currentQty  = parseInt( $wrapper.find('input[name="quantity"]').val() );
                    var maxQty      = parseInt( $wrapper.attr("data-max-qty") );
                    var ticketItem  = $this.closest(".ticket_item");

                    var ticketID    = ticketItem.find('input[name="ticket_id"]').val();
                    var ticketName  = ticketItem.find('input[name="ticket_name"]').val();
                    var setupSeat   = $wrapper.attr("data-setup-seat");

                    
                    if ( isNaN( maxQty ) ) {
                        maxQty = 0;
                    }

                    if ( isNaN( currentQty ) ) {
                        currentQty = 0;
                    }
                    currentQty += 1;

                    if ( currentQty <= maxQty ) {
                        $wrapper.find('input[name="quantity"]').val( currentQty );
                        // add to cart
                        EL_Frontend.el_ticket_manager.cart.add_to_cart( ticketID, ticketName, currentQty );
                        if ( setupSeat === 'yes' ) {
                            EL_Frontend.el_ticket_manager.show_seats( currentQty, ticketItem );
                        }
                        EL_Frontend.el_ticket_manager.show_extra_service();
                    }

                });
            },

            save_tickets: function(){
                $("#el_create_tickets_modal #el_create_tickets_form").off().on("submit",function(e){
                    e.preventDefault();
                });

                $("#el_create_tickets_modal .el_save_tickets").off().on("click",function(e){
                    e.preventDefault();

                    var $wrapper = $("#el_create_tickets_form");
                    var $this       = $(this);
                    var nonce       = $wrapper.attr("data-nonce");
                    var eventID     = $wrapper.find("#el_create_tickets_select_event").val();
                    var calendarID  = $wrapper.find("#el_create_tickets_calendar_event").val();
                    var seatOption  = EL_Frontend.el_ticket_manager.get_seat_option();
                    var cart        = EL_Frontend.el_ticket_manager.cart.get_data();
                    var $flag       = true;
                    var totalQty    = EL_Frontend.el_ticket_manager.cart.get_total_qty_ticket();

                    var customerName    = $wrapper.find("#el_create_tickets_name").val();
                    var phoneNumber     = $wrapper.find("#el_create_tickets_phone").val();
                    var email           = $wrapper.find("#el_create_tickets_email").val();
                    var address         = $wrapper.find("#el_create_tickets_address").val();

                    // Remove error
                    $wrapper.find("input").removeClass("is-invalid");
                    $wrapper.find("select").removeClass("is-invalid");
                    $wrapper.find(".invalid-feedback").removeClass("is-active");


                    if ( $this.hasClass("disabled") ) {
                        return false;
                    }

                    if ( eventID == "" ) {
                        $wrapper.find("#el_create_tickets_select_event").addClass("is-invalid");
                        $flag = false;
                    }

                    if ( calendarID == "" ) {
                        $wrapper.find("#el_create_tickets_calendar_event").addClass("is-invalid");
                        $flag = false;
                    }

                    const validateEmail = (email) => {
                        return email.match(
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            );
                    };

                    if ( email == "" ) {
                        $wrapper.find("#el_create_tickets_email").addClass("is-invalid");
                        $flag = false;
                    }

                    if ( email != "" && ! validateEmail( email ) ) {
                        $wrapper.find("#el_create_tickets_email").addClass("is-invalid");
                        $flag = false;
                    }

                    if ( totalQty < 1 ) {
                        if ( seatOption !== "map" ) {
                            $(".el_tickets_container .empty_ticket").addClass("is-active");
                        } else {
                            $(".el_seats_container .empty_ticket").addClass("is-active");
                        }

                        $flag = false;
                    } else {
                        if ( seatOption === "map" ) {
                            var checkDataPerson = EL_Frontend.el_ticket_manager.cart.check_area_data_person();
                            if ( checkDataPerson === false ) {
                                $(".el_seats_container .invalid_seat").addClass("is-active");
                                $flag = false;
                            }
                        }
                    }

                    if ( seatOption === "simple" ) {
                        if ( EL_Frontend.el_ticket_manager.cart.check_seat_valid() === false ) {
                            $(".el_tickets_container .invalid_seat").addClass("is-active");
                            $flag = false;
                        }
                    }
                    

                    if ( $flag === false ) {
                        return false;
                    }

                    var data = {
                        'action': 'el_create_tickets_save',
                        'nonce': nonce,
                        'ide': eventID,
                        'idcal': calendarID,
                        'cart': cart,
                        'name': customerName,
                        'phone': phoneNumber,
                        'email': email,
                        'address': address,
                        'seat_option': seatOption,
                    };

                    $("#el_create_tickets_modal").block({
                        message: null,
                        overlayCSS:  { 
                            backgroundColor: '#fff', 
                            opacity: 0.3, 
                            cursor: 'wait' 
                        },
                    });

                    
                    $this.addClass("disabled");

                    $.post( ajax_object.ajax_url, data, function(res){
                        var res = JSON.parse(res);

                        if ( res.status === "success" ) {
                            EL_Frontend.el_ticket_manager.cart.clear_cart();
                            $('#el_create_tickets_modal').modal('hide');
                            if ( res.mess ) {
                                alert( res.mess );
                            }
                            window.location.reload( true );
                        }


                        $("#el_create_tickets_modal").unblock();
                        
                        $this.removeClass("disabled");

                    } );

                });
            },

            event_calendar_change: function(){
                $("#el_create_tickets_calendar_event").off().on("change",function(){
                    var $this = $(this);

                    var eventID = $("#el_create_tickets_select_event").val();
                    var calendarID = $this.val();

                    var nonce = $("#el_create_tickets_form").attr("data-nonce");

                    var data = {
                        'action': 'el_create_tickets_show_tickets',
                        'event_id': eventID,
                        'calendar_id': calendarID,
                        'nonce': nonce
                    };

                    $("#el_create_tickets_modal").block({
                        message: null,
                        overlayCSS:  { 
                            backgroundColor: '#fff', 
                            opacity: 0.3, 
                            cursor: 'wait' 
                        },
                    });

                    $(".el_calendar_event_content").html("");

                    $.post( ajax_object.ajax_url, data, function(res){
                        $(".el_calendar_event_content").html(res);
                        if ( res ) {
                            // init cart
                            EL_Frontend.el_ticket_manager.cart.init();
                        }

                        EL_Frontend.el_ticket_manager.change_qty_tickets();
                        EL_Frontend.el_ticket_manager.select_seat_map();

                        $("#el_create_tickets_modal").unblock();
                    } );

                });
            },

            create_tickets_form_init: function(){
                var eventID = $("#el_create_tickets_select_event").val();

                if ( eventID !== "" && eventID !== null ) {
                    var nonce = $("#el_create_tickets_form").attr("data-nonce");
                    var data = {
                        'action': 'el_create_tickets_show_calendar',
                        'nonce': nonce,
                        'event_id': eventID,
                    };

                    $("#el_create_tickets_modal").block({
                        message: null,
                        overlayCSS:  { 
                            backgroundColor: '#fff', 
                            opacity: 0.3, 
                            cursor: 'wait' 
                        },
                    });

                    $.post( ajax_object.ajax_url, data, function(res){
                        
                        $(".el_calendar_event_container").html( res );
                        EL_Frontend.el_ticket_manager.event_calendar_change();

                        $("#el_create_tickets_modal").unblock();
                    } );
                }

                $("#el_create_tickets_select_event").off().on("change",function(){

                    var $this = $(this);

                    var eventID = $this.val();
                    var nonce = $("#el_create_tickets_form").attr("data-nonce");

                    var data = {
                        'action': 'el_create_tickets_show_calendar',
                        'nonce': nonce,
                        'event_id': eventID,
                    };

                    $("#el_create_tickets_modal").block({
                        message: null,
                        overlayCSS:  { 
                            backgroundColor: '#fff', 
                            opacity: 0.3, 
                            cursor: 'wait' 
                        },
                    });

                    $.post( ajax_object.ajax_url, data, function(res){
                        
                        $(".el_calendar_event_container").html( res );
                        EL_Frontend.el_ticket_manager.event_calendar_change();

                        $("#el_create_tickets_modal").unblock();
                    } );

                });
            },

            create_tickets: function(){

                $(".el_manager_ticket_action_row .el_create_tickets").off().on("click",function(e){
                    e.preventDefault();

                    var $this = $(this);

                    var $wrapper    = $this.closest(".el_manager_ticket_action_row");
                    var nonce       = $wrapper.attr("data-nonce");
                    var eventID     = $wrapper.attr("data-event-id");
                    var data = {
                        'action': 'el_ticket_manager_create_tickets',
                        'nonce': nonce,
                        'event_id': eventID,
                    };

                    $(".contents").block({
                        message: null,
                        overlayCSS:  { 
                            backgroundColor: '#fff', 
                            opacity: 0.3, 
                            cursor: 'wait' 
                        },
                    });

                    $("#el_create_tickets_modal").modal();

                    $("#el_create_tickets_modal .modal-body").html("");

                    $.post( ajax_object.ajax_url, data, function(res){
                        try {
                            $("#el_create_tickets_modal .modal-body").html(res);
                            // Form init
                            EL_Frontend.el_ticket_manager.create_tickets_form_init();
                        }
                        catch(err){
                            $("#el_create_tickets_modal .modal-body").html( err.message );
                        }

                        $(".contents").unblock();
                    });

                    
                });
            },
            select_all_ticket: function(){
                $("#el_ticket_check_all").off().on("click",function(){
                    var $this = this;
                    $(".event_body").each( function(i,el){
                        $(el).find('input:checkbox').not($this).prop('checked', $this.checked);
                    } );
                });
            },
            download_ticket: function(){
                
                $(".el_ticket_manager_action_wrap .el_download_ticket").off().on("click",function(e){
                    e.preventDefault();
                    var $this = $(this);

                    var $wrapper = $this.closest(".el_ticket_manager_action_wrap");

                    var nonce       = $wrapper.attr("data-nonce");
                    var ticketID    = $wrapper.attr("data-ticket-id");
                    
                    var data = {
                        'action': 'el_ticket_manager_download_ticket',
                        'nonce': nonce,
                        'ticket_id': ticketID,
                    };

                    $(".contents").block({
                        message: null,
                        overlayCSS:  { 
                            backgroundColor: '#fff', 
                            opacity: 0.3, 
                            cursor: 'wait' 
                        },
                    });

                    $.post( ajax_object.ajax_url, data, function(res){
  
                        try {
                            var res = JSON.parse( res );

                            if ( res.status === "success" ) {
                                var $a = $('<a />', {
                                    'href': res.file_url,
                                    'download': res.file_name,
                                    'text': "click"
                                }).hide().appendTo("body")[0].click();

                                // Delete file
                                var data = {
                                    'action': 'el_ticket_manager_remove_ticket_pdf',
                                    'nonce': nonce,
                                    'file_name': res.file_name,
                                };

                                $.post( ajax_object.ajax_url, data, function(res_second){} );

                            } else {
                                if ( res.mess ) {
                                    alert( res.mess );
                                }
                            }
                        }
                        catch(err) {
                            alert( err.message );
                        }

                        $(".contents").unblock();

                    } );

                });
            },

            send_ticket: function(){
                $(".el_ticket_manager_action_wrap .el_send_ticket").off().on("click",function(e){
                    e.preventDefault();
                    var $this = $(this);

                    var $wrapper = $this.closest(".el_ticket_manager_action_wrap");

                    var nonce       = $wrapper.attr("data-nonce");
                    var ticketID    = $wrapper.attr("data-ticket-id");
                    
                    var data = {
                        'action': 'el_ticket_manager_send_ticket',
                        'nonce': nonce,
                        'ticket_id': ticketID,
                    };

                    $(".contents").block({
                        message: null,
                        overlayCSS:  { 
                            backgroundColor: '#fff', 
                            opacity: 0.3, 
                            cursor: 'wait' 
                        },
                    });

                    $.post( ajax_object.ajax_url, data, function(res){

                        try {
                            var res = JSON.parse( res );

                            if ( res.mess ) {
                                alert( res.mess );
                            }

                        }
                        catch(err){
                            alert( err.message );
                        }

                        $(".contents").unblock();
                    });

                });
            },

            download_all_ticket: function(){
                $(".el_manager_ticket_action_row .el_download_tickets").off().on("click",function(e){
                    e.preventDefault();

                    var $this = $(this);

                    var $wrapper    = $this.closest(".el_manager_ticket_action_row");
                    var nonce       = $wrapper.attr("data-nonce");
                    var eventID     = $wrapper.attr("data-event-id");
                    var error       = $this.attr("data-empty");
                    var ticketIds   = [];

                    $('.event_body input[name="ticket_id"]:checked').each(function(i,el){
                        ticketIds.push( $(el).val() );
                    });
                    

                    if ( ticketIds.length < 1 ) {
                        alert( error );
                        return false;
                    }

                    var data = {
                        'action': 'el_ticket_manager_download_tickets',
                        'event_id': eventID,
                        'ticket_ids': ticketIds,
                        'nonce': nonce,
                    };

                    $(".contents").block({
                        message: null,
                        overlayCSS:  { 
                            backgroundColor: '#fff', 
                            opacity: 0.3, 
                            cursor: 'wait' 
                        },
                    });

                    $.post( ajax_object.ajax_url, data, function(res){
                        try {
                            var res = JSON.parse( res );

                            if ( res.status === "success" ) {

                                var fileUrls = res.file_urls;
                                var fileNames = res.file_names;

                                if ( fileUrls.length ) {

                                    for (var i = 0; i < fileUrls.length; i++) {
                                        var fileUrl = fileUrls[i];
                                        var fileName = fileNames[i];
                                        var $a = $('<a />', {
                                            'href': fileUrl,
                                            'download': fileName,
                                            'text': "click"
                                        }).hide().appendTo("body")[0].click();
                                    }

                                    // Delete file
                                    var data = {
                                        'action': 'el_ticket_manager_remove_all_ticket',
                                        'nonce': nonce,
                                        'file_names': fileNames,
                                    };

                                    $.post( ajax_object.ajax_url, data, function(res_second){} );
                                }

                            } else {
                                if ( res.mess ) {
                                    alert( res.mess );
                                }
                            }
                        }
                        catch(err) {
                            alert( err.message );
                        }

                        $(".contents").unblock();
                    } );

                });
            }
        },

        el_show_password_field: function(){

            $('input[name="event_status"]').on("change",function(){
                var $this = $(this);
                var $id = $this.attr("id");
                if ( $id === 'event_status_protected' || $this.val() === 'private' ) {
                    $(".wrap_event_password").addClass("is-active");
                } else {
                    $(".wrap_event_password").removeClass("is-active");
                }
            });
        },

        el_show_hide_password_create_event: function(){
            $(".vendor_edit_event").each( function(i,el){
                $(el).find(".show_hide_password").off().on("click",function(e){
                    e.preventDefault();
                    var $this = $(this);
                    var $icon = $this.find("i");
                    var $input = $this.closest(".input_group").find("input");
                    if ( $this.hasClass("is-show") ) {
                        $this.removeClass("is-show");
                        $icon.addClass("fa-eye");
                        $icon.removeClass("fa-eye-slash");
                        $input.attr("type","password");
                    } else {
                        $this.addClass("is-show");
                        $icon.removeClass("fa-eye");
                        $icon.addClass("fa-eye-slash");
                        $input.attr("type","text");
                    }
                });
            } );
        },

        el_profile_tab: function(){

            if ( $('.vendor_profile').length ) {
                var current_tab = 'author_profile';

                if ( window.location.hash ) {
                    var hash = window.location.hash;
                    current_tab = hash.replace('#','');
                }

                $('.vendor_tab li[data-id="'+current_tab+'"]').addClass('is-active');
                $('#'+current_tab).addClass('is-active');


                $('.vendor_tab li').off().on('click',function(e){
                    e.preventDefault();
                    var current_tab = $(this).attr('data-id');

                    history.pushState({}, '', '#'+current_tab);

                    $('.vendor_tab li').removeClass('is-active');
                    $('.tab-contents').removeClass('is-active');

                    $('.vendor_tab li[data-id="'+current_tab+'"]').addClass('is-active');
                    $('#'+current_tab).addClass('is-active');

                });
            }
        },

        el_create_event_tab: function(){

            if ( $('.vendor_edit_event').length ) {
                var current_tab = 'mb_basic';

                if ( window.location.hash ) {
                    var hash = window.location.hash;
                    current_tab = hash.replace('#','');
                }

                $('.vendor_tab li[data-id="'+current_tab+'"]').addClass('is-active');
                $('#'+current_tab).addClass('is-active');


                $('.vendor_tab li').off().on('click',function(e){
                    e.preventDefault();
                    var current_tab = $(this).attr('data-id');
                    history.pushState({}, '', '#'+current_tab);

                    $('.vendor_tab li').removeClass('is-active');
                    $('.tab-contents').removeClass('is-active');

                    $('.vendor_tab li[data-id="'+current_tab+'"]').addClass('is-active');
                    $('#'+current_tab).addClass('is-active');

                });
            }

        },

        el_payment_paypal: function( $booking_id, localStorage, key_store ){
            const $nonce = $(document).find('#paypal_button_wrapper').attr('data-nonce');
            const $url = $(document).find('#paypal_button_wrapper').attr('data-url');
            window.paypal.Buttons({
                async createOrder() {
                    try {
                        let data = {
                            'action': 'el_payment_paypal_create_order',
                            'booking_id': $booking_id,
                            'nonce': $nonce,
                        };
                        var post_data = async function(){
                            return $.post(ajax_object.ajax_url, data);
                        };

                        let orderData = await post_data();
                        orderData = JSON.parse( orderData );

                        const { el_error: $err } = orderData;
                        if ( $err ) {
                            resultMessage($err);
                            return false;
                        }

                        const errorDetail = orderData?.details?.[0];

                        if (orderData.id) {
                            return orderData.id;
                        } else {
                            const errorDetail = orderData?.details?.[0];
                            const errorMessage = errorDetail
                            ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                            : JSON.stringify(orderData);

                            throw new Error(errorMessage);
                        }

                    } catch( err ){
                        console.error(err);
                        resultMessage(err);
                    }
                    
                },
                async onApprove(data, actions) {
                    try {
                        const $order_id = data.orderID;
                        let $data = {
                            'action': 'el_payment_paypal_capture_order',
                            'nonce': $nonce,
                            'order_id': $order_id,
                        };
                        var post_data = async function(){
                            return $.post(ajax_object.ajax_url, $data, await function(response) {
                                // check error
                                const { el_error: $err } = JSON.parse( response );
                                if ( $err ) {
                                    resultMessage($err);
                                    return false;
                                }

                                const orderData = JSON.parse( response);
                                const errorDetail = orderData?.details?.[0];

                                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                    return actions.restart();
                                } else if (errorDetail) {
                                // (2) Other non-recoverable errors -> Show a failure message
                                    throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                                } else if (!orderData.purchase_units) {
                                    throw new Error(JSON.stringify(orderData));
                                } else {
                                // (3) Successful transaction -> Show confirmation or thank you message
                                // Or go to another URL:  actions.redirect('thank_you.html');
                                    if ( orderData.status === 'COMPLETED' ) {
                                        // clear cart
                                        localStorage.removeItem(key_store);
                                        window.location.href = $url;
                                    }
                                }
                            });
                        };
                        await post_data();
                        
                    }
                    catch(err){
                        console.error(err);
                        resultMessage(err);
                    }
                }
            }).render("#paypal-button-container");

            function resultMessage(message) {
                const container = $(document).find("#payment_paypal_modal #result-message");
                container.html(message);
            }

        },

        el_payment_stripe: function( $booking_id ,localStorage, key_store ){
            if ( ! $booking_id || ! localStorage || ! key_store ) { return false; }

            if ( $(document).find('#el_payment_stripe').length ) {
                const $public_key       = $(document).find('#el_payment_stripe').attr('data-key');
                const $currency         = $(document).find('#el_payment_stripe').attr('data-currency');
                const $redirect_url     = $(document).find('#el_payment_stripe').attr('data-url');
                const $themes           = $(document).find('#el_payment_stripe').attr('data-themes');
                const $err_messages     = $(document).find('#el_payment_stripe_error');
                var $amount = $(document).find('#checkout-button').attr('data-amount');

                var zeroDecimal = ["bif","clp","djf","gnf","jpy","kmf","krw","mga",
                "pyg","rwf","ugx","vnd","vuv","xaf","xof","xpf"];

                if ( zeroDecimal.includes( $currency ) ) {
                    $amount = parseFloat( $amount );
                } else {
                    $amount = parseFloat( $amount ) * 100;
                }

                $amount = Math.ceil($amount);


                var stripe = Stripe($public_key);

                var elements;

                var element_options = {
                    mode: 'payment',
                    currency: $currency,
                    amount: $amount,
                    paymentMethodTypes: ['card'],
                    appearance: {
                        theme: 'stripe',
                        labels: 'above',
                    }
                };

                // themes setup
                switch ($themes) {
                    case 'night':
                        element_options['appearance']['theme'] = 'night';
                        break;
                    case 'flat':
                        element_options['appearance']['theme'] = 'flat';
                        element_options['appearance']['labels'] = 'floating';
                        break;
                    default:
                }

                elements = stripe.elements(element_options);

                var paymentElementOptions = {
                };
                
                var paymentElement = elements.create("payment", paymentElementOptions);
                // call api stripe, check load error
                try {
                    paymentElement.mount("#el_payment_stripe");
                    paymentElement.on('loaderror', function(event) {
                        if ( event.error ) {
                            $err_messages.html( event.error.message );
                            return false;
                        }
                    });
                }
                catch(err) {
                    $err_messages.html( err );
                    return false;
                }

                $(document).find('#el_payment_stripe_submit').off().on('click',async function(){

                    /* Hide Submit Button*/
                    $('#checkout-button').css({
                        "z-index": "-1"
                    });
                    $('#checkout-button').find('.submit-load-more').css({
                        "z-index": "1"
                    });
                    $(".error-empty-input").css({
                        "display": "none"
                    });

                    $(document).find('#payment_stripe_modal .el_loading').show();
                    $(this).attr("disabled", true);

                    const $nonce = $(document).find('#el_payment_stripe_submit').attr('data-nonce');
                    // Trigger form validation and wallet collection
                    const {error: submitError} = await elements.submit();
                    if ( submitError ) {
                        // Show button
                        $('#checkout-button').css({
                            "z-index": "1"
                        });
                        $('#checkout-button').find('.submit-load-more').css({
                            "z-index": "-1"
                        });

                        $(document).find('#payment_stripe_modal .el_loading').hide();
                        $(document).find('#el_payment_stripe_submit').attr("disabled", false);
                        $err_messages.html(submitError.message);
                        return false;
                    }

                    // clear cart
                    localStorage.removeItem(key_store);

                    var $data = {
                        action: 'el_payment_stripe',
                        amount: $amount,
                        nonce: $nonce,
                        booking_id: $booking_id
                    };

                    $.post(ajax_object.ajax_url, $data, function(response){
                        response = JSON.parse( response );

                        if ( response.el_error ) {
                            $err_messages.html( response.el_error );
                            return false;
                        }

                        elConfirmPayment( response );
                    });

                    async function elConfirmPayment( response ) {
                        try {
                            const { client_secret: clientSecret } = response;
                            const { error } = await stripe.confirmPayment({
                                elements,
                                clientSecret,
                                confirmParams: {
                                    return_url: $redirect_url,
                                },
                            });

                            if ( error ) {
                                // Show button
                                $('#checkout-button').css({
                                    "z-index": "1"
                                });
                                $('#checkout-button').find('.submit-load-more').css({
                                    "z-index": "-1"
                                });

                                $(document).find('#payment_stripe_modal .el_loading').hide();
                                $(document).find('#el_payment_stripe_submit').attr("disabled", false);

                                $err_messages.html( error.message );
                                return false;
                            }
                        } catch (err) {
                            if ( err ) {
                                // Show button
                                $('#checkout-button').css({
                                    "z-index": "1"
                                });
                                $('#checkout-button').find('.submit-load-more').css({
                                    "z-index": "-1"
                                });

                                $(document).find('#payment_stripe_modal .el_loading').hide();
                                $(document).find('#el_payment_stripe_submit').attr("disabled", false);

                                $err_messages.html( err );
                                return false;
                            }
                        }
                    }
                });
            }
        },

        el_cart_optimize: function(){
            if ( $('.cart_detail').length > 0 && $("#checkout-button").length > 0 ) {

                // hide customer info
                $(document).find('.cart_detail .cart-customer-infor').css('display','none');
                $(document).find('.cart_detail .payment_method_choosed').css('display','none');


                $(".cart_detail .step-2").css({
                    'display': 'block'
                });

                $(document).find('.cart_detail .cart-customer-input').addClass('cart_optimize');
                $(".cart_detail .next_step_button").css({
                    'display': 'none'
                });

                
                EL_Frontend.cart_next_step_hidden();


            }
                
        },

        el_vendor_event_listing: function(){

            $(document).find(".header_listing .btn_toggle_filter").on("click",function(){
                var icon = $(this).find("i");
                if ( ! icon.hasClass("fa-caret-up") ) {
                    $(this).find("i").addClass("fa-caret-up");
                    $(this).find("i").removeClass("fa-caret-down");
                    $(this).addClass("active");
                    $(this).closest('.header_listing').find('.menu_tab').fadeIn();
                } else {
                    $(this).find("i").addClass("fa-caret-down");
                    $(this).find("i").removeClass("fa-caret-up");
                    $(this).removeClass("active");
                    $(this).closest('.header_listing').find('.menu_tab').fadeOut();
                }
            });
        },

        el_ticket_list: function(){

            if ( $(document).find('.table-list-booking .ticket-list').length ) {
                var ticket_list_btn = $(document).find('.table-list-booking .ticket-list');
                ticket_list_btn.each( function(i,el){
                    $(el).on('click',function(){
                        $(document).find('#ticket_list_modal .ticket-list-loading').fadeIn();
                        $(document).find('#ticket_list_modal .ticket-list-body').html('');
                        var $nonce = $(el).data('nonce');
                        var $booking_id = $(el).data('booking-id');
                        var data = {
                            'action': 'el_ticket_list',
                            'nonce': $nonce,
                            'booking_id': $booking_id,
                        };
                        $.post(ajax_object.ajax_url, data, function(response) {
                            $(document).find('#ticket_list_modal .ticket-list-loading').fadeOut();
                            $(document).find('#ticket_list_modal .ticket-list-body').html(response);
                            $(document).find('#ticket_transfer_submit').attr('data-booking-id',$booking_id);
                            $(document).find('#ticket_transfer_submit').attr('data-ticket-list-nonce',$nonce);
                        });
                    });
                } );
            }


            $(document).on('click','#check_all_ticket',function(){
                $('input:checkbox').not(this).prop('checked', this.checked);
            });

            $(document).on('click','.btn-ticket-transfer',function(){
                var $ticket_list = [];
                var ticket_check = $(document).find('.form-check .ticket_check');

                if ( ticket_check.length ) {
                    ticket_check.each(function(i,el){
                        if ( $(el).is(':checked') ) {
                            $ticket_list.push( $(el).val() );
                        }
                    });
                }

                if ( $ticket_list.length ) {
                    $(document).find('#ticket_transfer_submit').attr('data-ticket-id',JSON.stringify($ticket_list));
                    $(document).find('#ticket_transfer_modal').modal('show');
                }
                
            });

            const validateEmail = (email) => {
                return email.match(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            };

            const validate = () => {
                const email = $(document).find('#ticket_receiver_email').val();
                $(document).find('#ticket_receiver_email').removeClass('is-valid');
                $(document).find('#ticket_receiver_email').removeClass('is-invalid');
                if( validateEmail(email) ){
                    $(document).find('#ticket_receiver_email').addClass('is-valid');
                } else{
                    $(document).find('#ticket_receiver_email').addClass('is-invalid');
                }
                return false;
            }

            $(document).find('#ticket_receiver_email').on('keyup', validate);

            $(document).find('#ticket_receiver_name').on('keyup',function(){
                const $name = $(document).find('#ticket_receiver_name').val();
                $(document).find('#ticket_receiver_name').removeClass('is-valid');
                $(document).find('#ticket_receiver_name').removeClass('is-invalid');
                if ( $name != '' && XRegExp('^[\\p{Letter}\\p{Separator}\p{Common}]*$').test($name) ) {
                    $(document).find('#ticket_receiver_name').addClass('is-valid');
                } else {
                    $(document).find('#ticket_receiver_name').addClass('is-invalid');
                }
                return false;
            });

            
            const regex_phone = /^(\(?\+?\d{1,3}\)?)?[-. ]?\(?\d{1,4}\)?[-. ]?\d{1,4}[-. ]?\d{1,9}$/;
            $(document).find('#ticket_receiver_phone').on('keyup',function(){
                const $phone = $(document).find('#ticket_receiver_phone').val();
                $(document).find('#ticket_receiver_phone').removeClass('is-valid');
                $(document).find('#ticket_receiver_phone').removeClass('is-invalid');
                if ( $phone != '' && regex_phone.test($phone) ) {
                    $(document).find('#ticket_receiver_phone').addClass('is-valid');
                } else {
                    $(document).find('#ticket_receiver_phone').addClass('is-invalid');
                }
                return false;
            });

            async function el_reload_ticket_list(){
                var ticket_transfer_submit = $(document).find('#ticket_transfer_submit');
                $(document).find('#ticket_list_modal .ticket-list-loading').fadeIn();
                $(document).find('#ticket_list_modal .ticket-list-body').html('');
                var $nonce = ticket_transfer_submit.attr('data-ticket-list-nonce');
                var $booking_id = ticket_transfer_submit.attr('data-booking-id');
                var data = {
                    'action': 'el_ticket_list',
                    'nonce': $nonce,
                    'booking_id': $booking_id,
                };
                $.post(ajax_object.ajax_url, data, function(res) {
                    $(document).find('#ticket_list_modal .ticket-list-loading').fadeOut();
                    $(document).find('#ticket_list_modal .ticket-list-body').html('');
                    $(document).find('#ticket_list_modal .ticket-list-body').html(res);
                    $(document).find('#ticket_list_modal').css('overflow-y','auto');
                    ticket_transfer_submit.attr('data-booking-id',$booking_id);
                });
            }

            $(document).find('.ticket_receiver_loading').fadeOut();

            $(document).on('click','#ticket_transfer_submit',function(){
                $(document).find('.ticket_transfer_alert').fadeOut();
                $(document).find('.ticket_receiver_loading').fadeIn();
                var ticket_transfer_submit = $(document).find('#ticket_transfer_submit');
                ticket_transfer_submit.prop("disabled", true);

                const $email_input = $(document).find('#ticket_receiver_email');
                const $name_input = $(document).find('#ticket_receiver_name');
                const $phone_input = $(document).find('#ticket_receiver_phone');
                const $nonce = ticket_transfer_submit.attr('data-nonce');
                const $ticket_ids = ticket_transfer_submit.attr('data-ticket-id');
                const $booking_id = ticket_transfer_submit.attr('data-booking-id');

                if ( $email_input.hasClass('is-valid') && $name_input.hasClass('is-valid') && $phone_input.hasClass('is-valid') ) {
                    
                    const $email    = $email_input.val();
                    const $name     = $name_input.val();
                    const $phone    = $phone_input.val();
                    var data = {
                        'action': 'el_ticket_transfer',
                        'nonce': $nonce,
                        'ticket_ids': $ticket_ids,
                        'email': $email,
                        'name': $name,
                        'phone': $phone,
                        'booking_id': $booking_id,
                    };

                    $.post(ajax_object.ajax_url, data, function(response) {
                        response = JSON.parse(response);
                        $(document).find('#ticket_list_modal .ticket-list-loading').fadeOut();
                        if ( response.status !== 'error' ) {
                            // clear form
                            $email_input.val('');
                            $name_input.val('');
                            $phone_input.val('');
                            $email_input.removeClass('is-valid');
                            $name_input.removeClass('is-valid');
                            $phone_input.removeClass('is-valid');
                            // reload ticket list
                            el_reload_ticket_list();

                            $(document).find('.ticket_receiver_loading').fadeOut();
                            ticket_transfer_submit.prop("disabled", false);
                        } else {
                            $(document).find('.ticket_receiver_loading').fadeOut();
                            ticket_transfer_submit.prop("disabled", false);
                        }
                        var $mess = `<div class="alert alert-${response.class} alert-dismissible fade show" role="alert">
                            ${response.mess}<button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>`;
                        var $mail = '';
                        if ( response.mail === 'true' ) {
                            $mail = `<div class="alert alert-success alert-dismissible fade show" role="alert">
                            ${response.mail_mess}<button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>`;
                        }
                        var $alert_content = $mess + $mail;
                        $(document).find('.ticket_transfer_mess').append($alert_content);

                    });
                } else {
                    $(document).find('.ticket_transfer_alert').fadeIn();

                    $(document).find('.ticket_receiver_loading').fadeOut();
                    ticket_transfer_submit.prop("disabled", false);
                }
            });

            $(document).on('click', '.ticket_qr_toggle', function(){

                if ( $(this).find('i').hasClass('fa-eye') ) {
                    $(this).find('i').removeClass('fa-eye');
                    $(this).find('i').addClass('fa-eye-slash');
                    $(this).closest('.ticket_qr_wrap').find('.ticket_qr').fadeIn();
                } else {
                    $(this).find('i').removeClass('fa-eye-slash');
                    $(this).find('i').addClass('fa-eye');
                    $(this).closest('.ticket_qr_wrap').find('.ticket_qr').fadeOut();
                }
            });

        },

        el_enable_cancellation_booking: function(){
            if ( $( "input[name='ova_mb_event_allow_cancellation_booking']" ).length > 0 ) {
                var allow_cancel_bk = $("input[name='ova_mb_event_allow_cancellation_booking']:checked").val();

                if( allow_cancel_bk == 'yes' ){
                    $( ".vendor_edit_event .cancel_bk_before_x_day" ).show();
                }else if( allow_cancel_bk == 'no' ){
                    $( ".vendor_edit_event .cancel_bk_before_x_day" ).hide();
                }

                $( "input[name='ova_mb_event_allow_cancellation_booking']" ).on( 'click', function(){
                    allow_cancel_bk = $("input[name='ova_mb_event_allow_cancellation_booking']:checked").val();
                    
                    if( allow_cancel_bk == 'yes' ){
                        $( ".vendor_edit_event .cancel_bk_before_x_day" ).show();
                    }else if( allow_cancel_bk == 'no' ){
                        $( ".vendor_edit_event .cancel_bk_before_x_day" ).hide();
                    }
                });
            }
        },

        el_menu_mobile: function() {
            $('.el_vendor_mobile_menu a').on('click', function(){
                $(this).closest('.vendor_sidebar').find('.dashboard_nav').slideToggle();
            });
        },

        el_single_gallery: function() {
            var rtl = false;
            if( $('body').hasClass('rtl') ){
                rtl = true;
            }

            if ($('.event-gallery .wrap_slide').slick) {
                $('.event-gallery .wrap_slide').slick({
                    lazyLoad: 'ondemand',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    fade: true,
                    asNavFor: '.event-gallery .thumbnail_gallery',
                    rtl: rtl,
                });

                $('.event-gallery .thumbnail_gallery').slick({
                    lazyLoad: 'ondemand',
                    slidesToShow: 5,
                    slidesToScroll: 3,
                    asNavFor: '.event-gallery .wrap_slide',
                    dots: false,
                    arrows: false,
                    centerPadding: '0px',
                    focusOnSelect: true,
                    centerMode: true,
                    swipeToSlide: true,
                    rtl: rtl,
                    responsive: [{
                        breakpoint: 600,
                        settings: {
                            arrows: false,
                            slidesToShow: 3
                        }
                    }]
                });
            }
        },

        el_get_width: function(){
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        },

        el_get_height: function(){
            return Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.documentElement.clientHeight
            );
        },

        event_calendar: function() {

            $('.fullcalendar').each(function() {
                var that            = $(this)[0];
                var data_js         = $(this).data('listevent');
                var local           = $(this).data('local');
                var initdate        = $(this).data( 'initdate' );
                var data_ide        = $(this).data('ide');
                var schedules_time  = $(this).data('schedules_time');
                var time_slot       = $(this).data('time_slot');
                var first_day       = $(this).data('firstday');
                var external_link   = $(this).data('external_link');
                var timeNow         = $(this).data('time-now');
                var eventSources = [
                    {
                        'events': data_js
                    }
                ];

                var srcCalendar = new FullCalendar.Calendar( that, {
                    headerToolbar: {
                        start: 'prev,next today',
                        center: 'title',
                        end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    },
                    navLinks: true,
                    firstDay: first_day,
                    nowIndicator: true,
                    initialDate: initdate,
                    locale: local,
                    now: timeNow,
                    eventSources: eventSources,
                    dayMaxEvents: true,
                    eventColor: '#e86c60',
                    height: 'auto',
                    dayMaxEvents: true,
                    eventContent: function( info ) {
                      return {html: info.event.title};
                    },
                    eventDidMount: function ( arg ){
                        EL_Frontend.el_tippy();
                    }
                });

                srcCalendar.render();

                $(window).resize(function() {

                    const width = EL_Frontend.el_get_width();

                    if ( width < 600 ) {
                        srcCalendar.changeView('listWeek');
                    } else if( width < 1100 ){
                        srcCalendar.changeView('timeGridDay');
                    } else if ( width < 1200 ) {
                        srcCalendar.changeView('timeGridWeek');
                    } else {
                        srcCalendar.changeView('dayGridMonth');
                    }

                });
            });
        },

        el_load_location: function() {
            $('.location .get_country select').change(function() {
                var country = $(this).val();
                var $this   = $(this).closest('.location');
                var city_selected = $this.find('.get_city select option:selected').val();

                if ( country == '' || country == undefined ) {
                    $('.location .get_city select').attr('disabled', 'disabled');
                } else {
                    $.post(ajax_object.ajax_url, {
                        action: 'el_load_location',
                        data: {
                            country: country,
                            city_selected: city_selected,
                        },
                    }, function(response) {
                        $('.vendor_edit_event .location').find('#event_city').html(response);
                    });

                    $('.location .get_city select').removeAttr('disabled');
                }
            });

            $(window).on( 'load', function() {
                var country         = $('.location .get_country select option:selected').val();
                var city_selected   = $('.location .get_city select option:selected').val();

                if ( country == '' || country == undefined ) {
                    $('.location .get_city select').attr('disabled', 'disabled');
                } else {
                    $.post(ajax_object.ajax_url, {
                        action: 'el_load_location',
                        data: {
                            country: country,
                            city_selected: city_selected,
                        },
                    }, function(response) {
                        $('.vendor_edit_event .location').find('#event_city').html(response);
                    });

                    $('.location .get_city select').removeAttr('disabled');
                }
            });
        },

        ticket_info_according: function() {
            var heading_ticket = $('.ticket-info .heading-ticket');
            heading_ticket.each(function() {
                var empty_desc = $(this).data('desc');

                if (empty_desc) {
                    $(this).children('p').children('i').css('display', 'none');
                    $(this).css('cursor', 'unset');
                }

                $(this).off('click').on('click', function() {
                    if (empty_desc) {
                        return false;
                    }
                    let i = 1;
                    let data_according = $(this).attr('data-according');

                    $('.ticket-info .heading-ticket').not(this).removeAttr('data-according');
                    if (data_according > 1) {
                        $(this).siblings('.desc-ticket').slideToggle();
                    } else {
                        i++;
                        $(this).attr('data-according', i);
                        $('.ticket-info .heading-ticket').not(this).siblings('.desc-ticket').slideUp();
                        $(this).siblings('.desc-ticket').slideDown();
                    }
                });
            });
        },

        cart_payment_according: function() {
            $('.cart_detail .el_payments ul li .type-payment label').on('click', function() {
                $(".el_payments ul li.free .type-payment").css({
                    'border-bottom': 'none'
                });
                $(".el_payments ul li.free .payment_form").css({
                    'border-bottom': '1px solid #ddd'
                });

                var that = $(this).parent('.type-payment');

                $('.cart_detail .el_payments ul li .type-payment').not(that).siblings('.payment_form').slideUp();
                $(that).siblings('.payment_form').slideDown();

                let title_payment = $(this).text();
                $(".cart_detail .payment_method_choosed .content").text(title_payment);
            });
        },

        cart_ticket_event: function() {
            const CART = {
                KEY: '',
                contents: [],
                init: function() {
                    CART.get_key_cart();
                    let _contents = localStorage.getItem(CART.KEY);
                    if (_contents) {
                        CART.contents = JSON.parse(_contents);
                    } else {
                        CART.add_store();
                    }
                },

                add_store: function() {
                    let _cart = JSON.stringify(CART.contents);
                    localStorage.setItem(CART.KEY, _cart);
                },

                get_key_cart: function() {
                    let key = "";
                    let id_event = "";
                    let id_cal  = "";
                    id_event    = $(".cart_detail .cart-content .cart-ticket-info").attr('data-id-event');
                    id_cal      = $(".cart_detail .cart-content .cart-ticket-info").attr('data-id-cal');

                    if ( id_event && id_cal ) {
                        CART.KEY = id_event + '_' + id_cal;
                        Cookies.set('id_event', id_event);
                        Cookies.set('id_cal', id_cal);
                    }
                },


                add_item: function(id, name, price, qty) {

                    let obj = {
                        'id': id,
                        'name': name,
                        'price': price,
                        'qty': qty,
                        'seat': [],
                        'extra_service': [],
                    };
                    var data_extra_service = [];

                    if ( $('.cart-ticket-info').attr('data-extra-service') ) {

                        data_extra_service = JSON.parse( $('.cart-ticket-info').attr('data-extra-service') );

                    }
                    // return false;
                    var extra_service = [];
                    var extra_item = [];

                    if ( ! $.isEmptyObject( data_extra_service ) ) {
                        
                        for ( let key in data_extra_service ) {
                            var data = {
                                'id': data_extra_service[key].id,
                                'name': data_extra_service[key].name,
                                'price': data_extra_service[key].price,
                                'qty': 0,
                                'rest_qty': data_extra_service[key].rest_qty,
                                'max_qty': data_extra_service[key].max_qty,
                            };
                            extra_item.push(data);
                        }
                        extra_service.push( extra_item );
                    }

                    obj.extra_service = extra_service;

                    //update localStorage
                    var check_item = CART.check_item(id);
                    if ( check_item ) {
                        //has item in cart increase qty +1
                        CART.contents = CART.contents.map(function(item) {
                            if ( item.id === id ){
                                item.qty = item.qty + 1;
                                if ( ! $.isEmptyObject( extra_service ) ) {
                                    item.extra_service = item.extra_service.concat( extra_service );
                                }
                            }
                            return item;
                        });

                    } else {
                        CART.contents.push(obj);
                    }
                    CART.add_store();
                },
                add_extra_service: function( $ticket_id, $index_ticket, $id_sv ,$qty ){
                    CART.contents = CART.contents.map( function(item){
                        if ( item.id === $ticket_id ) {
                            var $this_service = item.extra_service;
                            if ( $this_service.length ) {
                                $this_service[$index_ticket].forEach( function(jtem,index){
                                    if ( jtem.id === $id_sv ) {
                                        item.extra_service[$index_ticket][index].qty = $qty;
                                    }
                                } );
                            }
                        }
                        return item;
                    } );
                    CART.add_store();
                },

                add_extra_service_map: function( $ticket_id, $index, $id, $current_qty, multiple_service = false, $person_type = null ){
                    

                    if ( multiple_service === false ) {
                        CART.contents = CART.contents.map( function(item){
                            if ( item.id === $ticket_id ) {
                                var $this_service = item.extra_service;
                                if ( typeof $this_service != 'undefined' && $this_service.length ) {
                                    $this_service[$index].qty = $current_qty;
                                }
                            }
                            return item;
                        } );
                    } else {

                        if ( $person_type == null ) {

                            CART.contents = CART.contents.map( function(item){
                                if ( item.id === $ticket_id ) {
                                    if ( typeof item.extra_service != 'undefined' && $.isArray( item.extra_service ) ) {
                                        item.extra_service = item.extra_service.map( function(jtem,jndex,arr){

                                            if ( jndex == $index ) {
                                                jtem.map( function(ktem){
                                                    if ( ktem.id === $id ) {
                                                        ktem.qty = $current_qty;
                                                    }
                                                    return ktem;
                                                } );
                                            }

                                            return jtem;
                                            
                                        } );
                                    }
                                }
                                return item;
                            } );

                        } else {

                            CART.contents = CART.contents.map( function(item){
                                if ( item.id === $ticket_id ) {

                                    item['data_person'] = item['data_person'].map( function(jtem){
                                        if ( jtem.id == $person_type ) {

                                            jtem['extra_service'] = jtem['extra_service'].map( function(ktem,jndex,arr){
                                                if ( jndex == $index ) {
                                                    ktem.map( function(ftem){
                                                        
                                                        if ( ftem.id === $id ) {
                                                            ftem.qty = $current_qty;
                                                        }
                                                        return ftem;
                                                    } );
                                                }
                                                return ktem;
                                            });
                                        }
                                        return jtem;
                                    } );
                                }
                                return item;
                            } );
                        }
                        
                    }
                    
                    CART.add_store();
                },

                add_extra_service_area: function( $area_id, add_service = true ){

                    var data_extra_service = [];
                    if ( $('.cart-ticket-info').attr('data-extra-service') ) {
                        data_extra_service = JSON.parse( $('.cart-ticket-info').attr('data-extra-service') );
                    }

                    var extra_service = [];
                    var extra_item = [];

                    if ( ! $.isEmptyObject( data_extra_service ) ) {

                        for (let key in data_extra_service ) {
                            var data = {
                                'id': data_extra_service[key].id,
                                'name': data_extra_service[key].name,
                                'price': data_extra_service[key].price,
                                'qty': 0,
                                'rest_qty': data_extra_service[key].rest_qty,
                                'max_qty': data_extra_service[key].max_qty,
                            };
                            extra_item.push(data);
                        }
                        extra_service.push(extra_item);
                    }

                    if ( add_service == true ) {
                        CART.contents.map( function(item){
                            if ( item.id === $area_id ) {
                                if ( typeof item.extra_service != 'undefined' ) {
                                    item.extra_service = item.extra_service.concat(extra_service);
                                }
                            }
                            return item;
                        } );
                    } else {
                        CART.contents.map( function(item){
                            if ( item.id === $area_id ) {
                                if ( typeof item.extra_service != 'undefined' ) {
                                    var cur_sv = item.extra_service;
                                    cur_sv.splice(-1);
                                    item.extra_service = cur_sv;
                                }
                            }
                            return item;
                        } );
                    }
                    
                    CART.add_store();
                },

                map_add_item: function(id, price, qty = null, multiple_service = false ) {
                    let obj = {
                        'id': id,
                        'price': price,
                        'extra_service': [],
                    };

                    var data_extra_service = [];
                    if ( $('.cart-ticket-info').attr('data-extra-service') ) {
                        data_extra_service = JSON.parse( $('.cart-ticket-info').attr('data-extra-service') );
                    }

                    var extra_item = [];
                    var extra_service = [];
                    if ( ! $.isEmptyObject( data_extra_service ) ) {

                        for (let key in data_extra_service ) {
                            var data = {
                                'id': data_extra_service[key].id,
                                'name': data_extra_service[key].name,
                                'price': data_extra_service[key].price,
                                'qty': 0,
                                'rest_qty': data_extra_service[key].rest_qty,
                                'max_qty': data_extra_service[key].max_qty,
                            };
                            extra_item.push(data);
                        }
                        extra_service = extra_service.concat( [extra_item] );   
                        obj.extra_service = extra_service;
                    }

                    if ( parseInt( qty ) ) obj['qty'] = qty;

                    //update localStorage
                    var check_item = CART.check_item(id);
                    if (check_item) {
                        //has item in cart increase qty +1
                        CART.contents = CART.contents.map(function(item) {
                            if ( item.id === id ) return item;
                        });
                    } else {
                        CART.contents.push(obj);
                    }
                    CART.add_store();
                },

                map_add_item_seat: function(id, price, person_type = null){
                    let obj = {
                        'id': id,
                        'price': price,
                        'extra_service': [],
                    };

                    if ( person_type !== null && person_type !== '' ) {
                        obj['person_type'] = person_type;
                    }

                    var data_extra_service = [];
                    if ( $('.cart-ticket-info').attr('data-extra-service') ) {
                        data_extra_service = JSON.parse( $('.cart-ticket-info').attr('data-extra-service') );
                    }

                    var extra_item = [];
                    var extra_service = [];

                    if ( ! $.isEmptyObject( data_extra_service ) ) {

                        for (let key in data_extra_service ) {
                            var data = {
                                'id': data_extra_service[key].id,
                                'name': data_extra_service[key].name,
                                'price': data_extra_service[key].price,
                                'qty': 0,
                                'rest_qty': data_extra_service[key].rest_qty,
                                'max_qty': data_extra_service[key].max_qty,
                            };
                            extra_item.push(data);
                        }
                        extra_service = extra_service.concat( [extra_item] );

                        obj.extra_service = extra_service;
                    }

                    //update localStorage
                    var check_item = CART.check_item(id);
                    if (check_item) {
                        //has item in cart increase qty +1
                        CART.contents = CART.contents.map(function(item) {
                            if ( item.id === id ) return item;
                        });
                    } else {
                        CART.contents.push(obj);
                    }
                    CART.add_store();
                },

                map_person_add_item: function( id, dataPersonType, dataPersonPrice ){

                    var data_extra_service = [];
                    if ( $('.cart-ticket-info').attr('data-extra-service') ) {
                        data_extra_service = JSON.parse( $('.cart-ticket-info').attr('data-extra-service') );
                    }

                    dataPersonPrice = JSON.parse( dataPersonPrice ).map( function(item){
                        item = parseFloat( item );
                        return item;
                    } );

                    var extra_item = [];
                    var extra_service = [];
                    if ( data_extra_service ) {

                        for (let key in data_extra_service ) {
                            var data = {
                                'id': data_extra_service[key].id,
                                'name': data_extra_service[key].name,
                                'price': data_extra_service[key].price,
                                'qty': 0,
                                'rest_qty': data_extra_service[key].rest_qty,
                                'max_qty': data_extra_service[key].max_qty,
                            };
                            extra_item.push(data);
                        }
                        extra_service.push( extra_item );
                    }

                    var data_person = [];
                    var i = 0;
                    for( const key in dataPersonType ){

                        var person_item = {
                            'id': key,
                            'name': dataPersonType[key],
                            'price': dataPersonPrice[i],
                            'qty': 0,
                            'extra_service': extra_service,
                        };

                        person_item.extra_service = [];

                        i += 1;
                        data_person.push( person_item );
                    }

                    var obj = {
                        'id': id,
                        'data_person': data_person,
                    };

                    CART.contents.push(obj);
                    CART.add_store();
                },

                map_person_update: function(id,key, qty = true , dataPersonType = null, dataPersonPrice = null){

                    var data_extra_service = [];
                    if ( $('.cart-ticket-info').attr('data-extra-service') ) {
                        data_extra_service = JSON.parse( $('.cart-ticket-info').attr('data-extra-service') );
                    }

                    if ( dataPersonPrice !== null ) {
                        dataPersonPrice = JSON.parse( dataPersonPrice ).map( function(item){
                            item = parseFloat( item );
                            return item;
                        } );
                    }
                    

                    var extra_item = [];
                    var extra_service = [];
                    if ( data_extra_service ) {

                        for (let key in data_extra_service ) {
                            var data = {
                                'id': data_extra_service[key].id,
                                'name': data_extra_service[key].name,
                                'price': data_extra_service[key].price,
                                'qty': 0,
                                'rest_qty': data_extra_service[key].rest_qty,
                                'max_qty': data_extra_service[key].max_qty,
                            };
                            extra_item.push(data);
                        }

                        extra_service.push( extra_item );
                    }

                    CART.contents = CART.contents.map(function(item) {
                        if (item.id === id) {
                            item.data_person.map( function(jtem){
                                if ( jtem.id === key ) {
                                    if ( qty == true ) {
                                        jtem.qty = jtem.qty + 1;
                                        jtem.extra_service = jtem.extra_service.concat( extra_service );
                                    } else {
                                        var $qty = jtem.qty - 1;
                                        if ( $qty < 0 ) {
                                            $qty = 0;
                                        }
                                        jtem.qty = $qty;
                                        var cur_extra_sv = jtem.extra_service;
                                        if ( cur_extra_sv.length ) {
                                            cur_extra_sv.splice(-1);
                                        }
                                        jtem.extra_service = cur_extra_sv;
                                    }
                                    
                                }
                                return jtem;
                            } );
                        }
                        return item;
                    });

                    CART.add_store();

                    var check_qty_per = 0;

                    CART.contents.forEach( function(item){
                        if ( typeof item.data_person != 'undefined' ) {
                            if ( item.id == id ) {
                                item.data_person.forEach( function(jtem){
                                    check_qty_per += jtem.qty;
                                } );
                            }
                            
                        }
                        
                    } );
                    
                    if ( check_qty_per < 1 ) {

                        CART.remove(id);
                        $('.imp-object.selected[data-title="'+id+'"]').removeClass('selected');
                    }

                    
                },

                map_create_item: function(id, name, price, qty) {
                    let obj = {
                        'id': id,
                        'price': price,
                        'qty': qty,
                    };

                    var check_item = CART.check_item(id);
                    if (!check_item) {
                        CART.contents.push(obj);
                    }

                    CART.add_store();
                },

                update_seat: function(id, position, seat) {
                    CART.contents = CART.contents.map(function(item) {
                        if (item.id === id) {
                            let arr_seat = item.seat;
                            arr_seat[position] = seat;
                            item.seat = arr_seat;
                        }
                        return item;
                    });
                    CART.add_store();
                },

                update_seat_price: function( id, newPrice, person_type = null ){

                    CART.contents = CART.contents.map(function(item) {
                        if ( item.id === id ) {
                            item['price'] = newPrice;
                            if ( person_type != "" && person_type != null ) {
                                item['person_type'] = person_type;
                            }
                        }
                        return item;
                    });

                    CART.add_store();
                },

                update_area: function( id, position, value ) {
                    CART.contents = CART.contents.map(function(item) {
                        if ( item.id === id ) {
                            item[position] = value;
                        }

                        return item;
                    });
                    CART.add_store();
                },

                reduce_seat: function(id, position) {
                    CART.contents = CART.contents.map(function(item) {
                        if (item.id === id) {
                            if (typeof(item.seat) != 'undefined') {
                                let arr_seat = item.seat;
                                if (typeof(arr_seat[position]) !== 'undefined') {
                                    arr_seat.splice(position, 1);
                                }
                                item.seat = arr_seat;
                            }
                        }
                        return item;
                    });
                    CART.add_store();
                },

                delete_seat: function(id) {
                    CART.contents = CART.contents.map(function(item) {
                        if (item.id == id) {
                            if (typeof item.seat !== 'undefined') {
                                delete item.seat;
                            }
                        }
                        return item;
                    });
                    CART.add_store();
                },

                remove: function(id) {
                    //remove an item entirely from CART.contents based on its id
                    CART.contents = CART.contents.filter(function(item) {
                        if ( item.id !== id ) return true;
                    });
                    //update localStorage
                    CART.add_store()
                },

                reduce: function(id) {
                    CART.contents = CART.contents.map(function(item) {
                        if ( item.id === id ){
                            item.qty = item.qty - 1;
                            var extra_service = item.extra_service;
                            if ( typeof extra_service !== 'undefined' ) {
                                extra_service.splice(-1);
                                item.extra_service = extra_service;
                            }
                            
                        }
                        return item;
                    });
                    CART.contents.forEach(function(item) {
                        if ( item.id === id && item.qty === 0 ) CART.remove(id);
                    });
                    CART.add_store();
                },

                check_item: function(id) {
                    let match = CART.contents.filter(function(item) {
                        if ( item.id == id ) return true;
                    });
                    if ( match && match[0] ) return match[0];
                },

                show_cart: function() {
                    let contents = CART.contents;
                    let html_info_cart = "";
                    let total = 0;
                    var seat_option = $(".cart_detail .cart-content .cart-ticket-info").attr("data-seat-option");

                    //empty select in wp-select
                    $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type .wp-select-seat").empty();
                    $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type .image-seat").hide();

                    // Discount
                    $(".cart_detail .cart-sidebar .total-discount").css('display', 'none');
                    $(".cart_detail .cart-sidebar .total-discount .discount-number").text('');
                    $(".cart_detail .cart-sidebar .total-discount .discount-number").attr('data-discount', '0');
                    $("#submit-code-discount").attr('data-discount-code', '');
                    $('.cart_detail .cart-sidebar .cart-info .total-cart-info').attr('data-price-before-tax', 0);
                    $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount .discount-number").empty();
                    $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount .discount-number").attr('data-discount', 0);
                    $(".cart_detail .cart-sidebar .form-discount").css('display', 'none');
                    $(".cart_detail .cart-sidebar #cart-discount-button").css('display', 'block');

                    var enable_tax              = $(".cart_detail .cart-content .cart-ticket-info").attr("data-enable-tax");
                    var data_type_system_fee    = $(".cart_detail .cart-content .cart-ticket-info").attr("data-type-system-fee");
                    var data_percent_tax        = parseFloat( $(".cart_detail .cart-content .cart-ticket-info").attr("data-percent-tax") );
                    var data_percent_system_fee = parseFloat( $(".cart_detail .cart-content .cart-ticket-info").attr("data-percent-system-fee") );
                    var data_fixed_system_fee   = parseFloat( $(".cart_detail .cart-content .cart-ticket-info").attr("data-fixed-system-fee") );
                    var data_type_price         = $(".cart_detail .cart-content .cart-ticket-info").find('input[name="ticket_ids"]').data('type-price');
                    var typePrice = '';
                    var extra_item_prefix = $('.cart-ticket-info').attr('data-extra-item-prefix');
                    
                    if ( isNaN( data_percent_tax ) ) {
                        data_percent_tax = 0;
                    }
                    if ( isNaN( data_percent_system_fee ) ) {
                        data_percent_system_fee = 0;
                    }
                    if ( isNaN( data_fixed_system_fee ) ) {
                        data_fixed_system_fee = 0;
                    }

                    //set background button when sold out all
                    var value_sold_out_all = $("input[name=sold_all]").val();
                    if ( value_sold_out_all == '1' ) {
                        $(".cart_detail .cart-sidebar .checkout_button a, .cart_detail .cart-sidebar .next_step_button a ").css({
                            "background": "#ccc",
                            "border-color": "#b0b0b0",
                            "color": "#888",
                            "font-weight": "500",
                            "text-shadow": "none"
                        }).text("Sold out");
                    }

                    var total_tax = 0;
                    var system_fee = 0;
                    var total_extra_service = 0;

                    if ( seat_option != 'map' ) {

                        contents.forEach(function(item) {
                            let sub_total, price, sub_total_display;
                            sub_total           = item.qty * item.price;
                            price               = EL_Frontend.cart_price_display(item.price);
                            sub_total_display   = EL_Frontend.cart_price_display(sub_total);
                            
                            var extra_service   = item.extra_service;
                            var html_info_seat  = '';
                            var setup_display_seat = $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + item.id).attr("data-setup_seat");

                            if ( seat_option == 'simple' && setup_display_seat == 'yes') {
                                var list_seat_store = item.seat;

                                if ( item.qty >= 1 ) {
                                    for ( let i = 0; i < item.qty; i++ ) {
                                        let position = 'empty';
                                        if ( typeof list_seat_store != 'undefined' ) {
                                            if ( list_seat_store.length > 0 && typeof list_seat_store[i] !== 'undefined' && list_seat_store[i] != "" ) {
                                                position = list_seat_store[i];
                                                html_info_seat += '<span class="seat-' + i + '">' + position + '</span>';
                                            }
                                        }
                                    }
                                }
                            }
                            html_info_cart += '<div class="item" data-id="'+item.id+'">';

                            html_info_cart += '<div data-qty="' + item.qty + '" data-price="' + sub_total + '"  class="item-info ' + item.id + '">';
                            html_info_cart += '<div class="info-type-ticket">';
                            html_info_cart += '<p class="title-ticket">' + item.name + '</p>';
                            html_info_cart += '<p class="price">' + price + '</p>';
                            html_info_cart += '<div class="wp-seat-info">' + html_info_seat + '</div>';
                            html_info_cart += '</div>';
                            html_info_cart += '<div class="info-sub-price">';
                            html_info_cart += '<p class="sub-number">' + item.qty + '</p>';
                            html_info_cart += '<p class="sub-price">' + sub_total_display + '</p>';
                            html_info_cart += '</div>';
                            html_info_cart += '</div>';

                            // Add extra services
                            if ( typeof extra_service != 'undefined' ) {
                                var $total_extra_sv = 0;
                                var extra_service_html = '';

                                extra_service.forEach( function(items,index,arr){
                                    items.forEach( function(item,jndex){
                                        var $count_jtem = jndex == 0 ? true : false;
                                        var $border_color_jtem = $count_jtem == true ? 'border_color' : '';

                                        extra_service_html += '<li class="extra-item '+$border_color_jtem+'"';
                                        extra_service_html += ' data-index="'+index+'"';
                                        extra_service_html += ' data-subtotal="'+(item.price * item.qty )+'"';
                                        extra_service_html += ' data-rest="'+item.rest_qty+'"';
                                        extra_service_html += ' data-price="'+item.price+'"';
                                        extra_service_html += ' data-qty="'+item.qty+'"';
                                        extra_service_html += ' data-max="'+item.max_qty+'"';
                                        extra_service_html += ' data-name="'+item.name+'"';
                                        extra_service_html += ' data-id="'+item.id+'" >';
                                            if ( $count_jtem == true ) {
                                                extra_service_html += '<span class="count_item">'+extra_item_prefix+(index+1)+'</span>';
                                            }
                                            
                                            extra_service_html += '<span class="name">'+item.name+'</span>';
                                            extra_service_html += '<span class="qty">';
                                                extra_service_html += '<span class="service-minus"><i aria-hidden="true" class="icon_minus-06"></i></span>';
                                                extra_service_html += '<span class="service-qty">'+item.qty+'</span>';
                                                extra_service_html += '<span class="service-plus"><i aria-hidden="true" class="icon_plus"></i></span>';
                                            extra_service_html += '</span>';
                                            extra_service_html += '<span class="price">'+EL_Frontend.cart_price_display(item.price * item.qty)+'</span>';
                                        extra_service_html += '</li>';
                                        $total_extra_sv += item.price * item.qty;
                                    } );
                                } );
                                total_extra_service += $total_extra_sv;
                                html_info_cart += '<ul class="extra-services" data-total="'+$total_extra_sv+'">';
                                html_info_cart += extra_service_html;
                                html_info_cart += '</ul>';
                            }

                            html_info_cart += '</div>';
                            total += sub_total;
                            

                            $(".cart-ticket-info .item-ticket-type .control span.qty-" + item.id).text(item.qty);

                            if ( seat_option == 'simple' && setup_display_seat == 'yes' ) {

                                var qty_item = item.qty;

                                if ( qty_item >= 1 ) {
                                    for ( var i = 0; i < qty_item; i++ ) {
                                        var list_rest_seat      = $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + item.id).attr("data-list-seat-rest");
                                        var select_seat_text    = $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + item.id).attr("data-select-seat-text");
                                        var html_option         = "";
                                        var list_seat_store     = item.seat;

                                        if ( typeof list_seat_store != 'undefined' ) {
                                            list_rest_seat = JSON.parse(list_rest_seat);

                                            html_option += "<option value=''>"+select_seat_text+"</option>";

                                            for ( var key in list_rest_seat ) {
                                                var selected = '';

                                                if ( list_rest_seat[key] == list_seat_store[i] ) {
                                                    selected = 'selected="selected"';
                                                }

                                                html_option += "<option " + selected + " value='" + list_rest_seat[key] + "'>" + list_rest_seat[key] + "</option>"
                                            }
                                        }


                                        let html_select = "";
                                        html_select += "<div class='item-select-num num-" + i + "' ><select data-position='" + i + "' data-id-ticket='" + item.id + "' name='select_seat' class='item-select-" + item.id + "'>";

                                        html_select += html_option;
                                        html_select += "</select></div>";
                                        $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + item.id + " .wp-select-seat").append(html_select);
                                        EL_Frontend.el_update_seat_option();
                                    }

                                     $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + item.id + " .image-seat").css('display', 'table-cell');
                                }
                            } else {
                                CART.delete_seat(item.id);
                            }

                            if ( seat_option != 'map' && data_type_price[item.id] == 'paid' ) {
                                typePrice = 'paid';
                            }
                        });
                        // add total extra price
                        total += total_extra_service;

                        switch( data_type_system_fee ) {
                            case 'percent':
                                system_fee = data_percent_system_fee / 100 * total;
                            break;
                            case 'amount':
                                system_fee = data_fixed_system_fee;
                            break;
                            case 'both':
                                system_fee += data_percent_system_fee / 100 * total;
                                system_fee += data_fixed_system_fee;
                            break;
                            default:
                        }

                        EL_Frontend.el_select2_simple_seat();
                        
                        
                        if ( enable_tax == 'yes' ) {

                            total_tax = ( data_percent_tax * total ) / 100;
                            if ( total_tax > 0 ) {
                                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax").css({'display': 'flex'});
                            } else {
                                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax").css({'display': 'none'});
                            }
                            
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax .tax-number").empty().text("+" + EL_Frontend.cart_price_display(total_tax));
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax .tax-number").attr("data-tax", total_tax);
                        } else {
                            
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax .tax-number").empty().text(0);
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax .tax-number").attr("data-tax", 0);
                        }

                        
                        if ( system_fee ) {

                            if ( total > 0 ) {
                                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee").css({'display': 'flex'});
                            } else {
                                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee").css({'display': 'none'});
                            }

                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee .system-fee-number").empty().text("+" + EL_Frontend.cart_price_display(system_fee));
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee .system-fee-number").attr("data-system-fee", system_fee);
                        } else {
                            
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee .system-fee-number").empty().text("+" + EL_Frontend.cart_price_display(system_fee));
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee .system-fee-number").attr("data-system-fee", system_fee);
                        }
                 
                        let total_display = total + total_tax;

                        if ( total > 0 ) {
                            total_display += system_fee;
                        }

                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .placeholder").css({
                            'display': 'none'
                        });
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .item-info.header").css({
                            'display': 'flex'
                        });

                        $(".cart_detail .cart-sidebar .cart-info .total-cart-info").attr("data-price", total);
                        $(".cart_detail .cart-sidebar .cart-info .total-cart-info span.total-price").empty().text( EL_Frontend.cart_price_display(total_display) );
                    } else {
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .placeholder").css({
                            'display': 'inline-block'
                        });
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .item-info.header").css({
                            'display': 'none'
                        });

                        $(".cart_detail .cart-sidebar .cart-info .total-cart-info").attr("data-price", total);
                        $(".cart_detail .cart-sidebar .cart-info .total-cart-info span.total-price").empty().text(EL_Frontend.cart_price_display(total + total_extra_service));

                        // Tax
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax").css({'display': 'none'});
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax .tax-number").empty().text(0);
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax .tax-number").attr("data-tax", 0);

                        // System fee
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee").css({'display': 'none'});
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee .system-fee-number").empty().text(0);
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee .system-fee-number").attr("data-system-fee", 0);
                    }

                    $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .wp-content-item").empty();
                    $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .wp-content-item").append(html_info_cart);
                },

                seat_map: function() {
                    var dataSeatName = [], dataSeatPrice = [];
                    var dataAreaName = [], dataAreaPrice = [], dataAreaQty = [];
                    var dataSeat        = $(document).find('input[name="ova-data-seat"]').data('seat');
                    var dataArea        = $(document).find('input[name="ova-data-seat"]').data('area');
                    var dataPersonType  = $(document).find('input[name="ova-data-seat"]').data('person-type');
                    var dataBooked      = $(document).find('input[name="ova-data-seat"]').data('seat-booked');
                    var dataPast        = $(document).find('input[name="ova-data-seat"]').data('seat-past');
                    var dataUpcoming    = $(document).find('input[name="ova-data-seat"]').data('seat-upcoming');
                    var seat_holding    = [];
                    if ( $('.cart-ticket-info.event_ticket_map_type').length ) {
                        seat_holding = JSON.parse( $('.cart-ticket-info').attr('data-seat-holding-ticket') );
                    }
                    

                    var arrSeat = el_convertObjectToArray( dataSeat );
                    var arrArea = el_convertObjectToArray( dataArea );

                    if ( typeof ImageMapPro != "undefined" ) {
                        // Image Map Pro Init
                        ImageMapPro.subscribe( ( action ) => {
                            if ( action.type === 'mapInit') {
                                el_mapInit();
                            }
                        });

                        // Image Map Pro Click
                        ImageMapPro.subscribe( ( action ) => {
                            if ( action.type === 'objectClick') {
                                el_objectClick( action.payload.object );
                            }
                        });
                    }

                    function el_get_person_price( area_id ){
                        for ( const area of dataArea ) {
                            if ( area.id === area_id ) {
                                if ( area['person_price'] !== undefined ) {
                                    return area['person_price'];
                                } else {
                                    return false;
                                }
                            }
                        }
                        return false;
                    }

                    function el_get_max_area( area_id ){
                        var $data_seat_available = $(document).find('input[name="ova-data-seat"]').attr('data-seat-available');
                        if ( $data_seat_available ) {
                            $data_seat_available = JSON.parse( $data_seat_available );
                            for ( const area in $data_seat_available ) {
                                if ( area == area_id ) {
                                    return $data_seat_available[area];
                                }
                            }
                        }
                        return 1;
                    }

                    function el_person_plus_event(){

                        $(document).on('click','.person_type_wrap .person_plus',function(){

                            var totalQty    = el_getTotalQtySeat();
                            var maxTicket   = parseInt( $('.cart-ticket-info').attr('data-max_ticket') );

                            if ( isNaN( maxTicket ) ) {
                                maxTicket = 0;
                            }

                            if ( totalQty >= maxTicket ) {
                                $(".cart_detail .error-empty-cart").css('display', 'none');
                                $(".cart_detail .error-seat-status").css('display', 'block');
                                $(".cart_detail .error-seat-status .error").css('display', 'none');
                                $(".cart_detail .error.error-max-num").css('display', 'inline-block');
                                 $(".cart_detail .error-area-status .error-maxium-number").css('display', 'none');
                                return false;
                            }

                            var person_type = $(this).closest('.person_type_item').find('.person_type');
                            var person_qty  = $(this).closest('.person_type_item').find('.person_qty');
                            var area_item   = $(this).closest('.area-item');
                            var $cur_qty    = parseInt( area_item.attr('data-qty') );
                            var $max_area   = parseInt( area_item.attr('data-max') );
                            var $qty        = parseInt( person_type.attr('data-qty') );
                            var $id         = area_item.attr('data-id');
                            var $key        = person_type.attr('data-key');
                            var dataPersonPrice;

                            if ( isNaN( $max_area ) ) {
                                $max_area = 0;
                            }
                            if ( isNaN( $qty ) ) {
                                $qty = 0;
                            }
                            if ( isNaN( $cur_qty ) ) {
                                $cur_qty = 0;
                            }

                            if ( $max_area - $cur_qty > 0 ) {
                                $qty += 1;
                            } else {
                                 $(".cart_detail .error.error-max-num").css('display', 'none');
                                $(".cart_detail .error-area-status").css('display', 'block');
                                var mess = $(".cart_detail .error-area-status .error-maxium-number").attr("data-mess");
                                $(".cart_detail .error-area-status .error-maxium-number").css('display', 'inline-block');
                                $(".cart_detail .error-area-status .error-maxium-number").html( mess +' ' + $max_area );
                                return false;
                            }

                            dataArea.forEach( function(item,i){
                                if ( item.id === $id ) {
                                    dataPersonPrice = dataArea[i]['person_price'];
                                }
                            } );

                            CART.map_person_update( $id, $key, true, dataPersonType, dataPersonPrice );

                            el_updateCartInfo();
                        });
                    }

                    function el_person_minus_event(){

                        $(document).on('click','.person_type_wrap .person_minus',function(){

                            var person_type = $(this).closest('.person_type_item').find('.person_type');
                            var person_qty  = $(this).closest('.person_type_item').find('.person_qty');
                            var area_item   = $(this).closest('.area-item');
                            var $cur_qty    = parseInt( person_type.attr('data-qty') );
                            var $qty        = parseInt( person_type.attr('data-qty') );
                            var $id         = area_item.attr('data-id');
                            var $key        = person_type.attr('data-key');

                            if ( isNaN( $cur_qty ) ) {
                                $cur_qty = 0;
                            }

                            CART.map_person_update( $id, $key, false );

                            $(document).find('.cart-ticket-info .error-area-status').css('display','none');
                            $(document).find('.cart-ticket-info .error-maxium-number').css('display','none');

                            el_updateCartInfo();
                        });
                    }

                    function el_updateCartInfo(){
         
                        var total = 0, totalTax = 0, systemFee = 0, totalSeat = 0, totalArea = 0, totalExtraService = 0, total_display = 0;

                        // Get data
                        var percentTax          = parseFloat( $('.cart-ticket-info').attr('data-percent-tax') );
                        var enable_tax          = $('.cart-ticket-info').attr('data-enable-tax');
                        var typeSystemFee       = $('.cart-ticket-info').attr('data-type-system-fee');
                        var percentSystemFee    = parseFloat( $('.cart-ticket-info').attr('data-percent-system-fee') );
                        var fixedSystemFee      = parseFloat( $('.cart-ticket-info').attr('data-fixed-system-fee') );
                        var eventID             = $('.cart-ticket-info').attr('data-id-event');
                        var calendarID          = $('.cart-ticket-info').attr('data-id-cal');
                        var dataLocalStorage    = JSON.parse( localStorage.getItem( eventID + '_' + calendarID ) );
                        var extra_item_prefix   = $('.cart-ticket-info').attr('data-extra-item-prefix');
                        var dataPersonTypeSeat  = JSON.parse( $('input[name="ova-data-seat"]').attr("data-person-type-seat") );

                        if ( isNaN( percentTax ) ) {
                            percentTax = 0;
                        }
                        if ( isNaN( percentSystemFee ) ) {
                            percentSystemFee = 0;
                        }
                        if ( isNaN( fixedSystemFee ) ) {
                            fixedSystemFee = 0;
                        }
 
                        // Calculate total seat
                        if ( dataSeatPrice && dataSeatPrice.length > 0 ) {
                            for ( let i = dataSeatPrice.length - 1; i >= 0; i-- ) {
                                totalSeat += parseFloat( dataSeatPrice[i] );
                            }
                        }

                        $('.wp-content-item').html("");
                        // Total Area and extra service
                        if ( $.isArray( dataLocalStorage ) && dataLocalStorage.length > 0 ) {

                            var $extra_service_html = '';
                            var $total_extra_sv = 0;
                            dataLocalStorage.forEach( function(item,index,arr){
                                var extra_service = item['extra_service'];
                                var $id = item['id'];
                                var $qty = parseInt( item['qty'] );
                                var $price = parseFloat( item['price'] );
                                var $data_person = item['data_person'];

                                if ( isNaN( $qty ) ) {
                                    $qty = 0;
                                }
                                if ( isNaN( $price ) ) {
                                    $price = 0;
                                }

                                // Update Seat on Cart Info
         
                                if ( $.isArray( dataSeatName ) && dataSeatName.length > 0 && dataSeatName.indexOf( $id ) !== -1 ) {
              
                                    var personPrice = [];
                                    var $html = '';
                                    var seatArr = el_get_arr_seat_item();
                                    // Get personPrice in Data Seat
                                    if ( $.isArray( seatArr ) && seatArr.length > 0 ) {
                                        for (var j = 0; j < seatArr.length; j++) {
                                            var seatItem = seatArr[j];
                                            if ( seatItem['id'].includes( $id ) ) {
                                                personPrice = seatItem['person_price'];
                                                break;
                                            }
                                        }
                                    }

                                    $html = '<div class="item-info seat-item seat-id-'+$id+'" data-id="'+$id+'">';
                                        $html += '<div class="info-type-ticket">';
                                            $html += '<p class="title-ticket">'+$id+'</p>';
                                        $html += '</div>';
                                        
                                        if ( ! $.isEmptyObject( dataPersonTypeSeat ) ) {
                                            
                                            $html += '<div class="info-person-type">';
                                                $html += '<select name="person_type" style="width: 100%;">';
                                                    var k = 0;
                                                    for( const key in dataPersonTypeSeat ){
                                                        var $selected = $price == personPrice[k] ? 'selected' : '';
                                                        $html += '<option value="'+personPrice[k]+'" '+$selected+'>'+dataPersonTypeSeat[key]+'</option>';
                                                        k++;
                                                    }
                                                $html += '</select>';
                                            $html += '</div>';
                                        }
                                        $html += '<div class="info-sub-price">'+EL_Frontend.cart_price_display( $price )+'</div>';
                                    $html += '</div>';

                                    $('.wp-content-item').append( $html );
                                    el_seat_change_price();
                                    var isRtl = $("body").hasClass("rtl") ? "rtl" : "ltr";
                                    $('.wp-content-item select[name="person_type"]').select2({
                                        dir: isRtl,
                                    });
                                }

                                // Area HTML
                                dataArea.forEach( function(area_item){
                                    if ( area_item.id === $id ) {
                                        var $subtotal = $qty * $price;
                                        var $per_subtotal = 0;
                                        var $per_qty = 0;
                                        var outer_html = '';
                                        var person_type_html = '';
                                        var htmlAreaInfo = '';

                                        if ( typeof $data_person != 'undefined' ) {
                                            person_type_html += '<ul class="person_type_wrap">';
                                            $data_person.forEach( function(per_item){
                                                person_type_html += '<li class="person_type_item">';
                                                    person_type_html += '<div class="person_type" data-key="'+per_item.id+'" data-name="'+per_item.name+'" data-qty="'+per_item.qty+'" data-price="'+per_item.price+'">'+per_item.name+'</div>';
                                                    person_type_html += '<div class="person_qty_wrap">';
                                                    person_type_html += '<span class="person_minus"><i aria-hidden="true" class="icon_minus-06"></i></span>';
                                                    person_type_html += '<span class="person_qty">'+per_item.qty+'</span>';
                                                    person_type_html += '<span class="person_plus"><i aria-hidden="true" class="icon_plus"></i></span>';
                                                    person_type_html += '</div>';
                                                    person_type_html += '<div class="person_price">'+EL_Frontend.cart_price_display( per_item.qty * per_item.price )+'</div>';
                                                person_type_html += '</li>';
                                                $per_subtotal += per_item.qty * per_item.price;
                                                $per_qty += per_item.qty;
                                            } );
                                            person_type_html += '</ul>';
                                        }

                                        if ( $subtotal > 0 ) {
                                            outer_html += '<div class="item-info area-item area-id-'+area_item.id+'" data-max="'+el_get_max_area( area_item.id )+'" data-id="'+area_item.id+'" data-qty="'+$qty+'" data-price="'+$subtotal+'">';
                                            htmlAreaInfo += '<div class="area-item-wrap">';
                                                htmlAreaInfo += '<div class="info-type-ticket">';
                                                    htmlAreaInfo += '<p class="title-ticket">' + area_item.id + '</p>';
                                                htmlAreaInfo += '</div>';
                                                if ( $qty > 0 ) {
                                                    htmlAreaInfo += '<div class="info-qty-ticket">';
                                                        htmlAreaInfo += '<span class="area-minus"><i aria-hidden="true" class="icon_minus-06"></i></span>';
                                                        htmlAreaInfo += '<span class="area-qty">'+$qty+'</span>';
                                                        htmlAreaInfo += '<span class="area-plus"><i aria-hidden="true" class="icon_plus"></i></span>';
                                                    htmlAreaInfo += '</div>';
                                                    htmlAreaInfo += '<div class="info-sub-price">' + EL_Frontend.cart_price_display( $subtotal ) + '</div>';
                                                }
                                            htmlAreaInfo += '</div>';
                                            outer_html += htmlAreaInfo;
                                            outer_html += person_type_html;
                                            outer_html += '</div>';
                                            // add total area
                                            totalArea += $subtotal;

                                        } else {
                                            outer_html += '<div class="item-info area-item area-id-'+area_item.id+'" data-max="'+el_get_max_area( area_item.id )+'" data-id="'+area_item.id+'" data-qty="'+$per_qty+'" data-price="'+$per_subtotal+'">';
                                            htmlAreaInfo += '<div class="area-item-wrap">';
                                                htmlAreaInfo += '<div class="info-type-ticket">';
                                                    htmlAreaInfo += '<p class="title-ticket">' + area_item.id + '</p>';
                                                htmlAreaInfo += '</div>';
                                            htmlAreaInfo += '</div>';
                                            outer_html += htmlAreaInfo;
                                            outer_html += person_type_html;
                                            outer_html += '</div>';
                                            // add total person type
                                            totalArea += $per_subtotal;
                                        }
                                        
                                        $('.wp-content-item').append(outer_html);
                                    }
                                        
                                });

                                // Extra service HTML
                                if ( typeof extra_service != 'undefined' ) {
                                    if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                        
                                        extra_service.forEach( function(e_item,e_index,e_arr){
                                            var $count_item = e_index == 0 ? true : false;
                                            var $border_color_item = $count_item == true ? 'border_color' : '';

                                                if ( $.isArray( e_item ) && e_item.length > 0 ) {
                                                    e_item.forEach( function( j_item,j_index,j_arr ){
                                                        var $count_jtem = j_index == 0 ? true : false;
                                                        var $border_color_jtem = $count_jtem == true ? 'border_color' : '';

                                                        $extra_service_html += '<li class="extra-item '+$border_color_jtem+'"';
                                                        $extra_service_html += ' data-seat="'+$id+'"';
                                                        $extra_service_html += ' data-map="area"';
                                                        $extra_service_html += ' data-index="'+e_index+'"';
                                                        $extra_service_html += ' data-subtotal="'+(j_item.price * j_item.qty )+'"';
                                                        $extra_service_html += ' data-rest="'+j_item.rest_qty+'"';
                                                        $extra_service_html += ' data-price="'+j_item.price+'"';
                                                        $extra_service_html += ' data-qty="'+j_item.qty+'"';
                                                        $extra_service_html += ' data-max="'+j_item.max_qty+'"';
                                                        $extra_service_html += ' data-name="'+j_item.name+'"';
                                                        $extra_service_html += ' data-id="'+j_item.id+'" >';
                                                            if ( $count_jtem == true ) {
                                                                var $after = '';
                                                                if ( $qty > 0 ) {
                                                                    $after = ' - '+ extra_item_prefix + ( e_index + 1 );
                                                                }
                                                                $extra_service_html += '<span class="count_item">'+ $id + $after +'</span>';
                                                     
                                                            }
                                                            $extra_service_html += '<span class="name">'+ j_item.name +'</span>';
                                                            $extra_service_html += '<span class="qty">';
                                                                $extra_service_html += '<span class="service-minus"><i aria-hidden="true" class="icon_minus-06"></i></span>';
                                                                $extra_service_html += '<span class="service-qty">'+j_item.qty+'</span>';
                                                                $extra_service_html += '<span class="service-plus"><i aria-hidden="true" class="icon_plus"></i></span>';
                                                            $extra_service_html += '</span>';
                                                            $extra_service_html += '<span class="price">'+EL_Frontend.cart_price_display(j_item.price * j_item.qty)+'</span>';
                                                        $extra_service_html += '</li>';

                                                        $total_extra_sv += j_item.price * j_item.qty;
                                                    } );
                                                }

                                        } );

                                    }
                                }

                                if ( typeof $data_person != 'undefined' ) {

                                    if ( $.isArray( $data_person ) && $data_person.length > 0 ) {
                                        $data_person.forEach( function(item){
                                            var per_name = item['name'];
                                            var qty = parseInt( item['qty'] );
                                            var price = parseFloat( item['price'] );
                                            var id_person = item['id'];
                                            var extra_service = item['extra_service'];

                                            if ( isNaN( qty ) ) {
                                                qty = 0;
                                            }
                                            if ( isNaN( price ) ) {
                                                price = 0;
                                            }

                                            if ( typeof extra_service != 'undefined' && qty > 0 ) {

                                                if ( $.isArray( extra_service ) && extra_service.length > 0 ) {

                                                    extra_service.forEach( function(e_item,e_index,e_arr){

                                                        
                                                        e_item.forEach( function( j_item,j_index,j_arr ){
                                                            var $count_item = j_index == 0 ? true : false;
                                                            var $border_color_item = $count_item == true ? 'border_color' : '';

                                                            $extra_service_html += '<li class="extra-item '+$border_color_item+'"';
                                                            $extra_service_html += ' data-seat="'+$id+'"';
                                                            $extra_service_html += ' data-map="area"';
                                                            $extra_service_html += ' data-person="'+id_person+'"';
                                                            $extra_service_html += ' data-index="'+e_index+'"';
                                                            $extra_service_html += ' data-subtotal="'+(j_item.price * j_item.qty )+'"';
                                                            $extra_service_html += ' data-rest="'+j_item.rest_qty+'"';
                                                            $extra_service_html += ' data-price="'+j_item.price+'"';
                                                            $extra_service_html += ' data-qty="'+j_item.qty+'"';
                                                            $extra_service_html += ' data-max="'+j_item.max_qty+'"';
                                                            $extra_service_html += ' data-name="'+j_item.name+'"';
                                                            $extra_service_html += ' data-id="'+j_item.id+'" >';
                                                                if ( $count_item == true ) {
                                                                    $extra_service_html += '<span class="count_item">'+ $id + ' - ' + per_name + ' - '+ extra_item_prefix + ( e_index + 1 ) +'</span>';
                                                                }
                                                                
                                                                $extra_service_html += '<span class="name">'+ j_item.name +'</span>';
                                                                $extra_service_html += '<span class="qty">';
                                                                    $extra_service_html += '<span class="service-minus"><i aria-hidden="true" class="icon_minus-06"></i></span>';
                                                                    $extra_service_html += '<span class="service-qty">'+j_item.qty+'</span>';
                                                                    $extra_service_html += '<span class="service-plus"><i aria-hidden="true" class="icon_plus"></i></span>';
                                                                $extra_service_html += '</span>';
                                                                $extra_service_html += '<span class="price">'+EL_Frontend.cart_price_display(j_item.price * j_item.qty)+'</span>';
                                                            $extra_service_html += '</li>';

                                                            $total_extra_sv += j_item.price * j_item.qty;

                                                        } );

                                                    });
                                                }
                                            }

                                        } );
                                    }

                                }
                                
                            } );

                            totalExtraService += $total_extra_sv;

                            if ( $extra_service_html != '' ) {
                                $('.extra-services').attr('data-total',$total_extra_sv);
                                $('.extra-services').html( $extra_service_html );
                            } else {
                                $('.extra-services').attr('data-total','0');
                                $('.extra-services').html('');
                            }

                            $('.wp-content-item').css('display', 'block');
                            $('.item-info.header').css('display', 'flex');

                            $('.cart-info .placeholder').css('display', 'none');
                        } else {

                            $('.extra-services').attr('data-total','0');
                            $('.extra-services').html('');

                            $('.item-info.header').css('display', 'none');
                            $('.wp-content-item').css('display', 'none');
                            $('.total-tax').css('display', 'none');
                            $('.system-fee').css('display', 'none');
                            $('.cart_detail .total-cart-info').attr("data-price-before-tax", 0);
                            $('.cart_detail .total-cart-info').attr("data-price", 0);
                            $('.cart-info .placeholder').css('display', 'block');
                        }

                        // Total
                        total = totalSeat + totalArea + totalExtraService;
           
                        // Total before tax
                        var totalBeforeTax = total;
                        total_display += total;
                        // Total tax
                        if ( enable_tax == 'yes' ) {
                            totalTax = ( percentTax * total ) / 100;
                            
                            if ( totalTax > 0 ) {
                                $('.total-tax').css('display', 'flex');
                            } else {
                                $('.total-tax').css('display', 'none');
                            }
                            
                        } else {
                            $('.total-tax').css('display', 'none');
                        }

                        total_display += totalTax;

                        // System fee
                        switch( typeSystemFee ) {
                            case 'percent':
                                systemFee = ( percentSystemFee * total ) / 100;
                            break;
                            case 'amount':
                                systemFee = fixedSystemFee;
                            break;
                            case 'both':
                                systemFee += ( percentSystemFee * total ) / 100;
                                systemFee += fixedSystemFee;
                            break;
                            default:
                        }
                        if ( systemFee > 0 ) {

                            if ( total > 0 ) {
                                $('.system-fee').css('display', 'flex');
                            } else {
                                $('.system-fee').css('display', 'none');
                            }
                            
                        } else {
                            $('.system-fee').css('display', 'none');
                        }

                        if ( total_display > 0 ) {
                            total_display += systemFee;
                        }

                        // Update attr cart info
                        $('.content-cart-info .tax-number').attr('data-tax', totalTax );
                        $('.content-cart-info .system-fee-number').attr('data-system-fee', systemFee );
                        $('.cart_detail .total-cart-info').attr("data-price-before-tax", totalBeforeTax );
                        $('.cart_detail .total-cart-info').attr("data-price", total_display );

                        // Update cart info
                        $(".cart-info .tax-number").empty().text("+" + EL_Frontend.cart_price_display( totalTax ));
                        $(".cart-info .system-fee-number").empty().html("+" + EL_Frontend.cart_price_display( systemFee ));
                        $(".cart-info .total-price").empty().text(EL_Frontend.cart_price_display( total_display ));

                        // Reset Discount
                        $(".cart_detail .cart-sidebar .total-discount").css('display', 'none');
                        $(".cart_detail .cart-sidebar .total-discount .discount-number").text('');
                        $(".cart_detail .cart-sidebar .total-discount .discount-number").attr('data-discount', '0');
                        $("#submit-code-discount").attr('data-discount-code', '');
                        $(".cart_detail .cart-sidebar .form-discount").css('display', 'none');
                        $(".cart_detail .cart-sidebar #cart-discount-button").css('display', 'block');

                        EL_Frontend.cart_next_step_hidden();
                    }

                    function el_check_max_qty_area( $max_area, $total_qty ){

                        if ( parseInt( $max_area ) == parseInt( $total_qty ) ) {
                            $(document).find('.cart-ticket-info .error-area-status').css('display','block');
                            var error = $(document).find('.cart-ticket-info .error-maxium-number');
                            var $mess = error.attr('data-mess');
                            error.html( $mess + ' ' + $max_area );
                            error.css('display','inline-block');
                            return false;
                        }
                        if ( parseInt( $max_area ) - parseInt( $total_qty ) >= 0 ) {
                            return $total_qty;
                        }     
                    }
                    
                    function el_get_arr_seat_item(){
                        let seatArr = [];
                        const dataPersonTypeSeat = JSON.parse( $('input[name="ova-data-seat"]').attr("data-person-type-seat") );
                        let dataPersonArr = [];
                        if ( ! $.isEmptyObject( dataPersonTypeSeat ) ) {
                            for(const key in dataPersonTypeSeat ){
                                dataPersonArr.push( dataPersonTypeSeat[key] );
                            }
                        }

                        if ( $.isArray( dataSeat ) && dataSeat.length > 0 ) {
                            for (var j = 0; j < dataSeat.length; j++) {
                                let seatItem = dataSeat[j];
                                let personPrice = seatItem['person_price'] ? JSON.parse( seatItem['person_price'] ) : [];
                                let seatItemArr = seatItem['id'].split(",");
                                seatItemArr = $.map( seatItemArr,function(val){
                                    return val.trim();
                                } );
                                let data = {
                                    'id': seatItemArr,
                                    'person_price': personPrice,
                                    'data_person': dataPersonArr,
                                    'price': seatItem['price']
                                };
                                seatArr = seatArr.concat( [data] );
                            }
                        }
                        return seatArr;
                    }

                    // IMP init
                    function el_mapInit() {

                        if ( ! ImageMapPro.isMobile() ) {
                            $(document).find('.imp-fullscreen-button').css('display', 'none');
                        }
                        var seat_holding = JSON.parse( $('.cart-ticket-info').attr('data-seat-holding-ticket') );

                        var dataPersonTypeSeat = JSON.parse( $('input[name="ova-data-seat"]').attr("data-person-type-seat") );

                        let htmlCartInfo    = '';
                        let eventID         = $('.cart-ticket-info').data('id-event');
                        let calendarID      = $('.cart-ticket-info').data('id-cal');

                        el_person_plus_event();
                        el_person_minus_event();

                        // Map Seat Holding
                        if ( seat_holding.length > 0 ) {
                            for(let seat of seat_holding){
                                CART.remove(seat);
                                $(document).find('.imp-objects .imp-object[data-title="' + seat + '"]').addClass('holding');
                            }
                        }

                        // Map Seat Booked
                        for ( var x = dataBooked.length - 1; x >= 0; x-- ) {
                            if ( ( $.isArray( arrSeat ) && $.inArray( dataBooked[x], arrSeat ) !== -1 ) || ( $.isArray( arrArea ) && $.inArray( dataBooked[x], arrArea ) !== '-1' ) ) {
                                CART.remove(dataBooked[x]);
                                $(document).find('.imp-objects .imp-object[data-title="' + dataBooked[x] + '"]').addClass('booked');
                            }
                        }

                        // Map Seat Past
                        for ( var x = dataPast.length - 1; x >= 0; x-- ) {
                            if ( ( $.isArray( arrSeat ) && $.inArray( dataPast[x], arrSeat ) !== -1 ) || ( $.isArray( arrArea ) && $.inArray( dataPast[x], arrArea ) !== -1 ) ) {
                                CART.remove(dataPast[x]);
                                $(document).find('.imp-objects .imp-object[data-title="' + dataPast[x] + '"]').addClass('past');
                            }
                        }

                        // Map Seat Upcoming
                        for ( var x = dataUpcoming.length - 1; x >= 0; x-- ) {
                            if ( ( $.isArray( arrSeat ) && $.inArray( dataUpcoming[x], arrSeat ) !== -1 ) || ( $.isArray( arrArea ) && $.inArray( dataUpcoming[x], arrArea ) !== -1 ) ) {
                                CART.remove(dataUpcoming[x]);
                                $(document).find('.imp-objects .imp-object[data-title="' + dataUpcoming[x] + '"]').addClass('upcoming');
                            }
                        }

                        // Get Data local storage
                        let dataLocalStorage = JSON.parse( localStorage.getItem( eventID + '_' + calendarID ) );

                        if ( $.isArray( dataLocalStorage ) && dataLocalStorage.length ) {

                            $('.wp-content-item').css('display', 'block');
                            $('.item-info.header').css('display', 'flex');
                            $('.total-tax').css('display', 'flex');
                            $('.system-fee').css('display', 'flex');
                            $('.cart-info .placeholder').css('display', 'none');

                        } else {
                            $('.wp-content-item').css('display', 'none');
                            $('.item-info.header').css('display', 'none');
                            $('.total-tax').css('display', 'none');
                            $('.system-fee').css('display', 'none');
                            $('.cart-info .placeholder').css('display', 'block');
                        }

                        // Map Selected Local Storage
                        for ( var i = 0; i < dataLocalStorage.length; i++ ) {
                            // Add HTML Seat Info
                            if ( $.inArray( dataLocalStorage[i].id, arrSeat ) !== -1 ) {

                                // Add Data Seat
                                if ( $.inArray( dataLocalStorage[i].id, dataSeatName ) == -1 ) {
                                    dataSeatName.push(dataLocalStorage[i].id);
                                    dataSeatPrice.push(dataLocalStorage[i].price);
                                }
                                if ( ! $.isEmptyObject( dataPersonTypeSeat ) ) {
                                    
                                    let personPrice = [];
                                    let $html = '';
                                    let seatArr = el_get_arr_seat_item();
                                    // Get personPrice in Data Seat
                                    if ( $.isArray( seatArr ) && seatArr.length > 0 ) {
                                        for (var j = 0; j < seatArr.length; j++) {
                                            let seatItem = seatArr[j];
                                            if ( seatItem['id'].indexOf( dataLocalStorage[i].id ) !== -1 ) {
                                                personPrice = seatItem['person_price'];
                                                break;
                                            }
                                        }
                                    }
                                    
                                    $html = '<div class="item-info seat-item seat-id-'+dataLocalStorage[i].id+'"  data-id="'+dataLocalStorage[i].id+'">';
                                        $html += '<div class="info-type-ticket">';
                                            $html += '<p class="title-ticket">'+dataLocalStorage[i].id+'</p>';
                                        $html += '</div>';
                                        $html += '<div class="info-person-type">';
                                            $html += '<select name="person_type" style="width: 100%;">';
                                                let k = 0;
                                                for( const key in dataPersonTypeSeat ){
                                                    let $selected = dataLocalStorage[i].price == personPrice[k] ? 'selected' : '';
                                                    $html += '<option value="'+personPrice[k]+'" '+$selected+'>'+dataPersonTypeSeat[key]+'</option>';
                                                    k++;
                                                }
                                            $html += '</select>';
                                        $html += '</div>';
                                        $html += '<div class="info-sub-price">'+EL_Frontend.cart_price_display( dataLocalStorage[i].price )+'</div>';
                                    $html += '</div>';

                                    $('.wp-content-item').append( $html );
                                    var isRtl = $("body").hasClass("rtl") ? "rtl" : "ltr";
                                    $('.wp-content-item select[name="person_type"]').select2({
                                        dir: isRtl
                                    });
                                    el_seat_change_price();

                                } else {
                                    let $html = '';
                                    $html = '<div class="item-info seat-item seat-id-'+dataLocalStorage[i].id+'" data-id="'+dataLocalStorage[i].id+'">';
                                        $html += '<div class="info-type-ticket">';
                                            $html += '<p class="title-ticket">'+dataLocalStorage[i].id+'</p>';
                                        $html += '</div>';

                                        $html += '<div class="info-sub-price">'+EL_Frontend.cart_price_display( dataLocalStorage[i].price )+'</div>';
                                    $html += '</div>';

                                    $('.wp-content-item').append( $html );

                                }

                            }

                            // Add HTML Area Info
                            if ( $.inArray( dataLocalStorage[i].id, arrArea ) !== -1 ) {

                                // Add Data Area

                                if ( $.inArray( dataLocalStorage[i].id, dataAreaName ) == -1 ) {
                                    dataAreaName.push(dataLocalStorage[i].id);
                                    if ( ! dataLocalStorage[i].data_person ) {
                                        dataAreaPrice.push(dataLocalStorage[i].price);
                                        dataAreaQty.push(dataLocalStorage[i].qty);
                                    }
                                }
                                
                            }
                            
                            
                            // Add selected class
                            $(document).find('.imp-objects .imp-object[data-title="' + dataLocalStorage[i].id + '"]').attr('selected', 'selected').addClass('selected');
                        }

                        // Update Cart Info
                        el_updateCartInfo();

    

                        // Check IDs on Map
                        if ( $('#el_cart .event_ticket_map_type .imp-objects .imp-object').length ) {
                            el_impObjectsCheck();
                        }
                    };

                    function el_seat_change_price(){

                        $('.info-person-type select[name="person_type"]').off().on("change",function(){
                            var price               = $(this).val();
                            var seatID              = $(this).closest(".seat-item").attr("data-id");
                            
                            // Update Cart Info
                            
                            let indexSeat = dataSeatName.indexOf(seatID);
                            if ( indexSeat !== -1 ) {
                                dataSeatName.splice(indexSeat,1);
                                dataSeatPrice.splice(indexSeat,1);
                            }

                            var seatArr = el_get_arr_seat_item();
                            
                            var person_type = '';

                            if ( $.isArray( seatArr ) && seatArr.length > 0 ) {
                                for (var i = 0; i < seatArr.length; i++) {
                                    let seatItem = seatArr[i];
                                    if ( seatItem.id.includes( seatID ) ) {
                                        let indexPrice = seatItem['person_price'].indexOf( price );
                                        if ( indexPrice !== -1 ) {
                                            person_type = seatItem['data_person'][indexPrice];
                                            break;
                                        }
                                    }
                                }
                            }
                            
                            CART.update_seat_price( seatID, price, person_type );

                            dataSeatName.push( seatID );
                            dataSeatPrice.push( price );

                            el_updateCartInfo();
                        });
                    }

                    // IMP click 
                    function el_objectClick( shapeTitle ) {

                        if ( $.inArray( shapeTitle, dataBooked ) !== -1 ) {
                            $(".cart_detail .error-empty-cart").css('display', 'none');
                            $(".cart_detail .error-seat-status .error").css('display', 'none');
                            $(".cart_detail .error-area-status .error").css('display', 'none');

                            if ( $.isArray( arrArea ) && $.inArray( shapeTitle, arrArea ) !== -1 ) {                                
                                $(".cart_detail .error-area-status").css('display', 'block');
                                $(".cart_detail .error-area-status .error.error-outofstock").css('display', 'inline-block');
                            } else {
                                $(".cart_detail .error-seat-status").css('display', 'block');
                                $(".cart_detail .error-seat-status .error.error-booked").css('display', 'inline-block');
                            }

                            return false;
                        } else if ( $.inArray( shapeTitle, seat_holding ) !== -1 ){
                            $(".cart_detail .error-empty-cart").css('display', 'none');
                            $(".cart_detail .error-seat-status .error").css('display', 'none');
                            $(".cart_detail .error-area-status .error").css('display', 'none');

                            if ( $.isArray( seat_holding ) && $.inArray( shapeTitle, seat_holding ) !== -1 ) {  
                           
                                $(".cart_detail .error-seat-status").css('display', 'block');
                                $(".cart_detail .error-seat-status .error.error-holding").css('display', 'inline-block');
                            } else {
                                $(".cart_detail .error-seat-status").css('display', 'block');
                                $(".cart_detail .error-seat-status .error.error-holding").css('display', 'inline-block');
                            }

                            return false;
                            
                        } else if ( $.inArray( shapeTitle, dataPast ) !== -1 ) {

                            $(".cart_detail .error-empty-cart").css('display', 'none');
                            $(".cart_detail .error-seat-status .error").css('display', 'none');
                            $(".cart_detail .error-area-status .error").css('display', 'none');

                            if ( $.isArray( arrArea ) && $.inArray( shapeTitle, arrArea ) !== -1 ) {                                
                                $(".cart_detail .error-area-status").css('display', 'block');
                                $(".cart_detail .error-area-status .error.error-closed").css('display', 'inline-block');
                            } else {
                                $(".cart_detail .error-seat-status").css('display', 'block');
                                $(".cart_detail .error-seat-status .error.error-closed").css('display', 'inline-block');
                            }
                            return false;

                        } else if ( $.inArray( shapeTitle, dataUpcoming ) !== -1 ) {
                            $(".cart_detail .error-empty-cart").css('display', 'none');
                            $(".cart_detail .error-seat-status .error").css('display', 'none');
                            $(".cart_detail .error-area-status .error").css('display', 'none');

                            if ( $.isArray( arrArea ) && $.inArray( shapeTitle, arrArea ) !== -1 ) {                                
                                $(".cart_detail .error-area-status").css('display', 'block');
                                $(".cart_detail .error-area-status .error.error-upcoming").css('display', 'inline-block');
                            } else {
                                $(".cart_detail .error-seat-status").css('display', 'block');
                                $(".cart_detail .error-seat-status .error.error-upcoming").css('display', 'inline-block');
                            }                                

                            return false;
                        } else {
                            
                            $(document).find('.imp-objects .imp-object[data-title="' + shapeTitle + '"]').each(function() {
                                // Seat Error
                                $(".cart_detail .error-empty-cart").css('display', 'none');
                                $(".cart_detail .error-seat-status").css('display', 'none');
                                $(".cart_detail .error-seat-status .error").css('display', 'none');
                                $(".cart_detail .error-area-status").css('display', 'none');
                                $(".cart_detail .error-area-status .error").css('display', 'none');

                                // Remove selected imp object
                                if ( $(this).hasClass('selected') ) {
                                    $(this).removeClass('selected').removeAttr('selected');
                                    $(document).find('.imp-objects .imp-object[data-title="' + shapeTitle + '"]').removeClass('selected').removeAttr('selected');

                                    // Check Seat
                                    if ( dataSeatName.length > 0 ) {
                                        for ( let i = dataSeatName.length - 1; i >= 0; i-- ) {
                                            if ( shapeTitle == dataSeatName[i] ) {
                                                dataSeatName.splice(i, 1);
                                                dataSeatPrice.splice(i, 1);
                                                $(document).find('.wp-content-item .seat-id-'+shapeTitle).remove();
                                                // Update cart
                                                CART.remove(shapeTitle);
                                            }
                                        }
                                    }

                                    // Check Area
                                    if ( dataAreaName.length > 0 ) {
                                        for ( let i = dataAreaName.length - 1; i >= 0; i-- ) {
                                            if ( shapeTitle == dataAreaName[i] ) {
                                                dataAreaName.splice(i, 1);
                                                dataAreaPrice.splice(i, 1);
                                                dataAreaQty.splice(i, 1);

                                                // Remove area
                                                $(document).find('.wp-content-item .area-id-'+shapeTitle).remove();
                                                
                                                // Update cart
                                                CART.remove(shapeTitle);
                                            }
                                        }
                                    }
                                } else {
                                    var totalQty    = el_getTotalQtySeat();
                                    let maxTicket   = $('.cart-ticket-info').data('max_ticket');

                                    if ( totalQty >= maxTicket ) {
                                        $(".cart_detail .error-empty-cart").css('display', 'none');
                                        $(".cart_detail .error-seat-status").css('display', 'block');
                                        $(".cart_detail .error-seat-status .error").css('display', 'none');
                                        $(".cart_detail .error.error-max-num").css('display', 'inline-block');

                                        return false;
                                    }

                                    $(this).attr('selected', 'selected').addClass('selected');
                                    $(document).find('.imp-objects .imp-object[data-title="' + shapeTitle + '"]').attr('selected', 'selected').addClass('selected');

                                    // Add Seat
                                    if ( $.inArray( shapeTitle, arrSeat ) !== -1 ) {
                                        var dataPersonTypeSeat = JSON.parse( $('input[name="ova-data-seat"]').attr("data-person-type-seat") );

                                        for ( let i = 0; i < dataSeat.length; i++ ) {
                                            let seat, $html = '';
                                            
                                            seat = dataSeat[i].id.split(',');
                                            seat = seat.filter(function(el) {
                                                return el != null && el != "" && el != " ";
                                            });

                                            seat = seat.map(function(el) {
                                                return el.trim();
                                            });

                                            if ( seat.indexOf(shapeTitle) !== -1 ) {

                                                dataSeatName.push(shapeTitle);

                                                // For Person Type
                                                if ( ! $.isEmptyObject( dataPersonTypeSeat ) ) {
                                                    var seatArr = el_get_arr_seat_item();
                                                    
                                                    var personPrice = [];
                                                    if ( $.isArray( seatArr ) && seatArr.length > 0 ) {
                                                        for (var j = 0; j < seatArr.length; j++) {
                                                            let seatItem = seatArr[j];
                                                            if ( seatItem['id'].indexOf( shapeTitle ) !== -1 ) {
                                                                personPrice = seatItem['person_price'];
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    
                                                    var personType = '';
                                                    dataSeatPrice.push( personPrice[0] );

                                                    var k = 0;
                                                    for( const key in dataPersonTypeSeat ){
                                                        let $selected = k == 0 ? 'selected' : '';
                                                        if ( k == 0 ) {
                                                            personType = dataPersonTypeSeat[key];
                                                        }
            
                                                        k++;
                                                    }
                                     
                                                    CART.map_add_item_seat( shapeTitle, personPrice[0], personType );
                                     
                                                } else {
                                                    
                                                    dataSeatPrice.push(dataSeat[i].price);
                                                    
                                                    CART.map_add_item( shapeTitle, dataSeat[i].price );
                                                }

                                            }
                                        }
                                    }

                                    // Add Area

                                    if ( $.inArray( shapeTitle, arrArea ) !== -1 ) {
                                        for ( let i = 0; i < dataArea.length; i++ ) {
                                            let areaID, htmlAreaInfo = '';
                                            areaID = dataArea[i].id;

                                            if ( areaID ) areaID.trim();
                                            if ( areaID === shapeTitle ) {

                                                dataAreaName.push(shapeTitle);

                                                // check person type
                                                if ( JSON.parse( dataArea[i].person_price  ).length > 0 ) {
                                                    CART.map_person_add_item( shapeTitle, dataPersonType, dataArea[i].person_price );
                                                } else {

                                                    if ( ! isNaN( parseFloat( dataArea[i].price ) ) ) {
                                                        dataAreaPrice.push( dataArea[i].price );
                                                        dataAreaQty.push(1);
                                                        CART.map_add_item(shapeTitle, dataArea[i].price, 1, true );
                                                    }
                                                }                        
                                                
                                            }
                                        }
                                    }
                                }

                                // Update Cart Info
                                el_updateCartInfo();
                                
                            });
                        }
                    };

                    // Checke IMP Object
                    function el_impObjectsCheck() {
                        $('#el_cart .event_ticket_map_type .imp-objects .imp-object:not(.booked)').each( function() {
                            var title = $(this).data('title');

                            if ( ( $.isArray( arrSeat ) && $.inArray( title, arrSeat ) == -1 ) && ( $.isArray( arrArea ) && $.inArray( title, arrArea ) == -1 ) ) {
                                dataBooked.push( title );
                                $(this).addClass('booked');
                            }
                        });
                    }

                    // Convert Object to Array
                    function el_convertObjectToArray( dataSeat = null ) {
                        if ( ! dataSeat ) return '';

                        var result = '';

                        for ( var i = dataSeat.length - 1; i >= 0; i-- ) {
                            if ( i == 0 ) {
                                result += dataSeat[i].id;
                            } else {
                                result += dataSeat[i].id + ', ';
                            }
                        }

                        if ( result ) {
                            result = result.split(',');

                            if ( $.isArray( result ) ) {
                                result = $.map( result, function( value ) {
                                    return value.replace(/ /g, '').trim();
                                });
                            }
                        }

                        return result;
                    }

                    // Get Total Qty
                    function el_getTotalQtySeat() {
                        var totalQty    = 0;
                        var countSeat   = $('.cart_detail .wp-seat-info').children('span').length;

                        if ( countSeat ) totalQty += parseInt( countSeat );

                        $('.wp-content-item .area-item').each( function() {
                            var areaQty = $(this).find('.area-qty').text();
                            if ( ! areaQty ) {
                                areaQty = $(this).attr( 'data-qty' );
                            }
                            if ( areaQty ) totalQty += parseInt( areaQty );
                        });

                        return totalQty;
                    }

                    el_areaPlus();
                    el_areaMinus();

                    // Plus area button
                    function el_areaPlus() {
                        $(document).on( 'click', '.wp-content-item .area-plus', function(e) {
                            e.preventDefault();

                            if ( $(this).hasClass('disable') ) return false;

                            // Seat Error
                            $(".cart_detail .error-empty-cart").css('display', 'none');
                            $(".cart_detail .error-area-status").css('display', 'none');
                            $(".cart_detail .error-area-status .error").css('display', 'none');

                            let totalQty    = el_getTotalQtySeat();
                            let maxTicket   = $('.cart-ticket-info').data('max_ticket');


                            if ( totalQty >= maxTicket ) {
                                $(".cart_detail .error-empty-cart").css('display', 'none');
                                $(".cart_detail .error-area-status").css('display', 'block');
                                $(".cart_detail .error-area-status .error").css('display', 'none');
                                $(".cart_detail .error.error-max-num").css('display', 'inline-block');
                                return false;
                            }

                            var area_item   = $(this).closest('.area-item');
                            var $max_area   = area_item.attr('data-max');
                            var $qty        = parseInt( area_item.attr('data-qty') );
                            if ( ! el_check_max_qty_area( $max_area, $qty ) ) {
                                return false;
                            }

                            var currentID   = $(this).closest('.area-item').find('.title-ticket').text();
                            var currentQty  = $(this).closest('.area-item').find('.area-qty').text();
                            let eventID     = $('.cart-ticket-info').data('id-event');
                            let calendarID  = $('.cart-ticket-info').data('id-cal');
                            let datalocalStorage = JSON.parse( localStorage.getItem( eventID + '_' + calendarID ) );

                            if ( datalocalStorage.length > 0 ) {
                                // Index Ticket ID
                                let index = dataAreaName.indexOf(currentID);

                                for ( var i = 0; i < datalocalStorage.length; i++ ) {
                                    if ( datalocalStorage[i].id === currentID && index !== -1 ) {
                                        datalocalStorage[i].qty += 1;
                                        dataAreaQty[index] += 1;

                                        $(this).closest('.area-item').attr('data-qty', datalocalStorage[i].qty);
                                        $(this).closest('.area-item').attr('data-price', parseFloat(datalocalStorage[i].price) * parseInt(datalocalStorage[i].qty));
                                        $(this).closest('.area-item').find('.area-qty').text(datalocalStorage[i].qty);
                                        $(this).closest('.area-item').find('.info-sub-price').text(EL_Frontend.cart_price_display(parseFloat(datalocalStorage[i].price) * parseInt(datalocalStorage[i].qty)));

                                        CART.update_area(currentID, 'qty', datalocalStorage[i].qty);
                                        CART.add_extra_service_area( currentID );
                                        // Update cart info
                                        el_updateCartInfo();
                                    }
                                }
                            }                            
                        });
                    }

                    // Minus area button
                    function el_areaMinus() {
                        $(document).on( 'click', '.wp-content-item .area-minus', function(e) {
                            e.preventDefault();

                            if ( $(this).hasClass('disable') ) return false;

                            // Seat Error
                            $(".cart_detail .error-empty-cart").css('display', 'none');
                            $(".cart_detail .error-area-status").css('display', 'none');
                            $(".cart_detail .error-area-status .error").css('display', 'none');

                            var currentID   = $(this).closest('.area-item').find('.title-ticket').text();
                            var currentQty  = $(this).closest('.area-item').find('.area-qty').text();

                            let eventID     = $('.cart-ticket-info').data('id-event');
                            let calendarID  = $('.cart-ticket-info').data('id-cal');
                            let datalocalStorage = JSON.parse( localStorage.getItem( eventID + '_' + calendarID ) );

                            if ( datalocalStorage.length > 0 ) {
                                for ( var i = 0; i < datalocalStorage.length; i++ ) {
                                    // Index Ticket ID
                                    let index = dataAreaName.indexOf(currentID);

                                    if ( datalocalStorage[i].id === currentID && index !== -1 ) {
                                        if ( datalocalStorage[i].qty > 1 ) {
                                            datalocalStorage[i].qty -= 1;
                                            dataAreaQty[index] -= 1;

                                            $(this).closest('.area-item').attr('data-qty', datalocalStorage[i].qty);
                                            $(this).closest('.area-item').attr('data-price', parseFloat(datalocalStorage[i].price) * parseInt(datalocalStorage[i].qty));
                                            $(this).closest('.area-item').find('.area-qty').text(datalocalStorage[i].qty);
                                            $(this).closest('.area-item').find('.info-sub-price').text(EL_Frontend.cart_price_display(parseFloat(datalocalStorage[i].price) * parseInt(datalocalStorage[i].qty)));

                                            CART.update_area(currentID, 'qty', datalocalStorage[i].qty);
                                            CART.add_extra_service_area( currentID, false );
                                        } else {
                                            $('.wp-content-item .area-id-'+currentID).remove();
                                            $(document).find('.imp-objects .imp-object[data-title="' + currentID + '"]').removeClass('selected').removeAttr('selected');

                                            // Update data
                                            dataAreaName.splice(index, 1);
                                            dataAreaPrice.splice(index, 1);
                                            dataAreaQty.splice(index, 1);

                                            // Update cart
                                            CART.remove(currentID);
                                        }

                                        // Update cart info
                                        el_updateCartInfo();
                                    }
                                }
                            }
                        });
                    }
                },

                check_tickets: function() {
                    let contents = CART.contents;
                    var seat_option = $(".cart_detail .cart-content .cart-ticket-info").attr("data-seat-option");

                    // Check Ticket
                    if ( seat_option != 'map' ) {
                        var ticket_ids = $(".cart_detail .cart-content .cart-ticket-info").find('input[name="ticket_ids"]').val();
                        
                        if ( ticket_ids ) {
                            ticket_ids = JSON.parse( ticket_ids );

                            if ( $.isArray( ticket_ids ) && $.isArray( contents ) ) {
                                contents.forEach(function(item) {
                                    var ticket_id = item.id;

                                    if ( $.inArray( ticket_id, ticket_ids ) == -1 ) {
                                        CART.remove(ticket_id);
                                    }
                                });
                            }
                        }
                    }
                },

                extra_map_info: function(){
                    var $enable_tax             = $('.cart_detail .cart-ticket-info').attr('data-enable-tax');
                    var $system_fee_type        = $('.cart_detail .cart-ticket-info').attr('data-type-system-fee');
                    var $percent_tax            = parseFloat( $('.cart_detail .cart-ticket-info').attr('data-percent-tax') );
                    var $percent_system_fee     = parseFloat( $('.cart_detail .cart-ticket-info').attr('data-percent-system-fee') );
                    var $fixed_system_fee       = parseFloat( $('.cart_detail .cart-ticket-info').attr('data-fixed-system-fee') );
                    var $total_extra_sv = 0;
                    var $ticket_price = 0;
                    var $total_tax = 0;
                    var $total = 0;
                    var $system_fee = 0;
                    var $total_display = 0;

                    if ( isNaN( $percent_tax ) ) {
                        $percent_tax = 0;
                    }
                    if ( isNaN( $percent_system_fee ) ) {
                        $percent_system_fee = 0;
                    }
                    if ( isNaN( $fixed_system_fee ) ) {
                        $fixed_system_fee = 0;
                    }

                    $total += $ticket_price + $total_extra_sv;
                    $total_display += $total;
                    // Tax
                    if ( $enable_tax == 'yes' ) {
                        $total_tax += $percent_tax / 100 * $total;
                    }
                    $total_display += $total_tax;
                    // System fee
                    switch( $system_fee_type ) {
                        case 'percent':
                            $system_fee += $percent_system_fee / 100 * $total;
                        break;
                        case 'amount':
                            $system_fee += $fixed_system_fee;
                        break;
                        case 'both':
                            $system_fee += $percent_system_fee / 100 * $total;
                            $system_fee += $fixed_system_fee;
                        break;
                        default:
                    }
                    $total_display += $system_fee;

                    if ( extra_service_html != '' ) {
                        $('.extra-services').html( extra_service_html );
                        $('.extra-services').attr('data-total',$total_extra_sv);
                    } else {
                        $('.extra-services').html('');
                        $('.extra-services').attr('data-total','0');
                    }

                    $('.cart-info .total-tax .tax-number').attr('data-tax',$total_tax);
                    $('.cart-info .total-tax .tax-number').html( EL_Frontend.cart_price_display( $total_tax ) );

                    $('.cart-info .system-fee .system-fee-number').attr('data-system-fee',$system_fee);
                    $('.cart-info .system-fee .system-fee-number').html( EL_Frontend.cart_price_display( $system_fee ) );

                    $('.cart-info .total-cart-info').attr('data-price',$total_display);
                    $('.cart-info .total-cart-info .total-price').html( EL_Frontend.cart_price_display( $total_display ) );
                }

            } //end const CART 
        
            CART.init();
            CART.check_tickets();
            CART.show_cart();
            CART.seat_map();

            $(document).on('click','.cart-info .service-plus',function(e){

                e.preventDefault();
                var $this = $(this);
                var $this_item = $this.closest('.extra-item');
                var $index  = parseInt( $this_item.attr('data-index') );
                var $max    = parseInt( $this_item.attr('data-max') );
                var $price  = parseInt( $this_item.attr('data-price') );
                var $rest   = parseInt( $this_item.attr('data-rest') );
                var $qty    = parseInt( $this_item.attr('data-qty') );
                var $id     = $this_item.attr('data-id');
                var $ticket_id      = $this.closest('.item').attr('data-id');
                var $extra_service  = $('.extra-services');
                
                var $seat_option        = $('.cart_detail .cart-ticket-info').attr('data-seat-option');
                var $enable_tax         = $('.cart-ticket-info').attr('data-enable-tax');
                var $percent_tax        = parseFloat( $('.cart-ticket-info').attr('data-percent-tax') );
                var $system_fee_type    = $('.cart-ticket-info').attr('data-type-system-fee');
                var $percent_system_fee = parseFloat( $('.cart-ticket-info').attr('data-percent-system-fee') );
                var $fixed_system_fee   = parseFloat( $('.cart-ticket-info').attr('data-fixed-system-fee') );

                var $total_tax          = 0;
                var $total              = 0;
                var $area_price         = 0;
                var $total_display      = 0;
                var $totalExtraService  = 0;
                var $system_fee         = 0;
                var $total_qty          = 0;

                var cartData = CART.contents;

                if ( isNaN( $percent_tax ) ) {
                    $percent_tax = 0;
                }
                if ( isNaN( $percent_system_fee ) ) {
                    $percent_system_fee = 0;
                }
                if ( isNaN( $fixed_system_fee ) ) {
                    $fixed_system_fee = 0;
                }

                $extra_service.each( function(i,el){
                    var $extra_item = $(el).find('.extra-item[data-id="'+$id+'"]');
                    $extra_item.each( function(j,elm){
                        $total_qty += parseInt( $(elm).attr('data-qty') );
                    } );
                } );

                $rest = $rest - $total_qty;

                var $current_qty = $qty + 1;
                if ( $current_qty > $max || $rest < 1 ) {
                    return false;
                }

                if ( $seat_option !== 'map' ) {
                    CART.add_extra_service( $ticket_id, $index, $id, $current_qty );
                    CART.show_cart();
                } else {
                    var $seat_type = $this_item.attr('data-map');
                    $ticket_id = $this_item.attr('data-seat');

                    if ( $seat_type !== 'area' ) {
                        CART.add_extra_service_map( $ticket_id, $index, $id, $current_qty );
                    } else {
                        var $person_type = $this_item.attr('data-person');
                        if ( $person_type ) {
                            CART.add_extra_service_map( $ticket_id, $index, $id, $current_qty, true, $person_type );
                        } else {
                            CART.add_extra_service_map( $ticket_id, $index, $id, $current_qty, true );
                        }
                    }

                    $this_item.attr('data-qty', $current_qty );
                    $this_item.attr('data-subtotal', $current_qty * $price );
                    $this_item.find('.qty .service-qty').html( $current_qty );
                    $this_item.find('.price').html( EL_Frontend.cart_price_display( $current_qty * $price ) );

                    if ( $.isArray( cartData ) && cartData.length > 0 ) {
                        for (var i = 0; i < cartData.length; i++) {
                            var cartItem    = cartData[i];
                            var price       = cartItem['price'] ? parseFloat( cartItem['price'] ) : 0;
                            var qty         = cartItem['qty'] ? parseInt( cartItem['qty'] ) : 1;
                            var data_person = cartItem['data_person'] ? cartItem['data_person'] : [];
                            var extra_service = cartItem['extra_service'] ? cartItem['extra_service'] : [];
                            var $subtotal_ticket    = 0;
                            var $subtotal_extra     = 0;
                            // For Area Person Type
                            if ( ! $.isEmptyObject( data_person ) ) {
                                
                                for (var j = 0; j < data_person.length; j++) {
                                    var personItem = data_person[j];
                                    var perExtra = personItem['extra_service'];
                                    $subtotal_ticket += parseFloat( personItem['price'] ) * parseInt( personItem['qty'] );
                                    if ( $.isArray( perExtra ) && ! $.isEmptyObject( perExtra ) ) {
                                        for (var k = 0; k < perExtra.length; k++) {
                                            var perExtraItems = perExtra[k];
                                            if ( $.isArray( perExtraItems ) && perExtraItems.length > 0 ) {
                                                for (var m = 0; m < perExtraItems.length; m++) {
                                                    var perExtraItem = perExtraItems[m];
                                                    $subtotal_extra += parseFloat( perExtraItem['price'] ) * parseInt( perExtraItem['qty'] );
                                                }
                                            }
                                           
                                        }
                                    }
                                }
                            } else {
                                $subtotal_ticket += price * qty;
                                if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                    for (var j = 0; j < extra_service.length; j++) {
                                        var serviceItem = extra_service[j];

                                        if ( $.isArray( serviceItem ) && serviceItem.length > 0 ) {
                                            for (var k = 0; k < serviceItem.length; k++) {
                                                var svItem = serviceItem[k];
                                                $subtotal_extra += parseFloat( svItem['price'] ) * parseInt( svItem['qty'] );
                                            }
                                        } else {
                                            $subtotal_extra += parseFloat( serviceItem['price'] ) * parseInt( serviceItem['qty'] );
                                        }

                                    }
                                }
                            }

                            $total += $subtotal_ticket + $subtotal_extra;
                            $totalExtraService += $subtotal_extra;
                        }
                    }

                    $total_display += $total;

                    // Tax
                    if ( $enable_tax == 'yes' ) {
                        $total_tax += $percent_tax / 100 * $total;
                    }
                    $total_display += $total_tax;
                    // System fee
                    switch( $system_fee_type ) {
                        case 'percent':
                            $system_fee += $percent_system_fee / 100 * $total;
                        break;
                        case 'amount':
                            $system_fee += $fixed_system_fee;
                        break;
                        case 'both':
                            $system_fee += $percent_system_fee / 100 * $total;
                            $system_fee += $fixed_system_fee;
                        break;
                        default:
                    }

                    $total_display += $system_fee;
                    $(".extra-services").attr( "data-total", $totalExtraService );
                    $('.cart-info .total-tax .tax-number').attr('data-tax',$total_tax);
                    $('.cart-info .total-tax .tax-number').html( EL_Frontend.cart_price_display( $total_tax ) );

                    $('.cart-info .system-fee .system-fee-number').attr('data-system-fee',$system_fee);
                    $('.cart-info .system-fee .system-fee-number').html( EL_Frontend.cart_price_display( $system_fee ) );

                    $('.cart-info .total-cart-info').attr('data-price',$total_display);
                    $('.cart-info .total-cart-info .total-price').html( EL_Frontend.cart_price_display( $total_display ) );
                }

            });

            $(document).on('click','.cart-info .service-minus',function(e){
                e.preventDefault();
                var $this       = $(this);
                var $this_item  = $this.closest('.extra-item');
                var $index      = parseInt( $this_item.attr('data-index') );
                var $max        = parseInt( $this_item.attr('data-max') );
                var $qty        = parseInt( $this_item.attr('data-qty') );
                var $price      = parseFloat( $this_item.attr('data-price') );
                var $name       = $this_item.attr('data-name');
                var $this_qty   = $this_item.find('.service-qty');
                var $this_price = $this_item.find('.price');
                var $id         = $this_item.attr('data-id');
                var $id_event   = $('.cart-ticket-info').attr('data-id-event');
                var $id_cal     = $('.cart-ticket-info').attr('data-id-cal');
                var $ticket_id  = $this.closest('.item').attr('data-id');
                var $seat_option    = $('.cart_detail .cart-ticket-info').attr('data-seat-option');
                var $enable_tax     = $('.cart-ticket-info').attr('data-enable-tax');
                var $percent_tax    = parseFloat( $('.cart-ticket-info').attr('data-percent-tax') );
                var $system_fee_type    = $('.cart-ticket-info').attr('data-type-system-fee');
                var $percent_system_fee = parseFloat( $('.cart-ticket-info').attr('data-percent-system-fee') );
                var $fixed_system_fee   = parseFloat( $('.cart-ticket-info').attr('data-fixed-system-fee') );
               
                var $total_tax          = 0;
                var $total              = 0;
                var $area_price         = 0;
                var $total_display      = 0;
                var $totalExtraService  = 0;
                var $system_fee         = 0;

                var cartData = CART.contents;

                if ( isNaN( $percent_tax ) ) {
                    $percent_tax = 0;
                }
                if ( isNaN( $percent_system_fee ) ) {
                    $percent_system_fee = 0;
                }
                if ( isNaN( $fixed_system_fee ) ) {
                    $fixed_system_fee = 0;
                }

                var $current_qty = $qty - 1;
                if ( $current_qty < 0 ) {
                    return false;
                }

                if ( $seat_option !== 'map' ) {
                    CART.add_extra_service( $ticket_id, $index, $id, $current_qty );
                    CART.show_cart();
                } else {
                    var $seat_type = $this_item.attr('data-map');
                    $ticket_id = $this_item.attr('data-seat');

                    if ( $seat_type !== 'area' ) {
                        CART.add_extra_service_map( $ticket_id, $index, $id, $current_qty );
                    } else {
                        var $person_type = $this_item.attr('data-person');
                        if ( $person_type ) {
                            CART.add_extra_service_map( $ticket_id, $index, $id, $current_qty, true, $person_type );
                        } else {
                            CART.add_extra_service_map( $ticket_id, $index, $id, $current_qty, true );
                        }
                    }

                    $this_item.attr('data-qty', $current_qty );
                    $this_item.attr('data-subtotal', $current_qty * $price );
                    $this_item.find('.qty .service-qty').html( $current_qty );
                    $this_item.find('.price').html( EL_Frontend.cart_price_display( $current_qty * $price ) );

          
                    if ( $.isArray( cartData ) && cartData.length > 0 ) {
                        for (var i = 0; i < cartData.length; i++) {
                            var cartItem    = cartData[i];
                            var price       = cartItem['price'] ? parseFloat( cartItem['price'] ) : 0;
                            var qty         = cartItem['qty'] ? parseInt( cartItem['qty'] ) : 1;
                            var data_person = cartItem['data_person'] ? cartItem['data_person'] : [];
                            var extra_service = cartItem['extra_service'] ? cartItem['extra_service'] : [];
                            var $subtotal_ticket    = 0;
                            var $subtotal_extra     = 0;
                            // For Area Person Type
                            if ( ! $.isEmptyObject( data_person ) ) {
                                
                                for (var j = 0; j < data_person.length; j++) {
                                    var personItem = data_person[j];
                                    var perExtra = personItem['extra_service'];
                                    $subtotal_ticket += parseFloat( personItem['price'] ) * parseInt( personItem['qty'] );
                                    if ( $.isArray( perExtra ) && ! $.isEmptyObject( perExtra ) ) {
                                        for (var k = 0; k < perExtra.length; k++) {
                                            var perExtraItems = perExtra[k];
                                            if ( $.isArray( perExtraItems ) && perExtraItems.length > 0 ) {
                                                for (var m = 0; m < perExtraItems.length; m++) {
                                                    var perExtraItem = perExtraItems[m];
                                                    $subtotal_extra += parseFloat( perExtraItem['price'] ) * parseInt( perExtraItem['qty'] );
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                $subtotal_ticket += price * qty;
                                if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                    for (var j = 0; j < extra_service.length; j++) {
                                        var serviceItem = extra_service[j];
                                        if ( $.isArray( serviceItem ) && serviceItem.length > 0 ) {
                                            for (var k = 0; k < serviceItem.length; k++) {
                                                var svItem = serviceItem[k];
                                                $subtotal_extra += parseFloat( svItem['price'] ) * parseInt( svItem['qty'] );
                                            }
                                        } else {
                                            $subtotal_extra += parseFloat( serviceItem['price'] ) * parseInt( serviceItem['qty'] );
                                        }
                                    }
                                }
                            }

                            $total += $subtotal_extra + $subtotal_ticket;
                            $totalExtraService += $subtotal_extra;
                        }
                    }


                    $total_display += $total;
                    // Tax
                    if ( $enable_tax == 'yes' ) {
                        $total_tax += $percent_tax / 100 * $total;
                    }
                    $total_display += $total_tax;
                    // System fee
                    switch( $system_fee_type ) {
                        case 'percent':
                            $system_fee += $percent_system_fee / 100 * $total;
                        break;
                        case 'amount':
                            $system_fee += $fixed_system_fee;
                        break;
                        case 'both':
                            $system_fee += $percent_system_fee / 100 * $total;
                            $system_fee += $fixed_system_fee;
                        break;
                        default:
                    }
                    $total_display += $system_fee;

                    $(".extra-services").attr("data-total", $totalExtraService );

                    $('.cart-info .total-tax .tax-number').attr('data-tax',$total_tax);
                    $('.cart-info .total-tax .tax-number').html( EL_Frontend.cart_price_display( $total_tax ) );

                    $('.cart-info .system-fee .system-fee-number').attr('data-system-fee',$system_fee);
                    $('.cart-info .system-fee .system-fee-number').html( EL_Frontend.cart_price_display( $system_fee ) );

                    $('.cart-info .total-cart-info').attr('data-price',$total_display);
                    $('.cart-info .total-cart-info .total-price').html( EL_Frontend.cart_price_display( $total_display ) );
                    
                }
            });

         
            $(".plus").off().on("click", function(e) {
                e.preventDefault();

                let id_ticket = $(this).attr("data-id-ticket");
                $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + id_ticket + " .quanty-ticket .error.error-max-num").css('display', 'none');
                $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + id_ticket + " .quanty-ticket .error.error-min-num").css('display', 'none');
                $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + id_ticket + " .control").css('padding-top', '0px');
                $(".cart_detail .cart-sidebar .total-discount").css('display', 'none');
                $(".cart_detail .cart-sidebar .total-discount .discount-number").text('');
                $(".cart_detail .cart-sidebar .total-discount .discount-number").attr('data-discount', '0');
                $("#submit-code-discount").attr('data-discount-code', '');
                $(".cart_detail .cart-sidebar .form-discount").css('display', 'none');
                $(".cart_detail .cart-sidebar #cart-discount-button").css('display', 'block');

                let name_ticket     = $(this).attr("data-title");
                let price_ticket    = $(this).attr("data-price");
                let qty             = parseInt($(this).siblings(".qty").text());
                let max_qty         = parseInt($(this).attr("data-max-num"));
                let min_qty         = parseInt($(this).siblings(".minus").attr("data-min-num"));
                let num_click       = parseInt($(this).attr("data-mark"));

                if ( isNaN(qty) ) {
                    qty = 0;
                }
                
                if ( qty >= max_qty ) {
                    $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + id_ticket + " .quanty-ticket .error.error-max-num").css('display', 'inline-block');
                    $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + id_ticket + " .control").css('padding-top', '10px');

                    if ( max_qty == 1 ) {
                        EL_Frontend.cart_next_step_hidden();
                    }

                    return false;
                }
                qty += 1;
                // local storage

                CART.add_item(id_ticket, name_ticket, price_ticket, qty);

                CART.show_cart();
                $(this).attr("data-mark", num_click + 1);

                EL_Frontend.cart_next_step_hidden();
                
            });

            $(".minus").off().on("click", function(e) {
                e.preventDefault();
                let id_ticket = $(this).attr("data-id-ticket");
                $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + id_ticket + " .quanty-ticket .error.error-min-num").css('display', 'none');
                $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + id_ticket + " .quanty-ticket .error.error-max-num").css('display', 'none');
                $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + id_ticket + " .control").css('padding-top', '0px');
                $(".cart_detail .cart-sidebar .total-discount").css('display', 'none');
                $(".cart_detail .cart-sidebar .total-discount .discount-number").text('');
                $(".cart_detail .cart-sidebar .total-discount .discount-number").attr('data-discount', '0');
                $("#submit-code-discount").attr('data-discount-code', '');
                $(".cart_detail .cart-sidebar .form-discount").css('display', 'none');
                $(".cart_detail .cart-sidebar #cart-discount-button").css('display', 'block');

                let qty     = parseInt($(this).siblings(".qty").text());
                let min_qty = parseInt($(this).attr("data-min-num"));

                if (qty <= min_qty) {
                    $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + id_ticket + " .quanty-ticket .error.error-min-num").css('display', 'inline-block');
                    $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type.item-" + id_ticket + " .control").css('padding-top', '10px');
                    return false;
                }

                CART.reduce(id_ticket);
                CART.reduce_seat(id_ticket, qty - 1);

                if (qty > 0) {
                    qty = qty - 1;
                    $(this).siblings(".qty").text(qty);
                }

                CART.show_cart();

                EL_Frontend.cart_next_step_hidden();
            });

            //remove ticket by ticket id
            $(".btn-delete-item-cart").on("click", function() {
                let id_ticket   = $(this).attr("data-id");
                let num_min     = $(this).attr("data-min");

                $(this).siblings(".control").children(".qty").text(0);

                CART.remove(id_ticket);

                CART.show_cart();

                EL_Frontend.cart_next_step_hidden();
                
            });

            $(".wp-select-seat").on('change', 'select', function() {
                let seat        = $(this).val();
                let position    = $(this).attr("data-position");
                let id_ticket   = $(this).attr("data-id-ticket");
                
                CART.update_seat(id_ticket, position, seat);
                EL_Frontend.el_update_seat_option();

                if (seat == '' || seat == null ) {
                    $("." + id_ticket + " .wp-seat-info .seat-" + position).remove();
                } else {
                    var dom_info_item = $("." + id_ticket + " .wp-seat-info .seat-" + position);
                    if (typeof dom_info_item != 'undefined' && dom_info_item.length > 0) {
                        dom_info_item.text(seat);
                    } else {
                        let html_info = '<span class="seat-' + position + '">' + seat + '</span>';
                        $("." + id_ticket + " .wp-seat-info").append(html_info);
                    }
                }

                EL_Frontend.el_select2_simple_seat();

                EL_Frontend.cart_next_step_hidden();

            });
        },
        el_update_seat_option: function(){
            
            $(document).find('.wp-select-seat').each( function(i,el){
                var select_seat = $(el).find('select');
                var $data_seat_selected = [];

                select_seat.each(function(k,ele){
                    var $val = $(ele).find(':selected').val();
                    if ( $val != '' ) {
                        $data_seat_selected.push( $val );
                    }
                    
                });

                $(el).closest('.item-ticket-type').attr('data-seat-selected', JSON.stringify( $data_seat_selected ) );

            } );
        },

        el_select2_simple_seat: function(){
            var $select_seat = $(document).find(".cart_detail .cart-content .cart-ticket-info .wp-select-seat select").select2({
                width: '100%'
            });
            // disabled seat selected
            $select_seat.on( 'select2:open', function(e){

                var $seat_selected = $(this).closest('.item-ticket-type').attr('data-seat-selected');
                var $val = $(this).find(':selected').val();

                if ( $seat_selected != undefined && $seat_selected != '' ) {
                    $seat_selected = JSON.parse( $seat_selected );
                    $(this).find('option').each( function(i,el){
                        if ( $seat_selected.includes( $(el).val() ) && $(el).val() != $val ) {
                            $(el).prop('disabled',true);
                        }
                    } );
                }
            
            } );
        },

        cart_price_display: function(price) {
            let data_settings = $(".cart_detail .cart-content .cart-ticket-info").attr("data-setting");

            if ( data_settings ) {

                data_settings           = JSON.parse(data_settings);
                var currency            = ( data_settings.currency ) ? data_settings.currency : '$';
                var decimal_separator   = ( data_settings.decimal_separator ) ? data_settings.decimal_separator : '.';
                var thousand_separator  = ( data_settings.thousand_separator ) ? data_settings.thousand_separator : ',';
                var number_decimals     = ( data_settings.number_decimals ) ? data_settings.number_decimals : 0;
                var currency_position   = data_settings.currency_position ? data_settings.currency_position : 'left';

                price = parseFloat(price).toFixed(parseInt(number_decimals));
                price = price.toString().replace(".", decimal_separator);
                var price = price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + thousand_separator);

                switch (currency_position) {
                    case "left":
                        price = currency + price;
                        break;
                    case "left_space":
                        price = currency + " " + price;
                        break;
                    case "right":
                        price = price + currency;
                        break;
                    case "right_space":
                        price = price + " " + currency;
                        break;
                }

                return price;
            }
        },

        /*** Cart Discount ***/
        cart_discount: function() {
            $(".cart_detail .cart-sidebar .cart-discount-button .form-discount input").on('focus', function() {
                $(this).siblings('button').css({
                    'background': '#e86c60',
                    'border-color': '#e86c60'
                });
            });

            $(".cart_detail .cart-sidebar .cart-discount-button .form-discount input").on('focusout', function() {
                $(this).siblings('button').css({
                    'background': '#999',
                    'border-color': '#828181'
                });
            });
            $(".cart_detail .cart-sidebar .cart-discount-button a").on("click", function() {
                $(this).css({
                    'display': 'none'
                });
                $(this).siblings('.form-discount').css({
                    'display': 'flex'
                });
            });

            $(".cart_detail .cart-sidebar .cart-discount-button .form-discount i").on('click', function(e) {
                e.preventDefault();
                // Update cart
                var enable_tax      = $(".cart_detail .cart-content .cart-ticket-info").attr("data-enable-tax");
                var system_fee_type = $(".cart_detail .cart-content .cart-ticket-info").attr("data-type-system-fee");
                var $seat_option    = $(".cart_detail .cart-content .cart-ticket-info").attr("data-seat-option");

                var data_percent_tax    = parseFloat($(".cart_detail .cart-content .cart-ticket-info").attr("data-percent-tax"));
                var percent_system_fee  = parseFloat($(".cart_detail .cart-content .cart-ticket-info").attr("data-percent-system-fee"));
                var fixed_system_fee    = parseFloat($(".cart_detail .cart-content .cart-ticket-info").attr("data-fixed-system-fee"));

                var $system_fee     = 0;
                var $total_display  = 0;
                var $total_price    = 0;
                var $total_tax      = 0;
                var $system_fee     = 0;

                var id_event        = $(".cart-ticket-info").attr("data-id-event");
                var id_cal          = $(".cart-ticket-info").attr("data-id-cal");
                var cartData        = JSON.parse( localStorage.getItem(id_event+"_"+id_cal) );
                
                if ( isNaN( data_percent_tax )) {
                    data_percent_tax = 0;
                }

                if ( isNaN(data_percent_tax) ) {
                    data_percent_tax = 0;
                }

                if ( isNaN(fixed_system_fee) ) {
                    fixed_system_fee = 0;
                }

                if ( isNaN( percent_system_fee ) ) {
                    percent_system_fee = 0;
                }

                $('.wp-cart-info').removeClass('disabled');
                $('.cart-ticket-info').removeClass('disabled');
                $('.el_ask_ticket').removeClass('disabled');

                if ( $seat_option !== 'map' ) {

                    if ( $.isArray( cartData ) && cartData.length > 0 ) {
                        for (var i = 0; i < cartData.length; i++) {
                            var cartItem = cartData[i];
                            var price       = cartItem['price'] ? parseFloat( cartItem['price'] ) : 0;
                            var qty         = cartItem['qty'] ? parseInt( cartItem['qty'] ) : 1;
                            var $subtotal   = 0;
                            var extra_service = cartItem['extra_service'] ? cartItem['extra_service'] : [];

                            $subtotal += price * qty;

                            if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                for (var j = 0; j < extra_service.length; j++) {
                                    var serviceItem = extra_service[j];
                                    if ( $.isArray( serviceItem ) && serviceItem.length > 0 ) {
                                        for (var k = 0; k < serviceItem.length; k++) {
                                            var svItem = serviceItem[k];
                                            $subtotal += parseFloat( svItem['price'] ) * parseInt( svItem['qty'] );
                                        }
                                    } else {
                                        $subtotal += parseFloat( serviceItem['price'] ) * parseInt( serviceItem['qty'] );
                                    }
                                }
                            }
                            $total_price += $subtotal;
                        }
                    }


                } else {
                 
                    if ( $.isArray( cartData ) && cartData.length > 0 ) {
                        for (var i = 0; i < cartData.length; i++) {
                            var cartItem    = cartData[i];
                            var price       = cartItem['price'] ? parseFloat( cartItem['price'] ) : 0;
                            var qty         = cartItem['qty'] ? parseInt( cartItem['qty'] ) : 1;
                            var data_person = cartItem['data_person'] ? cartItem['data_person'] : [];
                            var extra_service = cartItem['extra_service'] ? cartItem['extra_service'] : [];
                            var $subtotal   = 0;
                            // For Area Person Type
                            if ( ! $.isEmptyObject( data_person ) ) {
                                
                                for (var j = 0; j < data_person.length; j++) {
                                    var personItem = data_person[j];
                                    var perExtra = personItem['extra_service'];
                                    $subtotal += parseFloat( personItem['price'] ) * parseInt( personItem['qty'] );
                                    if ( $.isArray( perExtra ) && ! $.isEmptyObject( perExtra ) ) {
                                        for (var k = 0; k < perExtra.length; k++) {
                                            var perExtraItems = perExtra[k];
                                            if ( $.isArray( perExtraItems ) && perExtraItems.length > 0 ) {
                                                for (var m = 0; m < perExtraItems.length; m++) {
                                                    var perExtraItem = perExtraItems[m];
                                                    $subtotal += parseFloat( perExtraItem['price'] ) * parseInt( perExtraItem['qty'] );
                                                }
                                            }
                                           
                                        }
                                    }
                                }
                            } else {
                                $subtotal += price * qty;
                                if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                    for (var j = 0; j < extra_service.length; j++) {
                                        var serviceItem = extra_service[j];
                                        if ( $.isArray( serviceItem ) && serviceItem.length > 0 ) {
                                            for (var k = 0; k < serviceItem.length; k++) {
                                                var svItem = serviceItem[k];
                                                $subtotal += parseFloat( svItem['price'] ) * parseInt( svItem['qty'] );
                                            }
                                        } else {
                                            $subtotal += parseFloat( serviceItem['price'] ) * parseInt( serviceItem['qty'] );
                                        }
                                    }
                                }
                            }

                            $total_price += $subtotal;
                        }
                    }
                    
                }

                if ( enable_tax == 'yes' ) {
                    $total_tax += data_percent_tax / 100 * $total_price;
                }

                $total_display += $total_price;
                $total_display += $total_tax;

                switch( system_fee_type ) {
                    case 'amount':
                        $system_fee = fixed_system_fee;
                    break;
                    case 'percent':
                        $system_fee = percent_system_fee * $total_price / 100;
                    break;
                    case 'both':
                        $system_fee += fixed_system_fee;
                        $system_fee += percent_system_fee * $total_price / 100;
                    break;
                    default:
                }

                $total_display += $system_fee;

                $(".cart_detail .cart-sidebar .cart-info .total-cart-info").attr('data-price',$total_display);
                $(".cart_detail .cart-sidebar .cart-info .total-cart-info span.total-price").empty().text(EL_Frontend.cart_price_display($total_display));
                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax .tax-number").empty().text("+" + EL_Frontend.cart_price_display($total_tax));
                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax .tax-number").attr('data-tax',$total_tax);
                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee .system-fee-number").empty().text("+" + EL_Frontend.cart_price_display($system_fee));
                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee .system-fee-number").attr('data-system-fee',$system_fee);

                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount").css({'display': 'none'});
                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount .discount-number").empty();
                $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount .discount-number").attr('data-discount', 0);

                $(this).parent('.form-discount').css({
                   'display': 'none'
                });
                $(this).parent('.form-discount').siblings('#cart-discount-button').css({
                   'display': 'block'
                });
                $(this).siblings('#submit-code-discount').attr('data-discount-code', '');
            });

            $("#submit-code-discount").off().on("click", function(e) {
                e.preventDefault();

                var code_discount   = $(this).siblings('input').val();
                var id_event        = $(this).attr('data-id');
                var id_cal          = $(".cart-ticket-info").attr("data-id-cal");
                var cartData        = JSON.parse( localStorage.getItem(id_event+"_"+id_cal) );

                $('.wp-cart-info').addClass('disabled');
                $('.cart-ticket-info').addClass('disabled');
                $('.el_ask_ticket').addClass('disabled');

                $.post( ajax_object.ajax_url, {
                    action: 'el_check_discount',
                    data: {
                        code_discount: code_discount,
                        id_event: id_event,
                    },
                }, function(response) {
                    var enable_tax          = $(".cart-ticket-info").attr("data-enable-tax");
                    var number_tax          = parseFloat( $(".cart-ticket-info").attr("data-percent-tax") );
                    var system_fee_type     = $(".cart-ticket-info").attr("data-type-system-fee");
                    var percent_system_fee  = parseFloat($(".cart-ticket-info").attr("data-percent-system-fee"));
                    var fixed_system_fee    = parseFloat($(".cart-ticket-info").attr("data-fixed-system-fee"));

                    var $total_tax          = 0;
                    var $system_fee         = 0;
                    var $total_display      = 0;
                    var $total_extra_sv     = 0;
                    var $total_qty_ticket   = 0;
                    var $total_price        = 0;

                    if ( isNaN( percent_system_fee ) ) {
                        percent_system_fee = 0;
                    }
                    if ( isNaN( fixed_system_fee ) ) {
                        fixed_system_fee = 0;
                    }

                    if ( response ) {
                        var seat_option         = $(".cart_detail .cart-content .cart-ticket-info").data("seat-option");
                        var data_discount       = JSON.parse(response);
                        var id_ticket           = data_discount.id_ticket;
                        var discount_number     = parseFloat( data_discount.discount_number );
                        var discount_percent    = parseFloat( data_discount.discount_percenr );
                        var discount_quantity   = parseInt( data_discount.quantity );
                        var total_discount      = 0;

                        if ( isNaN( discount_percent ) ) {
                            discount_percent = 0;
                        }
                        if ( isNaN( discount_number ) ) {
                            discount_number = 0;
                        }
                        if ( isNaN( discount_quantity ) ) {
                            discount_quantity = 0;
                        }
                        if ( isNaN( number_tax ) ) {
                            number_tax = 0;
                        }

                        if ( id_ticket == null ) {
                            $('.wp-cart-info').removeClass('disabled');
                            $('.cart-ticket-info').removeClass('disabled');
                            $('.el_ask_ticket').removeClass('disabled');

                            $(".cart-discount-button .error").css({
                                'display': 'block'
                            });
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount").css({
                                'display': 'none'
                            });
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount .discount-number").empty();
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount .discount-number").attr('data-discount', 0);
                            return false;
                        }
                        
                        
                        id_ticket = Object.values(id_ticket);

                        if ( seat_option !== 'map' ) {
               
                            var $subtotal_extra     = 0;
                            var $subtotal_ticket    = 0;
                            
                            if ( $.isArray( cartData ) && cartData.length > 0 ) {
                                for (var j = 0; j < cartData.length; j++) {
                                    var cartItem = cartData[j];
                                    var extra_service = cartItem['extra_service'] ? cartItem['extra_service'] : [];
                                    var $qty = parseInt( cartItem['qty'] );
                                    var $price = parseFloat( cartItem['price'] );
                            
                                    $subtotal_ticket += $qty * $price;

                                    if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                        for (var k = 0; k < extra_service.length; k++) {
                                            var serviceItem = extra_service[k];
                                            if ( $.isArray( serviceItem ) && serviceItem.length > 0 ) {
                                                for (var m = 0; m < serviceItem.length; m++) {
                                                    var svItem = serviceItem[m];
                                                    $subtotal_extra += parseFloat( svItem['price'] ) * parseInt( svItem['qty'] );
                                                }
                                            } else {
                                                $subtotal_extra += parseFloat( serviceItem['price'] ) * parseInt( serviceItem['qty'] );
                                            }
                                        }
                                    }

                                    if ( id_ticket.indexOf( cartItem['id'] ) !== -1 ) {
                                        var sub_total_discount = $qty * $price;
                                        // Discount caculator
                            
                                        if ( discount_quantity && $qty > discount_quantity ) {
                                            sub_total_discount = $price * discount_quantity;
                                        }

                                        if ( discount_number ) {
                                            sub_total_discount = discount_number * $qty;
                                        } else if (discount_percent) {
                                            sub_total_discount = (sub_total_discount * discount_percent) / 100;
                                        }

                                        total_discount += sub_total_discount;
                                    }
                                }
                            }
                            
                            $total_price += $subtotal_ticket + $subtotal_extra;

                        } else {
                            var subTotalDiscount = 0;
                            var $total_ticket = 0;
                            if ( $.isArray( cartData ) && cartData.length > 0 ) {
                                for (var i = 0; i < cartData.length; i++) {
                                    var cartItem    = cartData[i];
                                    var price       = cartItem['price'] ? parseFloat( cartItem['price'] ) : 0;
                                    var qty         = cartItem['qty'] ? parseInt( cartItem['qty'] ) : 1;
                                    var data_person = cartItem['data_person'] ? cartItem['data_person'] : [];
                                    var extra_service = cartItem['extra_service'] ? cartItem['extra_service'] : [];
                                    var $subtotal_extra     = 0;
                                    var $subtotal_ticket    = 0;
                                    // For Area Person Type
                                    if ( ! $.isEmptyObject( data_person ) ) {
                                        for (var j = 0; j < data_person.length; j++) {
                                            var personItem = data_person[j];
                                            var perExtra = personItem['extra_service'];
                                            $total_qty_ticket += parseInt( personItem['qty'] );
                                            $subtotal_ticket += parseFloat( personItem['price'] ) * parseInt( personItem['qty'] );

                                            if ( $.isArray( perExtra ) && ! $.isEmptyObject( perExtra ) ) {
                                                for (var k = 0; k < perExtra.length; k++) {
                                                    var perExtraItems = perExtra[k];
                                                    if ( $.isArray( perExtraItems ) && perExtraItems.length > 0 ) {
                                                        for (var m = 0; m < perExtraItems.length; m++) {
                                                            var perExtraItem = perExtraItems[m];
                                                            $subtotal_extra += parseFloat( perExtraItem['price'] ) * parseInt( perExtraItem['qty'] );
                                                        }
                                                    }
                                                   
                                                }
                                            }
                                        }
                                    } else {
                                        $subtotal_ticket += price * qty;
                                        $total_qty_ticket += qty;
                                        if ( $.isArray( extra_service ) && extra_service.length > 0 ) {
                                            for (var j = 0; j < extra_service.length; j++) {
                                                var serviceItem = extra_service[j];
                                                if ( $.isArray( serviceItem ) && serviceItem.length > 0 ) {
                                                    for (var k = 0; k < serviceItem.length; k++) {
                                                        var svItem = serviceItem[k];
                                                        $subtotal_extra += parseFloat( svItem['price'] ) * parseInt( svItem['qty'] );
                                                    }
                                                } else {
                                                    $subtotal_extra += parseFloat( serviceItem['price'] ) * parseInt( serviceItem['qty'] );
                                                }
                                            }
                                        }
                                    }
                                    $total_ticket += $subtotal_ticket;
                                    $total_price += $subtotal_ticket + $subtotal_extra;
                                }
                            }

                            if ( discount_number ) {
                                subTotalDiscount = discount_number * $total_qty_ticket;
                            } else if ( discount_percent ) {
                                subTotalDiscount = ( $total_ticket * discount_percent ) / 100;
                            }

                            total_discount += subTotalDiscount;
                        }

                        $(".cart-discount-button .error").css({
                            'display': 'none'
                        });

                        // Display Total

                        
                        $total_display += $total_price - total_discount;

                        if ( enable_tax == 'yes' ) {
                            $total_tax += number_tax / 100 * $total_display;
                        }
                        // tax
                        $total_display += $total_tax;
                        
                        switch( system_fee_type ) {
                            case 'amount':
                                $system_fee += fixed_system_fee;
                            break;
                            case 'percent':
                                $system_fee += percent_system_fee * ( $total_price - total_discount ) / 100;
                            break;
                            case 'both':
                                $system_fee += fixed_system_fee;
                                $system_fee += percent_system_fee * ( $total_price - total_discount ) / 100;
                            break; 
                            default:
                        }
                        // system fee
                        $total_display += $system_fee;

                        $(".cart_detail .cart-sidebar .cart-info .total-cart-info").attr('data-price',$total_display);
                        $(".cart_detail .cart-sidebar .cart-info .total-cart-info span.total-price").empty().text( EL_Frontend.cart_price_display( $total_display ) );
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount").css({
                            'display': 'flex'
                        });
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount .discount-number").attr('data-discount', total_discount);
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount .discount-number").empty().text("-" + EL_Frontend.cart_price_display( total_discount ) );
                        $("#submit-code-discount").attr('data-discount-code', code_discount);
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax .tax-number").empty().text("+" + EL_Frontend.cart_price_display($total_tax));
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-tax .tax-number").attr('data-tax', $total_tax);
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee .system-fee-number").empty().text("+" + EL_Frontend.cart_price_display($system_fee));
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .system-fee .system-fee-number").attr('data-system-fee',$system_fee);

                    } else {

                        $(".cart-discount-button .error").css({
                            'display': 'block'
                        });
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount").css({
                            'display': 'none'
                        });
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount .discount-number").empty();
                        $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .content-cart-info .total-discount .discount-number").attr('data-discount', 0);

                        $('.wp-cart-info').removeClass('disabled');
                        $('.cart-ticket-info').removeClass('disabled');
                        $('.el_ask_ticket').removeClass('disabled');
                    }
                });
            });
        },

        cart_next_step: function() {

            let seat_option = $(".cart_detail .cart-content .cart-ticket-info").data("seat-option");

            $("#cart-next-step").off().on('click', function() {

                var el_next_event_nonce = $(this).closest('.next_step_button').find('#el_next_event_nonce').val();
                var login_link          = $(this).closest('.next_step_button').find( 'input[name="login_link"]' ).val();
                var value_sold_out_all  = $("input[name=sold_all]").val();

                if ( value_sold_out_all == '1' ) {
                    return false;
                }
                // hide error
                $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart").css({ "display": "none" });
                $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart span").css({ "display": "none" });

                let id_event, id_cal, key_store, cart;
                id_event    = $(".cart_detail .cart-content .cart-ticket-info").attr('data-id-event');
                id_cal      = $(".cart_detail .cart-content .cart-ticket-info").attr('data-id-cal');

                if ( ! id_event || ! id_cal ) {
                    key_store = "";
                }

                key_store = id_event + '_' + id_cal;
                cart = localStorage.getItem(key_store);
                cart = JSON.parse(cart);

                //check seat 
                var cart_check = cart;
                var flag_check_seat = '';

                // Find Duplicate in array
                const findDuplicates = (arr) => {
                    let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
                    // JS by default uses a crappy string compare.
                    // (we use slice to clone the array so the
                    // original array won't be modified)
                    let results = [];

                    for ( let i = 0; i < sorted_arr.length - 1; i++ ) {
                        if ( sorted_arr[i + 1] == sorted_arr[i] ) {
                            results.push(sorted_arr[i]);
                        }
                    }

                    return results;
                }

                cart_check = cart_check.map(function(item) {
                    if (typeof(item.seat) != 'undefined') {
                        let seat = item.seat;
                        if ( seat.length !== item.qty ) {
                            flag_check_seat = 'error_undefined';
                        }

                        // Check duplicate Seat Code
                        let arr_duplicate = findDuplicates(seat);
                        if ( arr_duplicate.length > 0 ) {
                            flag_check_seat = 'error_duplicate';
                        }

                        let el_have_empty_val = (currentValue) => currentValue != '';
                        let el_is_empty = seat.every(el_have_empty_val);

                        if ( ! el_is_empty ) {
                            flag_check_seat = 'error_empty';
                        }
                    }
                });


                $(".cart_detail .cart-sidebar .message-error-seat p").css('display', 'none');

                if ( flag_check_seat == 'error_empty' || flag_check_seat == 'error_undefined' ) {
                    $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart").css({
                        "display": "block",
                        "text-align": "center"
                    });

                    $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart span.error-empty").css({
                        "display": "inline-block"
                    });

                    return false;
                } else if ( flag_check_seat == 'error_duplicate' ) {
                    $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart").css({
                        "display": "block",
                        "text-align": "center"
                    });

                    $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart span.error-duplicate").css({
                        "display": "inline-block"
                    });

                    return false;
                }

                let data_price_total = $(".cart_detail .cart-sidebar .cart-info .total-cart-info").attr("data-price");
                data_price_total = parseFloat(data_price_total);
                var error_qty = true;
                var i = 0;
                var j = 0;

                $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type .control span.qty").each(function() {
                    var qty = parseInt($(this).text());
                    if (qty <= 0) {
                        i++;
                    }
                    j++;
                });

                if (i == j) {
                   error_qty = false;
                }


                if ( seat_option == 'map' && (Array.isArray(cart) && cart.length)) {
                    var totalQty    = 0;
                    var countSeat   = $('.cart_detail .wp-seat-info').children('span').length;

                    if ( countSeat ) totalQty += parseInt( countSeat );

                    $('.wp-content-item .item-info').each( function() {
                        var areaQty = $(this).find('.area-qty').text();
                        if ( ! areaQty ) {
                            areaQty = $(this).attr('data-qty');
                        }
                        totalQty += parseInt( areaQty );
                    });

                    error_qty       = true;
                    let minTicket   = $('.cart-ticket-info').data('min_ticket');
                        
                    if ( totalQty < minTicket ) {
      
                        $(".cart_detail .error-seat-status").css('display', 'block');
                        $(".cart_detail .error.error-min-num").css('display', 'inline-block');
                        return false;
                    }
                }


                if ( error_qty ) {
                    var el_next_event_nonce = $(this).closest('.next_step_button').find('#el_next_event_nonce').val();
                    $.post(ajax_object.ajax_url, {
                        action: 'el_check_user_login',
                        data: {
                            el_next_event_nonce: el_next_event_nonce,
                        },
                   }, function(response) {
                        if ( response == 'true' ) {
                            var total = $(".cart_detail .cart-sidebar .cart-info .total-cart-info").attr("data-price");
                            total = parseFloat(total);
                            $(".el_payments ul li ").css('display', 'block');

                            if ( ! total ) {
                                $(".el_payments ul li:not(.free)").html('');
                                $(".el_payments ul li .type-payment input").first().attr('checked', 'checked');
                                $(".el_payments ul li .payment_form").first().css('display', 'none');

                                var content_payment = $(".el_payments ul li .type-payment").first().children("label").text();
                               
                                $(".el_payments ul li.free .type-payment").css({
                                   'border-bottom': '1px solid #ddd'
                                });
                            } else {
                                $(".el_payments ul li.free").html('');
                                $(".el_payments ul li .type-payment input").first().attr('checked', 'checked');
                                $(".el_payments ul li .payment_form").first().css('display', 'block');

                                var content_payment = $(".el_payments ul li .type-payment").first().children("label").text();
                            }

                            $(".cart_detail .payment_method_choosed .content").text(content_payment);

                            $(".cart_detail .step-2").css({
                                'display': 'block'
                            });
                            $(".cart_detail .step-1-hide-step-2").css({
                                'display': 'none'
                            });
                            $(".cart_detail .next_step_button").css({
                                'display': 'none'
                            });
                            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .cart_title span.edit").css({
                                "display": "inline-block"
                            });

                            // Disable plus & minus buttons for area
                            if ( seat_option === 'map' ) {
                                $('.wp-content-item .area-plus').each( function() {
                                    if ( $(this).length > 0 ) $(this).addClass('disable');
                                });
                                $('.wp-content-item .area-minus').each( function() {
                                    if ( $(this).length > 0 ) $(this).addClass('disable');
                                });
                                if ( $(document).find('.wp-content-item .person_type_wrap').length ) {
                                    $(document).find('.wp-content-item .person_qty_wrap').each( function(i,el){
                                        $(el).css('pointer-events','none');
                                    } );
                                }
                            }
                        } else {
                            window.location.href = login_link;
                        }
                    });
                } else {
                    $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart").css({
                        "display": "block"
                    });
                    $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart span.empty-item-cart").css({
                        "display": "inline-block"
                    });
                }

                var qty = 0;
                if ( $.isArray(cart) && cart.length ) {
                    if ( seat_option == 'map' ) {
                        $.map( cart, function( val, i ) {
                            if ( 'qty' in val === true ) {
                                qty += parseInt( val.qty );
                            } else if ( 'data_person' in val === true ){
                                
                                for(let property in val['data_person']){
                                    qty += val['data_person'][property]['qty'];
                                }
                            } else {
                                qty += 1;
                            }
                        });
                    } else {
                        $.map( cart, function( val, i ) {
                            if ( 'qty' in val === true ) {
                                qty += parseInt( val.qty );
                            }
                        });
                    }
                }


                if ( qty > 1 ) {
                    $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket").show();
                } else {
                    $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket").hide();
                }

                if ( $(".el_ask_ticket").length > 0 ) {
                    $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").not('.input_mult_ticket').show();
                    // Remove HTML
                    $("ul.el_multiple_ticket").remove();
                    $("ul.input_mult_ticket").remove();
                    $("div.error-select_seats").remove();
                    $("li.select_seats").remove();
                    $("li.person_type").remove();
                    $("div.error-ticket_type").remove();
                    $("li.ticket_type").remove();
                    $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver li .first_name").closest('li').addClass('first_name');

                    $('.el_ask_ticket input[name="radio_ask"]:checked').each( function() {
                        if ( $(this).val() == 'yes' ) {
                            el_multiple_customers( $(this) );

                        }
                    });

                    $('.el_ask_ticket input[name="radio_ask"]').off().on( 'change', function(e) {
                        e.preventDefault();
                        // icon loading
                        $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .ask_loading").css('display', 'inline-block');

                        // Disable click
                        $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .label-checked").addClass('no-click');

                        el_multiple_customers( $(this) );

                    });

                    function el_multiple_customers( that ) {
                        var multiple_customers = that.val();

                        if ( multiple_customers == 'yes' ) {
                            cart = localStorage.getItem(key_store);
                            cart = JSON.parse(cart);

                            $.post(ajax_object.ajax_url, {
                                action: 'el_multiple_customers_ticket',
                                data: {
                                    el_next_event_nonce: el_next_event_nonce,
                                    seat_option: seat_option,
                                    cart: cart,
                                    event_id: id_event
                                },
                            }, function(response) {

                                var data = JSON.parse(response);

                                if ( data != 0 ) {
                                    $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket").after(data.nav);

                                    if ( seat_option == 'map' ) {
                                        $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver li").removeClass('first_name');
                                        $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").prepend(data.seat_map);
                                    }

                                    if ( seat_option == 'simple' ) {
                                        $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver li").removeClass('first_name');
                                        $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").prepend(data.ticket_type);
                                    }

                                    $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").after(data.result);
                                    $("select").select2({
                                        width: '100%',
                                        allowClear: true,
                                    });
                                } else {
                                    $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver li .first_name").closest('li').addClass('first_name');
                                }

                                $(".el_multiple_ticket .ticket_item").each( function() {
                                    $(this).on( 'click', function() {
                                        $(".el_multiple_ticket .ticket_item").removeClass('actived');
                                        $(this).addClass('actived');
                                        var index = $(this).data('index');
                                        $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").hide();
                                        var input_ticket_receiver = null;

                                        if ( index == 0 ) {
                                            input_ticket_receiver = $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").not('.input_mult_ticket');
                                        } else {
                                            input_ticket_receiver = $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver.input_mult_ticket.el_ticket_"+index);
                                        }
                                        input_ticket_receiver.show();
                                        EL_Frontend.cart_change_ticket_info(input_ticket_receiver);
                                    });
                                });

                                // icon loading
                                $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .ask_loading").hide();
                                EL_Frontend.cart_change_customer_info();

                                // Enable click
                                $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .label-checked").removeClass('no-click');
                            });
                        } else {
                            var input_ticket_receiver = $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").not('.input_mult_ticket');
                            input_ticket_receiver.show();
                            EL_Frontend.cart_change_ticket_info(input_ticket_receiver);
                            // Remove HTML
                            $("ul.el_multiple_ticket").remove();
                            $("ul.input_mult_ticket").remove();
                            $("div.error-select_seats").remove();
                            $("li.select_seats").remove();
                            $("li.person_type").remove();
                            $("div.error-ticket_type").remove();
                            $("li.ticket_type").remove();
                            $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver li .first_name").closest('li').addClass('first_name');
                            // icon loading
                            $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .ask_loading").hide();

                            // Enable click
                            $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .label-checked").removeClass('no-click');
                        }
                    }
                }
            });
        },

        cart_next_step_hidden: function(){

            var $result = true;
            let seat_option = $(".cart_detail .cart-content .cart-ticket-info").data("seat-option");

            var el_next_event_nonce = $(this).closest('.next_step_button').find('#el_next_event_nonce').val();
            var value_sold_out_all  = $("input[name=sold_all]").val();

            if ( value_sold_out_all == '1' ) {
                $result = false;
            }
            // hide error
            $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart").css({ "display": "none" });
            $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart span").css({ "display": "none" });
            $(".cart_detail .error-seat-status").css('display', 'none');
            $(".cart_detail .error.error-min-num").css('display', 'none');

            let id_event, id_cal, key_store, cart;
            id_event    = $(".cart_detail .cart-content .cart-ticket-info").attr('data-id-event');
            id_cal      = $(".cart_detail .cart-content .cart-ticket-info").attr('data-id-cal');

            if ( ! id_event || ! id_cal ) {
                key_store = "";
            }


            key_store = id_event + '_' + id_cal;
            cart = localStorage.getItem(key_store);
            cart = JSON.parse(cart);

            //check seat 
            var cart_check = cart;
            var flag_check_seat = '';

            // Find Duplicate in array
            const findDuplicates = (arr) => {
                let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
                // JS by default uses a crappy string compare.
                // (we use slice to clone the array so the
                // original array won't be modified)
                let results = [];

                for ( let i = 0; i < sorted_arr.length - 1; i++ ) {
                    if ( sorted_arr[i + 1] == sorted_arr[i] ) {
                        results.push(sorted_arr[i]);
                    }
                }

                return results;
            }

            var check_seat = function(seat){
                return seat != '';
            }

            cart_check = cart_check.map(function(item) {
                if (typeof(item.seat) != 'undefined') {
                    let seat = item.seat;

                    if ( seat.length !== item.qty ) {
                        flag_check_seat = 'error_undefined';
                    }

                    // Check duplicate Seat Code
                    let arr_duplicate = findDuplicates(seat);
                    if ( arr_duplicate.length > 0 ) {
                        flag_check_seat = 'error_duplicate';
                    }

                    let check_seat_empty = seat.every(check_seat);

                    if ( ! check_seat_empty ) {
                        flag_check_seat = 'error_empty';
                    }
                }
            });

             // Check min ticket qty
            if ( seat_option !== "map" ) {
                if ( $.isArray( cart ) && cart.length > 0 ) {
                    for (var i = 0; i < cart.length; i++) {
                        var cartItem = cart[i];
                        var qty = parseInt( cartItem['qty'] );
                        var ticketItem = $('.item-ticket-type.item-'+cartItem.id);
                        if ( ticketItem.length ) {
                            var minTicketNum = parseInt( ticketItem.find('.quanty-ticket .minus').attr("data-min-num") );
                            if ( ! isNaN( minTicketNum ) && minTicketNum > 0 ) {
                                if ( qty < minTicketNum ) {
                                    ticketItem.find(".quanty-ticket .error-min-num").css("display", "inline-block");
                                    $result = false;
                                    break;
                                }
                            }
                        }
                        
                    }
                }
            }
            


            $(".cart_detail .cart-sidebar .message-error-seat p").css('display', 'none');

            if ( flag_check_seat == 'error_empty' || flag_check_seat == 'error_undefined' ) {
                $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart").css({
                    "display": "block",
                    "text-align": "center"
                });

                $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart span.error-empty").css({
                    "display": "inline-block"
                });

                $result = false;
            } else if ( flag_check_seat == 'error_duplicate' ) {
                $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart").css({
                    "display": "block",
                    "text-align": "center"
                });

                $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart span.error-duplicate").css({
                    "display": "inline-block"
                });

                $result = false;
            }


            let data_price_total = $(".cart_detail .cart-sidebar .cart-info .total-cart-info").attr("data-price");
            data_price_total = parseFloat(data_price_total);
            var error_qty = true;
            var i = 0;
            var j = 0;


            $(".cart_detail .cart-content .cart-ticket-info .item-ticket-type .control span.qty").each(function() {
                var qty = parseInt($(this).text());

                if (qty <= 0) {
                    i++;
                }
                j++;
            });

            if (i == j) {
               error_qty = false;
            }

            if ( seat_option == 'map' && (Array.isArray(cart) && cart.length)) {
                var totalQty    = 0;
                var countSeat   = $('.cart_detail .wp-content-item .seat-item').length;

                if ( countSeat ) totalQty += parseInt( countSeat );

                $('.wp-content-item .item-info.area-item').each( function() {
                    var areaQty = $(this).find('.area-qty').text();

                    if ( ! areaQty ) {
                        areaQty = $(this).attr('data-qty');

                    }

                    totalQty += parseInt( areaQty );

                });

                error_qty       = true;
                let minTicket   = $('.cart-ticket-info').data('min_ticket');
              
                if ( totalQty < parseInt( minTicket ) ) {

                    $(".cart_detail .error-seat-status").css('display', 'block');
                    $(".cart_detail .error.error-min-num").css('display', 'inline-block');
                    $result = false;
                }
            }


            if ( ! error_qty ) {

                $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart").css({
                    "display": "block"
                });
                $(".cart_detail .cart-content .cart-ticket-info .error-empty-cart span.empty-item-cart").css({
                    "display": "inline-block"
                });
                $result = false;
            }



            var qty = 0;
            if ( $.isArray(cart) && cart.length ) {
                if ( seat_option == 'map' ) {
                    $.map( cart, function( val, i ) {
                        if ( 'qty' in val === true ) {
                            qty += parseInt( val.qty );
                        } else if ( 'data_person' in val === true ){
                            
                            for(let property in val['data_person']){
                                qty += val['data_person'][property]['qty'];
                            }
                        } else {
                            qty += 1;
                        }
                    });
                } else {
                    $.map( cart, function( val, i ) {
                        if ( 'qty' in val === true ) {
                            qty += parseInt( val.qty );
                        }
                    });
                }
            }

            if ( qty > 1 ) {
                $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket").show();
            } else {
                $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket").hide();
            }

            if ( $(".el_ask_ticket").length > 0 ) {
                $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").not('.input_mult_ticket').show();
                // Remove HTML
                $("ul.el_multiple_ticket").remove();
                $("ul.input_mult_ticket").remove();
                $("div.error-select_seats").remove();
                $("li.select_seats").remove();
                $("li.person_type").remove();
                $("div.error-ticket_type").remove();
                $("li.ticket_type").remove();
                $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver li .first_name").closest('li').addClass('first_name');

                $('.el_ask_ticket input[name="radio_ask"]:checked').each( function() {
                    if ( $(this).val() == 'yes' ) {
                        el_multiple_customers( $(this) );
                    }
                });

                $('.el_ask_ticket input[name="radio_ask"]').off().on( 'change', function(e) {
                    e.preventDefault();
                    // icon loading
                    $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .ask_loading").css('display', 'inline-block');

                    // Disable click
                    $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .label-checked").addClass('no-click');

                    el_multiple_customers( $(this) );

                });

                function el_multiple_customers( that ) {
                    var multiple_customers = that.val();
                    var el_next_event_nonce = $(document).find('#el_next_event_nonce').val();
                    if ( multiple_customers == 'yes' ) {
                        cart = localStorage.getItem(key_store);
                        cart = JSON.parse(cart);

                        if ( $(document).find('.wp-content-item .person_type_wrap').length ) {
                            $(document).find('.wp-content-item .person_qty_wrap').each( function(i,el){
                                $(el).css('pointer-events','none');
                            } );
                        }

                        $(document).find('.cart-ticket-info').addClass('disabled');

                        $.post(ajax_object.ajax_url, {
                            action: 'el_multiple_customers_ticket',
                            data: {
                                el_next_event_nonce: el_next_event_nonce,
                                seat_option: seat_option,
                                cart: cart,
                                event_id: id_event
                            },
                        }, function(response) {

                            var data = JSON.parse(response);

                            if ( data != 0 ) {
                                $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket").after(data.nav);

                                if ( seat_option == 'map' ) {
                                    $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver li").removeClass('first_name');
                                    $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").prepend(data.seat_map);
                                }

                                if ( seat_option == 'simple' ) {
                                    $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver li").removeClass('first_name');
                                    $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").prepend(data.ticket_type);
                                }

                                $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").after(data.result);
                                $("select").select2({
                                    width: '100%',
                                    allowClear: true,
                                });

                            } else {
                                $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver li .first_name").closest('li').addClass('first_name');
                            }

                            $(".el_multiple_ticket .ticket_item").each( function() {
                                $(this).on( 'click', function() {
                                    $(".el_multiple_ticket .ticket_item").removeClass('actived');
                                    $(this).addClass('actived');
                                    var index = $(this).data('index');
                                    $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").hide();
                                    var input_ticket_receiver = null;

                                    if ( index == 0 ) {
                                        input_ticket_receiver = $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").not('.input_mult_ticket');
                                    } else {
                                        input_ticket_receiver = $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver.input_mult_ticket.el_ticket_"+index);
                                    }
                                    input_ticket_receiver.show();
                                    EL_Frontend.cart_change_ticket_info(input_ticket_receiver);
                                });
                            });

                            // icon loading
                            $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .ask_loading").hide();
                            EL_Frontend.cart_change_customer_info();

                            // Enable click
                            $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .label-checked").removeClass('no-click');

                            if ( $(document).find('.wp-content-item .person_type_wrap').length ) {
                                $(document).find('.wp-content-item .person_qty_wrap').each( function(i,el){
                                    $(el).css('pointer-events','auto');
                                } );
                            }

                            $(document).find('.cart-ticket-info').removeClass('disabled');

                        });
                    } else {
                        var input_ticket_receiver = $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").not('.input_mult_ticket');
                        input_ticket_receiver.show();
                        EL_Frontend.cart_change_ticket_info(input_ticket_receiver);
                        // Remove HTML
                        $("ul.el_multiple_ticket").remove();
                        $("ul.input_mult_ticket").remove();
                        $("div.error-select_seats").remove();
                        $("li.select_seats").remove();
                        $("li.person_type").remove();
                        $("div.error-ticket_type").remove();
                        $("li.ticket_type").remove();
                        $(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver li .first_name").closest('li').addClass('first_name');
                        // icon loading
                        $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .ask_loading").hide();

                        // Enable click
                        $(".cart_detail .step-2 .cart-customer-input .el_ask_ticket .ask_ticket_checked .label-checked").removeClass('no-click');
                    }
                }
            }

            if ( ! $result ) {
                $(document).find(".cart-discount-button").addClass("disabled");
                $(document).find('#checkout-button').addClass('disable');
            } else {
                $(document).find(".cart-discount-button").removeClass("disabled");
                $(document).find('#checkout-button').removeClass('disable');

            }

            return $result;
        },

        cart_change_ticket_info: function( that = null ) {
            if ( that != null ) {
                var index = that.data('ticket');

                var first_name = that.find("li input#first_name").val();
                $(".cart_detail .info_ticket_receiver li .span.first_name").text(first_name);

                var last_name = that.find("li input#last_name").val();
                $(".cart_detail .info_ticket_receiver li .span.last_name").text(last_name);

                var email = that.find("li input#email").val();
                $(".cart_detail .info_ticket_receiver li .span.email").text(email);

                var phone = that.find("li input#phone").val();
                $(".cart_detail .info_ticket_receiver li .span.phone").text(phone);

                var address = that.find("li input#address").val();
                $(".cart_detail .info_ticket_receiver li .span.address").text(address);

                // File
                $('.input_mult_ticket .el-ckf-file input[type="file"]').each( function() {
                    $(this).on( 'change', function(e) {
                        e.preventDefault();

                        var file            = $(this);
                        var name_field      = file.data('global-name');

                        file.closest('.input_mult_ticket').find( ".error-" + name_field ).css({
                            "display": "none"
                        });

                        var limit           = file.data('max-file-size');
                        var required_msg    = file.data('required');
                        var max_size_msg    = file.data('max-file-size-msg');
                        var formats_msg     = file.data('formats');
                        var file_mimes      = file.data('file-mimes');
                        var formats_file    = [];

                        if ( typeof( file_mimes ) == 'object' ) {
                            $.each( file_mimes, function( key, val ) {
                                formats_file.push( val );
                            });
                        }

                        file.closest('.el-ckf-file').find('.el-ckf-file-name').html('');
                        
                        var name = this.files[0].name;
                        var size = this.files[0].size;
                        var type = this.files[0].type;
                        
                        if ( name && size && type ) {
                            var mb = ( size/1048576 ).toFixed(2);

                            if ( mb > limit ) {
                                file.closest('.input_mult_ticket').find( ".error-" + name_field ).html('').append('<span>'+max_size_msg+'</span>').css({
                                    "display": "block"
                                });
                                file.closest('.input_mult_ticket').find( ".error-" + name_field ).siblings("li").css({
                                    "border-top": "1px solid #ccc"
                                });
                                file.closest('.input_mult_ticket').find( ".error-"  + name_field ).siblings("li").css({
                                    "margin-bottom": "10px",
                                });
                                file.val('');
                                $(".cart_detail .info_ticket_receiver li .span." + name_field).text('');

                                return false;
                            }

                            if ( $.inArray( type, formats_file ) == -1 ) {
                                file.closest('.input_mult_ticket').find( ".error-" + name_field ).html('').append('<span>'+formats_msg+'</span>').css({
                                    "display": "block"
                                });
                                file.closest('.input_mult_ticket').find( ".error-" + name_field ).siblings("li").css({
                                    "border-top": "1px solid #ccc"
                                });
                                file.closest('.input_mult_ticket').find( ".error-"  + name_field ).siblings("li").css({
                                    "margin-bottom": "10px",
                                });
                                file.val('');
                                $(".cart_detail .info_ticket_receiver li .span." + name_field).text('');

                                return false;
                            }

                            file.closest('.el-ckf-file').find('.el-ckf-file-name').html(name);
                            $(".cart_detail .info_ticket_receiver li .span." + name_field).text(name);
                        }
                    });
                });
                // End

                var list_key_checkout_field     = $('#el_list_key_checkout_field').val();
                var list_type_checkout_field    = $('#el_list_key_checkout_field').data('type');

                if ( list_key_checkout_field ) {
                    list_key_checkout_field = JSON.parse(list_key_checkout_field);

                    for ( var key_ckf in list_key_checkout_field ) {
                        var name_ckf   = list_key_checkout_field[key_ckf];
                        var name_field = name_ckf;

                        if ( index ) {
                            name_field = name_ckf+'_index'+index;
                        }

                        var type_ckf    = list_type_checkout_field[name_ckf];
                        var value_ckf   = '';

                        if ( type_ckf == 'select' ) {
                            value_ckf       = that.find('select[name="'+name_field+'"]').val();
                            var text_ckf    = that.find('select[name="'+name_field+'"] option:checked').text();

                            if ( value_ckf && text_ckf ) {
                                value_ckf = text_ckf.replaceAll('\n', '').replaceAll('\t', '');
                            }
                        } else if ( type_ckf == 'radio' ) {
                            value_ckf       = that.find('input[name="'+name_field+'"]:checked').val();
                            var text_ckf    = that.find('input[name="'+name_field+'"]:checked').closest('.el-ckf-radio').find('label').text();

                            if ( value_ckf && text_ckf ) {
                                value_ckf = text_ckf.replaceAll('\n', '').replaceAll('\t', '');
                            }
                        } else if ( type_ckf == 'checkbox' ) {
                            value_ckf = [];

                            that.find('input[data-name="'+name_field+'"]:checked').each( function() {
                                var val = $(this).val();
                                var text_val = $(this).closest('.el-ckf-checkbox').find('label').text();

                                if ( text_val ) {
                                    val = text_val.replaceAll('\n', '').replaceAll('\t', '');
                                }

                                value_ckf.push(val);
                            });

                            if ( value_ckf && $.isArray( value_ckf ) ) {
                                value_ckf = value_ckf.join( ', ' );
                            } else {
                                value_ckf = '';
                            }
                        } else if ( type_ckf == 'file' ) {
                            var inputFile = that.find('input[name="'+name_field+'"]');
                            value_ckf = inputFile.closest('.el-ckf-file').find('.el-ckf-file-name').text();
                        } else if ( type_ckf == 'textarea' ) {
                            value_ckf = that.find('textarea[name="'+name_ckf+'"]').val();
                        } else {
                            value_ckf = that.find('input[name="'+name_ckf+'"]').val();
                        }

                        $(".cart_detail .info_ticket_receiver li .span." + name_ckf).text(value_ckf);
                    }
                }
            }
        },

        cart_change_customer_info: function() {
            if ($(".cart_detail .input_ticket_receiver li input#first_name").val() != '') {
                let value_input = $(".cart_detail .input_ticket_receiver li input#first_name").val();
                $(".cart_detail .info_ticket_receiver li .span.first_name").text(value_input);
            }
            if ($(".cart_detail .input_ticket_receiver li input#last_name").val() != '') {
                let value_input = $(".cart_detail .input_ticket_receiver li input#last_name").val();
                $(".cart_detail .info_ticket_receiver li .span.last_name").text(value_input);
            }
            if ($(".cart_detail .input_ticket_receiver li input#email").val() != '') {
                let value_input = $(".cart_detail .input_ticket_receiver li input#email").val();
                $(".cart_detail .info_ticket_receiver li .span.email").text(value_input);
            }
            if ($(".cart_detail .input_ticket_receiver li input#phone").val() != '') {
                let value_input = $(".cart_detail .input_ticket_receiver li input#phone").val();
                $(".cart_detail .info_ticket_receiver li .span.phone").text(value_input);
            }
            if ($(".cart_detail .input_ticket_receiver li input#address").val() != '') {
                let value_input = $(".cart_detail .input_ticket_receiver li input#address").val();
                $(".cart_detail .info_ticket_receiver li .span.address").text(value_input);
            }
            $(".cart_detail .input_ticket_receiver li input#first_name").on("keyup", function() {
                let value_input = $(this).val();
                $(".cart_detail .info_ticket_receiver li .span.first_name").text(value_input);
            });
            $(".cart_detail .input_ticket_receiver li input#last_name").on("keyup", function() {
                let value_input = $(this).val();
                $(".cart_detail .info_ticket_receiver li .span.last_name").text(value_input);
            });
            $(".cart_detail .input_ticket_receiver li input#email").on("keyup change paste", function() {
                let value_input = $(this).val();
                $(".cart_detail .info_ticket_receiver li .span.email").text(value_input);
            });
            $(".cart_detail .input_ticket_receiver li input#phone").on("keyup", function() {
                let value_input = $(this).val();
                $(".cart_detail .info_ticket_receiver li .span.phone").text(value_input);
            });
            $(".cart_detail .input_ticket_receiver li input#address").on("keyup", function() {
                let value_input = $(this).val();
                $(".cart_detail .info_ticket_receiver li .span.address").text(value_input);
            });

            var list_key_checkout_field     = $('#el_list_key_checkout_field').val();
            var list_type_checkout_field    = $('#el_list_key_checkout_field').data('type');

            if ( list_key_checkout_field ) {
                list_key_checkout_field = JSON.parse(list_key_checkout_field);

                for ( var key_ckf in list_key_checkout_field ) {
                    var name_ckf    = list_key_checkout_field[key_ckf];
                    var type_ckf    = list_type_checkout_field[name_ckf];
                    var value_input = '';

                    if ( type_ckf == 'select' ) {
                        $('.cart_detail .input_ticket_receiver li select[data-global-name="'+name_ckf+'"]').on("change", function() {
                            var name_ckf    = $(this).data('global-name');
                            let value_input = $(this).val();
                            var text_ckf    = $(this).find('option:checked').text();

                            if ( value_input && text_ckf ) {
                                value_input = text_ckf.replaceAll('\n', '').replaceAll('\t', '');;
                            }

                            $(".cart_detail .info_ticket_receiver li .span." + name_ckf).text(value_input);
                        });
                    } else if ( type_ckf == 'radio' ) {
                        $('.cart_detail .input_ticket_receiver li input[data-global-name="'+name_ckf+'"]').on("change", function() {
                            var name_ckf    = $(this).data('global-name');
                            let value_input = $(this).val();
                            var text_ckf    = $(this).closest('.el-ckf-radio').find('label').text();

                            if ( value_input && text_ckf ) {
                                value_input = text_ckf.replaceAll('\n', '').replaceAll('\t', '');;
                            }

                            $(".cart_detail .info_ticket_receiver li .span." + name_ckf).text(value_input);
                        });
                    } else if ( type_ckf == 'checkbox' ) {
                            $('.cart_detail .input_ticket_receiver li input[data-global-name="'+name_ckf+'"]').on( 'change', function() {
                                var name_ckf    = $(this).data('global-name');
                                var value_ckf   = [];

                                $(this).closest('.rental_item ').find('input[data-global-name="'+name_ckf+'"]:checked').each( function() {
                                    var val         = $(this).val();
                                    var text_val    = $(this).closest('.el-ckf-checkbox').find('label').text();

                                    if ( text_val ) {
                                        val = text_val.replaceAll('\n', '').replaceAll('\t', '');
                                    }

                                    value_ckf.push(val);
                                });

                                if ( value_ckf && $.isArray( value_ckf ) ) {
                                    value_input = value_ckf.join( ', ' );
                                } else {
                                    value_input = '';
                                }
                                
                                $(".cart_detail .info_ticket_receiver li .span." + name_ckf).text(value_input);
                            });
                    } else if ( type_ckf == 'textarea' ) {
                        $('.cart_detail .input_ticket_receiver li textarea[name="'+name_ckf+'"]').on("keyup", function() {
                            var name_ckf    = $(this).attr('name');
                            let value_input = $(this).val();
                            $(".cart_detail .info_ticket_receiver li .span." + name_ckf).text(value_input);
                        });
                    } else {
                        $('.cart_detail .input_ticket_receiver li input[name="'+name_ckf+'"]').on("keyup", function() {
                            var name_ckf    = $(this).attr('name');
                            let value_input = $(this).val();
                            $(".cart_detail .info_ticket_receiver li .span." + name_ckf).text(value_input);
                        });
                    }
                }
            }
        },

        cart_edit_button: function() {
            $(".cart_detail .cart-sidebar .cart-info .wp-cart-info .cart_title span.edit").off().on("click", function() {
                $(this).css({
                    'display': 'none'
                });
                $(".cart_detail .step-2").css({
                    'display': 'none'
                });
                $(".cart_detail .step-1-hide-step-2").css({
                    'display': 'block'
                });
                $(".cart_detail .next_step_button").css({
                    'display': 'block'
                });

                if ( $(document).find('.wp-content-item .person_type_wrap').length ) {
                    $(document).find('.wp-content-item .person_qty_wrap').each( function(i,el){
                        $(el).css('pointer-events','auto');
                    } );
                }

            });
        },

        /* process_checkout */
        process_checkout: function() {

            $(document).find('#checkout-button').off().on('click', async function(e) {
                $(".cart_detail .cart-sidebar .message-error p").empty();
                e.preventDefault();

                var $this = $(this);
                var valid = 1;

                var el_checkout_event_nonce = $this.closest('#el_cart').find('#el_checkout_event_nonce').val();
                var cart = '';

                let id_event, id_cal, key_store, first_name, last_name, email, phone, address, address_required, phone_required, payment_method, coupon, seat_option, multiple_ticket, seat, ticket_type;
                id_event    = $(".cart_detail .cart-content .cart-ticket-info").attr('data-id-event');
                id_cal      = $(".cart_detail .cart-content .cart-ticket-info").attr('data-id-cal');

                if ( ! id_event || ! id_cal ) {
                    key_store = "";
                }

                seat        = $(".cart_detail input[name='select_seats']").val();
                ticket_type = $(".cart_detail input[name='ticket_receiver_ticket_type']").val();
                first_name  = $(".cart_detail input[name='ticket_receiver_first_name']").val();
                last_name   = $(".cart_detail input[name='ticket_receiver_last_name']").val();
                email       = $(".cart_detail input[name='ticket_receiver_email']").val();
                phone       = $(".cart_detail input[name='ticket_receiver_phone']").val();
                address     = $(".cart_detail input[name='ticket_receiver_address']").val();

                address_required    = $(".cart_detail .info_ticket_receiver li input.address_required").val();
                phone_required      = $(".cart_detail .info_ticket_receiver li input.phone_required").val();
                coupon              = $("#submit-code-discount").attr("data-discount-code");
                payment_method      = $('input[name=payment]:checked').val();
                seat_option         = $(".cart_detail .cart-content .cart-ticket-info").data("seat-option");

                key_store = id_event + '_' + id_cal;

                cart = localStorage.getItem(key_store);
                cart = JSON.parse(cart);
                
                /* Hide Submit Button*/

                $(this).css({
                    "z-index": "-1"
                });

                $(this).find('.submit-load-more').css({
                    "z-index": "1"
                });

                $(".error-empty-input").css({
                    "display": "none"
                });

                $(document).find('.error-empty-input').css('display','none');

                $(document).find('.cart-content').addClass('disabled');
                $(document).find('.wp-cart-info').addClass('disabled');
                $(document).find('.cart-discount-button').css('display','none');

                let flag = null;

                if ( ! $("#first_name").val() && $("#first_name").length != 0 ) {
                    $(".error-first_name").css({
                        "display": "block"
                    });
                    $(".error-first_name").siblings("li").css({
                        "border-top": "1px solid #ccc"
                    });
                    $(".error-first_name").siblings("li").css({
                        "margin-bottom": "10px"
                    });
                    flag = 'error';
                }

                if ( ! $("#last_name").val() && $("#last_name").length != 0 ) {
                    $(".error-last_name").css({
                        "display": "block"
                    });
                    $(".error-last_name").siblings("li").css({
                        "border-top": "1px solid #ccc"
                    });
                    $(".error-last_name").siblings("li").css({
                        "margin-bottom": "10px"
                    });
                    flag = 'error';
                }

                const validateEmail = (email) => {
                    return email.match(
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
                };

                if ( ! $("#email").val() && $("#email").length != 0 ) {
                    $(".error-email").css({
                        "display": "block"
                    });
                    $(".error-email").siblings("li").css({
                        "border-top": "1px solid #ccc"
                    });
                    $(".error-email").siblings("li").css({
                        "margin-bottom": "10px"
                    });
                    flag = 'error';
                } else if ( ! validateEmail( $("#email").val() ) ) {
                    $(".error-invalid-email").css({
                        "display": "block"
                    });
                    $(".error-invalid-email").siblings("li").css({
                        "border-top": "1px solid #ccc"
                    });
                    $(".error-invalid-email").siblings("li").css({
                        "margin-bottom": "10px"
                    });
                    flag = 'error';
                }

                if ( $("#email_confirm").length ) {

                    if ( ! $("#email_confirm").val() && $("#email_confirm").length != 0 ) {
                        $(".error-email-confirm-require").css({
                            "display": "block"
                        });
                        $(".error-email-confirm-require").siblings("li").css({
                            "border-top": "1px solid #ccc"
                        });
                        $(".error-email-confirm-require").siblings("li").css({
                            "margin-bottom": "10px"
                        });
                        flag = 'error';
                    } else {
                        var email_confirm   = $("#email_confirm").val();
                        var email_one       = $("#email").val();

                        if ( email_one != email_confirm ) {
                            $(".error-email-confirm-not-match").css({
                                "display": "block"
                            });
                            $(".error-email-confirm-not-match").siblings("li").css({
                                "border-top": "1px solid #ccc"
                            });
                            $(".error-email-confirm-not-match").siblings("li").css({
                                "margin-bottom": "10px"
                            });
                            flag = 'error';
                        }
                    }
                }

                if ( ! $("#phone").val() && phone_required == 'true' ) {
                    $(".error-phone").css({
                        "display": "block"
                    });
                    $(".error-phone").siblings("li").css({
                        "border-top": "1px solid #ccc"
                    });
                    $(".error-phone").siblings("li").css({
                        "margin-bottom": "10px"
                    });
                    flag = 'error';
                }

                if ( ! $("#address").val() && address_required == 'true' ) {
                    $(".error-address").css({
                        "display": "block"
                    });
                    $(".error-address").siblings("li").css({
                        "border-top": "1px solid #ccc"
                    });
                    $(".error-address").siblings("li").css({
                        "margin-bottom": "10px"
                    });
                    flag = 'error';
                }

                if ( ! $('input:radio[name=payment]').is(':checked') ) {
                    $(".error-payment").css({
                        "display": "block"
                    });
                    flag = 'error';
                }

                var list_key_checkout_field     = $('#el_list_key_checkout_field').val();
                var list_type_checkout_field    = $('#el_list_key_checkout_field').data('type');
                var data_checkout_field         = {};

                if ( list_key_checkout_field ) {
                    list_key_checkout_field = JSON.parse(list_key_checkout_field);
                    var special_fields  = [ 'textarea' ,'select' ,'radio', 'checkbox', 'file' ];
                    var ticket_receiver = $('.input_ticket_receiver').not('.input_mult_ticket');

                    for ( var key_ckf in list_key_checkout_field ) {
                        var name_ckf    = list_key_checkout_field[key_ckf];
                        var type_ckf    = list_type_checkout_field[name_ckf];
                        var value_ckf   = '';

                        if ( $.inArray( type_ckf, special_fields ) !== -1 ) {
                            // Select
                            if ( type_ckf == 'select' ) {
                                var required    = ticket_receiver.find('select[name="'+name_ckf+'"]').hasClass('required');
                                var value_ckf   = ticket_receiver.find('select[name="'+name_ckf+'"]').val();

                                if ( ! value_ckf && required ) {
                                    ticket_receiver.find( ".error-" + name_ckf ).css({
                                        "display": "block"
                                    });
                                    ticket_receiver.find( ".error-" + name_ckf ).siblings("li").css({
                                        "border-top": "1px solid #ccc"
                                    });
                                    ticket_receiver.find( ".error-"  + name_ckf ).siblings("li").css({
                                        "margin-bottom": "10px",
                                    });
                                    flag = 'error';
                                }
                            }
                            // End

                            // Radio
                            if ( type_ckf == 'radio' ) {
                                var required    = ticket_receiver.find('.el-ckf-radio').hasClass('required');
                                var value_ckf   = ticket_receiver.find('input[name="'+name_ckf+'"]:checked' ).val();

                                if ( ! value_ckf && required ) {
                                    ticket_receiver.find( ".error-" + name_ckf ).css({
                                        "display": "block"
                                    });
                                    ticket_receiver.find( ".error-" + name_ckf ).siblings("li").css({
                                        "border-top": "1px solid #ccc"
                                    });
                                    ticket_receiver.find( ".error-"  + name_ckf ).siblings("li").css({
                                        "margin-bottom": "10px",
                                    });
                                    flag = 'error';
                                }
                            }
                            // End

                            // Checkbox
                            if ( type_ckf == 'checkbox' ) {
                                var required    = ticket_receiver.find('.el-ckf-checkbox').hasClass('required');
                                var value_ckf   = [];

                                ticket_receiver.find('input[data-name="'+name_ckf+'"]:checked' ).each( function() {
                                    var val = $(this).val();
                                    value_ckf.push(val);
                                });

                                if ( value_ckf && $.isArray( value_ckf ) ) {
                                    value_ckf = value_ckf.join( ', ' );
                                } else {
                                    value_ckf = '';
                                }

                                if ( ! value_ckf && required ) {
                                    ticket_receiver.find( ".error-" + name_ckf ).css({
                                        "display": "block"
                                    });
                                    ticket_receiver.find( ".error-" + name_ckf ).siblings("li").css({
                                        "border-top": "1px solid #ccc"
                                    });
                                    ticket_receiver.find( ".error-"  + name_ckf ).siblings("li").css({
                                        "margin-bottom": "10px",
                                    });
                                    flag = 'error';
                                }
                            }
                            // End

                            // File
                            if ( type_ckf == 'file' ) {
                                var required    = ticket_receiver.find('input[name="'+name_ckf+'"]').hasClass('required');
                                var value_ckf   = ticket_receiver.find('input[name="'+name_ckf+'"]').val();
                                var message     = ticket_receiver.find('input[name="'+name_ckf+'"]').data('required');

                                if ( ! value_ckf && required ) {
                                    ticket_receiver.find( ".error-" + name_ckf ).html('').append('<span>'+message+'</span>').css({
                                        "display": "block"
                                    });
                                    ticket_receiver.find( ".error-" + name_ckf ).siblings("li").css({
                                        "border-top": "1px solid #ccc"
                                    });
                                    ticket_receiver.find( ".error-"  + name_ckf ).siblings("li").css({
                                        "margin-bottom": "10px",
                                    });
                                    flag = 'error';
                                }
                            }
                            // End
                            
                            // Textarea
                            if ( type_ckf == 'textarea' ) {
                                var value_ckf = ticket_receiver.find('textarea[name="'+name_ckf+'"]').val();
                                var required  = ticket_receiver.find('textarea[name="'+name_ckf+'"]').hasClass('required');

                                if ( ! value_ckf && required ) {
                                    ticket_receiver.find( ".error-" + name_ckf ).css({
                                        "display": "block"
                                    });
                                    ticket_receiver.find( ".error-" + name_ckf ).siblings("li").css({
                                        "border-top": "1px solid #ccc"
                                    });
                                    ticket_receiver.find( ".error-"  + name_ckf ).siblings("li").css({
                                        "margin-bottom": "10px",
                                    });
                                    flag = 'error';
                                }
                            }
                            // End
                        } else {
                            var value_ckf = ticket_receiver.find('input[name="'+name_ckf+'"]').val();
                            var required  = ticket_receiver.find('input[name="'+name_ckf+'"]').hasClass('required');
                        
                            if ( ! value_ckf && required ) {
                                ticket_receiver.find( ".error-" + name_ckf ).css({
                                    "display": "block"
                                });
                                ticket_receiver.find( ".error-" + name_ckf ).siblings("li").css({
                                    "border-top": "1px solid #ccc"
                                });
                                ticket_receiver.find( ".error-"  + name_ckf ).siblings("li").css({
                                    "margin-bottom": "10px",
                                });
                                flag = 'error';
                            }
                        }

                        if ( ! value_ckf || value_ckf == 'undefined' ) {
                            value_ckf = '';
                        }

                        data_checkout_field[name_ckf] = value_ckf;
                    }
                }

                // Multiple customers
                var data_customers = {};

                // Check input ticket required
                multiple_ticket = $(document).find('input[name=radio_ask]:checked').val();

                if ( multiple_ticket === 'yes' && flag == null ) {
                    flag            = input_ticket_receiver_required();
                    data_customers  = get_data_customers_input();
                }

                if ( flag === 'error' ) {
                    var h_wpadmin = $('#wpadminbar').outerHeight();
                    var h_ovamenu = $(document).find('.ovamenu_shrink .elementor-container').height();
                    

                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(".cart-customer-input").offset().top
                    }, 1000);


                    // hide loader
                    $(this).css({
                        "z-index": "1"
                    });

                    $(this).find('.submit-load-more').css({
                        "z-index": "-1"
                    });
                    var discount_code = $(document).find('#submit-code-discount').attr('data-discount-code');
                    if ( ! discount_code ) {
                        $(document).find('.wp-cart-info').removeClass('disabled');
                    }

                    $(document).find('.cart-content').removeClass('disabled');
                    $(document).find('.cart-discount-button').css('display','block');

                    return false;
                }

                /* Check Terms and Condition */
                if ( $( 'input[name="require_terms_condition"]' ).length && $( 'input[name="require_terms_condition"]:checked' ).val() == null ){
                    $(".error_terms_condition").css({
                        "display": "block"
                    });

                    var h_wpadmin = $('#wpadminbar').outerHeight();
                    var h_ovamenu = $(document).find('.ovamenu_shrink .elementor-container').height();
                    var $margin_top = 0;
                    if ( ! isNaN( parseInt( h_wpadmin ) ) ) {
                        $margin_top += parseInt( h_wpadmin );
                    }
                    if ( ! isNaN( parseInt( h_ovamenu ) ) ) {
                        $margin_top += parseInt( h_ovamenu );
                    }
                    $('html, body').animate({
                        scrollTop: ($(".error_terms_condition").offset().top - $margin_top)
                    }, 1000);

                    // hide loader
                    $(this).css({
                        "z-index": "1"
                    });

                    $(this).find('.submit-load-more').css({
                        "z-index": "-1"
                    });

                    var discount_code = $(document).find('#submit-code-discount').attr('data-discount-code');
                    if ( ! discount_code ) {
                        $(document).find('.wp-cart-info').removeClass('disabled');
                    }

                    $(document).find('.cart-content').removeClass('disabled');
                    $(document).find('.cart-discount-button').css('display','block');
                    return false;
                }

                var create_account = false;

                if ( $( 'input[name="create-account"]' ).length && $( 'input[name="create-account"]:checked' ).val() ) {
                   create_account = true;
                }

                var ovaevent_el_process_checkout = async function() {
                    // Upload Files
                    var fileArr     = [];
                    var fileResult  = {};

                    $('.cart_detail input[type="file"]').each( await function() {
                        var file = $(this).prop('files')[0];
                        
                        if ( file && file != 'undefined' ) {
                            file['input_name'] = $(this).attr('name');
                            fileArr.push( file );
                        }
                    });

                    // open popup again when submit form
                    if ( payment_method === 'stripe' ) {
                        let $booking_id = $(document).find('#checkout-button').attr('data-booking-id');
                        if ( $booking_id ) {

                            EL_Frontend.el_payment_stripe( $booking_id, localStorage, key_store );
                            $('#payment_stripe_modal').modal('show');

                            // show submit button
                            $(".submit-load-more").css({
                                "z-index": "-1"
                            });
                            $('#checkout-button').css({
                                "z-index": "1"
                            });

                            return false;
                        }
                    } else if ( payment_method === 'paypal' ) {
                        
                        let $booking_id = $(document).find('#checkout-button').attr('data-booking-id' );
                        if ( $booking_id ) {
                            $('#payment_paypal_modal').modal('show');
                            
                            // show submit button
                            $(".submit-load-more").css({
                                "z-index": "-1"
                            });
                            $('#checkout-button').css({
                                "z-index": "1"
                            });

                            return false;
                        }
                    }

                    if ( fileArr ) {
                        var formData = new FormData();
                        formData.append('action', 'el_upload_files');
                        formData.append('security', el_checkout_event_nonce);

                        for ( let i = 0; i < fileArr.length; i++ ) {
                            formData.append(fileArr[i]['input_name'], fileArr[i]);
                        }

                        $.ajax({
                            url: ajax_object.ajax_url,
                            type: 'POST',
                            async: false,
                            contentType: false,
                            processData: false,
                            cache: false,
                            data: formData,
                            success: await function(response) {
                                if ( response ) {
                                    var fileData    = JSON.parse(response);
                                    fileResult      = fileData.files
                                }
                            }
                        });
                    }
                    var $total_extra_sv = 0;
                    $('.extra-services').each( function(i,el){
                        $total_extra_sv += parseFloat( $(el).attr('data-total') );
                    } );
                    
                    if ( isNaN( $total_extra_sv ) ) {
                        $total_extra_sv = 0;
                    }

                    var $data = {
                        'el_checkout_event_nonce': el_checkout_event_nonce,
                        'cart': cart,
                        'ide': id_event,
                        'idcal': id_cal,
                        'first_name': first_name,
                        'last_name': last_name,
                        'email': email,
                        'phone': phone,
                        'address': address,
                        'payment_method': payment_method,
                        'coupon': coupon,
                        'total_extra_sv': $total_extra_sv,
                        'data_checkout_field': data_checkout_field,
                        'seat_option': seat_option,
                        'create_account': create_account,
                        'seat': seat,
                        'ticket_type': ticket_type,
                        'multiple_ticket': multiple_ticket,
                        'data_customers': data_customers,
                        'files': fileResult,
                    };

                    $.post(ajax_object.ajax_url, {
                        action: 'el_process_checkout',
                        data: $data,
                    }, function(response) {


                        var data = JSON.parse(response);
                        var message_error = data.el_message;
                        var $payment_method = data.payment_method;
                        var $show_countdown = data.show_countdown;
                        var $booking_id = data.booking_id;
                        var $amount = data.amount;
                        var $url = data.el_url;
                        
                        $(document).find('#checkout-button').attr('data-booking-id', $booking_id );
                        $(document).find('#checkout-button').attr('data-amount', $amount );
                        // remove extra service
                        localStorage.removeItem('s_'+key_store);
                        // Check seat map
                        if ( data.el_option == 'map' ) {
                            for ( var i = data.el_content.length - 1; i >= 0; i-- ) {
                                for ( var k = cart.length - 1; k >= 0; k-- ) {
                                    if ( data.el_content[i] == cart[k].id ) cart.splice(k, 1);
                                }
                            }

                            localStorage.setItem(key_store, JSON.stringify(cart));

                            $(".submit-load-more").css({
                                "z-index": "-1"
                            });

                            $(".cart_detail .cart-sidebar .message-error .auto_reload").append(data.el_reload_page);
                            setTimeout(function() {
                                location.reload();
                            }, 5000);
                        }

                        // Check Holding Tickets
                        if ( data.el_option == 'holding_ticket' ) {
                            if ( data.el_reload_page ) {
                                localStorage.removeItem(key_store);

                                $(".submit-load-more").css({
                                    "z-index": "-1"
                                });

                                $(".cart_detail .cart-sidebar .message-error .auto_reload").append(data.el_reload_page);
                                setTimeout(function() {
                                    location.reload();
                                }, 10000);
                            }
                        }

                        if ( $show_countdown == true ) {
                            switch( $payment_method ) {
                              case 'stripe':
                              case 'paypal':
                                let data = {
                                    'action': 'el_payment_countdown',
                                    'booking_id': $booking_id,
                                    'ide': id_event,
                                    'idcal': id_cal,
                                };
                                $.post( ajax_object.ajax_url, data, function(res){
                                    $('.cart-content').append(res);
                                    EL_Frontend.countdown_checkout();
                                } );
                                break;
                              default:
                            }
                        }

                        // Stripe
                        if ( $payment_method === 'stripe' ) {
                            // handle payment
                            let $booking_id = $(document).find('#checkout-button').attr('data-booking-id' );
                            if ( $booking_id ) {
                                EL_Frontend.el_payment_stripe( $booking_id ,localStorage, key_store );
                            }
                           
                            $('#payment_stripe_modal').modal('show');
                            
                            // show submit button
                            $(".cart_detail .cart-sidebar .message-error p").append(message_error);
                            $(".submit-load-more").css({
                                "z-index": "-1"
                            });
                            $('#checkout-button').css({
                                "z-index": "1"
                            });

                            return false;
                        // Paypal
                        } else if ( payment_method === 'paypal' ) {
                            let $booking_id = $(document).find('#checkout-button').attr('data-booking-id' );
                            if ( $booking_id ) {
                                EL_Frontend.el_payment_paypal( $booking_id, localStorage, key_store );
                                $('#payment_paypal_modal').modal('show');
                                
                                // show submit button
                                $(".submit-load-more").css({
                                    "z-index": "-1"
                                });
                                $('#checkout-button').css({
                                    "z-index": "1"
                                });

                                return false;
                            }
                        }

                        // booking successful
                        if ( ! message_error ) {
                            localStorage.removeItem(key_store);
                            window.location.href = $url;
                        } else {
                            $(".cart_detail .cart-sidebar .message-error p").append(message_error);
                            $(".submit-load-more").css({
                                "z-index": "-1"
                            });
                            $('#checkout-button').css({
                                "z-index": "1"
                            });
                        }
                    });
                }

                var recapcha_v2_cart_event = async function(){

                    var $token = $(document).find(".cart_detail #ovaevent_recapcha_token").val();
                    var $secret_key = $(document).find(".cart_detail #ovaevent_recapcha_token").attr('data-key'); 

                    if ( $secret_key && $token ) {
                        await $.post(ajax_object.ajax_url, {
                            action: 'el_verify_google_recapcha',
                            data : {
                                'secret': $secret_key,
                                'response': $token
                            }
                        },await function( response ) {
                            if ( response == 0 ) {
                                $(document).find('.cart_detail #ovaevent_recapcha_token').attr("data-pass","no");
                            } else {
                                $(document).find('.cart_detail #ovaevent_recapcha_token').attr("data-pass","yes");
                            }
                        },
                        );
                    } else {
                        $(document).find('.cart_detail #ovaevent_recapcha_token').attr("data-pass","no");
                    }
                    
                    let $check_recapcha = $(document).find('.cart_detail #ovaevent_recapcha_token').attr("data-pass");

                    if ( $check_recapcha === "yes" ) {
                        await ovaevent_el_process_checkout();
                    } else {
                        $this.css({
                            "z-index": "1"
                        });

                        $this.find('.submit-load-more').css({
                            "z-index": "-1"
                        });


                        var discount_code = $(document).find('#submit-code-discount').attr('data-discount-code');
                        if ( ! discount_code ) {
                            $(document).find('.wp-cart-info').removeClass('disabled');
                        }
                        $(document).find('.cart-content').removeClass('disabled');
                        $(document).find('.cart-discount-button').css('display','block');
                        var mess = '';
                        mess = $(document).find('.cart_detail #ovaevent_recapcha_token').data("mess");
                        if ( mess ) {
                            alert( mess );
                        }
                        return false;
                    }
                }

                var recapcha_v3_cart_event = async function(){
                    var $token      = $(document).find('#el_cart input[name="g-recaptcha-response"]').val();
                    var $secret_key = $(document).find('#el_cart input[name="g-recaptcha-response"]').attr('data-key');  

                    if ( $secret_key && $token ) {
                        await $.post(ajax_object.ajax_url, {
                            action: 'el_verify_google_recapcha',
                            data : {
                                'secret': $secret_key,
                                'response': $token
                            }
                        },await function( response ) {
                            if ( response == 0 ) {
                                $(document).find('#el_cart input[name="g-recaptcha-response"]').attr("data-pass","no");
                            } else {
                                $(document).find('#el_cart input[name="g-recaptcha-response"]').attr("data-pass","yes");
                            }
                        },
                        );
                    } else {
                        $(document).find('#el_cart input[name="g-recaptcha-response"]').attr("data-pass","no");
                    }

                    let $check_recapcha = $(document).find('#el_cart input[name="g-recaptcha-response"]').attr("data-pass");

                    if ( $check_recapcha !== "yes" ) {
                        $this.css({
                            "z-index": "1"
                        });

                        $this.find('.submit-load-more').css({
                            "z-index": "-1"
                        });

                        var discount_code = $(document).find('#submit-code-discount').attr('data-discount-code');
                        if ( ! discount_code ) {
                            $(document).find('.wp-cart-info').removeClass('disabled');
                        }

                        $(document).find('.cart-content').removeClass('disabled');
                        $(document).find('.cart-discount-button').css('display','block');

                        // display err
                        var mess = '';
                        mess = $(document).find('#el_cart input[name="g-recaptcha-response"]').data("mess");
                        if ( mess ) {
                            alert( mess );
                        }
                        return false;
                    } else {
                        await ovaevent_el_process_checkout();
                    }
                }

                // recapcha code here
                if ( $(document).find('#el_cart #ovaevent-recaptcha-wrapper').length ) {
                    recapcha_v2_cart_event();
                } else if ( $(document).find('#el_cart input[name="g-recaptcha-response"]').length ) {
                    recapcha_v3_cart_event();
                } else {
                    ovaevent_el_process_checkout();
                }
            });

            // File
            $('.input_ticket_receiver .el-ckf-file input[type="file"]').each( function() {
                $(this).on( 'change', function(e) {
                    e.preventDefault();

                    var file            = $(this);
                    var name_field      = file.attr('name');

                    file.closest('.input_ticket_receiver').find( ".error-" + name_field ).css({
                        "display": "none"
                    });

                    var limit           = file.data('max-file-size');
                    var required_msg    = file.data('required');
                    var max_size_msg    = file.data('max-file-size-msg');
                    var formats_msg     = file.data('formats');
                    var file_mimes      = file.data('file-mimes');
                    var formats_file    = [];

                    if ( typeof( file_mimes ) == 'object' ) {
                        $.each( file_mimes, function( key, val ) {
                            formats_file.push( val );
                        });
                    }

                    file.closest('.el-ckf-file').find('.el-ckf-file-name').html('');
                    
                    var name = this.files[0].name;
                    var size = this.files[0].size;
                    var type = this.files[0].type;
                    
                    if ( name && size && type ) {
                        var mb = ( size/1048576 ).toFixed(2);

                        if ( mb > limit ) {
                            file.closest('.input_ticket_receiver').find( ".error-" + name_field ).html('').append('<span>'+max_size_msg+'</span>').css({
                                "display": "block"
                            });
                            file.closest('.input_ticket_receiver').find( ".error-" + name_field ).siblings("li").css({
                                "border-top": "1px solid #ccc"
                            });
                            file.closest('.input_ticket_receiver').find( ".error-"  + name_field ).siblings("li").css({
                                "margin-bottom": "10px",
                            });
                            file.val('');
                            $(".cart_detail .info_ticket_receiver li .span." + name_field).text('');

                            return false;
                        }

                        if ( $.inArray( type, formats_file ) == -1 ) {
                            file.closest('.input_ticket_receiver').find( ".error-" + name_field ).html('').append('<span>'+formats_msg+'</span>').css({
                                "display": "block"
                            });
                            file.closest('.input_ticket_receiver').find( ".error-" + name_field ).siblings("li").css({
                                "border-top": "1px solid #ccc"
                            });
                            file.closest('.input_ticket_receiver').find( ".error-"  + name_field ).siblings("li").css({
                                "margin-bottom": "10px",
                            });
                            file.val('');
                            $(".cart_detail .info_ticket_receiver li .span." + name_field).text('');

                            return false;
                        }

                        file.closest('.el-ckf-file').find('.el-ckf-file-name').html(name);
                        $(".cart_detail .info_ticket_receiver li .span." + name_field).text(name);
                    }
                });
            });
            // End

            var input_ticket_receiver_required = function() {
                // Get Cart Data
                let eventID     = $(document).find(".cart_detail .cart-content .cart-ticket-info").attr('data-id-event');
                let calendarID  = $(document).find(".cart_detail .cart-content .cart-ticket-info").attr('data-id-cal');
                let cart        = JSON.parse( localStorage.getItem( eventID + '_' + calendarID ) );

                // Areas
                let areas = [], areaQty = {}, areaQtyInput = {};

                for ( var i = 0; i < cart.length; i++ ) {
                    if ( parseInt( cart[i].qty ) ) {
                        if ( $.inArray( cart[i].id, areas ) == -1 ) areas.push( cart[i].id );
                        areaQty[cart[i].id] = cart[i].qty;
                    }
                }

                let seat_option         = $(document).find(".cart_detail .cart-content .cart-ticket-info").data("seat-option");
                let flag                = null;
                var address_required    = $(document).find(".cart_detail .info_ticket_receiver li input.address_required").val();
                var phone_required      = $(document).find(".cart_detail .info_ticket_receiver li input.phone_required").val();

                // Seats
                let seats   = [];
                let seat    = $(document).find(".cart_detail .step-2 .cart-customer-input ul.input_ticket_receiver").not('.input_mult_ticket').find("#select_seats").val();
                if ( seat ) {
                    seats.push(seat);

                    // Check area input
                    if ( $.inArray( seat, areas ) !== -1 ) {
                        if ( areaQtyInput.hasOwnProperty(seat) ) {
                            areaQtyInput[seat] += 1;
                        } else {
                            areaQtyInput[seat] = 1;
                        }
                    }
                }

                $(document).find(".input_mult_ticket").each(function(i,el) {
                    var that = $(el);
                    var index = that.data('ticket');

                    if ( typeof index === "undefined" ) {
                        index = 0;
                    }

                    if ( ! that.find("#first_name").val() ) {
                        that.find(".error-first_name").css({
                            "display": "block"
                        });
                        that.find(".error-first_name").siblings("li").css({
                            "border-top": "1px solid #ccc"
                        });
                        that.find(".error-first_name").siblings("li").css({
                            "margin-bottom": "10px"
                        });
                        flag = 'error';
                    }

                    if ( ! that.find("#last_name").val() ) {
                        that.find(".error-last_name").css({
                            "display": "block"
                        });
                        that.find(".error-last_name").siblings("li").css({
                            "border-top": "1px solid #ccc"
                        });
                        that.find(".error-last_name").siblings("li").css({
                            "margin-bottom": "10px"
                        });
                        flag = 'error';
                    }

                    const validateEmail = (email) => {
                        return email.match(
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            );
                    };

                    if ( ! that.find("#email").val() ) {
                        that.find(".error-email").css({
                            "display": "block"
                        });
                        that.find(".error-email").siblings("li").css({
                            "border-top": "1px solid #ccc"
                        });
                        that.find(".error-email").siblings("li").css({
                            "margin-bottom": "10px"
                        });
                        flag = 'error';
                    } else if ( ! validateEmail( $("#email").val() ) ) {
                        $(".error-invalid-email").css({
                            "display": "block"
                        });
                        $(".error-invalid-email").siblings("li").css({
                            "border-top": "1px solid #ccc"
                        });
                        $(".error-invalid-email").siblings("li").css({
                            "margin-bottom": "10px"
                        });
                        flag = 'error';
                    }

                    if ( that.find("#email_confirm").length ) {

                        if ( ! that.find("#email_confirm").val() ) {
                            that.find(".error-email-confirm-require").css({
                                "display": "block"
                            });
                            that.find(".error-email-confirm-require").siblings("li").css({
                                "border-top": "1px solid #ccc"
                            });
                            that.find(".error-email-confirm-require").siblings("li").css({
                                "margin-bottom": "10px"
                            });
                            flag = 'error';
                        } else {
                            var email_confirm = that.find("#email_confirm").val();
                            var email_one     = that.find("#email").val();

                            if ( email_one != email_confirm ) {
                                that.find(".error-email-confirm-not-match").css({
                                    "display": "block"
                                });
                                that.find(".error-email-confirm-not-match").siblings("li").css({
                                    "border-top": "1px solid #ccc"
                                });
                                that.find(".error-email-confirm-not-match").siblings("li").css({
                                    "margin-bottom": "10px"
                                });
                                flag = 'error';
                            }
                        }
                    }

                    if ( ! that.find("#phone").val() && phone_required == 'true' ) {
                        that.find(".error-phone").css({
                            "display": "block"
                        });
                        that.find(".error-phone").siblings("li").css({
                            "border-top": "1px solid #ccc"
                        });
                        that.find(".error-phone").siblings("li").css({
                            "margin-bottom": "10px"
                        });
                        flag = 'error';
                    }

                    if ( ! that.find("#address").val() && address_required == 'true' ) {
                        that.find(".error-address").css({
                            "display": "block"
                        });
                        that.find(".error-address").siblings("li").css({
                            "border-top": "1px solid #ccc"
                        });
                        that.find(".error-address").siblings("li").css({
                            "margin-bottom": "10px"
                        });
                        flag = 'error';
                    }

                    if ( seat_option === 'map' ) {
                        if ( ! that.find("#select_seats").val() ) {
                            that.find(".error-select_seats").css({
                                "display": "block"
                            });
                            that.find(".error-select_seats .required").css({
                                "display": "inline-block"
                            });
                            that.find(".error-select_seats .duplicate_seats").css({
                                "display": "none"
                            });
                            that.find(".error-select_seats").siblings("li").css({
                                "border-top": "1px solid #ccc"
                            });
                            that.find(".error-select_seats").siblings("li").css({
                                "margin-bottom": "10px"
                            });
                            flag = 'error';
                        }

                        seat = that.find("#select_seats").val();
                        if ( seat ) {
                            if ( $.inArray( seat, areas ) !== -1 ) {
                                if ( areaQtyInput.hasOwnProperty(seat) ) {
                                    areaQtyInput[seat] += 1;
                                } else {
                                    areaQtyInput[seat] = 1;
                                }
                            } else {
                                if ( $.inArray(seat, seats) == -1 ) {
                                    seats.push(seat);
                                }/* else {
                                    that.find(".error-select_seats").css({
                                        "display": "block"
                                    });
                                    that.find(".error-select_seats .required").css({
                                        "display": "none"
                                    });
                                    that.find(".error-select_seats .area_not_match").css({
                                        "display": "none"
                                    });
                                    that.find(".error-select_seats .duplicate_seats").css({
                                        "display": "inline-block"
                                    });
                                    flag = 'error';
                                }*/
                            }
                        }
                    }

                    if ( seat_option === 'simple' ) {
                        if ( ! that.find("#ticket_type").val() ) {
                            that.find(".error-ticket_type").css({
                                "display": "block"
                            });
                            that.find(".error-ticket_type").siblings("li").css({
                                "border-top": "1px solid #ccc"
                            });
                            that.find(".error-ticket_type").siblings("li").css({
                                "margin-bottom": "10px"
                            });
                            flag = 'error';
                        }
                    }

                    var list_key_checkout_field     = $('#el_list_key_checkout_field').val();
                    var list_type_checkout_field    = $('#el_list_key_checkout_field').data('type');

                    if ( list_key_checkout_field ) {
                        list_key_checkout_field = JSON.parse(list_key_checkout_field);
                        var special_fields = [ 'textarea', 'select', 'radio', 'checkbox', 'file' ];

                        for( var key_ckf in list_key_checkout_field ) {
                            var name_ckf = list_key_checkout_field[key_ckf];
                            var type_ckf = list_type_checkout_field[name_ckf];

                            if ( $.inArray( type_ckf, special_fields ) !== -1 ) {
                                var nameIndex = name_ckf;

                                if ( index ) {
                                    nameIndex = name_ckf+'_index'+index;
                                }

                                // Select
                                if ( type_ckf == 'select' ) {
                                    var required    = that.find('select[name="'+nameIndex+'"]').hasClass('required');
                                    var value_ckf   = that.find('select[name="'+nameIndex+'"]' ).val();

                                    if ( ! value_ckf && required ) {
                                        that.find( ".error-" + name_ckf ).css({
                                            "display": "block"
                                        });
                                        that.find( ".error-" + name_ckf ).siblings("li").css({
                                            "border-top": "1px solid #ccc"
                                        });
                                        that.find( ".error-"  + name_ckf ).siblings("li").css({
                                            "margin-bottom": "10px",
                                        });
                                        flag = 'error';
                                    }
                                }
                                // End

                                // Radio
                                if ( type_ckf == 'radio' ) {
                                    var required  = that.find('.el-ckf-radio').hasClass('required');
                                    var value_ckf = that.find('input[name="'+nameIndex+'"]:checked' ).val();
                                    
                                    if ( ! value_ckf && required ) {
                                        that.find( ".error-" + name_ckf ).css({
                                            "display": "block"
                                        });
                                        that.find( ".error-" + name_ckf ).siblings("li").css({
                                            "border-top": "1px solid #ccc"
                                        });
                                        that.find( ".error-"  + name_ckf ).siblings("li").css({
                                            "margin-bottom": "10px",
                                        });
                                        flag = 'error';
                                    }
                                }
                                // End

                                // Checkbox
                                if ( type_ckf == 'checkbox' ) {
                                    var required    = that.find('.el-ckf-checkbox').hasClass('required');
                                    var value_ckf   = that.find('input[data-name="'+nameIndex+'"]:checked' ).val();

                                    if ( ! value_ckf && required ) {
                                        that.find( ".error-" + name_ckf ).css({
                                            "display": "block"
                                        });
                                        that.find( ".error-" + name_ckf ).siblings("li").css({
                                            "border-top": "1px solid #ccc"
                                        });
                                        that.find( ".error-"  + name_ckf ).siblings("li").css({
                                            "margin-bottom": "10px",
                                        });
                                        flag = 'error';
                                    }
                                }
                                // End

                                // File
                                if ( type_ckf == 'file' ) {
                                    var required    = that.find('input[name="'+nameIndex+'"]').hasClass('required');
                                    var value_ckf   = that.find('input[name="'+nameIndex+'"]').val();
                                    var message     = that.find('input[name="'+nameIndex+'"]').data('required');

                                    if ( ! value_ckf && required ) {
                                        that.find( ".error-" + name_ckf ).html('').append('<span>'+message+'</span>').css({
                                            "display": "block"
                                        });
                                        that.find( ".error-" + name_ckf ).siblings("li").css({
                                            "border-top": "1px solid #ccc"
                                        });
                                        that.find( ".error-"  + name_ckf ).siblings("li").css({
                                            "margin-bottom": "10px",
                                        });
                                        flag = 'error';
                                    }
                                }
                                // End
                                
                                // Textarea
                                if ( type_ckf == 'textarea' ) {
                                    var required   = that.find('textarea[name="'+name_ckf+'"]').hasClass('required');
                                    var value_ckf  = that.find('textarea[name="'+name_ckf+'"]').val();

                                    if ( ! value_ckf && required ) {
                                        that.find( ".error-" + name_ckf ).css({
                                            "display": "block"
                                        });
                                        that.find( ".error-" + name_ckf ).siblings("li").css({
                                            "border-top": "1px solid #ccc"
                                        });
                                        that.find( ".error-"  + name_ckf ).siblings("li").css({
                                            "margin-bottom": "10px",
                                        });
                                        flag = 'error';
                                    }
                                }
                                // End
                            } else {
                                var value_ckf = that.find('input[name="'+name_ckf+'"]').val();

                                if ( ! value_ckf && that.find("#" + name_ckf ).hasClass('required') ) {
                                    that.find( ".error-" + name_ckf ).css({
                                        "display": "block"
                                    });
                                    that.find( ".error-" + name_ckf ).siblings("li").css({
                                        "border-top": "1px solid #ccc"
                                    });
                                    that.find( ".error-"  + name_ckf ).siblings("li").css({
                                        "margin-bottom": "10px",
                                    });
                                    flag = 'error';
                                }
                            }
                        }
                    }

                    if ( flag !== null ) {
                        $(".cart_detail .step-2 .cart-customer-input .el_multiple_ticket").find('.ticket_item_'+index+'').click();
                        return false;
                    }
                });

                // Check Area
                if ( Object.keys(areaQty).length !== 0 && Object.keys(areaQtyInput).length !== 0 ) {
                    for ( var key in areaQty ) {
                        if ( areaQty[key] != areaQtyInput[key] ) {
                            $(document).find('.input_ticket_receiver').find(".error-select_seats").css({
                                "display": "block"
                            });
                            $(document).find('.input_ticket_receiver').find(".error-select_seats .required").css({
                                "display": "none"
                            });
                            $(document).find('.input_ticket_receiver').find(".error-select_seats .area_not_match").css({
                                "display": "inline-block"
                            });
                            $(document).find('.input_ticket_receiver').find(".error-select_seats .duplicate_seats").css({
                                "display": "none"
                            });
                            flag = 'error';
                        }
                    }
                }

                return flag;
            }

            var get_data_customers_input = function() {
                var data        = {};
                let seat_option = $(document).find(".cart_detail .cart-content .cart-ticket-info").data("seat-option");

                // Get Cart Data
                let eventID     = $(document).find(".cart_detail .cart-content .cart-ticket-info").attr('data-id-event');
                let calendarID  = $(document).find(".cart_detail .cart-content .cart-ticket-info").attr('data-id-cal');
                let cart        = JSON.parse( localStorage.getItem( eventID + '_' + calendarID ) );

                // Areas
                let areas = [];

                for ( var i = 0; i < cart.length; i++ ) {
                    if ( parseInt( cart[i].qty ) ) {
                        if ( $.inArray( cart[i].id, areas ) == -1 ) areas.push( cart[i].id );
                    } else if ( cart[i].data_person !== undefined ) {
                        areas.push( cart[i].id );
                    }
                }

                let items, checkout_fields;
                let seat, area, ticket_type, first_name, last_name, email, phone, address;
                var list_key_checkout_field     = $(document).find('#el_list_key_checkout_field').val();
                var list_type_checkout_field    = $(document).find('#el_list_key_checkout_field').data('type');

                if ( list_key_checkout_field ) {
                   list_key_checkout_field = JSON.parse(list_key_checkout_field);
                }

                $(document).find(".input_ticket_receiver").each(function(i,el) {
                    var that = $(el);

                    var index = that.attr('data-ticket');

                    if ( typeof index === 'undefined' ) {
                        index = 0;
                    }

                    var ticket_id = that.closest('.cart-customer-input').find('.el_multiple_ticket .ticket_item[data-index="'+index+'"]').attr('data-ticket-id');

                    items = {};
                    checkout_fields = {};

                    items['index'] = index;

                    seat = that.find("#select_seats").val();
                    items['seat'] = seat;

                    area = that.find("#select_area").val();
                    items['area'] = area;

                    ticket_type = that.find("#ticket_type").val();
                    items['ticket_type'] = ticket_type;

                    first_name = that.find("#first_name").val();
                    items['first_name'] = first_name;

                    last_name = that.find("#last_name").val();
                    items['last_name'] = last_name;

                    email = that.find("#email").val();
                    items['email'] = email;

                    phone = that.find("#phone").val();
                    items['phone'] = phone;

                    address = that.find("#address").val();
                    items['address'] = address;

                    if ( list_key_checkout_field && $.isArray( list_key_checkout_field ) ) {
                        var special_fields = [ 'textarea', 'select', 'radio', 'checkbox', 'file' ];

                        for ( var key_ckf in list_key_checkout_field ) {
                            var name_ckf    = list_key_checkout_field[key_ckf];
                            var name_field  = name_ckf;

                            if ( index ) {
                                name_field = name_field+'_index'+index;
                            }

                            var type_ckf    = list_type_checkout_field[name_ckf];
                            var value_ckf   = '';

                            if ( $.inArray( type_ckf, special_fields ) !== -1 ) {
                                // Select
                                if ( type_ckf == 'select' ) {
                                    var value_ckf = that.find('select[name="'+name_field+'"]' ).val();
                                }
                                // End

                                // Radio
                                if ( type_ckf == 'radio' ) {
                                    value_ckf = that.find('input[name="'+name_field+'"]:checked' ).val();
                                }
                                // End

                                // Checkbox
                                if ( type_ckf == 'checkbox' ) {
                                    value_ckf   = [];

                                    that.find('input[data-name="'+name_field+'"]:checked' ).each( function() {
                                        var val = $(this).val();
                                        value_ckf.push(val);
                                    });

                                    if ( value_ckf && $.isArray( value_ckf ) ) {
                                        value_ckf = value_ckf.join( ', ' );
                                    } else {
                                        value_ckf = '';
                                    }
                                }
                                // End

                                // File
                                if ( type_ckf == 'file' ) {
                                    var value_ckf = that.find('input[name="'+name_field+'"]').val();
                                }
                                // End
                                
                                // Textarea
                                if ( type_ckf == 'textarea' ) {
                                    var value_ckf = that.find('textarea[name="'+name_ckf+'"]').val();
                                }
                                // End
                            } else {
                                value_ckf = that.find('input[name="'+name_ckf+'"]').val();
                            }

                            if ( ! value_ckf || value_ckf == 'undefined' ) {
                                value_ckf = '';
                            }

                            checkout_fields[name_ckf] = value_ckf;
                        }
                    }
                    items['checkout_fields'] = checkout_fields;

                    if ( seat_option === 'map' ) {
                        if ( $.inArray( ticket_id, areas ) !== -1 ) {

                            if ( $.isArray( data[ticket_id] ) ) {
                                data[ticket_id].push(items);
                            } else {
                                data[ticket_id] = [];
                                data[ticket_id].push(items);
                            }
                        } else {
                            data[ticket_id] = items;
                        }
                    } else {
                        if ( $.isArray( data[ticket_id] ) ) {
                            data[ticket_id].push(items);
                        } else {
                            data[ticket_id] = [];
                            data[ticket_id].push(items);
                        }
                    }
                });

                return data;
            }
        },

        /* countdown_checkout */
        countdown_checkout: function() {
            if ( $('.countdown-checkout').length > 0 ) {
                $('.countdown-checkout').each( function() {
                    var that       = $(this);
                    var time       = that.data('time-countdown-checkout');
                    var redirect   = that.data('redirect');
                    var nonce      = that.data('countdown-checkout-nonce');
                    var booking_id = that.data('booking-id');
                    var event_id   = that.data('event-id');
                    var id_cal     = that.data('id-cal');
                    var key_store  = event_id + '_' + id_cal;

                    if ( !event_id || !id_cal ) {
                        key_store = '';
                    }

                    if ( time == 0 ) {
                        $.post(ajax_object.ajax_url, {
                            action: 'el_countdown_checkout',
                            data: {
                                nonce: nonce,
                                booking_id: booking_id,
                            },
                        }, function(response) {
                            if ( response == 'success' ) {
                                localStorage.removeItem(key_store);
                                window.location.href = redirect;
                            }
                        });
                    } else {
                        var minutes = Math.floor( time / 60 );
                        var seconds = parseInt( time % 60 );

                        var interval = setInterval( function() {
                            --seconds;
                            minutes = (seconds < 0) ? --minutes : minutes;
                            seconds = (seconds < 0) ? 59 : seconds;
                            seconds = (seconds < 10) ? '0' + seconds : seconds;
                            minutes = (minutes < 10) ? '0' + minutes : minutes;
                            that.find('.time').html(minutes + ':' + seconds);

                            minutes = parseInt(minutes);
                            seconds = parseInt(seconds);

                            if ( (seconds <= 0 ) && ( minutes <= 0 ) ) {
                                clearInterval(interval);

                                $.post(ajax_object.ajax_url, {
                                    action: 'el_countdown_checkout',
                                    data: {
                                        nonce: nonce,
                                        booking_id: booking_id,
                                    },
                                }, function(response) {
                                    if ( response == 'success' ) {
                                        localStorage.removeItem(key_store);
                                        window.location.href = redirect;
                                    }
                                });
                            }
                        }, 1000);
                    }
                });
            }
        },

        /*** Image Profile ***/
        image_profile: function() {
            /* Add Image Banner */
            var file_frame;

            $(document).on('click', '.author_image .add_image', function(e) {
                e.preventDefault();

                if ( typeof file_frame != 'undefined' ) {
                    file_frame.close();
                }

                var that = $(this);

                file_frame = wp.media.frames.file_frame = wp.media({
                    title: $(this).data('uploader-title'),
                    button: {
                        text: $(this).data('uploader-button-text'),
                    },
                    library: {
                        type: ['image']
                    },
                    multiple: false
                });

                file_frame.on('select', function() {
                    var selection = file_frame.state().get('selection');

                    selection.map(function(attachment, i) {
                        attachment = attachment.toJSON();
                      
                        if (attachment.sizes.el_thumbnail) {
                            that.parent().find('.wrap').html('<img class="image-preview" src="' + attachment.sizes.el_thumbnail.url + '"><button class="button remove_image">Remove Image</button>');
                            that.closest('.vendor_wrap').find('.vendor_sidebar .vendor_user_profile .wrap_image').html('<img class="user_image" src="' + attachment.sizes.el_thumbnail.url + '">');
                        } else {
                            that.parent().find('.wrap').html('<img class="image-preview" src="' + attachment.sizes.full.url + '"><button class="button remove_image">Remove Image</button>');
                            that.closest('.vendor_wrap').find('.vendor_sidebar .vendor_user_profile .wrap_image').html('<img class="user_image" src="' + attachment.sizes.full.url + '">');
                        }
                        that.parent().find('input').val(attachment.id);
                        // Change image profile sidebar
                    });
                });

                file_frame.open();
            });

            /* Remove Image Banner */
            $(document).on('click', '.author_image .remove_image', function(e) {
                e.preventDefault();
                $(this).closest('.author_image').find('input').val('');
                $(this).parent().empty();
                $('.vendor_user_profile .wrap_image').empty();
            });
        },

        /*** Update Profile ***/
        update_profile: function() {

            $(document).on('click', 'input[name="el_update_profile"]' ,function(e) {
                e.preventDefault();
                
                var flag = true;
                // Validate form
                if ( $("#user_phone").length && $("#user_phone").val() != '' ) {
                    var $phone_number   = $("#user_phone").val();
                    const regex         = /^(\(?\+?\d{1,3}\)?)?[-. ]?\(?\d{1,4}\)?[-. ]?\d{1,4}[-. ]?\d{1,9}$/;
                    let msg             = '';
                    if( $phone_number != "" && ! $phone_number.match(regex) ) {
                        msg = $('#user_phone').data("msg");
                        if ( msg ) {
                            alert(msg);
                        }
                        flag = false;
                    }

                }
                if ( $('#el_save_profile .ova-cf').length ) {

                    // Required
                    if ( $('#el_save_profile .required').length ) {
                        var $input_required = $('#el_save_profile .required');
                        let msg = '';
                        $input_required.each(function(i,el){
                            if ( $(el).val() == '' ){
                                msg = $(el).data('msg');
                                if ( msg ) {
                                    alert(msg);
                                }
                                flag = false;
                            }
                        });
                        // handle checkbox group
                        if ( $('.checkbox_field_wrap').length ) {
                            var $checkbox_list = $('.checkbox_field_wrap');
                            $checkbox_list.each(function(i,el){
                                if ( $(el).find('.required:checkbox').length && ! $(el).find('.required:checkbox:checked').length ) {
                                    msg = $(el).data('msg');
                                    if (msg) {
                                        alert(msg);
                                    }
                                    flag = false;
                                }
                            });
                        }
                    }
                    // Tel
                    if ( $('input[data-type="tel"]').length ) {
                        var $input_tel = $('input[data-type="tel"]');
                        $input_tel.each(function(i,el){
                            var $tel_val    = $(el).val();
                            const regex     = /^(\(?\+?\d{1,3}\)?)?[-. ]?\(?\d{1,4}\)?[-. ]?\d{1,4}[-. ]?\d{1,9}$/;
                            let msg         = '';
                            if ( $tel_val != "" && ! $tel_val.match(regex) ) {
                                msg = $(el).data('invalid');
                                if ( msg ) {
                                    alert(msg);
                                }
                                flag = false;
                            }
                        });
                    }
                    // Email
                    if ( $('input[data-type="email"]').length ) {
                        var $input_email = $('input[data-type="email"]');
                        $input_email.each(function(i,el){
                            var $email_val = $(el).val();
                            const validateEmail = (email) => {
                                return email.match(
                                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    );
                            };
                            let msg = '';
                            if ( $email_val != '' && ! validateEmail( $email_val ) ) {
                                msg = $(el).data('invalid');
                                if ( msg ) {
                                    alert(msg);
                                }
                                flag = false;
                            }
                        });
                    }
                    
                }

                if ( flag == true ) {
                    // remove value checkbox not check
                    if ( $('input:checkbox').length ) {
                        $('input:checkbox').each(function(i,el){
                            if ( ! $(el).is(":checked") ) {
                                $(el).val("");
                            }
                        });
                    }
                    $('#el_save_profile .ova__loader').show();
                    var formData = new FormData( $("#el_save_profile")[0], $('input[name="el_update_profile"]')[0] );
                    var formDataArr = {};
                    for (const [key, value] of formData) {
                        if ( formDataArr[key] == undefined ) {
                            formDataArr[key] = value;
                        } else {
                            formDataArr[key] = formData.getAll(key);
                        }
                    }

                    $.post(ajax_object.ajax_url, {
                        action: 'el_update_profile',
                        data: formDataArr,
                    }, function(response) {
                        if (response) {
                            location.reload();
                        }
                    });
                }

            });
        },

        /*** Add social ***/
        add_social: function() {
            $(document).on('click', '#author_social .add_social', function(e) {
                e.preventDefault();

                var index = $(this).parent().find('.social_item').length;
                var that = $(this);
                $.post(ajax_object.ajax_url, {
                    action: 'el_add_social',
                    data: {
                        index: index,
                    },
                }, function(a) {
                    that.parent().find('.social_list').append(a);
                });
                EL_Frontend.remove_social();
            });
        },

        /*** Save social ***/
        save_social: function() {
            $(document).on('click', 'input[name="el_update_social"]', function(e) {
                e.preventDefault();

                var el_update_social_nonce = $(this).closest('#el_save_social').find('#el_update_social_nonce').val();
                var user_profile_social = [];

                $(this).closest('#el_save_social').find('.social_item').each(function() {
                    var selectedIcon    = $(this).find('.icon_social').val();
                    var link_social     = $(this).find('.link_social').val();
                    var social_item     = [link_social, selectedIcon];
                    user_profile_social.push(social_item);
                });

                var that = $(this);

                $.post(ajax_object.ajax_url, {
                    action: 'el_save_social',
                    data: {
                        el_update_social_nonce: el_update_social_nonce,
                        user_profile_social: user_profile_social,
                    },
                }, function(a) {
                    location.reload();
                });

                EL_Frontend.repair_key_social();
            });
        },

        /*** Remove social ***/
        remove_social: function() {
            $(document).on('click', '#author_social .remove_social', function(e) {
                e.preventDefault();

                $(this).parent().remove();

                EL_Frontend.repair_key_social();
            });
        },

        /*** Repair key social ***/
        repair_key_social: function() {
            var i = 0;

            $(document).find('#author_social .social_item').each(function() {
                $(this).find('.link_social').attr('name', 'user_profile_social[' + i + '][link]');
                $(this).find('.icon_social').attr('name', 'user_profile_social[' + i + '][icon]');

                i++;
            });
        },

        /*** Check password ***/
        check_password: function() {
            $('#author_password #old_password').on('keyup', function() {
                var old_password = $(this).val();
                var that = $(this);

                $.post(ajax_object.ajax_url, {
                    action: 'el_check_password',
                    data: {
                        old_password: old_password,
                    },
                }, function(response) {
                    if (response == 'false') {
                        $('.check_old_pass').css('display', 'block');
                        that.closest('form').attr('novalidate', 'novalidate');
                    } else {
                        $('.check_old_pass').css('display', 'none')
                        that.closest('form').removeAttr('novalidate');
                    };
                });
            });

            $('input[name="el_update_password"]').on( 'click' ,function(event) {
                $("#el_save_password").validate({
                    rules: {
                        old_password: "required",
                        new_password: "required",
                        confirm_password: {
                            equalTo: '#new_password'
                        }
                    }
                });
            });
        },

        /*** Change password ***/
        change_password: function() {
            $('#author_password input[name="el_update_password"]').on('click', function(e) {
                e.preventDefault();
                var that    = $(this);
                var valid   = $(this).closest('form').valid();

                var el_update_password_nonce = that.closest('#el_save_password').find('#el_update_password_nonce').val();
                var old_password = that.closest('#el_save_password').find('#old_password').val();
                var new_password = that.closest('#el_save_password').find('#new_password').val();

                if (valid) {
                    $.post(ajax_object.ajax_url, {
                        action: 'el_change_password',
                        data: {
                            el_update_password_nonce: el_update_password_nonce,
                            old_password: old_password,
                            new_password: new_password,
                        },
                    }, function(response) {
                        if ( response !== '' ) {
                            window.location.href = response;
                        }
                    });
                }
            });
        },

        /*** View password ***/
        view_password: function() {
            $(document).find('.show_pass')
            var $show_pass = $('.show_pass');
            $show_pass.each(function(i,el){
                $(el).on("click",function(e){
                    if ( $(el).find('i').hasClass("dashicons-visibility") ) {
                        $(el).parent().find('input').attr('type','password');
                        $(el).find('i').addClass("dashicons-hidden");
                        $(el).find('i').removeClass("dashicons-visibility");
                    } else {
                        $(el).parent().find('input').attr('type','text');
                        $(el).find('i').addClass("dashicons-visibility");
                        $(el).find('i').removeClass("dashicons-hidden");
                    }
                });
            });
        },

        /*** Selected Event ***/
        select_event: function() {
            $(document).find('.wrap_event .check_all_event').change(function() {
                $(this).closest('table').find('input[type=checkbox]').not($(this)).prop("checked",this.checked);
            });

            $(document).find('tbody .check_event input').change(function() {
                var count_post = $(this).closest('tbody').find('.check_event input').length;
                var count_post_selected = $(this).closest('tbody').find('.check_event input:checked').length;

                if (count_post == count_post_selected) {
                    $(this).closest('table').find('thead input[type=checkbox]').attr('checked', 'checked');
                } else {
                    $(this).closest('table').find('thead input[type=checkbox]').removeAttr('checked');
                }
            });
        },

        /*** Pending Post ***/
        el_pending_post: function() {
            $(document).find('.vendor_listing .action .pending, .vendor_listing .action .restore').on('click', function(e) {
                e.preventDefault();

                if ( ! confirm('Are you sure?') ) return;

                var post_id = $(this).closest('.action').find("input[name^='post_id']").val();

                var el_pending_post_nonce = $(this).parent().find('#el_pending_post_nonce').val();

                $.post(ajax_object.ajax_url, {
                    action: 'el_pending_post',
                    data: {
                        post_id: post_id,
                        el_pending_post_nonce: el_pending_post_nonce,
                    },
                }, function(a) {
                    if (a) {
                        location.reload();
                    }
                });
            });
        },

        /*** Publish Post ***/
        el_publish_post: function() {
            $(document).find('.vendor_listing .action .publish').on('click', function(e) {
                e.preventDefault();

                if ( ! confirm('Move to Publish?') ) return;

                var post_id = $(this).closest('.action').find("input[name^='post_id']").val();

                var el_publish_post_nonce = $(this).parent().find('#el_publish_post_nonce').val();

                $.post(ajax_object.ajax_url, {
                    action: 'el_publish_post',
                    data: {
                        post_id: post_id,
                        el_publish_post_nonce: el_publish_post_nonce,
                    },
                }, function(a) {
                    if (a) {
                        location.reload();
                    }
                });
            });
        },

        /*** Trash Post ***/
        el_trash_post: function() {
            $(document).find('.vendor_listing .action .trash').on('click', function(e) {
                e.preventDefault();

                if ( ! confirm('Move to Trash?') ) return;

                var post_id = $(this).closest('.action').find("input[name^='post_id']").val();

                var el_trash_post_nonce = $(this).parent().find('#el_trash_post_nonce').val();

                $.post(ajax_object.ajax_url, {
                    action: 'el_trash_post',
                    data: {
                        post_id: post_id,
                        el_trash_post_nonce: el_trash_post_nonce,
                    },
                }, function(a) {
                    if (a) {
                        location.reload();
                    }
                });
            });
        },

        /*** Clone Post ***/
        el_duplicate_post: function() {
            $(document).find('.vendor_listing .action .duplicate').off().on('click', function(e) {
                e.preventDefault();

                var post_id = $(this).closest('.action').find("input[name^='post_id']").val();
                var el_duplicate_post_nonce = $(this).parent().find('#el_duplicate_post_nonce').val();

                $(".action .submit-load-more").css({ 'z-index':'3', 'display':'block' });

                $.post(ajax_object.ajax_url, {
                    action: 'el_duplicate_post',
                    data: {
                        post_id: post_id,
                        el_duplicate_post_nonce: el_duplicate_post_nonce,
                    },
                }, function(response) {
                    var data = JSON.parse(response);

                    if ( data.status === "success" ) {
                        window.location.href = data.href;
                        $(".action .submit-load-more").css('z-index', '-1');
                    } else {
                        if ( window.confirm(data.msg) ) {
                            window.location.href = data.url;
                        };

                        $(".action .submit-load-more").css('z-index', '-1');
                    }

                });
            });
        },

        /*** Trash Post ***/
        el_delete_post: function() {
            $(document).find('.vendor_listing .action .delete').on('click', function(e) {
                e.preventDefault();

                if ( ! confirm('Are you SURE you want to delete permanently this post?') ) return;

                var post_id = $(this).closest('.action').find("input[name^='post_id']").val();
                var el_delete_post_nonce = $(this).parent().find('#el_delete_post_nonce').val();

                $.post(ajax_object.ajax_url, {
                    action: 'el_delete_post',
                    data: {
                        post_id: post_id,
                        el_delete_post_nonce: el_delete_post_nonce,
                    },
                }, function(a) {
                    if (a) {
                        location.reload();
                    }
                });
            });
        },

        /*** Bulk Action Post ***/
        el_bulk_action: function() {
            $(document).find('.vendor_listing .bulk_action input').on('click', function(e) {
                e.preventDefault();
                var value_select = $(this).parent().find('select').val();
                var el_bulk_action_nonce = $(this).parent().find('#el_bulk_action_nonce').val();

                var post_id = [];

                $(this).closest('.vendor_listing').find(".check_event input[name^='post_id']").each(function() {
                    if ($(this).prop('checked') == true) {
                        post_id.push($(this).val());
                    }
                });

                if (value_select == 'publish') {
                    var confirmation = confirm('Move to Publish?');
                    bulk_action(confirmation, el_bulk_action_nonce, post_id, value_select)
                } else if (value_select == 'trash') {
                    var confirmation = confirm('Move to Trash?');
                    bulk_action(confirmation, el_bulk_action_nonce, post_id, value_select)
                } else if (value_select == 'pending' || value_select == 'restore') {
                    var confirmation = confirm('Move to Pending?');
                    bulk_action(confirmation, el_bulk_action_nonce, post_id, value_select)
                } else if (value_select == 'delete') {
                    var confirmation = confirm('Are you SURE you want to delete permanently this post?');
                    bulk_action(confirmation, el_bulk_action_nonce, post_id, value_select)
                }
            });

            function bulk_action(confirmation, el_bulk_action_nonce, post_id, value_select) {
                if ( confirmation ) {
                    $.post(ajax_object.ajax_url, {
                        action: 'el_bulk_action',
                        data: {
                            el_bulk_action_nonce: el_bulk_action_nonce,
                            post_id: post_id,
                            value_select: value_select,
                        },
                    }, function(a) {
                        if (a) {
                            location.reload();
                        }
                    });
                }
            }
        },

        /* Edit Latitude Longitude */
        edit_lat_lng: function() {
            $(document).find('.location #editor_latlng').change(function() {
                var checked = $(this).prop('checked');

                if (checked) {
                    $(this).parents('.edit_latlng').find('.wrap_lnglat input').removeClass('readonly');
                    $(this).parents('.edit_latlng').find('.wrap_lnglat input').removeAttr('readonly');
                } else {
                    $(this).parents('.edit_latlng').find('.wrap_lnglat input').attr('readonly', 'readonly').addClass('readonly');
                    $(this).parents('.edit_latlng').find('.wrap_lnglat input').attr('readonly', 'readonly');
                }
            });
        },

        cut_string_cat: function() {
            $('.vendor_listing .tags').each(function() {
                var cat = $(this).html().trim().slice(0, -1);
                $(this).html(cat);
            });
        },

        /* Image Feature */
        image_feature: function() {
            var image_feature;
            $(document).on('click', '.image_feature .add_image', function(e) {
                e.preventDefault();

                if (typeof image_feature != 'undefined') {
                    image_feature.close();
                }

                var that = $(this);

                image_feature = wp.media({
                    title: $(this).data('uploader-title'),
                    button: {
                        text: $(this).data('uploader-button-text'),
                    },
                    library: {
                        type: ['image']
                    },
                    multiple: false
                });

                image_feature.on('open',function() {
                    var selection = image_feature.state().get('selection');
                    var $id = that.closest('.image_feature').find('input').val();
                    if ( $id ) {
                        var attachment = wp.media.attachment($id);
                        attachment.fetch();
                        selection.add(attachment ? [attachment] : []);
                    }
                });

                image_feature.on('select', function() {
                    var selection = image_feature.state().get('selection');

                    selection.map(function(attachment, i) {
                        attachment = attachment.toJSON();

                        that.closest('.image_feature').find('.wrap').html('<div class="image_box"><img class="image-preview" src="' + attachment.url + '"><button class="button remove_image"><i class="fa fa-times-circle" aria-hidden="true"></button></div>');
                        that.closest('.image_feature').find('input').val(attachment.id);
                    });
                });

                image_feature.open();
            });

            /* Remove Image Feature */
            $(document).on('click', '.image_feature .remove_image', function(e) {
                e.preventDefault();
                $(this).closest('.image_box').remove();
            });
        },
        sort_image_gallery: function(){
            if ( $(".image_gallery").length > 0 ) {
                $(".image_gallery .gallery_list").sortable();
            }
        },
        /* Image Gallery */
        add_image_gallery: function() {
            var file_frame;
            $(document).on('click', '.image_gallery .add_image_gallery', function(e) {
                e.preventDefault();

                if (typeof file_frame != 'undefined') {
                    file_frame.close();
                }

                var that = $(this);
                var $prefix = $("input.prefix").val();
                file_frame = wp.media({
                    title: $(this).data('uploader-title'),
                    button: {
                        text: $(this).data('uploader-button-text'),
                    },
                    library: {
                        type: ['image']
                    },
                    multiple: true
                });

                file_frame.on('open',function() {
                var selection = file_frame.state().get('selection');
                var galleryItems = that.parent().find('input.gallery_id');

                    if( galleryItems.length > 0 ) {
                        var ids = [];
                        galleryItems.each( function(i,el){
                            if ( $(el).val() ) {
                                ids.push( $(el).val() );
                            }
                        } );

                        ids.forEach(function(id) {
                            var attachment = wp.media.attachment(id);
                            attachment.fetch();
                            selection.add(attachment ? [attachment] : []);
                        });
                    }
                });

                file_frame.on('select', function() {
                    var selection    = file_frame.state().get('selection');

                    that.parent().find('.gallery_list').html("");

                    selection.map(function(attachment, i) {
                        attachment = attachment.toJSON();
                        let $html = '';
                        $html += '<div class="gallery_item">';
                        $html += '<div class="image_box">';
                        $html += '<input type="hidden" class="gallery_id" value="'+attachment.id+'">';
                        $html += '<img class="image-preview" src="'+attachment.url+'" />';
                        $html += '<a class="remove_image" href="#"><i class="fa fa-times-circle" aria-hidden="true"></i></a>';
                        $html += '</div>';
                        $html += '</div>';
                        that.parent().find('.gallery_list').append($html);
                    });

                });

                file_frame.open();
            });
        },

        /* Remove Image Gallery */
        remove_image_gallery: function() {
            $(document).on('click', '.image_gallery .remove_image', function(e) {
                e.preventDefault();
                $(this).closest('.gallery_item').remove();
            });
        },

        /*** Radio Single Banner ***/
        radio_single_banner: function() {
            $('.single_banner').on('click', function() {
                var val = $(this).val()

                if ( val == 'gallery' || val == 'video' ) {
                    $(this).closest('.single_banner').find('.image_banner').css('display', 'none');
                } else {
                    $(this).closest('.single_banner').find('.image_banner').css('display', 'block');
                }
            });
        },

        /*** Image Banner ***/
        image_banner: function() {
            /* Add Image Banner */
            $('.add_image_banner').on('click', function(e) {
                e.preventDefault();

                var that = $(this);

                wp.media.editor.send.attachment = function(prop, attachment) {
                    $(that).parent().find('input').val(attachment.id);
                    $(that).parent().find('.content_image').html('<img class="image-preview-banner" src="' + attachment.sizes.full.url + '" alt="image banner" style="max-height: 200px;"><button class="button remove_image_banner">Remove</button> ');
                }
                wp.media.editor.open(that);
            });

            /* Remove Image Banner */
            $(document).on('click', '.remove_image_banner', function(e) {
                e.preventDefault();
                $(this).closest('.image_banner').find('input').val('');
                $(this).parent().empty();
            });
        },

        /* Save Event */
        el_save_edit_event: function() {
            $(document).find('.el_edit_event_submit').off().on('click', function(e) {
                e.preventDefault();

                $(".vendor_wrap p.error-total-event").css('display', 'none');
                $(".vendor_wrap p.error-time-limit").css('display', 'none');
                var that = $(this);

                // Ticket Name Required
                that.closest('form').find('input.name_ticket').each( function() {
                    var inputTicketName = $(this);

                    if ( ! inputTicketName.val() && inputTicketName.prop('required') ) {
                        inputTicketName.closest('form').find('a[href="#mb_ticket"]').click();

                        $('html, body').animate({
                            scrollTop: inputTicketName.closest('form').find('a[href="#mb_ticket"]').offset().top
                        }, 1000, function() {
                            inputTicketName.focus();
                        });

                        return false;
                    }
                });

                var prefix      = that.parents('.vendor_edit_event').find('#ova_prefix').val();
                var el_edit_event_nonce = that.parents('.vendor_edit_event').find('#el_edit_event_nonce').val();
                var post_id     = that.parents('.vendor_edit_event').find('#post_id').val();
                var name_event  = that.parents('.vendor_edit_event').find('#name_event').val();
                var event_cat   = that.parents('.vendor_edit_event').find('#event_cat').val();
                var event_tax   = that.parents('.vendor_edit_event').find('#event_tax').val();
                var event_type  = that.parents('.vendor_edit_event').find("input[name='ova_mb_event_event_type']:checked").val();
                var ticket_link = that.parents('.vendor_edit_event').find("input[name='ova_mb_event_ticket_link']:checked").val();
                var ticket_external_link        = that.parents('.vendor_edit_event').find('input[name="ova_mb_event_ticket_external_link"]').val();
                var ticket_external_link_price  = that.parents('.vendor_edit_event').find('input[name="ova_mb_event_ticket_external_link_price"]').val();
                var event_password  = that.parents('.vendor_edit_event').find('input[name="event_password"]').val();
                var event_status    = that.parents('.vendor_edit_event').find('input[name="event_status"]:checked').val();
                var seating_map     = that.parents('.vendor_edit_event').find('input[name="ova_mb_event_seating_map"]').val();


                if ( event_status === 'protected' && ! event_password ) {
                    var mess = that.parents('.vendor_edit_event').find('input[name="event_password"]').attr("data-mess");
                    if ( mess ) {
                        $('html, body').animate({
                            scrollTop: that.parents('.vendor_edit_event').find('input[name="event_password"]').offset().top - 200
                        }, 2000);
                        alert( mess );
                    }
                    return false;
                }


                if( typeof tinyMCE == 'undefined' ){

                    var content_event = that.parents('.vendor_edit_event').find('#content_event').val();

                }else{

                    tinyMCE.init({
                        mode: "specific_textareas",
                        editor_selector: "wp-editor-area"
                    });

                    var content_event = tinyMCE.get('content_event').getContent();    
                }
                
                var data_taxonomy = {};
                var list_taxonomy = that.parents('.vendor_edit_event').find('#el_list_slug_taxonomy').val();
                var taxonomy_slug_required = that.closest('.vendor_edit_event').find('#el_custom_taxonomy_required').val();
                var el_list_taxonomy = that.closest('.vendor_edit_event').find('#el_list_taxonomy').val();
                var taxonomy_required_mess = that.closest('.vendor_edit_event').find('#el_list_taxonomy').data('mess');
                var data_tax_req_mess = [];
                var $alert_html = '';

                $(document).find('.event_basic_block_alert').html('');

                if ( name_event == '' || event_cat == "" ) {
                   let message = that.parents('.vendor_edit_event').find('.content').data('message');
                   $alert_html += `<div class="alert alert-danger" role="alert">
                                    ${message}
                                </div>`;
                }

                if ( list_taxonomy ) {
                    list_taxonomy = JSON.parse(list_taxonomy);
                    for( var key in list_taxonomy ) {
                        data_taxonomy[list_taxonomy[key]] = that.parents('.vendor_edit_event').find('#' + list_taxonomy[key]).val();
                    }
                }

                if ( data_taxonomy && taxonomy_slug_required ) {
                    el_list_taxonomy = JSON.parse( el_list_taxonomy );
                    taxonomy_slug_required = JSON.parse( taxonomy_slug_required );

                    for (const taxonomy in el_list_taxonomy) {
                        let $mess = taxonomy_required_mess.replace( '[taxonomy_name]', el_list_taxonomy[taxonomy].name );
                        data_tax_req_mess[el_list_taxonomy[taxonomy].slug] = $mess;
                    }

                    for (const tax_slug of taxonomy_slug_required) {
                        if ( data_tax_req_mess[tax_slug] ) {
                            if ( ! data_taxonomy[tax_slug].length || data_taxonomy[tax_slug][0] == '' ) {
                                $alert_html += `<div class="alert alert-danger" role="alert">
                                    ${data_tax_req_mess[tax_slug]}
                                </div>`;
                            }
                        }
                    }
                }

                if ( $alert_html != '' ) {

                    $('.vendor_tab li').removeClass('is-active');
                    $('.tab-contents').removeClass('is-active');

                    $('.vendor_tab li[data-id="mb_basic"]').addClass('is-active');
                    $('#mb_basic').addClass('is-active');

                    $(document).find('.event_basic_block_alert').html($alert_html);
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(document).find('.event_basic_block_alert').offset().top - 200
                    }, 2000);
                    return false;
                }
                

                var time_zone   = that.parents('.vendor_edit_event').find('#time_zone').val();
                
                var event_tag   = that.parents('.vendor_edit_event').find('#event_tag').val();
                event_tag       = event_tag.split(",").slice(0, 6);
                var status_pay  = that.parents('.vendor_edit_event').find('#event_status_pay').val();
                var event_state = that.parents('.vendor_edit_event').find('#event_state').val();
                var event_city  = that.parents('.vendor_edit_event').find('#event_city').val();

                var venue = [];
                that.parents('.vendor_edit_event').find('#data_venue li').each(function() {
                    var arr_venue = $(this).find('span').html();
                    venue.push(arr_venue);
                });

                var address = that.parents('.vendor_edit_event').find('#address').val();
                var map_lat = that.parents('.vendor_edit_event').find('#map_lat').val();
                var map_lng = that.parents('.vendor_edit_event').find('#map_lng').val();
                var map_address = that.parents('.vendor_edit_event').find('#pac-input').val();
                var edit_full_address = that.parents('.vendor_edit_event').find('#edit_full_address').val();

                var info_organizer  = that.parents('.vendor_edit_event').find('#info_organizer').val();
                var name_organizer  = that.parents('.vendor_edit_event').find('#name_organizer').val();
                var phone_organizer = that.parents('.vendor_edit_event').find('#phone_organizer').val();
                var mail_organizer  = that.parents('.vendor_edit_event').find('#mail_organizer').val();
                var job_organizer   = that.parents('.vendor_edit_event').find('#job_organizer').val();

                var social_organizer = [];
                that.parents('.vendor_edit_event').find('.social_item').each(function() {
                    var array_social = [];

                    var array_social = {
                        'link_social': $(this).find('.link_social').val(),
                        'icon_social': $(this).find('.icon_social').val()
                    };
                    social_organizer.push(array_social);
                });

                var img_thumbnail = that.parents('.vendor_edit_event').find('#img_thumbnail').val();

                var gallery = [];
                that.parents('.vendor_edit_event').find('.gallery_item').each(function() {
                    var gallery_id = $(this).find('.gallery_id').val();
                    gallery.push(gallery_id);
                });

                // Gallery & Video
                var link_video      = that.parents('.vendor_edit_event').find('#link_video').val();
                var single_banner   = that.parents('.vendor_edit_event').find('input.single_banner:checked').val();
                var image_banner    = that.parents('.vendor_edit_event').find('#image_banner').val();

                // Ticket
                var seat_option = that.parents('.vendor_edit_event').find('.seat_option:checked').val();
                var textare_seat_option = that.parents('.vendor_edit_event').find('#textare_seat_option').val();

                // Person type
                var person_type = that.closest('form').find('#data_person_type').val();
                // Person type seat
                var person_type_seat = that.closest('form').find("#person_type_seat_data").val();

                // Ticket map
                var short_code_map          = that.parents('.vendor_edit_event').find('#short_code_map').val();
                var number_min_ticket_map   = that.parents('.vendor_edit_event').find('.number_min_ticket_map').val();
                var number_max_ticket_map   = that.parents('.vendor_edit_event').find('.number_max_ticket_map').val();
                var start_ticket_date_map   = that.parents('.vendor_edit_event').find('.start_ticket_date_map').val();
                var start_ticket_time_map   = that.parents('.vendor_edit_event').find('.start_ticket_time_map').val();
                var close_ticket_date_map   = that.parents('.vendor_edit_event').find('.close_ticket_date_map').val();
                var close_ticket_time_map   = that.parents('.vendor_edit_event').find('.close_ticket_time_map').val();
                var color_ticket_map        = that.parents('.vendor_edit_event').find('#color_ticket_map').val();
                var color_label_ticket_map  = that.parents('.vendor_edit_event').find('.color_label_ticket_map').val();
                var color_content_ticket_map = that.parents('.vendor_edit_event').find('.color_content_ticket_map').val();
                var desc_ticket_map         = that.parents('.vendor_edit_event').find('.desc_ticket_map').val();
                var private_desc_ticket_map = that.parents('.vendor_edit_event').find('.private_desc_ticket_map').val();
                var map_image_ticket        = that.parents('.vendor_edit_event').find('input.image_ticket_map').val();
                var type_map                = that.parents('.vendor_edit_event').find('.type_map input[type="radio"]:checked').val();
                
                var ticket_map = {
                    'short_code_map': short_code_map,
                    'number_min_ticket': number_min_ticket_map,
                    'number_max_ticket': number_max_ticket_map,
                    'start_ticket_date': start_ticket_date_map,
                    'start_ticket_time': start_ticket_time_map,
                    'close_ticket_date': close_ticket_date_map,
                    'close_ticket_time': close_ticket_time_map,
                    'color_ticket': color_ticket_map,
                    'color_label_ticket': color_label_ticket_map,
                    'color_content_ticket': color_content_ticket_map,
                    'desc_ticket': desc_ticket_map,
                    'private_desc_ticket_map': private_desc_ticket_map,
                    'image_ticket': map_image_ticket,
                    'desc_seat': [],
                    'seat': [],
                    'area': [],
                    'person_type': person_type,
                    'person_type_seat': person_type_seat,
                };

                that.parents('.vendor_edit_event').find('.item_desc_seat').each(function() {
                    let arr_ticket_map = {
                        'map_type_seat': $(this).find('.map_type_seat').val(),
                        'map_price_type_seat': $(this).find('.map_price_type_seat').val(),
                        'map_desc_type_seat': $(this).find('.map_desc_type_seat').val(),
                        'map_color_type_seat': $(this).find('.map_color_type_seat').val(),
                    };
                    ticket_map.desc_seat.push(arr_ticket_map);
                });

                that.parents('.vendor_edit_event').find('.item_seat').each(function() {
                    var $person_price = [];
                    $(this).find('.person_type_field').each( function(i,el){
                        let $price = $(el).find('input').val();
                        $person_price.push( $price );
                    } );
                    $person_price = JSON.stringify( $person_price );
                    let arr_ticket_map = {
                        'id': $(this).find('.map_name_seat').val(),
                        'price': $(this).find('.map_price_seat').val(),
                        'type_seat': $(this).find('.select_type_seat').val(),
                        'start_date': $(this).find('.seat_start_date').val(),
                        'start_time': $(this).find('.seat_start_time').val(),
                        'end_date': $(this).find('.seat_end_date').val(),
                        'end_time': $(this).find('.seat_end_time').val(),
                        'person_price': $person_price,
                    };
                    ticket_map.seat.push(arr_ticket_map);
                });

                that.parents('.vendor_edit_event').find('.item_area').each(function() {

                    var $person_price = [];
                    $(this).find('.person_type_field').each( function(i,el){
                        let $price = $(el).find('input').val();
                        $person_price.push( $price );
                    } );
                    $person_price = JSON.stringify( $person_price );

                    let arr_area_map = {
                        'id': $(this).find('.map_name_area').val(),
                        'price': $(this).find('.map_price_area').val(),
                        'qty': $(this).find('.map_qty_area').val(),
                        'type_seat': $(this).find('.select_type_area').val(),
                        'start_date': $(this).find('.area_start_date').val(),
                        'start_time': $(this).find('.area_start_time').val(),
                        'end_date': $(this).find('.area_end_date').val(),
                        'end_time': $(this).find('.area_end_time').val(),
                        'person_price': $person_price,
                    };
                    ticket_map.area.push(arr_area_map);
                });

                var ticket = [];
                that.parents('.vendor_edit_event').find('.ticket_item').each(function() {

                    const seat_code_setup = [];
                    $(this).find('.seat_code_setup .setup_item').each( function(i,el){
                        const code = $(el).find('input.input_code').val();
                        const from = $(el).find('input.input_from').val();
                        const to = $(el).find('input.input_to').val();
                        seat_code_setup.push({
                            'code': code,
                            'from': from,
                            'to': to
                        });
                    } );

                    var arr_event = {
                        'ticket_id': $(this).find('input.ticket_id').val(),
                        'name_ticket': $(this).find('input.name_ticket').val(),
                        'type_price': $(this).find('input.type_price:checked').val(),
                        'price_ticket': $(this).find('input.price_ticket').val(),
                        'price_ticket_external': $(this).find('#price_ticket_external').val(),
                        'number_total_ticket': $(this).find('input.number_total_ticket').val(),
                        'number_min_ticket': $(this).find('input.number_min_ticket').val(),
                        'number_max_ticket': $(this).find('input.number_max_ticket').val(),
                        'start_ticket_date': $(this).find('.start_ticket_date').val(),
                        'start_ticket_time': $(this).find('input.start_ticket_time').val(),
                        'close_ticket_date': $(this).find('.close_ticket_date').val(),
                        'close_ticket_time': $(this).find('.close_ticket_time').val(),
                        'color_ticket': $(this).find('.color_ticket').val(),
                        'color_label_ticket': $(this).find('.color_label_ticket').val(),
                        'color_content_ticket': $(this).find('.color_content_ticket').val(),
                        'desc_ticket': $(this).find('.desc_ticket').val(),
                        'private_desc_ticket': $(this).find('textarea.private_desc_ticket').val(),
                        'online_link': $(this).find('input.online_link').val(),
                        'online_password': $(this).find('input.online_password').val(),
                        'online_other': $(this).find('input.online_other').val(),
                        'setup_mode': $(this).find('input.setup_mode_input:checked').val(),
                        'image_ticket': $(this).find('input.image_ticket').val(),
                        'seat_map_ticket': $(this).find('input.seat_map_ticket').val(),
                        'seat_list': $(this).find('textarea.seat_list').val(),
                        'seat_code_setup': seat_code_setup,
                        'setup_seat': $(this).find('input.setup_seat:checked').val(),
                    };
                    ticket.push(arr_event);
                });

                var calendar = [];
                that.parents('.vendor_edit_event').find('.item_calendar').each(function() {
                    var arr_calendar = {
                        'calendar_id': $(this).find('.calendar_id').val(),
                        'date': $(this).find('.calendar_date').val(),
                        'end_date': $(this).find('.calendar_end_date').val(),
                        'start_time': $(this).find('.calendar_start_time').val(),
                        'end_time': $(this).find('.calendar_end_time').val(),
                        'book_before_minutes': $(this).find('.number_time_book_before').val(),
                    };
                    calendar.push(arr_calendar);
                });

                var schedules_time = [];
                that.parents('.vendor_edit_event').find('.item_schedules_time').each(function() {
                    var arr_schedules_time = {
                        'start_time': $(this).find('.start_time').val(),
                        'end_time': $(this).find('.end_time').val(),
                        'book_before': $(this).find('.schedules_time_book').val(),
                    };
                    schedules_time.push(arr_schedules_time);
                });

                var disable_date = [];
                that.parents('.vendor_edit_event').find('.item_disable_date').each(function() {
                    var arr_disable_date = {
                        'start_date': $(this).find('.start_date').val(),
                        'end_date': $(this).find('.end_date').val(),
                        'schedules_time': $(this).find('.schedules_time').val(),
                    };
                    disable_date.push(arr_disable_date);
                });

                var disable_time_slot = [];
                that.parents('.vendor_edit_event').find('.item_disable_time_slot').each(function() {
                    var arr_disable_time_slot = {
                        'start_date': $(this).find('.start_date').val(),
                        'end_date': $(this).find('.end_date').val(),
                        'start_time': $(this).find('.start_time').val(),
                        'end_time': $(this).find('.end_time').val(),
                    };

                    disable_time_slot.push(arr_disable_time_slot);
                });

                var extra_service = [];

                that.parents('.vendor_edit_event').find('.el_service_item').each( function(i,el){
                    var arr_extra_item = {
                        'id': $(el).find('.extra_service_id').val(),
                        'name': $(el).find('.extra_service_name').val(),
                        'price': $(el).find('.extra_service_price').val(),
                        'qty': $(el).find('.extra_service_qty').val(),
                        'max_qty': $(el).find('.extra_service_max_qty').val(),
                    };
                    extra_service.push( arr_extra_item );
                } );

                var api_key = that.parents('.vendor_edit_event').find('.api_key').val();
                var allow_cancellation_booking  = that.parents('.vendor_edit_event').find('input[name="ova_mb_event_allow_cancellation_booking"]:checked').val();
                var cancel_before_x_day         = that.parents('.vendor_edit_event').find('input[name="ova_mb_event_cancel_before_x_day"]').val();
                var option_calendar             = that.parents('.vendor_edit_event').find('.option_calendar:checked').val();
                var calendar_recurrence_id      = that.parents('.vendor_edit_event').find('.calendar_recurrence_id').val();
                var calendar_recurrence_start_time  = that.parents('.vendor_edit_event').find('.calendar_recurrence_start_time').val();
                var calendar_recurrence_end_time    = that.parents('.vendor_edit_event').find('.calendar_recurrence_end_time').val();
                var calendar_recurrence_book_before = that.parents('.vendor_edit_event').find('.calendar_recurrence_time_book_before').val();
                var recurrence_frequency        = that.parents('.vendor_edit_event').find('#recurrence-frequency option:selected').val();
                var recurrence_interval         = that.parents('.vendor_edit_event').find('#recurrence-interval').val();
                var recurrence_byweekno         = that.parents('.vendor_edit_event').find('#monthly-modifier option:selected').val();
                var recurrence_byday            = that.parents('.vendor_edit_event').find('#recurrence-weekday option:selected').val();
                var recurrence_bydays           = [];
                that.parents('.vendor_edit_event').find("#weekly-selector input:checked").each(function() {
                    recurrence_bydays.push($(this).val());
                });
                var calendar_start_date = that.parents('.vendor_edit_event').find('.calendar_auto_start_date').val();
                var calendar_end_date   = that.parents('.vendor_edit_event').find('.calendar_auto_end_date').val();

                // Time Slot
                var time_slot_start = [];
                var time_slot_end   = [];
                that.parents('.vendor_edit_event').find("#weekly-selector .ts_recurrence_bydays").each(function() {
                    var ts_key   = $(this).find('.add_time_slot').data('key');
                    var ts_start = {};
                    ts_start[ts_key] = [];

                    var ts_end       = {}
                    ts_end[ts_key]   = [];

                    $(this).find('input.calendar_recurrence_ts_start').each( function() {
                        ts_start[ts_key].push( $(this).val() );
                    });

                    $(this).find('input.calendar_recurrence_ts_end').each( function() {
                        ts_end[ts_key].push( $(this).val() );
                    });

                    if ( ts_start[ts_key].length ) {
                        time_slot_start.push( ts_start );
                    }

                    if ( ts_end[ts_key].length ) {
                        time_slot_end.push( ts_end );
                    }
                });

                /* Coupon */
                var coupon = [];
                that.parents('.vendor_edit_event').find('.item_coupon').each(function() {
                    var list_ticket = [];
                    $(this).find('.list_ticket:checked').each(function() {
                        list_ticket.push($(this).val());
                    });

                    var arr_coupon = {
                        'coupon_id': $(this).find('.coupon_id').val(),
                        'discount_code': $(this).find('.discount_code').val(),
                        'discount_amout_number': $(this).find('.discount_amout_number').val(),
                        'discount_amount_percent': $(this).find('.discount_amount_percent').val(),
                        'start_date': $(this).find('.coupon_start_date').val(),
                        'start_time': $(this).find('.coupon_start_time').val(),
                        'end_date': $(this).find('.coupon_end_date').val(),
                        'end_time': $(this).find('.coupon_end_time').val(),
                        'all_ticket': $(this).find('.coupon_all_ticket').val(),
                        'quantity': $(this).find('.coupon_quantity').val(),
                        'list_ticket': list_ticket,
                    };
                    coupon.push(arr_coupon);
                });

                // recapcha code here
                var recapcha_v3_create_event = async function(){
                    var $token      = $(document).find('.vendor_edit_event input[name="g-recaptcha-response"]').val();
                    var $secret_key = $(document).find('.vendor_edit_event input[name="g-recaptcha-response"]').attr('data-key');  
                    if ( $secret_key && $token ) {
                        await $.post(ajax_object.ajax_url, {
                            action: 'el_verify_google_recapcha',
                            data : {
                                'secret': $secret_key,
                                'response': $token
                            }
                        },await function( response ) {
                            if ( response == 0 ) {
                                $(document).find('.vendor_edit_event input[name="g-recaptcha-response"]').attr("data-pass","no");
                            } else {
                                $(document).find('.vendor_edit_event input[name="g-recaptcha-response"]').attr("data-pass","yes");
                            }
                        },
                        );
                    } else {
                        $(document).find('.vendor_edit_event input[name="g-recaptcha-response"]').attr("data-pass","no");
                    }

                    let $check_recapcha = $(document).find('.vendor_edit_event input[name="g-recaptcha-response"]').attr("data-pass");

                    if ( $check_recapcha !== "yes" ) {
                        // display err
                        var mess = '';
                        mess = $(document).find('.vendor_edit_event input[name="g-recaptcha-response"]').data("mess");
                        if ( mess ) {
                            alert( mess );
                        }
                        return false;
                    } else {
                        el_update_event();
                    }
                }

                var recapcha_v2_create_event = async function(){

                    var $token      = $(document).find(".vendor_edit_event #ovaevent_recapcha_token").val();
                    var $secret_key = $(document).find('.vendor_edit_event #ovaevent_recapcha_token').attr('data-key'); 

                    if ( $secret_key && $token ) {
                        await $.post(ajax_object.ajax_url, {
                            action: 'el_verify_google_recapcha',
                            data : {
                                'secret': $secret_key,
                                'response': $token
                            }
                        },await function( response ) {
                            if ( response == 0 ) {
                                $(document).find('.vendor_edit_event #ovaevent_recapcha_token').attr("data-pass","no");
                            } else {
                                $(document).find('.vendor_edit_event #ovaevent_recapcha_token').attr("data-pass","yes");
                            }
                        },
                        );
                    } else {
                        $(document).find('.vendor_edit_event #ovaevent_recapcha_token').attr("data-pass","no");
                    }

                    let $check_recapcha = $(document).find('.vendor_edit_event #ovaevent_recapcha_token').attr("data-pass");

                    if ( $check_recapcha === "yes" ) {
                        await el_update_event();
                    } else {
                        var mess = '';
                        mess = $(document).find('.vendor_edit_event #ovaevent_recapcha_token').data("mess");
                        if ( mess ) {
                            alert( mess );
                        }
                        return false;
                    }
                }

                var el_update_event = async function(){
                    if ( that.parents('.vendor_edit_event > form').valid() ) {
                        $(".vendor_wrap .wrap_btn_submit .submit-load-more").css('z-index', '3');

                        await $.post(ajax_object.ajax_url, {
                            action: 'el_save_edit_event',
                            meta_data: {
                                'event_tax': event_tax,
                                'event_type': event_type,
                                'ticket_link': ticket_link,
                                'ticket_external_link': ticket_external_link,
                                'ticket_external_link_price': ticket_external_link_price,
                                'time_zone': time_zone,
                                'status_pay': status_pay,
                                'address': address,
                                'map_lat': map_lat,
                                'map_lng': map_lng,
                                'map_address': map_address,
                                'edit_full_address': edit_full_address,
                                'info_organizer': info_organizer,
                                'name_organizer': name_organizer,
                                'phone_organizer': phone_organizer,
                                'mail_organizer': mail_organizer,
                                'job_organizer': job_organizer,
                                'social_organizer': social_organizer,
                                'gallery': gallery,
                                'link_video': link_video,
                                'single_banner': single_banner,
                                'image_banner': image_banner,
                                'seat_option': seat_option,
                                'seating_map': seating_map,
                                'textare_seat_option': textare_seat_option,
                                'ticket': ticket,
                                'type_map': type_map,
                                'ticket_map': ticket_map,
                                'extra_service': extra_service,
                                'option_calendar': option_calendar,
                                'calendar_recurrence_start_time': calendar_recurrence_start_time,
                                'calendar_recurrence_end_time': calendar_recurrence_end_time,
                                'calendar_recurrence_book_before': calendar_recurrence_book_before,
                                'recurrence_frequency': recurrence_frequency,
                                'recurrence_interval': recurrence_interval,
                                'recurrence_byweekno': recurrence_byweekno,
                                'recurrence_byday': recurrence_byday,
                                'recurrence_bydays': recurrence_bydays,
                                'calendar_start_date': calendar_start_date,
                                'calendar_end_date': calendar_end_date,
                                'calendar': calendar,
                                'schedules_time': schedules_time,
                                'disable_date': disable_date,
                                'disable_date_time_slot': disable_time_slot,
                                'ts_start': time_slot_start,
                                'ts_end': time_slot_end,
                                'coupon': coupon,
                                'api_key': api_key,
                                'allow_cancellation_booking': allow_cancellation_booking,
                                'cancel_before_x_day': cancel_before_x_day,
                                'venue': venue,
                            },
                            data: {
                                'el_edit_event_nonce': el_edit_event_nonce,
                                'post_id': post_id,
                                'name_event': name_event,
                                'event_password': event_password,
                                'content_event': content_event,
                                'event_cat': event_cat,
                                'data_taxonomy': data_taxonomy,
                                'event_tag': event_tag,
                                'event_state': event_state,
                                'event_status': event_status,
                                'event_city': event_city,
                                'img_thumbnail': img_thumbnail,
                            }
                        }, await function(response) {

                            if (response == 'updated') {
                                location.reload();
                            } else if (response == 'false_total_event') {
                                $(".vendor_wrap p.error-total-event").css('display', 'block');
                                $(".vendor_wrap .wrap_btn_submit .submit-load-more").css('z-index', '-1');
                                return false;
                            } else if (response == 'false_time_membership') {
                                $(".vendor_wrap p.error-time-limit").css('display', 'block');
                                $(".vendor_wrap .wrap_btn_submit .submit-load-more").css('z-index', '-1');
                                return false;
                            } else {
                                var hash = '';

                                if ( window.location.hash ) {
                                    hash = window.location.hash;
                                }

                                window.location.replace(response+hash);
                            }
                            $(".vendor_wrap .wrap_btn_submit .submit-load-more").css('z-index', '-1');
                       });
                    }
                }

                // recapcha
                if ( $(this).closest('form').find('input[name="g-recaptcha-response"]').length ) {
                    recapcha_v3_create_event();
                } else if ( $(this).closest('form').find('#ovaevent-recaptcha-wrapper').length ) {
                    recapcha_v2_create_event();
                } else {
                    el_update_event();
                }

            });
        },

        export_csv: function() {
            const that = this;
            $("#export-csv-extra").off().on("click", function() {
                $(this).siblings(".list-check-export-csv").slideToggle();
            });

            // Datepicker
            $('#el_export_from_date').datepicker({
                changeMonth: true,
                changeYear: true,
                dateFormat: el_datepicker_obj?.date_format,
            });
            $('#el_export_to_date').datepicker({
                changeMonth: true,
                changeYear: true,
                dateFormat: el_datepicker_obj?.date_format,
            });

            $("#button-submit-export-csv").off().on("click", function() {
                var id_event    = $(this).attr("data-id-event");
                var slug_event  = $(this).attr("data-slug-event");

                var from_date = $('#el_export_from_date').val();
                var to_date = $('#el_export_to_date').val();

                $('#button-submit-export-csv').block({
                    message: null,
                    overlayCSS:  { 
                        backgroundColor: '#fff', 
                        opacity: 0.3, 
                        cursor: 'wait' 
                    },
                });

                $.post(ajax_object.ajax_url, {
                    'action': 'el_export_csv',
                    'nonce': ajax_object.nonce,
                    'from_date': from_date,
                    'to_date': to_date,
                    'id_event': id_event,
                }, function(response) {
                    $('#button-submit-export-csv').unblock();

                    $('body').append( response );
                    that.el_close_popup();
                    that.el_booking_download_all_in_one();
                    that.el_export_multiple_files();
                    that.el_export_booking_split_multi_file();
                    return false;
                });
            });

            $('.list-check-export-csv button.clean-field').on( 'click', function(e) {
                e.preventDefault();

                var list = $(this).closest('.list-check-export-csv');
                list.find('input[type="checkbox"]').prop('checked', false);
            });

            $('.list-check-export-csv button.checked-field').on( 'click', function(e) {
                e.preventDefault();

                var list = $(this).closest('.list-check-export-csv');
                list.find('input[type="checkbox"]').prop('checked', true);
            });
        },
        el_booking_download_all_in_one: function(){
            $("#el_export_booking_popup .el_export_all_in_one").off().on('click', function(e){
                e.preventDefault();
                const booking_ids = $('#el_export_booking_popup input[name="booking_ids"]').val();
                var slug_event  = $('#button-submit-export-csv').attr("data-slug-event");
                var li = $('.list-check-export-csv').find("ul").find("li");
                var id_event = $('#button-submit-export-csv').attr('data-id-event');
                var check_id_booking = false;
                var check_event = false;
                var check_calendar = false;
                var check_name = false;
                var check_phone = false;
                var check_email = false;
                var check_total_before_tax = false;
                var check_total_after_tax = false;
                var check_profit = false;

                var check_system_fee = false;
                var check_ticket_fee = false;
                var check_tax = false;
                var check_coupon = false;
                var check_status = false;
                var check_ticket_type = false;
                var check_extra_service = false;
                var check_date_create = false;
                
                var list_ckf_check = {};

                var list_name_ckf = $('#el_list_ckf').val();

                if( list_name_ckf ) {
                    list_name_ckf = JSON.parse(list_name_ckf);
                    for( var key in list_name_ckf ){
                        if (li.find("input[name='"+list_name_ckf[key]+"']").is(':checked')) {
                            list_ckf_check[key] = list_name_ckf[key];
                        }
                    }
                }

                if (li.find("input[name='id_booking']").is(':checked')) {
                    check_id_booking = li.find("input[name='id_booking']").val();
                }

                if (li.find("input[name='event']").is(':checked')) {
                    check_event = li.find("input[name='id_booking']").val();
                }

                if (li.find("input[name='calendar']").is(':checked')) {
                    check_calendar = li.find("input[name='calendar']").val();
                }

                if (li.find("input[name='name']").is(':checked')) {
                    check_name = li.find("input[name='name']").val();
                }

                if (li.find("input[name='phone']").is(':checked')) {
                    check_phone = li.find("input[name='phone']").val();
                }

                if (li.find("input[name='email']").is(':checked')) {
                    check_email = li.find("input[name='email']").val();
                }

                if (li.find("input[name='total']").is(':checked')) {
                    check_total_after_tax = li.find("input[name='total']").val();
                }

                if ( li.find("input[name='profit']").is(':checked')) {
                    check_profit = li.find("input[name='profit']").val();
                }
                if ( li.find("input[name='system_fee']").is(':checked')) {
                    check_system_fee = li.find("input[name='system_fee']").val();
                }
                if ( li.find("input[name='ticket_fee']").is(':checked')) {
                    check_ticket_fee = li.find("input[name='ticket_fee']").val();
                }
                if ( li.find("input[name='tax']").is(':checked')) {
                    check_tax = li.find("input[name='tax']").val();
                }

                if (li.find("input[name='coupon']").is(':checked')) {
                    check_coupon = li.find("input[name='coupon']").val();
                }

                if (li.find("input[name='total_before_tax']").is(':checked')) {
                    check_total_before_tax = li.find("input[name='total_before_tax']").val();
                }

                if (li.find("input[name='status']").is(':checked')) {
                    check_status = li.find("input[name='status']").val();
                }

                if (li.find("input[name='ticket_type']").is(':checked')) {
                    check_ticket_type = li.find("input[name='ticket_type']").val();
                }

                if (li.find("input[name='extra_service']").is(':checked')) {
                    check_extra_service = li.find("input[name='extra_service']").val();
                }

                if (li.find("input[name='date_create']").is(':checked')) {
                    check_date_create = li.find("input[name='date_create']").val();
                }

                const data = {
                    'action': 'el_booking_download_all_in_one',
                    'nonce': ajax_object.nonce,
                    'booking_ids': booking_ids,
                    'id_event': id_event,
                    'check_id_booking': check_id_booking,
                    'check_event': check_event,
                    'check_calendar': check_calendar,
                    'check_name': check_name,
                    'check_phone': check_phone,
                    'check_email': check_email,
                    'check_total_after_tax': check_total_after_tax,
                    'check_total_before_tax': check_total_before_tax,
                    'check_profit': check_profit,
                    'check_system_fee': check_system_fee,
                    'check_ticket_fee': check_ticket_fee,
                    'check_tax': check_tax,
                    'check_coupon': check_coupon,
                    'check_status': check_status,
                    'check_ticket_type': check_ticket_type,
                    'check_extra_service': check_extra_service,
                    'check_date_create': check_date_create,
                    'list_ckf_check': list_ckf_check,
                };

                $('.el_export_all_in_one').block({
                    message: null,
                    overlayCSS:  { 
                        backgroundColor: '#fff', 
                        opacity: 0.3, 
                        cursor: 'wait' 
                    },
                });

                $.ajax({
                    url: ajax_object.ajax_url,
                    complete: function( jqXHR, textStatus){
                        $('.el_export_all_in_one').unblock();
                    },
                    data: data,
                    method: 'POST',
                    type: 'POST',
                    error: function( jqXHR, textStatus, errorThrown){
                        console.error( errorThrown );
                    },
                    success: function(data){
                        var data = JSON.parse(data);

                        let csvContent = "data:text/csv;charset=utf-8," + "\uFEFF";

                        data.forEach(function(rowArray) {
                            let row = rowArray.join(",");
                            csvContent += row + "\r\n";
                        });

                        var encodedUri = encodeURI(csvContent);
                        var link = document.createElement("a");
                        link.setAttribute("href", encodedUri);
                        link.setAttribute("download", "list_bookings_" + slug_event + "_" + id_event + ".csv");
                        document.body.appendChild(link); // Required for FF
                        link.click();
                    }
                });
            });
        },
        el_export_booking_split_multi_file: function(){
            const that = this;
            $('#el_export_booking_popup .el_export_file').off().on('click', function(e){
                e.preventDefault();
                const number_file = $('.el_enter_number_file input[name="number_file"]').val();
                const booking_ids = $('#el_export_booking_popup input[name="booking_ids"]').val();

                const data = {
                    'action': 'el_export_booking_split_multi_file',
                    'booking_ids': booking_ids,
                    'number_file': number_file,
                    'nonce': ajax_object.nonce,
                };

                $('.el_export_file').block({
                    message: null,
                    overlayCSS:  { 
                        backgroundColor: '#fff', 
                        opacity: 0.3, 
                        cursor: 'wait' 
                    },
                });

                $.ajax({
                    url: ajax_object.ajax_url,
                    complete: function( jqXHR, textStatus){
                        $('.el_export_file').unblock();
                    },
                    data: data,
                    method: 'POST',
                    type: 'POST',
                    error: function( jqXHR, textStatus, errorThrown){
                        console.error( errorThrown );
                    },
                    success: function(data){
                        $('.el_export_pagination').html('');
                        $('.el_export_pagination').html(data);
                        that.el_export_booking_page_item();
                    }
                });
            });
        },
        el_export_booking_page_item: function(){
            $('#el_export_booking_popup .el_page_item').off().on('click', function(e){
                e.preventDefault();
                var $this = $(this);
                const page = $this.attr('data-page');
                const booking_ids = $this.closest('.el_item').find('.item_booking_ids').val();

                var slug_event  = $('#button-submit-export-csv').attr("data-slug-event");
                var li = $('.list-check-export-csv').find("ul").find("li");
                var id_event = $('#button-submit-export-csv').attr('data-id-event');
                var check_id_booking = false;
                var check_event = false;
                var check_calendar = false;
                var check_name = false;
                var check_phone = false;
                var check_email = false;
                var check_total_before_tax = false;
                var check_total_after_tax = false;
                var check_profit = false;

                var check_system_fee = false;
                var check_ticket_fee = false;
                var check_tax = false;
                var check_coupon = false;
                var check_status = false;
                var check_ticket_type = false;
                var check_extra_service = false;
                var check_date_create = false;
                
                var list_ckf_check = {};

                var list_name_ckf = $('#el_list_ckf').val();

                if( list_name_ckf ) {
                    list_name_ckf = JSON.parse(list_name_ckf);
                    for( var key in list_name_ckf ){
                        if (li.find("input[name='"+list_name_ckf[key]+"']").is(':checked')) {
                            list_ckf_check[key] = list_name_ckf[key];
                        }
                    }
                }

                if (li.find("input[name='id_booking']").is(':checked')) {
                    check_id_booking = li.find("input[name='id_booking']").val();
                }

                if (li.find("input[name='event']").is(':checked')) {
                    check_event = li.find("input[name='id_booking']").val();
                }

                if (li.find("input[name='calendar']").is(':checked')) {
                    check_calendar = li.find("input[name='calendar']").val();
                }

                if (li.find("input[name='name']").is(':checked')) {
                    check_name = li.find("input[name='name']").val();
                }

                if (li.find("input[name='phone']").is(':checked')) {
                    check_phone = li.find("input[name='phone']").val();
                }

                if (li.find("input[name='email']").is(':checked')) {
                    check_email = li.find("input[name='email']").val();
                }

                if (li.find("input[name='total']").is(':checked')) {
                    check_total_after_tax = li.find("input[name='total']").val();
                }

                if ( li.find("input[name='profit']").is(':checked')) {
                    check_profit = li.find("input[name='profit']").val();
                }
                if ( li.find("input[name='system_fee']").is(':checked')) {
                    check_system_fee = li.find("input[name='system_fee']").val();
                }
                if ( li.find("input[name='ticket_fee']").is(':checked')) {
                    check_ticket_fee = li.find("input[name='ticket_fee']").val();
                }
                if ( li.find("input[name='tax']").is(':checked')) {
                    check_tax = li.find("input[name='tax']").val();
                }

                if (li.find("input[name='coupon']").is(':checked')) {
                    check_coupon = li.find("input[name='coupon']").val();
                }

                if (li.find("input[name='total_before_tax']").is(':checked')) {
                    check_total_before_tax = li.find("input[name='total_before_tax']").val();
                }

                if (li.find("input[name='status']").is(':checked')) {
                    check_status = li.find("input[name='status']").val();
                }

                if (li.find("input[name='ticket_type']").is(':checked')) {
                    check_ticket_type = li.find("input[name='ticket_type']").val();
                }

                if (li.find("input[name='extra_service']").is(':checked')) {
                    check_extra_service = li.find("input[name='extra_service']").val();
                }

                if (li.find("input[name='date_create']").is(':checked')) {
                    check_date_create = li.find("input[name='date_create']").val();
                }

                const data = {
                    'action': 'el_export_page_item',
                    'nonce': ajax_object.nonce,
                    'booking_ids': booking_ids,
                    'id_event': id_event,
                    'check_id_booking': check_id_booking,
                    'check_event': check_event,
                    'check_calendar': check_calendar,
                    'check_name': check_name,
                    'check_phone': check_phone,
                    'check_email': check_email,
                    'check_total_after_tax': check_total_after_tax,
                    'check_total_before_tax': check_total_before_tax,
                    'check_profit': check_profit,
                    'check_system_fee': check_system_fee,
                    'check_ticket_fee': check_ticket_fee,
                    'check_tax': check_tax,
                    'check_coupon': check_coupon,
                    'check_status': check_status,
                    'check_ticket_type': check_ticket_type,
                    'check_extra_service': check_extra_service,
                    'check_date_create': check_date_create,
                    'list_ckf_check': list_ckf_check,
                };

                $('.el_export_pagination').block({
                    message: null,
                    overlayCSS:  { 
                        backgroundColor: '#fff', 
                        opacity: 0.3, 
                        cursor: 'wait' 
                    },
                });

                $.ajax({
                    url: ajax_object.ajax_url,
                    complete: function( jqXHR, textStatus){
                        $('.el_export_pagination').unblock();
                    },
                    data: data,
                    method: 'POST',
                    type: 'POST',
                    error: function( jqXHR, textStatus, errorThrown){
                        console.error( errorThrown );
                    },
                    success: function(data){
                        var data = JSON.parse(data);

                        let csvContent = "data:text/csv;charset=utf-8," + "\uFEFF";

                        data.forEach(function(rowArray) {
                            let row = rowArray.join(",");
                            csvContent += row + "\r\n";
                        });

                        $this.addClass('is-active');

                        var encodedUri = encodeURI(csvContent);
                        var link = document.createElement("a");
                        link.setAttribute("href", encodedUri);
                        link.setAttribute("download", "list_bookings_" + slug_event + "_" + id_event +"_"+ page + ".csv");
                        document.body.appendChild(link); // Required for FF
                        link.click();
                    }
                });
            });
        },
        el_export_multiple_files: function(){
            $('.el_export_multiple_files').off().on('click', function(e){
                e.preventDefault();
                $('.el_enter_number_file').toggleClass('is-shown');
            });
        },
        export_csv_ticket: function(){
            const that = this;
            $("#export-csv-extra-ticket").off().on("click", function(e) {
                e.preventDefault();
                $(".list-check-export-csv").slideToggle();
            });

            $("#button-submit-ticket-export-csv").on("click", function() {
                var id_event = $(this).attr("data-id-event");
                
                var from_date = $('#el_export_from_date').val();
                var to_date = $('#el_export_to_date').val();

                $('#button-submit-ticket-export-csv').block({
                    message: null,
                    overlayCSS:  { 
                        backgroundColor: '#fff', 
                        opacity: 0.3, 
                        cursor: 'wait' 
                    },
                });

                $.post(ajax_object.ajax_url, {
                    'action': 'export_csv_ticket',
                    'nonce': ajax_object.nonce,
                    'id_event': id_event,
                    'from_date': from_date,
                    'to_date': to_date,
                }, function(response) {

                    $('#button-submit-ticket-export-csv').unblock();

                    $('body').append( response );
                    that.el_close_popup();
                    that.el_ticket_download_all_in_one();
                    that.el_export_multiple_files();
                    that.el_export_ticket_split_multi_file();
                    return false;
                });
            });
        },
        el_ticket_download_all_in_one: function(){
            $("#el_export_ticket_popup .el_export_all_in_one").off().on('click', function(e){
                e.preventDefault();

                var id_event = $('#button-submit-ticket-export-csv').attr("data-id-event");
                var slug_event = $('#button-submit-ticket-export-csv').attr("data-slug-event");
                var li = $('#button-submit-ticket-export-csv').closest('.list-check-export-csv').find("ul").find("li");
                var ticket_ids = $('#el_export_ticket_popup input[name="ticket_ids"]').val();
                var check_event             = false;
                var check_booking_id        = false;
                var check_ticket_id         = false;
                var check_ticket_type       = false;
                var check_extra_service     = false;
                var check_name              = false;
                var check_phone_customer    = false;
                var check_email_customer    = false;
                var check_address_customer  = false;
                var check_venue             = false;
                var check_address           = false;
                var check_seat              = false;
                var check_qr_code           = false;
                var check_start_date        = false;
                var check_end_date          = false;
                var check_date_create       = false;
                var checkin_time            = false;
                var ticket_checked          = false;
                var ticket_price            = false;
                var list_ckf_check          = {};
                
                var from_date = $('#el_export_from_date').val();
                var to_date = $('#el_export_to_date').val();

                var list_name_ckf = $('#el_list_ckf').val();

                if( list_name_ckf ) {
                    list_name_ckf = JSON.parse(list_name_ckf);

                    for( var key in list_name_ckf ){
                        if (li.find("input[name='"+list_name_ckf[key]+"']").is(':checked')) {
                            list_ckf_check[key] = list_name_ckf[key];
                        }
                    }
                }

                if (li.find("input[name='event']").is(':checked')) {
                    check_event = li.find("input[name='event']").val();
                }

                if (li.find("input[name='booking-id']").is(':checked')) {
                    check_booking_id = li.find("input[name='booking-id']").val();
                }

                if (li.find("input[name='ticket-id']").is(':checked')) {
                    check_ticket_id = li.find("input[name='ticket-id']").val();
                }

                if (li.find("input[name='ticket_type']").is(':checked')) {
                    check_ticket_type = li.find("input[name='ticket_type']").val();
                }

                if (li.find("input[name='extra_service']").is(':checked')) {
                    check_extra_service = li.find("input[name='extra_service']").val();
                }

                if (li.find("input[name='name']").is(':checked')) {
                    check_name = li.find("input[name='name']").val();
                }

                if (li.find("input[name='phone_customer']").is(':checked')) {
                    check_phone_customer = li.find("input[name='phone_customer']").val();
                }

                if (li.find("input[name='email_customer']").is(':checked')) {
                    check_email_customer = li.find("input[name='email_customer']").val();
                }

                if (li.find("input[name='address_customer']").is(':checked')) {
                    check_address_customer = li.find("input[name='address_customer']").val();
                }

                if (li.find("input[name='venue']").is(':checked')) {
                    check_venue = li.find("input[name='venue']").val();
                }

                if (li.find("input[name='address']").is(':checked')) {
                    check_address = li.find("input[name='address']").val();
                }

                if (li.find("input[name='seat']").is(':checked')) {
                    check_seat = li.find("input[name='seat']").val();
                }

                if (li.find("input[name='qr_code']").is(':checked')) {
                    check_qr_code = li.find("input[name='qr_code']").val();
                }

                if (li.find("input[name='start_date']").is(':checked')) {
                    check_start_date = li.find("input[name='start_date']").val();
                }

                if (li.find("input[name='end_date']").is(':checked')) {
                    check_end_date = li.find("input[name='end_date']").val();
                }

                if (li.find("input[name='date_create']").is(':checked')) {
                    check_date_create = li.find("input[name='date_create']").val();
                }

                if (li.find("input[name='checkin_time']").is(':checked')) {
                    checkin_time = li.find("input[name='checkin_time']").val();
                }

                if (li.find("input[name='ticket_checked']").is(':checked')) {
                    ticket_checked = li.find("input[name='ticket_checked']").val();
                }

                if (li.find("input[name='ticket_price']").is(':checked')) {
                    ticket_price = li.find("input[name='ticket_price']").val();
                }

                const data = {
                    'action': 'el_ticket_download_all_in_one',
                    'nonce': ajax_object.nonce,
                    'ticket_ids': ticket_ids,
                    'id_event': id_event,
                    'check_event': check_event,
                    'check_booking_id': check_booking_id,
                    'check_ticket_id': check_ticket_id,
                    'check_ticket_type': check_ticket_type,
                    'check_extra_service': check_extra_service,
                    'check_name': check_name,
                    'check_phone_customer': check_phone_customer,
                    'check_email_customer': check_email_customer,
                    'check_address_customer': check_address_customer,
                    'check_venue': check_venue,
                    'check_address': check_address,
                    'check_seat': check_seat,
                    'check_qr_code': check_qr_code,
                    'check_start_date': check_start_date,
                    'check_end_date': check_end_date,
                    'check_date_create': check_date_create,
                    'checkin_time': checkin_time,
                    'ticket_checked': ticket_checked,
                    'ticket_price': ticket_price,
                    'list_ckf_check': list_ckf_check,
                };

                $('.el_export_all_in_one').block({
                    message: null,
                    overlayCSS:  { 
                        backgroundColor: '#fff', 
                        opacity: 0.3, 
                        cursor: 'wait' 
                    },
                });

                $.ajax({
                    url: ajax_object.ajax_url,
                    complete: function( jqXHR, textStatus){
                        $('.el_export_all_in_one').unblock();
                    },
                    data: data,
                    method: 'POST',
                    type: 'POST',
                    error: function( jqXHR, textStatus, errorThrown){
                        console.error( errorThrown );
                    },
                    success: function(data){
                        var data = JSON.parse(data);

                        let csvContent = "data:text/csv;charset=utf-8," + "\uFEFF";

                        data.forEach(function(rowArray) {
                            let row = rowArray.join(",");
                            csvContent += row + "\r\n";
                        });

                        var encodedUri = encodeURI(csvContent);
                        var link = document.createElement("a");
                        link.setAttribute("href", encodedUri);
                        link.setAttribute("download", "list_tickets_" + slug_event + "_" + id_event + ".csv");
                        document.body.appendChild(link); // Required for FF
                        link.click();
                    }
                });
            });
        },
        el_export_ticket_split_multi_file: function(){
            const that = this;
            $('#el_export_ticket_popup .el_export_file').off().on('click', function(e){
                e.preventDefault();
                const number_file = $('.el_enter_number_file input[name="number_file"]').val();
                const ticket_ids = $('#el_export_ticket_popup input[name="ticket_ids"]').val();

                const data = {
                    'action': 'el_export_ticket_split_multi_file',
                    'ticket_ids': ticket_ids,
                    'number_file': number_file,
                    'nonce': ajax_object.nonce,
                };

                $('.el_export_file').block({
                    message: null,
                    overlayCSS:  {
                        backgroundColor: '#fff',
                        opacity: 0.3,
                        cursor: 'wait'
                    },
                });

                $.ajax({
                    url: ajax_object.ajax_url,
                    complete: function( jqXHR, textStatus){
                        $('.el_export_file').unblock();
                    },
                    data: data,
                    method: 'POST',
                    type: 'POST',
                    error: function( jqXHR, textStatus, errorThrown){
                        console.error( errorThrown );
                    },
                    success: function(data){
                        $('.el_export_pagination').html('');
                        $('.el_export_pagination').html(data);
                        that.el_export_ticket_page_item();
                    }
                });
            });
        },
        el_export_ticket_page_item: function(){
            $('#el_export_ticket_popup .el_page_item').off().on('click', function(e){
                e.preventDefault();
                var $this = $(this);
                const page = $this.attr('data-page');

                const booking_ids = $this.closest('.el_item').find('.item_booking_ids').val();
                var id_event = $('#button-submit-ticket-export-csv').attr("data-id-event");
                var slug_event = $('#button-submit-ticket-export-csv').attr("data-slug-event");
                var li = $('#button-submit-ticket-export-csv').closest('.list-check-export-csv').find("ul").find("li");
                var ticket_ids = $this.closest('.el_item').find('.item_ticket_ids').val();
                var check_event             = false;
                var check_booking_id        = false;
                var check_ticket_id         = false;
                var check_ticket_type       = false;
                var check_extra_service     = false;
                var check_name              = false;
                var check_phone_customer    = false;
                var check_email_customer    = false;
                var check_address_customer  = false;
                var check_venue             = false;
                var check_address           = false;
                var check_seat              = false;
                var check_qr_code           = false;
                var check_start_date        = false;
                var check_end_date          = false;
                var check_date_create       = false;
                var checkin_time            = false;
                var ticket_checked          = false;
                var ticket_price            = false;
                var list_ckf_check          = {};
                
                var from_date = $('#el_export_from_date').val();
                var to_date = $('#el_export_to_date').val();

                var list_name_ckf = $('#el_list_ckf').val();

                if( list_name_ckf ) {
                    list_name_ckf = JSON.parse(list_name_ckf);

                    for( var key in list_name_ckf ){
                        if (li.find("input[name='"+list_name_ckf[key]+"']").is(':checked')) {
                            list_ckf_check[key] = list_name_ckf[key];
                        }
                    }
                }

                if (li.find("input[name='event']").is(':checked')) {
                    check_event = li.find("input[name='event']").val();
                }

                if (li.find("input[name='booking-id']").is(':checked')) {
                    check_booking_id = li.find("input[name='booking-id']").val();
                }

                if (li.find("input[name='ticket-id']").is(':checked')) {
                    check_ticket_id = li.find("input[name='ticket-id']").val();
                }

                if (li.find("input[name='ticket_type']").is(':checked')) {
                    check_ticket_type = li.find("input[name='ticket_type']").val();
                }

                if (li.find("input[name='extra_service']").is(':checked')) {
                    check_extra_service = li.find("input[name='extra_service']").val();
                }

                if (li.find("input[name='name']").is(':checked')) {
                    check_name = li.find("input[name='name']").val();
                }

                if (li.find("input[name='phone_customer']").is(':checked')) {
                    check_phone_customer = li.find("input[name='phone_customer']").val();
                }

                if (li.find("input[name='email_customer']").is(':checked')) {
                    check_email_customer = li.find("input[name='email_customer']").val();
                }

                if (li.find("input[name='address_customer']").is(':checked')) {
                    check_address_customer = li.find("input[name='address_customer']").val();
                }

                if (li.find("input[name='venue']").is(':checked')) {
                    check_venue = li.find("input[name='venue']").val();
                }

                if (li.find("input[name='address']").is(':checked')) {
                    check_address = li.find("input[name='address']").val();
                }

                if (li.find("input[name='seat']").is(':checked')) {
                    check_seat = li.find("input[name='seat']").val();
                }

                if (li.find("input[name='qr_code']").is(':checked')) {
                    check_qr_code = li.find("input[name='qr_code']").val();
                }

                if (li.find("input[name='start_date']").is(':checked')) {
                    check_start_date = li.find("input[name='start_date']").val();
                }

                if (li.find("input[name='end_date']").is(':checked')) {
                    check_end_date = li.find("input[name='end_date']").val();
                }

                if (li.find("input[name='date_create']").is(':checked')) {
                    check_date_create = li.find("input[name='date_create']").val();
                }

                if (li.find("input[name='checkin_time']").is(':checked')) {
                    checkin_time = li.find("input[name='checkin_time']").val();
                }

                if (li.find("input[name='ticket_checked']").is(':checked')) {
                    ticket_checked = li.find("input[name='ticket_checked']").val();
                }

                if (li.find("input[name='ticket_price']").is(':checked')) {
                    ticket_price = li.find("input[name='ticket_price']").val();
                }
                
                const data = {
                    'action': 'el_export_ticket_page_item',
                    'nonce': ajax_object.nonce,
                    'ticket_ids': ticket_ids,
                    'id_event': id_event,
                    'check_event': check_event,
                    'check_booking_id': check_booking_id,
                    'check_ticket_id': check_ticket_id,
                    'check_ticket_type': check_ticket_type,
                    'check_extra_service': check_extra_service,
                    'check_name': check_name,
                    'check_phone_customer': check_phone_customer,
                    'check_email_customer': check_email_customer,
                    'check_address_customer': check_address_customer,
                    'check_venue': check_venue,
                    'check_address': check_address,
                    'check_seat': check_seat,
                    'check_qr_code': check_qr_code,
                    'check_start_date': check_start_date,
                    'check_end_date': check_end_date,
                    'check_date_create': check_date_create,
                    'checkin_time': checkin_time,
                    'ticket_checked': ticket_checked,
                    'ticket_price': ticket_price,
                    'list_ckf_check': list_ckf_check,
                };

                $('.el_export_pagination').block({
                    message: null,
                    overlayCSS:  { 
                        backgroundColor: '#fff', 
                        opacity: 0.3, 
                        cursor: 'wait'
                    },
                });

                $.ajax({
                    url: ajax_object.ajax_url,
                    complete: function( jqXHR, textStatus){
                        $('.el_export_pagination').unblock();
                    },
                    data: data,
                    method: 'POST',
                    type: 'POST',
                    error: function( jqXHR, textStatus, errorThrown){
                        console.error( errorThrown );
                    },
                    success: function(data){
                        var data = JSON.parse(data);

                        let csvContent = "data:text/csv;charset=utf-8," + "\uFEFF";

                        data.forEach(function(rowArray) {
                            let row = rowArray.join(",");
                            csvContent += row + "\r\n";
                        });

                        $this.addClass('is-active');

                        var encodedUri = encodeURI(csvContent);
                        var link = document.createElement("a");
                        link.setAttribute("href", encodedUri);
                        link.setAttribute("download", "list_tickets_" + slug_event + "_" + id_event +"_"+ page + ".csv");
                        document.body.appendChild(link); // Required for FF
                        link.click();
                    }
                });
            });
        },
        add_package_paypal: function( membership_id ){
            const $nonce = $(document).find('#paypal_button_wrapper').attr('data-nonce');
            const $url = $(document).find('#paypal_button_wrapper').attr('data-url');
            window.paypal.Buttons({
                async createOrder() {
                    try {
                        let data = {
                            'action': 'el_package_paypal_create_order',
                            'membership_id': membership_id,
                            'nonce': $nonce,
                        };
                        var post_data = async function(){
                            return $.post(ajax_object.ajax_url, data);
                        };
                        let orderData = await post_data();
                        orderData = JSON.parse( orderData );
                        const { el_error: $err } = orderData;
                        if ( $err ) {
                            resultMessage($err);
                            return false;
                        }

                        const errorDetail = orderData?.details?.[0];

                        if (orderData.id) {
                            return orderData.id;
                        } else {
                            const errorDetail = orderData?.details?.[0];
                            const errorMessage = errorDetail
                            ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                            : JSON.stringify(orderData);

                            throw new Error(errorMessage);
                        }

                    }
                    catch( err ){
                        console.error(err);
                        resultMessage(err);
                    }
                    
                },
                async onApprove(data, actions) {
                    try {
                        const $order_id = data.orderID;
                        let $data = {
                            'action': 'el_package_paypal_capture_order',
                            'nonce': $nonce,
                            'order_id': $order_id,
                        };
                        var post_data = async function(){
                            return $.post(ajax_object.ajax_url, $data, await function(response) {
                                // check error
                                const { el_error: $err } = JSON.parse( response );
                                if ( $err ) {
                                    resultMessage($err);
                                    return false;
                                }

                                const orderData = JSON.parse( response);
                                const errorDetail = orderData?.details?.[0];

                                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                    return actions.restart();
                                } else if (errorDetail) {
                                // (2) Other non-recoverable errors -> Show a failure message
                                    throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                                } else if (!orderData.purchase_units) {
                                    throw new Error(JSON.stringify(orderData));
                                } else {
                                // (3) Successful transaction -> Show confirmation or thank you message
                                // Or go to another URL:  actions.redirect('thank_you.html');
                                    if ( orderData.status === 'COMPLETED' ) {
                                        window.location.href = $url;
                                    }
                                }
                            });
                        };
                        await post_data();
                        
                    }
                    catch(err){
                        console.error(err);
                        resultMessage(err);
                    }
                }
            }).render("#paypal-button-container");

            function resultMessage(message) {
                const container = $(document).find("#package_payment #result-message");
                container.html(message);
            }
        },
        add_package_woo: async function( membership_id ){
            let data = {
                'action': 'el_add_package_woo',
                'membership_id': membership_id,
            };

            $.post( ajax_object.ajax_url, data,function(data_res){
                let res = JSON.parse( data_res );
                let url = res.url;
                window.location.replace(url);
            });
        },
        add_package_stripe: async function( $pid, membership_id ){

            var register_btn      = $(document).find('.register_package[data-pid="'+$pid+'"]');
            var $public_key       = $(document).find('#el_payment_stripe').attr('data-key');
            var $currency         = $(document).find('#el_payment_stripe').attr('data-currency');
            var $themes           = $(document).find('#el_payment_stripe').attr('data-themes');
            var $err_messages     = $(document).find('#el_payment_stripe_error');
            var $redirect_url     = $(document).find('#el_payment_stripe').attr('data-url');

            var zeroDecimal = ["bif","clp","djf","gnf","jpy","kmf","krw","mga",
            "pyg","rwf","ugx","vnd","vuv","xaf","xof","xpf"];

            var $amount = parseFloat( register_btn.attr('data-price') ) * 100;

            if ( zeroDecimal.includes( $currency ) ) {
                $amount = parseFloat( register_btn.attr('data-price') );
            }
            
            $amount = Math.ceil($amount);
            var $currency = register_btn.attr('data-currency');
            var stripe = Stripe($public_key);

            var elements;

            var element_options = {
                mode: 'payment',
                currency: $currency,
                amount: $amount,
                paymentMethodTypes: ['card'],
                appearance: {
                    theme: 'stripe',
                    labels: 'above',
                }
            };

            // themes setup
            switch ($themes) {
                case 'night':
                    element_options['appearance']['theme'] = 'night';
                    break;
                case 'flat':
                    element_options['appearance']['theme'] = 'flat';
                    element_options['appearance']['labels'] = 'floating';
                    break;
                default:
            }

            elements = stripe.elements(element_options);

            var paymentElementOptions = {
            };
            
            var paymentElement = elements.create("payment", paymentElementOptions);
            // call api stripe, check load error

            try {
                paymentElement.mount("#el_payment_stripe");
                paymentElement.on('loaderror', function(event) {
                    if ( event.error ) {
                        $err_messages.html( event.error.message );
                        return false;
                    }
                });
            }
            catch(err) {
                $err_messages.html( err );
                return false;
            }

            $('#el_payment_stripe_submit').off().on('click',async function(e){
                e.preventDefault();

                $(document).find('#package_payment .el_loading').show();
                $(document).find('#el_payment_stripe_submit').attr("disabled", true);
                $(document).find('.el_package_prev').attr("disabled", true);

                // Trigger form validation and wallet collection
                const {error: submitError} = await elements.submit();

                if ( submitError ) {

                    $(document).find('#package_payment .el_loading').hide();
                    $(document).find('#el_payment_stripe_submit').attr("disabled", false);
                    $(document).find('.el_package_prev').attr("disabled", false);
                    $err_messages.html(submitError.message);
                    return false;
                }

                var $nonce = $(this).attr('data-nonce');
                var $data = {
                    'action': 'el_add_package_stripe',
                    'nonce': $nonce,
                    'membership_id': membership_id,
                    'amount': $amount,
                    'currency': $currency,
                };

                $.post(ajax_object.ajax_url, $data, function(response){
                    response = JSON.parse( response );
                    if ( response.el_error ) {
                        $err_messages.html( response.el_error );
                        return false;
                    }

                    elPackageConfirmPayment( response );
                });
            });

            async function elPackageConfirmPayment( response ) {
                try {
                    const { client_secret: clientSecret } = response;
                    const { error } = await stripe.confirmPayment({
                        elements,
                        clientSecret,
                        confirmParams: {
                            return_url: $redirect_url,
                        },
                    });

                    if ( error ) {

                        $(document).find('#package_payment .el_loading').hide();
                        $(document).find('#el_payment_stripe_submit').attr("disabled", false);
                        $(document).find('.el_package_prev').attr("disabled", false);
                        $err_messages.html( error.message );
                        return false;
                    }
                } catch (err) {
                    if ( err ) {

                        $(document).find('#package_payment .el_loading').hide();
                        $(document).find('#el_payment_stripe_submit').attr("disabled", false);
                        $(document).find('.el_package_prev').attr("disabled", false);
                        $err_messages.html( err );
                        return false;
                    }
                }
            }

        },

        /* add package */
        add_package: function() {

            $(document).find('.register_package').on('click', async function() {
                var $this   = $(this);
                var $pid    = $this.attr('data-pid');
                var $price  = $this.attr('data-price');
                var package_name    = $this.attr('data-package');
                var el_package_next = $('#package_payment').removeAttr('data-membership-id');

                if ( $pid != undefined ) {
                    if ( $price && $price != '0' ) {

                        var paymentMethods = $('#package_payment .package_payment_form input[name="payment_method"]');
                        $('#package_payment .tab').fadeOut();
                        if ( paymentMethods.length === 1 ) {
                            var payment_method = $('#package_payment input[name="payment_method"]:checked').val();
                            var current_membership_id = $('#package_payment').attr('data-membership-id');
                            var package_queue = sessionStorage.getItem('package_queue');

                            if ( package_queue ) {
                                package_queue = JSON.parse( package_queue );
                                package_queue.forEach( function(item){
                                    if ( item.pid == $pid ) {
                                        current_membership_id = item.membership_id;
                                    }
                                } );
                            }
                            
                            if ( ! current_membership_id ) {
                                $.post(ajax_object.ajax_url, {
                                    action: 'el_add_package',
                                    data: {
                                        'pid': $pid,
                                        'payment_method': payment_method,
                                    },
                                }, function(response) {
                                    var data = JSON.parse(response);
                                    var payment_method  = data.payment_method;
                                    var membership_id   = data.membership_id;
                                    var package         = data.code;

                                    if (data.code == '0') {
                                        alert(data.status);
                                        window.location.replace(data.url);
                                        return false;
                                    }
                                    var data_session;
                                    if ( ! package_queue ) {
                                        data_session = [];
                                    } else {
                                        data_session = JSON.parse(JSON.stringify(package_queue));
                                    }
                                    data_session.push({ 'pid': $pid, 'membership_id': membership_id });
                                    
                                    sessionStorage.setItem('package_queue', JSON.stringify(data_session));

                                    $('#package_payment').attr('data-membership-id',membership_id);

                                    switch( payment_method ) {
                                        case 'woo':
                                            $('#package_payment .tab-payment-woo').fadeIn();
                                            EL_Frontend.add_package_woo( membership_id );
                                        break;
                                        case 'paypal':
                                            
                                            $('#package_payment .tab-payment-paypal').fadeIn();
                                            $('#paypal-button-container').html('');
                                            EL_Frontend.add_package_paypal( membership_id );
                                        break;
                                        case 'stripe':
                                            $('#package_payment .tab-payment-stripe').fadeIn();
                                            EL_Frontend.add_package_stripe( $pid, membership_id );
                                        break;
                                        default:
                                    }
                             
                                });
                            } else {

                                $('#package_payment .tab-choose-payment-method').fadeOut();
                                switch( payment_method ) {
                                    case 'woo':
                                        $('#package_payment .tab-payment-woo').fadeIn();
                                        EL_Frontend.add_package_woo( current_membership_id );
                                    break;
                                    case 'paypal':
                                        $('#package_payment .tab-payment-paypal').fadeIn();
                                        $('#paypal-button-container').html('');
                                        EL_Frontend.add_package_paypal( current_membership_id );
                                    break;
                                    case 'stripe':
                                        $('#package_payment .tab-payment-stripe').fadeIn();
                                        EL_Frontend.add_package_stripe( $pid, current_membership_id );
                                    break;
                                    default:
                                        $this_btn.attr('disabled',false);
                                }
                            }

                        } else {
                            $('#package_payment .tab-choose-payment-method').fadeIn();
                        }

                        
                        $('#package_payment .package_payment_title').html('');
                        $('#package_payment .package_payment_title').html(package_name);
                        $('#package_payment').attr('data-pid', $pid);
                        $('#package_payment .el_package_next').attr('disabled',false);
                        $('#package_payment').modal('show');

                    } else {
                        var data = {
                            'action': 'el_add_package',
                            'data': {
                                'pid': $pid,
                            },
                        };
                        $.post( ajax_object.ajax_url, data, function(res){
                            res = JSON.parse( res );
                            alert(res.status);
                            window.location.replace(res.url);
                        } );
                    }
                }

            });
            // clear package_queue
            sessionStorage.removeItem("package_queue");

            $('#package_payment .el_package_next').on('click',function(e){
                e.preventDefault();
                var $this_btn = $(this);
                var payment_method = $('#package_payment input[name="payment_method"]:checked').val();
                var $pid = $('#package_payment').attr('data-pid');
                var current_membership_id = $('#package_payment').attr('data-membership-id');
                var package_queue = sessionStorage.getItem('package_queue');

                if ( package_queue ) {
                    package_queue = JSON.parse( package_queue );
                    package_queue.forEach( function(item){
                        if ( item.pid == $pid ) {
                            current_membership_id = item.membership_id;
                        }
                    } );
                }

                $this_btn.attr('disabled',true);

                if ( ! current_membership_id ) {
                    $.post(ajax_object.ajax_url, {
                        action: 'el_add_package',
                        data: {
                            'pid': $pid,
                            'payment_method': payment_method,
                        },
                    }, function(response) {
                        var data = JSON.parse(response);
                        var payment_method = data.payment_method;
                        var membership_id = data.membership_id;
                        var package = data.code;

                        if (data.code == '0') {
                            alert(data.status);
                            window.location.replace(data.url);
                            return false;
                        }
                        var data_session;
                        if ( ! package_queue ) {
                            data_session = [];
                        } else {
                            data_session = JSON.parse(JSON.stringify(package_queue));
                        }
                        data_session.push({ 'pid': $pid, 'membership_id': membership_id });
                        
                        sessionStorage.setItem('package_queue', JSON.stringify(data_session));

                        $('#package_payment').attr('data-membership-id',membership_id);

                        $('#package_payment .tab-choose-payment-method').fadeOut();

                        switch( payment_method ) {
                            case 'woo':
                                $('#package_payment .tab-payment-woo').fadeIn();
                                EL_Frontend.add_package_woo( membership_id );
                            break;
                            case 'paypal':
                                
                                $('#package_payment .tab-payment-paypal').fadeIn();
                                $('#paypal-button-container').html('');
                                EL_Frontend.add_package_paypal( membership_id );
                            break;
                            case 'stripe':
                                $('#package_payment .tab-payment-stripe').fadeIn();
                                EL_Frontend.add_package_stripe( $pid, membership_id );
                            break;
                            default:
                                $this_btn.attr('disabled',false);
                        }
                 
                    });
                } else {
                    $('#package_payment .tab-choose-payment-method').fadeOut();
                    switch( payment_method ) {
                        case 'woo':
                            $('#package_payment .tab-payment-woo').fadeIn();
                            EL_Frontend.add_package_woo( current_membership_id );
                        break;
                        case 'paypal':
                            $('#package_payment .tab-payment-paypal').fadeIn();
                            $('#paypal-button-container').html('');
                            EL_Frontend.add_package_paypal( current_membership_id );
                        break;
                        case 'stripe':
                            $('#package_payment .tab-payment-stripe').fadeIn();
                            EL_Frontend.add_package_stripe( $pid, current_membership_id );
                        break;
                        default:
                            $this_btn.attr('disabled',false);
                    }
                }

            });

            $('#package_payment .el_package_prev').on('click',function(){
                $('#package_payment .package_payment_form .tab').fadeOut();
                $('#package_payment .package_payment_form .tab-choose-payment-method').fadeIn();
                $('#package_payment .el_package_next').attr('disabled',false);
            });

        },

        comment_rating: function() {
            $(document).find('.event-single .rating-container input').on('mouseover', function() {
                var onStar = parseInt($(this).data('value'), 6); /* The star currently mouse on */

                /* Now highlight all the stars that's not after the current hovered star */
                $(this).parent().children('.star').each(function(e) {
                    if (e < onStar) {
                        $(this).addClass('hover');
                    } else {
                        $(this).removeClass('hover');
                    }
                });
            }).on('mouseout', function() {
                $(this).parent().children('.rating-container input').each(function(e) {
                    $(this).removeClass('hover');
                });
            });

            /* 2. Action to perform on click */
            $(document).find('.event-single .rating-container input').on('click', function() {
                var onStar  = parseInt($(this).data('value'), 6); /* The star currently selected */
                var stars   = $(this).parent().children('.star');
                var i = 0;

                for ( i = 0; i < stars.length; i++ ) {
                    $(stars[i]).removeClass('selected');
                }

                for ( i = 0; i < onStar; i++ ) {
                    $(stars[i]).addClass('selected');
                }
            });
        },

        update_wishlist: function() {
            $("body").on("click", ".el-wishlist", function() {
                if ( $(this).hasClass('active') ) {
                    var that     = $(this);
                    var id_event = $(this).attr("data-id");

                    $.post(ajax_object.ajax_url, {
                        action: 'el_remove_wishlist',
                        data: {
                            id_event: id_event,
                        }
                    }, function(response) {
                        that.removeClass('active');
                    });
                } else {
                    $(this).addClass('active');
                    var id_event = $(this).attr("data-id");

                    $.post(ajax_object.ajax_url, {
                        action: 'el_add_wishlist',
                        data: {
                            id_event: id_event,
                        }
                    }, function(response) {});         
                }
            });
        },

        delete_wishlist: function() {
            $(".wishlist_active").off().on("click", function() {
                var that        = $(this);
                var id_event    = $(this).attr("data-id");

                $.post(ajax_object.ajax_url, {
                    action: 'el_remove_wishlist',
                    data: {
                        id_event: id_event,
                    }
                }, function(response) {
                    that.removeClass('active');
                });
            });
        },

        remove_wishlist: function() {
            $(".el-my-wishlist td a.close-wl").off().on("click", function() {
                $(this).parent("td").parent("tr").fadeOut(700);
                var id_event = $(this).attr("data-id");

                $.post(ajax_object.ajax_url, {
                    action: 'el_remove_wishlist',
                    data: {
                        id_event: id_event,
                    }
                }, function(response) {});
            });
        },

        slide_event_related: function() {
            var rtl = false;

            if( $('body').hasClass('rtl') ){
                rtl = true;
            }

            const eventType = event_object.event_type;
            const options = {
                'margin': 45,
                'nav': true,
                'responsive': {
                    0: {
                        items: 1
                    },
                    600: {
                        items: 2
                    },
                    1000: {
                        items: 3
                    }
                },
                'rtl': rtl,
                'navText': ['<i class="arrow_left"></i>', '<i class="arrow_right"></i>'],
            }

            switch(eventType) {
              case 'type3':
                options['responsive'] = {
                     0: {
                        items: 1
                    },
                };
                break;
              default:
            }

            if ($(".event_related .wrap_event").length > 0) {
                $(".event_related .wrap_event").owlCarousel(options);
            }
        },

        slide_event_single_gallery: function() {
            var rtl = false;
            if( $('body').hasClass('rtl') ){
                rtl = true;
            }

            if ($(".event-banner .gallery-banner .wrap_event").length > 0) {
                $(".event-banner .gallery-banner .wrap_event").owlCarousel({
                    margin: 0,
                    nav: true,
                    dots: false,
                    autoplay: true,
                    autoplayTimeout: 8000,
                    loop: true,
                    rtl: rtl,
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        1000: {
                            items: 3
                        }
                    },
                    navText: ['<i class="arrow_left"></i>', '<i class="arrow_right"></i>'],
                });

                $(".event-banner").find(".owl-prev").attr("aria-label", "prev");
                $(".event-banner").find(".owl-prev").attr("role", "button");
                $(".event-banner").find(".owl-next").attr("aria-label", "next");
                $(".event-banner").find(".owl-next").attr("role", "button");
            }
        },

        banner_event_single: function() {
            if ($(document).find('.event-banner .single-banner').length = 1) {
                let widthBanner = $(document).find('.event-banner .single-banner');
                widthBanner.outerWidth();
                widthBanner.css('height', widthBanner.outerWidth() / 2.6);
            }

            $(window).resize(function() {
                if ($(document).find('.event-banner .single-banner').length = 1) {
                    let widthBanner = $(document).find('.event-banner .single-banner');
                    widthBanner.outerWidth();
                    widthBanner.css('height', widthBanner.outerWidth() / 2.6);
                }
            });
        },

        /*** Show more Description ***/
        el_show_more_desc: function() {
            var that = $('.single-event .event_desc .wrap_content');

            var h_data = that.outerHeight();

            if (that.data('height') != 'auto' && that.data('height') < h_data) {
                that.css('height', that.data('height'));

                that.find('.el_show_more_desc').css({
                    'display': 'block',
                });

                that.find('.el_show_more_desc .btn_showmore').on('click', function(e) {
                    e.preventDefault();

                    let parent = $(this).parents('.wrap_content');
                    parent.animate({
                        height: parent.get(0).scrollHeight
                    }, 1000);

                    $(this).parent().hide();
                });
            }
        },

        /*** Update Bank ***/
        update_payout_method: function() {
            $(document).on('click', '.el_update_payout_method', function(e) {
                e.preventDefault();

                var el_update_payout_method_nonce = $(this).parent().find('#el_update_payout_method_nonce').val();
                var user_bank_owner = $(this).parent().find('#user_bank_owner').val();
                var user_bank_number = $(this).parent().find('#user_bank_number').val();
                var user_bank_name = $(this).parent().find('#user_bank_name').val();
                var user_bank_branch = $(this).parent().find('#user_bank_branch').val();
                var user_bank_routing = $(this).parent().find('#user_bank_routing').val();
                var user_bank_paypal_email = $(this).parent().find('#user_bank_paypal_email').val();
                var user_bank_stripe_account = $(this).parent().find('#user_bank_stripe_account').val();
                var user_bank_iban = $(this).parent().find('#user_bank_iban').val();
                var user_bank_swift_code = $(this).parent().find('#user_bank_swift_code').val();
                var user_bank_ifsc_code = $(this).parent().find('#user_bank_ifsc_code').val();
                var payout_method = $(this).parent().find('input[name="ova_mb_event_payout_method"]:checked').val();
                var list_payout_method_field = $(this).parent().find('#el_list_payout_method_field').val();
                var data_payout_method_field = {};

                if ( list_payout_method_field ) {
                    list_payout_method_field = JSON.parse(list_payout_method_field);

                    for( var key_pmf in list_payout_method_field ) {
                        var value_pmf   = $("#" + list_payout_method_field[key_pmf] ).val();
                        var name_pmf    = list_payout_method_field[key_pmf];
                        data_payout_method_field[name_pmf] = value_pmf;
                    }
                }
                
                if ($(this).parent().valid()) {
                    $.post(ajax_object.ajax_url, {
                        action: 'el_update_payout_method',
                        data: {
                            el_update_payout_method_nonce: el_update_payout_method_nonce,
                            user_bank_owner: user_bank_owner,
                            user_bank_number: user_bank_number,
                            user_bank_name: user_bank_name,
                            user_bank_branch: user_bank_branch,
                            user_bank_routing: user_bank_routing,
                            user_bank_paypal_email: user_bank_paypal_email,
                            user_bank_stripe_account: user_bank_stripe_account,
                            user_bank_iban: user_bank_iban,
                            user_bank_swift_code: user_bank_swift_code,
                            user_bank_ifsc_code: user_bank_ifsc_code,
                            payout_method:payout_method,
                            data_payout_method_field:data_payout_method_field,
                        },
                    }, function(cons) {
                        if (cons) {
                            location.reload();
                        }
                    });
                }
            });
        },

        /*** Form Search ***/
        el_load_venue_search: function() {
            $('.wrap_form_search .venue input').autocomplete({
                source: function(request, response) {
                    $.ajax({
                        url: ajax_object.ajax_url,
                        type: 'POST',
                        dataType: "json",
                        data: {
                            action: 'el_load_venue',
                            keyword: request.term,
                        },
                        success: function(data) {
                            response(data);
                        },
                    });
                },
                delay: 0,
            });
        },

        datepicket_search: function() {
            $('.wrap_form_search .el_select_date').each(function() {
                var format      = $(this).attr('data-format');
                var first_day   = $(this).data('firstday');

                $(this).datepicker({
                    dateFormat: format,
                    firstDay: first_day,
                    changeMonth: true,
                    changeYear: true,
                    onSelect: function() {
                        $('.wrap_form_search .el_all_time').val(" ");
                        var $option_default = $('.wrap_form_search .el_all_time option').first().html();
                        $('.wrap_form_search .el_all_time option').first().attr('selected', 'selected');
                        $('.wrap_form_search .el_all_time span.select2-selection__rendered').html($option_default);
                        $('.wrap_form_search .el_all_time span.select2-selection__rendered').attr('title', $option_default);
                    }
                });
            });

            $('.wrap_form_search .el_all_time select').on('change', function() {
                $('.wrap_form_search .el_start_date input').val("");
                $('.wrap_form_search .el_end_date input').val("");
            });
        },

        el_load_location_search: function() {



            $('.el_search_filters .loc_state select').change(function() {
                var country = $(this).val();
                var $this   = $(this).parents('.el_search_filters');

                if ( country == '' || country == undefined ) {
                    $(".el_search_filters .loc_city select").prop("disabled", true);
                } else {
                    $(".el_search_filters .loc_city select").prop("disabled", true);
                    $.post(ajax_object.ajax_url, {
                        action: 'el_load_location',
                        data: {
                            country: country,
                        },
                    }, function(response) {
                        $this.find('#event_city').html(response);
                        $(".el_search_filters .loc_city select").prop("disabled", false);
                    });
                }
            });

            $(window).on( 'load' ,function() {
                var country         = $('.el_search_filters .loc_state select option:selected').val();
                var city_selected   = $('.el_search_filters .loc_city select option:selected').val();

                if ( country == '' || country == undefined ) {
                    $('.el_search_filters .loc_city select').attr('disabled', 'disabled');
                } else {
                    $.post(ajax_object.ajax_url, {
                        action: 'el_load_location',
                        data: {
                            country: country,
                            city_selected: city_selected,
                        },
                    }, function(response) {
                        $('.el_search_filters').find('#event_city').html(response);
                    });

                    $('.el_search_filters .loc_city select').removeAttr('disabled');
                }
            });

            /* Location Autocomplete */
            $('.wrap_form_search .loc_input [name="loc_input"]').autocomplete({
                source: function(request, response) {
                    $.ajax({
                        url: ajax_object.ajax_url,
                        type: 'POST',
                        dataType: "json",
                        data: {
                            action: 'el_load_location_search',
                            keyword: request.term,
                        },
                        success: function(data) {
                            response(data);
                        },
                   });
                },
                delay: 0,
            });
        },

        /* Search Map */
        el_search_map: async function() {
            var zoomMap         = parseInt($(document).find('.wrap_search_map .search_result').attr('data-zoom'));
            var latDefault      = parseFloat($(document).find('.wrap_search_map .search_result').attr('data-lat'));
            var lngDefault      = parseFloat($(document).find('.wrap_search_map .search_result').attr('data-lng'));
            var marker_option   = $(document).find('.wrap_search_map .search_result').attr('data-marker_option');
            var marker_icon     = $(document).find('.wrap_search_map .search_result').attr('data-marker_icon');
            var map_address     = $(document).find('.wrap_search_map').find('[name="map_address"]').val();
            var $restrictions   = [];
            if ( $(document).find('.wrap_search_map .search_result').attr('data-restrictions') ) {
                $restrictions   = JSON.parse( $(document).find('.wrap_search_map .search_result').attr('data-restrictions') );
            }
            
            var $bound          = parseInt( $(document).find('.wrap_search_map .search_result').attr('data-bound') );
            var $bound_lat      = parseFloat( $(document).find('.wrap_search_map .search_result').attr('data-bound-lat') );
            var $bound_lng      = parseFloat( $(document).find('.wrap_search_map .search_result').attr('data-bound-lng') );
            var $bound_radius   = parseFloat( $(document).find('.wrap_search_map .search_result').attr('data-bound-radius') );

            var options = {};

            const { Map } = await google.maps.importLibrary("maps");
            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

            if ( $bound === 1 && $bound_lat && $bound_lng && $bound_radius ) {
                var $bound_radius_km = $bound_radius / 100 ;
                var dataBounds = {
                    north: $bound_lat + $bound_radius_km,
                    south: $bound_lat - $bound_radius_km,
                    east: $bound_lng + $bound_radius_km,
                    west: $bound_lng - $bound_radius_km,
                };
                options['bounds']       = dataBounds;
                options['strictBounds'] = true;
            }

            if ( $restrictions ) {
                var $country = [];
                $.each($restrictions,function(index,value){
                    $country.push( value );
                });
                options['componentRestrictions'] = { country: $country };
                options['strictBounds'] = true;
            }

            if ( map_address ) {
                $(document).find('.wrap_search_map').find('.wrap_search_radius').show();
            } else {
                $(document).find('.wrap_search_map').find('.wrap_search_radius').hide();
            }

            /* averageGeolocation */
            async function averageGeolocation(data) {
                if (data.length == 1) {
                    return data[0];
                }

                let $numCoords = data.length;
                let $X = 0.0;
                let $Y = 0.0;
                let $Z = 0.0;

                for (let i = 0; i < data.length; i++) {
                    var lat = data[i]['lat'] * Math.PI / 180;
                    var lng = data[i]['lng'] * Math.PI / 180;
                    let $a = Math.cos(lat) * Math.cos(lng);
                    let $b = Math.cos(lat) * Math.sin(lng);
                    let $c = Math.sin(lat);

                    $X += $a;
                    $Y += $b;
                    $Z += $c;
                }

                $X = $X / $numCoords;
                $Y = $Y / $numCoords;
                $Z = $Z / $numCoords;

                lng = Math.atan2($Y, $X);
                let $hyp = Math.sqrt($X * $X + $Y * $Y);
                lat = Math.atan2($Z, $hyp);

                return {
                    'lat': lat * 180 / Math.PI,
                    'lng': lng * 180 / Math.PI
                };
            }

            /* markerClusterer */
            async function markerClusterer() {
                var locations = [];
                var locationsAverage = [];

                $('.data_event').each(function() {
                    locations.push(JSON.parse(JSON.stringify({
                        'title': $(this).attr('data-title_event'),
                        'thumbnail': $(this).attr('data-thumbnail_event'),
                        'lat': $(this).attr('data-map_lat_event'),
                        'lng': $(this).attr('data-map_lng_event'),
                        'link': $(this).attr('data-link_event'),
                        'date': $(this).attr('data-date'),
                        'average_rating': $(this).attr('data-average_rating'),
                        'number_comment': $(this).attr('data-number_comment'),
                        'marker_price': $(this).attr('data-marker_price'),
                        'marker_date': $(this).attr('data-marker_date'),
                    })));
                    locationsAverage.push(JSON.parse(JSON.stringify({
                        'lat': parseFloat($(this).attr('data-map_lat_event')),
                        'lng': parseFloat($(this).attr('data-map_lng_event')),
                    })));
                });

                locationsAverage = averageGeolocation(locationsAverage);
                if (isNaN(locationsAverage.lat) || isNaN(locationsAverage.lng)) {
                    var map = new Map(document.getElementById('show_map'), {
                        center: {
                            lat: parseFloat(latDefault),
                            lng: parseFloat(lngDefault)
                        },
                        zoom: zoomMap,
                        gestureHandling: 'cooperative',
                        mapId: "SEARCH_MAP",
                    });
                } else {
                    var map = new Map(document.getElementById('show_map'), {
                        center: {
                            lat: parseFloat(locationsAverage.lat),
                            lng: parseFloat(locationsAverage.lng)
                        },
                        zoom: zoomMap,
                        gestureHandling: 'cooperative',
                        mapId: "SEARCH_MAP",
                    });
                }

                var infowindow = new google.maps.InfoWindow();
                var oms = new OverlappingMarkerSpiderfier(map, { 
                    markersWontMove: true,   // we promise not to move any markers, allowing optimizations
                    markersWontHide: true,   // we promise not to change visibility of any markers, allowing optimizations
                    basicFormatEvents: true  // allow the library to skip calculating advanced formatting information
                });

                var markers = locations.map(await function(location, i) {
                    var lat = parseFloat(location.lat);
                    var lng = parseFloat(location.lng);
                    var average_rating = location.average_rating;
                    var floor_num_rating = Math.floor(average_rating);
                    var empty_num_rating = 5 - Math.ceil(average_rating);
                    var number_comment = location.number_comment;
                    var star = '';

                    if (floor_num_rating > 0) {
                        for (var $i = 1; $i <= floor_num_rating; $i++) {
                            star += '<i class="fa fa-star"></i>';
                        }
                    }

                    if (floor_num_rating != Math.ceil(average_rating)) {
                        star += '<i class="fa fa-star-half-o" ></i>';
                    }

                    if (empty_num_rating > 0) {
                        for (var $j = 1; $j <= empty_num_rating; $j++) {
                            star += '<i class="fa fa-star-o"></i>';
                        }
                    }

                    if (!average_rating || average_rating == 0) {
                        var star = '';
                    } else {}

                    var show_comment = '';
                    if (number_comment != 0) {
                        show_comment += '<span class="number second_font">(' + location.number_comment + ')</span>';
                    }

                    var contentString = '<div class="iw_map">' +
                      '<a href="' + location.link + '">' +
                      '<img style="max-width: 100%; width: 280px;" src="' + location.thumbnail + '" >' +
                      '<h2 class="title"><a href="' + location.link + '">' + location.title + '</a></h2>' +
                      '<div class="event-time"><span class="event-icon"><i class="fa fa-calendar"></i></span>' +
                      '<span class="date">' + location.date + '</span></div>' +
                      '<span class="event_ratting"><span class="star">' + star + '</span> ' + show_comment + ' </span>' +
                      '</a>' + 
                      '</div>';

                    var latlngset = new google.maps.LatLng(lat, lng);

                    if (marker_option == 'icon') {
                        var marker = new AdvancedMarkerElement({
                            map: map,
                            position: latlngset,
                        });

                        if ( marker_icon ) {
                            marker['icon'] = marker_icon;
                        }

                    } else if(marker_option == 'date') {
                        var marker = new RichMarker({
                            position: latlngset,
                            map: map,
                            draggable: false,
                            content: '<div class="my-marker">'+location.marker_date+'</div>',
                            shadow: 'none'
                        });
                    } else {
                        var marker = new RichMarker({
                            position: latlngset,
                            map: map,
                            draggable: false,
                            content: '<div class="my-marker">'+location.marker_price+'</div>',
                            shadow: 'none'
                        });
                    }
                    
                    google.maps.event.addListener(marker, 'spider_click', function(e) {  // 'spider_click', not plain 'click'
                        
                        infowindow.setContent(contentString);
                        infowindow.open(map, marker);
                    });

                    oms.addMarker(marker);
                   
                });

                window.map = map;  // for debugging/exploratory use in console
                window.oms = oms;  // ditto
            }

            $.fn.event_map = async function(paramObject) {
                paramObject = $.extend({
                    lat: latDefault,
                    lng: lngDefault,
                    zoom: zoomMap
                }, paramObject);

                var input = document.getElementById('pac-input');

                var autocomplete = '';
                if( input ){
                    var autocomplete = new google.maps.places.Autocomplete(input);
                    if (options) {
                        autocomplete = new google.maps.places.Autocomplete(input, options);
                    }
                }

                var locations = [];

                $('.data_event').each(function() {
                    locations.push(JSON.parse(JSON.stringify({
                        'title': $(this).attr('data-title_event'),
                        'thumbnail': $(this).attr('data-thumbnail_event'),
                        'lat': $(this).attr('data-map_lat_event'),
                        'lng': $(this).attr('data-map_lng_event'),
                        'link': $(this).attr('data-link_event'),
                        'date': $(this).attr('data-date'),
                        'average_rating': $(this).attr('data-average_rating'),
                        'number_comment': $(this).attr('data-number_comment'),
                    })));
                });

                /* Add a marker clusterer to manage the markers. */
                if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                    markerClusterer();
                }

                if( autocomplete !== '' ){
                    autocomplete.addListener('place_changed', function() {
                        var place = autocomplete.getPlace();
                        if (!place.geometry) {
                            return;
                        }

                        var map = new google.maps.Map(document.getElementById('show_map'), {
                            center: {
                                lat: parseFloat(place.geometry.location.lat()),
                                lng: parseFloat(place.geometry.location.lng())
                            },
                            zoom: zoomMap,
                            gestureHandling: 'cooperative'
                        });

                        $("#map_name").val(place.name);
                        $("#map_address").val(place.formatted_address);

                        $('#map_lat').val(place.geometry.location.lat());
                        $('#map_lng').val(place.geometry.location.lng());

                        var point = {};
                        point.lat = place.geometry.location.lat();
                        point.lng = place.geometry.location.lng();
                        updatePointSearch(point);
                    });
                }
            }

            if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                var map_lat = parseFloat($('input#map_lat').val() ? $('input#map_lat').val() : latDefault);
                var map_lng = parseFloat($('input#map_lng').val() ? $('input#map_lng').val() : lngDefault);

                var $w_h = $(window).height();
                $("#show_map").css('height', $w_h );

                $("#show_map").event_map({
                    lat: map_lat,
                    lng: map_lng,
                    zoom: zoomMap
                });
            }

            /* Autocomplete Address */
            function updatePointSearch(point) {
                var that = $(document).find('.wrap_search_map');
                var keyword = that.find('[name="keywords"]').val();
                var cat = that.find('[name="cat"]').val();
                var sort = that.find('[name="sort"]').val();
                var radius = that.find('[name="radius"]').val();
                var radius_unit = that.find('[name="radius"]').data('radius-unit');

                var event_state = that.find('[name="event_state"]').val();
                var event_city = that.find('[name="event_city"]').val();
                var name_venue = that.find('[name="name_venue"]').val();

                var start_date = that.find('[name="start_date"]').val();
                var end_date = that.find('[name="end_date"]').val();
                var time = that.find('[name="time"]').val();

                var event_type = that.find('[name="event_type"]').val();

                var type = that.find('.search_result').attr('data-type');
                var column = that.find('.search_result').attr('data-column');
                var show_featured = that.find('.search_result').attr('data-show_featured');

                var result = that.find('.search_result');
                var $max_price = $(document).find('.wrap_range_price input[name="el_max_price"]').val();
                var $min_price = $(document).find('.wrap_range_price input[name="el_min_price"]').val();

                $(document).find('.wrap_search_map .wrap_load_more').show();
                $(document).find('.wrap_search_map .event_archive').hide();

                if( point.lat != '' &&  point.lng != '' ){
                    $('.wrap_search_map').find('.wrap_search_radius').show();
                }else{
                    $('.wrap_search_map').find('.wrap_search_radius').hidden();
                }

                $.post(ajax_object.ajax_url, {
                    action: 'el_search_map',
                    dataType: "json",
                    data: {
                        keyword: keyword,
                        cat: cat,
                        radius: radius,
                        radius_unit: radius_unit,
                        map_lat: point.lat,
                        map_lng: point.lng,
                        event_state: event_state,
                        event_city: event_city,
                        name_venue: name_venue,
                        start_date: start_date,
                        end_date: end_date,
                        time: time,
                        sort: sort,
                        type: type,
                        column: column,
                        event_type: event_type,
                        show_featured:show_featured,
                        max_price: $max_price,
                        min_price: $min_price,
                    },
                }, function(response) {
                    var json = JSON.parse(response);
                    var item = $(json.result).fadeOut(300).fadeIn(500);

                    result.html(item);
                    $(document).find('.listing_found').html(json.pagination);
                    $(document).find('.wrap_search_map .wrap_load_more').hide();

                    if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                        markerClusterer();
                    }
                });
            }

            /* taxonomy changed */
            var el_list_taxonomy = $('#el_search_map_list_taxonomy').val();

            if(el_list_taxonomy) {
                el_list_taxonomy = JSON.parse(el_list_taxonomy);
                // var data_taxonomy_custom = {};

                for( var key in el_list_taxonomy ) {
                    var name_taxonomy = el_list_taxonomy[key];

                    //change taxonomy customize
                    $(' .wrap_search_map [name="' + name_taxonomy + '"]' ).on('change', function() {
                        var taxo = $(this).attr('name');
                        var el_data_taxonomy_custom = $('#data_taxonomy_custom').val();

                        if(el_data_taxonomy_custom) {
                            el_data_taxonomy_custom = JSON.parse(el_data_taxonomy_custom);
                            el_data_taxonomy_custom[taxo] = $(this).val();
                            el_data_taxonomy_custom = JSON.stringify(el_data_taxonomy_custom);
                            $('#data_taxonomy_custom').val(el_data_taxonomy_custom);
                        }
                      
                        var keyword = $(this).parents('.wrap_search_map').find('[name="keywords"]').val();
                        var cat = $(this).parents('.wrap_search_map').find('[name="cat"]').val();
                        var sort = $(this).parents('.wrap_search_map').find('[name="sort"]').val();
                        var radius = $(this).parents('.wrap_search_map').find('[name="radius"]').val();
                        var radius_unit = $(this).parents('.wrap_search_map').find('[name="radius"]').data('radius-unit');

                        if ($(this).parents('.wrap_search_map').find('[name="map_address"]').val() == '') {
                            var map_lat = '';
                            var map_lng = '';
                        } else {
                            var map_lat = $(this).parents('.wrap_search_map').find('[name="map_lat"]').val();
                            var map_lng = $(this).parents('.wrap_search_map').find('[name="map_lng"]').val();
                        }

                        var event_state = $(this).parents('.wrap_search_map').find('[name="event_state"]').val();
                        var event_city = $(this).parents('.wrap_search_map').find('[name="event_city"]').val();
                        var name_venue = $(this).parents('.wrap_search_map').find('[name="name_venue"]').val();
                        var start_date = $(this).parents('.wrap_search_map').find('[name="start_date"]').val();
                        var end_date = $(this).parents('.wrap_search_map').find('[name="end_date"]').val();
                        var time = $(this).parents('.wrap_search_map').find('[name="time"]').val();
                        var type = $(this).parents('.wrap_search_map').find('.search_result').attr('data-type');
                        var column = $(this).parents('.wrap_search_map').find('.search_result').attr('data-column');
                        var show_featured = $(this).parents('.wrap_search_map').find('.search_result').attr('data-show_featured');
                        var event_type = $(this).parents('.wrap_search_map').find('[name="event_type"]').val();
                        var result = $(this).parents('.wrap_search_map').find('.search_result');
                        var $max_price = $(document).find('.wrap_range_price input[name="el_max_price"]').val();
                        var $min_price = $(document).find('.wrap_range_price input[name="el_min_price"]').val();

                        $(document).find('.wrap_search_map .wrap_load_more').show();
                        $(document).find('.wrap_search_map .event_archive').hide();

                        $.post(ajax_object.ajax_url, {
                            action: 'el_search_map',
                            dataType: "json",
                            data: {
                                keyword: keyword,
                                cat: cat,
                                radius: radius,
                                radius_unit: radius_unit,
                                map_lat: map_lat,
                                map_lng: map_lng,
                                event_state: event_state,
                                event_city: event_city,
                                name_venue: name_venue,
                                start_date: start_date,
                                end_date: end_date,
                                time: time,
                                sort: sort,
                                type: type,
                                column: column,
                                event_type: event_type,
                                el_data_taxonomy_custom: el_data_taxonomy_custom,
                                show_featured:show_featured,
                                max_price: $max_price,
                                min_price: $min_price,
                            },
                        }, function(response) {
                            var json = JSON.parse(response);
                            var item = $(json.result).fadeOut(300).fadeIn(500);

                            result.html(item);
                            $(document).find('.listing_found').html(json.pagination);
                            $(document).find('.wrap_search_map .wrap_load_more').hide();

                            if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                                markerClusterer();
                            }
                        });
                    });
                }
            }

            /* change value search */
            $(' .wrap_search_map [name="keywords"], .wrap_search_map [name="cat"],  .wrap_search_map [name="sort"], .wrap_search_map [name="time"], .wrap_search_map [name="event_state"], .wrap_search_map [name="event_city"], .wrap_search_map [name="name_venue"], .wrap_search_map .search_extra_field, .wrap_search_map [name="event_type"] ').on('change', function(event) {
                var keyword = $(this).parents('.wrap_search_map').find('[name="keywords"]').val();
                var cat = $(this).parents('.wrap_search_map').find('[name="cat"]').val();
                var sort = $(this).parents('.wrap_search_map').find('[name="sort"]').val();
                var radius = $(this).parents('.wrap_search_map').find('[name="radius"]').val();
                var radius_unit = $(this).parents('.wrap_search_map').find('[name="radius"]').data('radius-unit');
                if ($(this).parents('.wrap_search_map').find('[name="map_address"]').val() == '') {
                   var map_lat = '';
                   var map_lng = '';
                   $(this).parents('.wrap_search_map').find('.wrap_search_radius').hide();
                } else {
                   var map_lat = $(this).parents('.wrap_search_map').find('[name="map_lat"]').val();
                   var map_lng = $(this).parents('.wrap_search_map').find('[name="map_lng"]').val();
                   $(this).parents('.wrap_search_map').find('.wrap_search_radius').show();
                }
                var event_state = $(this).parents('.wrap_search_map').find('[name="event_state"]').val();
                var event_city = $(this).parents('.wrap_search_map').find('[name="event_city"]').val();

                var name_venue = $(this).parents('.wrap_search_map').find('[name="name_venue"]').val();
                var start_date = $(this).parents('.wrap_search_map').find('[name="start_date"]').val();
                var end_date = $(this).parents('.wrap_search_map').find('[name="end_date"]').val();
                var time = $(this).parents('.wrap_search_map').find('[name="time"]').val();

                var type = $(this).parents('.wrap_search_map').find('.search_result').attr('data-type');
                var column = $(this).parents('.wrap_search_map').find('.search_result').attr('data-column');
                 var show_featured = $(this).parents('.wrap_search_map').find('.search_result').attr('data-show_featured');
               
                var event_type = $(this).parents('.wrap_search_map').find('[name="event_type"]').val();

                var result = $(this).parents('.wrap_search_map').find('.search_result');
                var $max_price = $(document).find('.wrap_range_price input[name="el_max_price"]').val();
                var $min_price = $(document).find('.wrap_range_price input[name="el_min_price"]').val();

                $(document).find('.wrap_search_map .wrap_load_more').show();
                $(document).find('.wrap_search_map .event_archive').hide();

                var el_data_taxonomy_custom = $('#data_taxonomy_custom').val();

                $.post(ajax_object.ajax_url, {
                    action: 'el_search_map',
                    dataType: "json",
                    data: {
                        keyword: keyword,
                        cat: cat,
                        radius: radius,
                        radius_unit: radius_unit,
                        map_lat: map_lat,
                        map_lng: map_lng,
                        event_state: event_state,
                        event_city: event_city,
                        name_venue: name_venue,
                        start_date: start_date,
                        end_date: end_date,
                        time: time,
                        sort: sort,
                        type: type,
                        column: column,
                        event_type: event_type,
                        el_data_taxonomy_custom: el_data_taxonomy_custom,
                        show_featured:show_featured,
                        max_price: $max_price,
                        min_price: $min_price,
                    },
                }, function(response) {
                    var json = JSON.parse(response);
                    var item = $(json.result).fadeOut(300).fadeIn(500);

                    result.html(item);
                    $(document).find('.listing_found').html(json.pagination);
                    $(document).find('.wrap_search_map .wrap_load_more').hide();

                    if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                        markerClusterer();
                    }
                });
            });

            /* Load when redirect form searchs */
            function loadFormSearch() {
                var that = $(document).find('.wrap_search_map');
                var keyword = that.find('[name="keywords"]').val();
                var cat = that.find('[name="cat"]').val();
                var sort = that.find('[name="sort"]').val();
                var radius = that.find('[name="radius"]').val();
                var radius_unit = that.find('[name="radius"]').data('radius-unit');

                if (that.find('[name="map_address"]').val() == '') {
                    var map_lat = '';
                    var map_lng = '';
                } else {
                    var map_lat = that.find('[name="map_lat"]').val();
                    var map_lng = that.find('[name="map_lng"]').val();
                }

                var $max_price = $(document).find('.wrap_range_price input[name="el_max_price"]').val();
                var $min_price = $(document).find('.wrap_range_price input[name="el_min_price"]').val();
                var event_state = that.find('[name="event_state"]').val();
                var event_city = that.find('[name="event_city"]').val();
                var name_venue = that.find('[name="name_venue"]').val();
                var start_date = that.find('[name="start_date"]').val();
                var end_date = that.find('[name="end_date"]').val();
                var time = that.find('[name="time"]').val();
                var event_type = that.find('[name="event_type"]').val();
                var type = that.find('.search_result').attr('data-type');
                var column = that.find('.search_result').attr('data-column');
                var el_data_taxonomy_custom = $('#data_taxonomy_custom').val();
                var show_featured = that.find('.search_result').attr('data-show_featured');

                var result = that.find('.search_result');

                $(document).find('.wrap_search_map .wrap_load_more').show();
                $(document).find('.wrap_search_map .event_archive').hide();

                $.post(ajax_object.ajax_url, {
                    action: 'el_search_map',
                    dataType: "json",
                    data: {
                        keyword: keyword,
                        cat: cat,
                        map_lat: map_lat,
                        map_lng: map_lng,
                        event_state: event_state,
                        event_city: event_city,
                        name_venue: name_venue,
                        start_date: start_date,
                        end_date: end_date,
                        time: time,
                        sort: sort,
                        radius: radius,
                        radius_unit: radius_unit,
                        type: type,
                        column: column,
                        event_type: event_type,
                        el_data_taxonomy_custom: el_data_taxonomy_custom,
                        show_featured:show_featured,
                        max_price: $max_price,
                        min_price: $min_price,
                    },
                }, function(response) {
                    if (response != '') {
                        var json = JSON.parse(response);
                        var item = $(json.result).fadeOut(300).fadeIn(500);

                        result.html(item);
                        $(document).find('.listing_found').html(json.pagination);
                        $(document).find('.wrap_search_map .wrap_load_more').hide();

                        if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                            markerClusterer();
                        }
                    }
                });
            };

            if ($('form.job_filters').length > 0) {
                if (
                    ($('.wrap_search_map [name="keywords"]').val() != '' && $('.wrap_search_map [name="keywords"]').length > 0) ||
                    ($('.wrap_search_map [name="time"]').val() != '' && $('.wrap_search_map [name="time"]').length > 0) ||
                    ($('.wrap_search_map [name="start_date"]').val() != '' && $('.wrap_search_map [name="start_date"]').length > 0) ||
                    ($('.wrap_search_map [name="end_date"]').val() != '' && $('.wrap_search_map [name="end_date"]').length > 0) ||
                    ($('.wrap_search_map [name="cat"]').val() != '' && $('.wrap_search_map [name="cat"]').length > 0) ||
                    ($('.wrap_search_map #data_taxonomy_custom').val() != '' && $('.wrap_search_map #data_taxonomy_custom').length > 0) ||
                    ($('.wrap_search_map [name="name_venue"]').val() != '' && $('.wrap_search_map [name="name_venue"]').length > 0) ||
                    ($('.wrap_search_map [name="event_state"]').val() != '' && $('.wrap_search_map [name="event_state"]').length > 0) ||
                    ($('.wrap_search_map [name="event_city"]').val() != '' && $('.wrap_search_map [name="event_city"]').length > 0)
                ) {
                    loadFormSearch();
                }
            }

            var min_radius = 0; var max_radius = 0; var value_radius = 0;
            if( typeof map_range_radius_min !== 'undefined' ){
                min_radius = map_range_radius_min;
            }
            if( typeof map_range_radius_max  !== 'undefined' ){
                max_radius = map_range_radius_max;
            }
            if( typeof map_range_radius  !== 'undefined' ){
                value_radius = map_range_radius;
            }

            /* Slider Radius */
            $('#wrap_pointer').slider({
                min: min_radius,
                max: max_radius,
                step: 1,
                value: value_radius,
                change: function(event, ui) {
                    $(this).parents('.wrap_search_map').find('[name="radius"]').val(ui.value);
                    var radius_unit = $(this).parents('.wrap_search_map').find('[name="radius"]').data('radius-unit');
                    $(this).parents('.wrap_search_map').find('.result_radius').html(ui.value + radius_unit);

                    var keyword = $(this).parents('.wrap_search_map').find('[name="keywords"]').val();
                    var cat = $(this).parents('.wrap_search_map').find('[name="cat"]').val();
                    var sort = $(this).parents('.wrap_search_map').find('[name="sort"]').val();
                    var radius = ui.value;

                    if ($(this).parents('.wrap_search_map').find('[name="map_address"]').val() == '') {
                        var map_lat = '';
                        var map_lng = '';
                    } else {
                        var map_lat = $(this).parents('.wrap_search_map').find('[name="map_lat"]').val();
                        var map_lng = $(this).parents('.wrap_search_map').find('[name="map_lng"]').val();
                    }
                    var $max_price = $(document).find('.wrap_range_price input[name="el_max_price"]').val();
                    var $min_price = $(document).find('.wrap_range_price input[name="el_min_price"]').val();
                    var event_state = $(this).parents('.wrap_search_map').find('[name="event_state"]').val();
                    var event_city = $(this).parents('.wrap_search_map').find('[name="event_city"]').val();
                    var name_venue = $(this).parents('.wrap_search_map').find('[name="name_venue"]').val();
                    var start_date = $(this).parents('.wrap_search_map').find('[name="start_date"]').val();
                    var end_date = $(this).parents('.wrap_search_map').find('[name="end_date"]').val();
                    var time = $(this).parents('.wrap_search_map').find('[name="time"]').val();
                    var event_type = $(this).parents('.wrap_search_map').find('[name="event_type"]').val();
                    var type = $(this).parents('.wrap_search_map').find('.search_result').attr('data-type');
                    var column = $(this).parents('.wrap_search_map').find('.search_result').attr('data-column');
                    var show_featured = $(this).parents('.wrap_search_map').find('.search_result').attr('data-show_featured');
                    var result = $(this).parents('.wrap_search_map').find('.search_result');

                    $(document).find('.wrap_search_map .wrap_load_more').show();
                    $(document).find('.wrap_search_map .event_archive').hide();

                    $.post(ajax_object.ajax_url, {
                        action: 'el_search_map',
                        dataType: "json",
                        data: {
                            keyword: keyword,
                            cat: cat,
                            radius: radius,
                            radius_unit: radius_unit,
                            map_lat: map_lat,
                            map_lng: map_lng,
                            event_state: event_state,
                            event_city: event_city,
                            name_venue: name_venue,
                            start_date: start_date,
                            end_date: end_date,
                            time: time,
                            sort: sort,
                            type: type,
                            column: column,
                            event_type: event_type,
                            show_featured:show_featured,
                            max_price: $max_price,
                            min_price: $min_price,
                        },
                    }, function(response) {
                        var json = JSON.parse(response);
                        var item = $(json.result).fadeOut(300).fadeIn(500);

                        result.html(item);
                        $(document).find('.listing_found').html(json.pagination);

                        $(document).find('.wrap_search_map .wrap_load_more').hide();

                        if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                            markerClusterer();
                        }
                    });
                }
            });

            if ( $(document).find(".wrap_range_price").length ) {

                var $prefix = '';
                var $suffix = '';

                switch( currency_object.position ) {
                    case 'left':
                        $prefix = currency_object.currency;
                    break;
                    case 'left_space':
                        $prefix = currency_object.currency+' ';
                    break;
                    case 'right':
                        $suffix = currency_object.currency;
                    break;
                    case 'right_space':
                        $suffix = ' '+currency_object.currency;
                    break;
                    default:
                    // code block
                }

                var slider = document.getElementById("init_range_price");
                var range_price_box_wrap = $(document).find('.range_price_box_wrap');
                var $min_slider = range_price_box_wrap.attr('data-min');
                var $max_slider = range_price_box_wrap.attr('data-max');
                var $start_slider = range_price_box_wrap.attr('data-start');
                var $end_slider = range_price_box_wrap.attr('data-end');

                var $rtl = $('body').hasClass('rtl') ? 'rtl' : 'ltr';

                noUiSlider.create(slider, {
                    start: [ parseFloat( $start_slider ) , parseFloat( $end_slider ) ],
                    connect: true,
                    tooltips: true,
                    format: wNumb({
                        decimals: parseInt( currency_object.decimals ),
                        thousand: currency_object.thousand,
                        prefix: $prefix,
                        suffix: $suffix,
                    }),
                    direction: $rtl, 
                    range: {
                        'min': parseFloat( $min_slider ),
                        'max': parseFloat( $max_slider ),
                    }
                });

                $(".noUi-handle-lower").attr("aria-label", "price from");
                $(".noUi-handle-upper").attr("aria-label", "price to");

                slider.noUiSlider.on('change',function(values, handle, unencoded){
                    var $max = unencoded[1];
                    var $min = unencoded[0];

                    $(document).find('.wrap_range_price input[name="el_max_price"]').val($max);
                    $(document).find('.wrap_range_price input[name="el_min_price"]').val($min);
                    // AJAX HANDLE
                    var keyword = $(document).find('.wrap_search_map input[name="keywords"]').val();
                    var cat = $(document).find('.wrap_search_map input[name="cat"]').val();
                    var sort = $(document).find('.wrap_search_map input[name="sort"]').val();
                    var radius = $(document).find('.wrap_search_map input[name="radius"]').val();
                    var radius_unit = $(document).find('.wrap_search_map input[name="radius"]').data('radius-unit');

                    if ($(document).find('.wrap_search_map input[name="map_address"]').val() == '') {
                        var map_lat = '';
                        var map_lng = '';
                    } else {
                        var map_lat = $(document).find('.wrap_search_map input[name="map_lat"]').val();
                        var map_lng = $(document).find('.wrap_search_map input[name="map_lng"]').val();
                    }

                    var event_state = $(document).find('.wrap_search_map input[name="event_state"]').val();
                    var event_city  = $(document).find('.wrap_search_map input[name="event_city"]').val();
                    var name_venue  = $(document).find('.wrap_search_map input[name="name_venue"]').val();
                    var start_date  = $(document).find('.wrap_search_map input[name="start_date"]').val();
                    var end_date    = $(document).find('.wrap_search_map input[name="end_date"]').val();
                    var time        = $(document).find('.wrap_search_map input[name="time"]').val();
                    var event_type  = $(document).find('.wrap_search_map input[name="event_type"]').val();
                    var type        = $(document).find('.wrap_search_map .search_result').attr('data-type');
                    var column      = $(document).find('.wrap_search_map .search_result').attr('data-column');
                    var show_featured = $(document).find('.wrap_search_map .search_result').attr('data-show_featured');
                    var result      = $(document).find('.wrap_search_map .search_result');

                    $(document).find('.wrap_search_map .wrap_load_more').show();
                    $(document).find('.wrap_search_map .event_archive').hide();

                    $.post(ajax_object.ajax_url, {
                        action: 'el_search_map',
                        dataType: "json",
                        data: {
                            keyword: keyword,
                            cat: cat,
                            radius: radius,
                            radius_unit: radius_unit,
                            map_lat: map_lat,
                            map_lng: map_lng,
                            event_state: event_state,
                            event_city: event_city,
                            name_venue: name_venue,
                            start_date: start_date,
                            end_date: end_date,
                            time: time,
                            sort: sort,
                            type: type,
                            column: column,
                            event_type: event_type,
                            show_featured:show_featured,
                            max_price: $max,
                            min_price: $min,
                        },
                    }, function(response) {
                        var json = JSON.parse(response);
                        var item = $(json.result).fadeOut(300).fadeIn(500);

                        result.html(item);
                        $(document).find('.listing_found').html(json.pagination);

                        $(document).find('.wrap_search_map .wrap_load_more').hide();

                        if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                            markerClusterer();
                        }
                    });

                });
            }

            /* Ajax pagination search map */
            $(document).on('click', '.wrap_search_map .el-pagination span', function(e) {
                e.preventDefault();
                $(window).scrollTop(0);

                var that = $(this);
                var keyword = $(this).parents('.wrap_search_map').find('[name="keywords"]').val();
                var cat = $(this).parents('.wrap_search_map').find('[name="cat"]').val();
                var sort = $(this).parents('.wrap_search_map').find('[name="sort"]').val();
                var radius = $(this).parents('.wrap_search_map').find('[name="radius"]').val();
                var radius_unit = $(this).parents('.wrap_search_map').find('[name="radius"]').data('radius-unit');
                if ($(this).parents('.wrap_search_map').find('[name="map_address"]').val() == '') {
                    var map_lat = '';
                    var map_lng = '';
                } else {
                    var map_lat = $(this).parents('.wrap_search_map').find('[name="map_lat"]').val();
                    var map_lng = $(this).parents('.wrap_search_map').find('[name="map_lng"]').val();
                }
                var $max_price = $(document).find('.wrap_range_price input[name="el_max_price"]').val();
                var $min_price = $(document).find('.wrap_range_price input[name="el_min_price"]').val();
                var event_state = $(this).parents('.wrap_search_map').find('[name="event_state"]').val();
                var event_city = $(this).parents('.wrap_search_map').find('[name="event_city"]').val();
                var name_venue = $(this).parents('.wrap_search_map').find('[name="name_venue"]').val();
                var start_date = $(this).parents('.wrap_search_map').find('[name="start_date"]').val();
                var end_date = $(this).parents('.wrap_search_map').find('[name="end_date"]').val();
                var time = $(this).parents('.wrap_search_map').find('[name="time"]').val();
                var event_type = $(this).parents('.wrap_search_map').find('[name="event_type"]').val();
                var type = $(this).parents('.wrap_search_map').find('.search_result').attr('data-type');
                var column = $(this).parents('.wrap_search_map').find('.search_result').attr('data-column');
                var show_featured = $(this).parents('.wrap_search_map').find('.search_result').attr('data-show_featured');
                var paged = $(this).attr('data-paged');
                var result = $(this).parents('.wrap_search_map').find('.search_result');
             
                $(document).find('.wrap_search_map .wrap_load_more').show();
                $(document).find('.wrap_search_map .event_archive').hide();

                $.post(ajax_object.ajax_url, {
                    action: 'el_search_map',
                    dataType: "json",
                    data: {
                        keyword: keyword,
                        cat: cat,
                        radius: radius,
                        radius_unit: radius_unit,
                        map_lat: map_lat,
                        map_lng: map_lng,
                        event_state: event_state,
                        event_city: event_city,
                        name_venue: name_venue,
                        start_date: start_date,
                        end_date: end_date,
                        time: time,
                        sort: sort,
                        type: type,
                        column: column,
                        event_type: event_type,
                        paged: paged,
                        show_featured:show_featured,
                        max_price: $max_price,
                        min_price: $min_price,
                    },
                }, function(response) {
                    var json = JSON.parse(response);
                    var item = $(json.result).fadeOut(300).fadeIn(500);

                    result.html(item);
                    $(document).find('.listing_found').html(json.pagination);
                    $(document).find('.wrap_search_map .wrap_load_more').hide();

                    if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                        markerClusterer();
                    }
                });
            });

            /* Click locate me */
            $('.locate-me').on('click', function() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(showPosition);
                } else {
                    x.innerHTML = "Geolocation is not supported by this browser.";
                }
            });

            /* Position locate me */
            function showPosition(position) {
                var map_lat = position.coords.latitude;
                var map_lng = position.coords.longitude;

                if ( map_lat != '' && map_lng != '') {
                    $(document).find('.wrap_search_map').find('.wrap_search_radius').show();
                } else {
                    $(document).find('.wrap_search_map').find('.wrap_search_radius').hide();
                }

                $('[name="map_lat"]').attr('value', map_lat);
                $('[name="map_lng"]').attr('value', map_lng);

                var latlng = {
                    lat: parseFloat(map_lat),
                    lng: parseFloat(map_lng)
                };
                var geocoder = new google.maps.Geocoder;
                geocoder.geocode({
                    'location': latlng
                }, function(results, status) {
                    if (status === 'OK') {
                        if (results[0]) {
                            $('.wrap_search_map [name="map_address"]').val(results[0].formatted_address);
                        } else {
                            window.alert('No results found');
                        }
                    } else {
                        window.alert('Geocoder failed due to: ' + status);
                    }
                });

                // Ajax load event
                var that = $(document).find('.wrap_search_map');
                var keyword = that.find('[name="keywords"]').val();
                var cat = that.find('[name="cat"]').val();
                var sort = that.find('[name="sort"]').val();
                var radius = that.find('[name="radius"]').val();
                var radius_unit = that.find('[name="radius"]').data('radius-unit');
                var event_state = that.find('[name="event_state"]').val();
                var event_city = that.find('[name="event_city"]').val();
                var name_venue = that.find('[name="name_venue"]').val();
                var start_date = that.find('[name="start_date"]').val();
                var end_date = that.find('[name="end_date"]').val();
                var time = that.find('[name="time"]').val();
                var event_type = that.find('[name="event_type"]').val();
                var type = that.find('.search_result').attr('data-type');
                var column = that.find('.search_result').attr('data-column');
                var show_featured = that.find('.search_result').attr('data-show_featured');
                var result = that.find('.search_result');
                var $max_price = $(document).find('.wrap_range_price input[name="el_max_price"]').val();
                var $min_price = $(document).find('.wrap_range_price input[name="el_min_price"]').val();

                $(document).find('.wrap_search_map .wrap_load_more').show();
                $(document).find('.wrap_search_map .event_archive').hide();

                $.post(ajax_object.ajax_url, {
                    action: 'el_search_map',
                    dataType: "json",
                    data: {
                        keyword: keyword,
                        cat: cat,
                        radius: radius,
                        radius_unit: radius_unit,
                        map_lat: map_lat,
                        map_lng: map_lng,
                        event_state: event_state,
                        event_city: event_city,
                        name_venue: name_venue,
                        start_date: start_date,
                        end_date: end_date,
                        time: time,
                        sort: sort,
                        type: type,
                        column: column,
                        event_type: event_type,
                        show_featured:show_featured,
                        max_price: $max_price,
                        min_price: $min_price,
                    },
                }, function(response) {
                    var json = JSON.parse(response);
                    var item = $(json.result).fadeOut(300).fadeIn(500);

                    result.html(item);
                    $(document).find('.listing_found').html(json.pagination);
                    $(document).find('.wrap_search_map .wrap_load_more').hide();

                    if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                        markerClusterer();
                    }
                });
            }

            /* Change Date */
            $('.wrap_search_map .el_select_date').each(function() {
                var format      = $(this).attr('data-format');
                var first_day   = $(this).data('firstday');

                $(this).datepicker({
                    dateFormat: format,
                    firstDay: first_day,
                    changeMonth: true,
                    changeYear: true,
                    onSelect: function() {
                        $('.wrap_search_map .wrap_search_time').val(" ");
                        var $option_default = $('.wrap_search_time option').first().html();
                        $('.wrap_search_map .wrap_search_time option').first().attr('selected', 'selected');
                        $('.wrap_search_map .wrap_search_time span.select2-selection__rendered').html($option_default);
                        $('.wrap_search_map .wrap_search_time span.select2-selection__rendered').attr('title', $option_default);

                        var keyword = $(this).parents('.wrap_search_map').find('[name="keywords"]').val();
                        var cat = $(this).parents('.wrap_search_map').find('[name="cat"]').val();
                        var sort = $(this).parents('.wrap_search_map').find('[name="sort"]').val();
                        var radius = $(this).parents('.wrap_search_map').find('[name="radius"]').val();
                        var radius_unit = $(this).parents('.wrap_search_map').find('[name="radius"]').data('radius-unit');
                        if ($(this).parents('.wrap_search_map').find('[name="map_address"]').val() == '') {
                            var map_lat = '';
                            var map_lng = '';
                        } else {
                            var map_lat = $(this).parents('.wrap_search_map').find('[name="map_lat"]').val();
                            var map_lng = $(this).parents('.wrap_search_map').find('[name="map_lng"]').val();
                        }

                        var $max_price = $(document).find('.wrap_range_price input[name="el_max_price"]').val();
                        var $min_price = $(document).find('.wrap_range_price input[name="el_min_price"]').val();
                        var event_state = $(this).parents('.wrap_search_map').find('[name="event_state"]').val();
                        var event_city = $(this).parents('.wrap_search_map').find('[name="event_city"]').val();
                        var name_venue = $(this).parents('.wrap_search_map').find('[name="name_venue"]').val();
                        var start_date = $(this).parents('.wrap_search_map').find('[name="start_date"]').val();
                        var end_date = $(this).parents('.wrap_search_map').find('[name="end_date"]').val();
                        var time = $(this).parents('.wrap_search_map').find('[name="time"]').val();
                        var event_type = $(this).parents('.wrap_search_map').find('[name="event_type"]').val();
                        var type = $(this).parents('.wrap_search_map').find('.search_result').attr('data-type');
                        var column = $(this).parents('.wrap_search_map').find('.search_result').attr('data-column');
                        var show_featured = $(this).parents('.wrap_search_map').find('.search_result').attr('data-show_featured');
                        var result = $(this).parents('.wrap_search_map').find('.search_result');

                        $(document).find('.wrap_search_map .wrap_load_more').show();
                        $(document).find('.wrap_search_map .event_archive').hide();

                        $.post(ajax_object.ajax_url, {
                            action: 'el_search_map',
                            dataType: "json",
                            data: {
                                keyword: keyword,
                                cat: cat,
                                radius: radius,
                                radius_unit: radius_unit,
                                map_lat: map_lat,
                                map_lng: map_lng,
                                event_state: event_state,
                                event_city: event_city,
                                name_venue: name_venue,
                                start_date: start_date,
                                end_date: end_date,
                                time: time,
                                sort: sort,
                                type: type,
                                column: column,
                                event_type: event_type,
                                show_featured:show_featured,
                                max_price: $max_price,
                                min_price: $min_price,
                            },
                        }, function(response) {
                            var json = JSON.parse(response);
                            var item = $(json.result).fadeOut(300).fadeIn(500);

                            result.html(item);
                            $(document).find('.listing_found').html(json.pagination);
                            $(document).find('.wrap_search_map .wrap_load_more').hide();

                            if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                                markerClusterer();
                            }
                        });
                    }
                });
            });

            /* Change Time */
            $('.wrap_search_map .wrap_search_time select').on('change', function() {
                $('.wrap_search_start_date input').val("");
                $('.wrap_search_end_date input').val("");
            });

            /* Toggle filters mobile */
            $('.wrap_search_map .toggle_filters').on( 'click' ,function() {
                $(this).parents('.wrap_search_map').find('.job_filters').slideToggle(300);
                $(this).find('.icon_down').toggle(300);
                $(this).find('.icon_up').toggle(300);
                $(this).toggleClass('active');
            });

            /* Change tabs mobile */
            $('.wrap_search_map .toggle_wrap span').on('click', function() {
                let id = $(this).attr('data-value');

                $('.wrap_search_map #show_map, .wrap_search_map #result_search').hide();
                $('#' + id).show();

                $('.wrap_search_map .toggle_wrap span').removeClass('active');
                $(this).addClass('active');
            });

            /* Autocomplete Venue */
            $('.wrap_search_map .venue input').autocomplete({
                source: function(request, response) {
                    $.ajax({
                        url: ajax_object.ajax_url,
                        type: 'POST',
                        dataType: "json",
                        data: {
                            action: 'el_load_venue',
                            keyword: request.term,
                        },
                        success: function(data) {
                            response(data);
                        },
                    })
                },
                select: function(e, i) {
                    var keyword = $(this).parents('.wrap_search_map').find('[name="keywords"]').val();
                    var cat = $(this).parents('.wrap_search_map').find('[name="cat"]').val();
                    var sort = $(this).parents('.wrap_search_map').find('[name="sort"]').val();
                    var radius = $(this).parents('.wrap_search_map').find('[name="radius"]').val();
                    var radius_unit = $(this).parents('.wrap_search_map').find('[name="radius"]').data('radius-unit');

                    if ($(this).parents('.wrap_search_map').find('[name="map_address"]').val() == '') {
                        var map_lat = '';
                        var map_lng = '';
                    } else {
                        var map_lat = $(this).parents('.wrap_search_map').find('[name="map_lat"]').val();
                        var map_lng = $(this).parents('.wrap_search_map').find('[name="map_lng"]').val();
                    }

                    var $max_price = $(document).find('.wrap_range_price input[name="el_max_price"]').val();
                    var $min_price = $(document).find('.wrap_range_price input[name="el_min_price"]').val();
                    var event_state = $(this).parents('.wrap_search_map').find('[name="event_state"]').val();
                    var event_city = $(this).parents('.wrap_search_map').find('[name="event_city"]').val();
                    // var name_venue = $(this).parents('.wrap_search_map').find('[name="name_venue"]').val();
                    var start_date = $(this).parents('.wrap_search_map').find('[name="start_date"]').val();
                    var end_date = $(this).parents('.wrap_search_map').find('[name="end_date"]').val();
                    var time = $(this).parents('.wrap_search_map').find('[name="time"]').val();
                    var event_type = $(this).parents('.wrap_search_map').find('[name="event_type"]').val();
                    var type = $(this).parents('.wrap_search_map').find('.search_result').attr('data-type');
                    var column = $(this).parents('.wrap_search_map').find('.search_result').attr('data-column');
                    var show_featured = $(this).parents('.wrap_search_map').find('.search_result').attr('data-show_featured');
                    var result = $(this).parents('.wrap_search_map').find('.search_result');

                    $(document).find('.wrap_search_map .wrap_load_more').show();
                    $(document).find('.wrap_search_map .event_archive').hide();

                    $.post(ajax_object.ajax_url, {
                        action: 'el_search_map',
                        dataType: "json",
                        data: {
                            keyword: keyword,
                            cat: cat,
                            radius: radius,
                            radius_unit: radius_unit,
                            map_lat: map_lat,
                            map_lng: map_lng,
                            event_state: event_state,
                            event_city: event_city,
                            name_venue: i.item.value,
                            start_date: start_date,
                            end_date: end_date,
                            time: time,
                            sort: sort,
                            type: type,
                            column: column,
                            event_type: event_type,
                            show_featured:show_featured,
                            max_price: $max_price,
                            min_price: $min_price,
                        },
                    }, function(response) {
                        var json = JSON.parse(response);
                        var item = $(json.result).fadeOut(300).fadeIn(500);

                        result.html(item);
                        $(document).find('.listing_found').html(json.pagination);
                        $(document).find('.wrap_search_map .wrap_load_more').hide();

                        if (typeof google !== 'undefined' && $("#show_map").length > 0) {
                            markerClusterer();
                        }
                    });
                },
                delay: 0,
            });

            /* Calculator height google map */
            function heightGoogleMap() {
                let h_Header        = $(document).find('.ovaheader').outerHeight();
                let h_Footer        = $(document).find('.ovafooter').outerHeight();
                let h_Wpadminbar    = $(document).find('#wpadminbar').outerHeight();

                $(document).find('.wrap_search_map #show_map').css({
                    'height': $(window).height() - h_Wpadminbar - h_Header,
                    'top': h_Header + h_Wpadminbar,
                    'bottom': h_Footer,
                });
            }
            heightGoogleMap();
            $(window).resize(function() {
                heightGoogleMap();
            });
        },

        event_map: async function() {
            if ( $('#event_map').length && (typeof google !== 'undefined') ) {
                var lat         = parseFloat($("#event_map").data('lat'));
                var lng         = parseFloat($("#event_map").data('lng'));
                var address     = $("#event_map").data('address');
                var zoom        = parseInt($("#event_map").data('zoom'));
                var infoWindow  = new google.maps.InfoWindow();
                const { Map } = await google.maps.importLibrary("maps");
                const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
                var loc = {
                    lat: lat,
                    lng: lng
                };
                var map = new Map(document.getElementById('event_map'), {
                    zoom: zoom,
                    center: loc,
                    scrollwheel: false,
                    mapId: "EVENT_MAP",
                });

                var marker = new AdvancedMarkerElement({
                    position: loc,
                    map: map,
                });

                google.maps.event.addListener(marker, 'click', (function(marker) {
                    return function() {
                        infoWindow.setContent(address);
                        infoWindow.open(map, marker);
                    }
                })(marker));
            }
        },

        el_single_send_mail_report: function(){
            $('.single-event').on('click', '.icon_close', function(){
                $(this).closest('.el_form_report').css({'display':'none'});
                $('.single-event .act_share .el_wrap_form_report').css({'z-index':'-1'});
            });

            $('.el_report_link').on('click', function(e){
                e.preventDefault();

                var myaccount_page  = $(this).data('myaccount_page');
                var id_event        = $(this).data('id_event');
                
                $('.single-event .act_share .el_wrap_form_report').empty();
                $('.single-event .act_share .el_wrap_form_report').css({'z-index':'-1'});

                $.post(ajax_object.ajax_url, {
                    action: 'el_check_login_report',
                    id_event: id_event,
                }, function(response) {
                    if( response !== 'false' ) {
                        $('.single-event .act_share .el_wrap_form_report').append(response);
                        $('.single-event .act_share .el_wrap_form_report').css({'z-index':'14'});
                    } else {
                        var current_url = window.location.origin + window.location.pathname + window.location.search;
                        window.location.href = myaccount_page + '?redirect_to=' + current_url;
                    }
                });
            });

            $(".single-event").on('click','.submit-sendmail-report', function(e) {
                e.preventDefault(e);
                var id_event    = $(this).data('id_event');
                var message     = $("textarea[name=el_message]").val();
                var flag        = "";

                if (message == '') {
                    $('.el_report .el-notify p.error-require').css('display', 'block');
                    return false;
                }

                $('.el_report .el-notify p').css('display', 'none');
                $('.el_report  .submit-load-more').css('z-index', '9');
                $('.el_report .submit-load-more').css('display', 'block');
                $.post(ajax_object.ajax_url, {
                    action: 'el_single_send_mail_report',
                    data: {
                        id_event: id_event,
                        message: message,
                    },
                }, function(response) {
                    var data = response;
                    if (data == 'true') {
                        $('.el_report .el-notify p.success').css('display', 'block');
                        $('.el_report .submit-load-more').css('z-index', '-1');
                        $('.el_report .submit-load-more').css('display', 'none');
                    } else {
                        $('.el_report .el-notify p.error').css('display', 'block');
                        $('.el_report .submit-load-more').css('z-index', '-1');
                        $('.el_report .submit-load-more').css('display', 'none');
                    }
                });
            });
        },

        el_single_send_mail_vendor: function() {
            $('.send_mess').on('click', function(e) {
                e.preventDefault();

                $(this).css('display', 'none');
                $(this).siblings('.el-sendmail-author').css('height', '100%');
            });

            $(document).on('click', '.submit-sendmail', function(e) {
                e.preventDefault(e);

                $('.info_user .el-notify p').css('display', 'none');
                $('.info_user .el-sendmail-author .submit-load-more').css({'z-index': '9','opacity':'1'});
                
                var id_event    = $(this).data('id');
                var name        = $("input[name=name_customer]").val();
                var email       = $("input[name=email_customer]").val();
                var phone       = $("input[name=phone_customer]").val();
                var subject     = $("input[name=subject_customer]").val();
                var content     = $("textarea[name=content]").val();
                // validate input required
                if ( name == '' || email == '' || phone == '' || subject == '' || content == '' ) {
                    $('.info_user .el-notify p.error-require').css('display', 'block');
                    $('.info_user .el-sendmail-author .submit-load-more').css({'z-index': '-1','opacity':'0'});
                    return false;
                }
                // recapcha v2
                var recapcha_send_mail_vendor = async function(){

                    var $token      = $(document).find(".el-sendmail-author #ovaevent_recapcha_token").val();
                    var $secret_key = $(document).find('.el-sendmail-author #ovaevent_recapcha_token').data('key');

                    if ( $secret_key && $token ) {
                        await $.post(ajax_object.ajax_url, {
                            action: 'el_verify_google_recapcha',
                            data : {
                                'secret': $secret_key,
                                'response': $token
                            }
                        },await function( response ) {
                            if ( response == 0 ) {
                                $(document).find('.el-sendmail-author #ovaevent_recapcha_token').attr("data-pass","no");
                            } else {
                                $(document).find('.el-sendmail-author #ovaevent_recapcha_token').attr("data-pass","yes");
                            }
                        },
                        );
                    } else {
                        $(document).find('.el-sendmail-author #ovaevent_recapcha_token').attr("data-pass","no");
                    }

                    let $check_recapcha = $(document).find('.el-sendmail-author #ovaevent_recapcha_token').attr("data-pass");

                    if ( $check_recapcha === "yes" ) {
                        await single_send_mail_vendor();
                    } else {
                        $('.info_user .el-notify p.recapcha-vetify').css('display', 'block');
                        $('.info_user .el-sendmail-author .submit-load-more').css({'z-index': '-1','opacity':'0'});
                        return false;
                    }
                }
                // recapcha v3
                var recapcha_v3_send_mail_vendor = async function(){
                    var $token      = $(document).find('.el-sendmail-author input[name="g-recaptcha-response"]').val();
                    var $secret_key = $(document).find('.el-sendmail-author input[name="g-recaptcha-response"]').data('key');  

                    if ( $secret_key && $token ) {
                        await $.post(ajax_object.ajax_url, {
                            action: 'el_verify_google_recapcha',
                            data : {
                                'secret': $secret_key,
                                'response': $token
                            }
                        },await function( response ) {
                            if ( response == 0 ) {
                                $(document).find('.el-sendmail-author input[name="g-recaptcha-response"]').attr("data-pass","no");
                            } else {
                                $(document).find('.el-sendmail-author input[name="g-recaptcha-response"]').attr("data-pass","yes");
                            }
                        },
                        );
                    } else {
                        $(document).find('.el-sendmail-author input[name="g-recaptcha-response"]').attr("data-pass","no");
                    }

                    let $check_recapcha = $(document).find('.el-sendmail-author input[name="g-recaptcha-response"]').attr("data-pass");

                    if ( $check_recapcha === "yes" ) {
                        await single_send_mail_vendor();
                    } else {
                        $('.info_user .el-notify p.recapcha-vetify').css('display', 'block');
                        $('.info_user .el-sendmail-author .submit-load-more').css({'z-index': '-1','opacity':'0'});
                        return false;
                    }
                }
                // send mail vendor
                var single_send_mail_vendor = async function(){
                    await $.post(ajax_object.ajax_url, {
                        action: 'el_single_send_mail_vendor',
                        data: {
                            id_event: id_event,
                            name: name,
                            email: email,
                            phone: phone,
                            subject: subject,
                            content: content,
                        },
                    }, await function(response) {
                        var data = response;

                        if (data == 'true') {
                            $('.info_user .el-notify p.success').css('display', 'block');
                            $('.info_user .el-sendmail-author .submit-load-more').css({'z-index': '-1','opacity':'0'});
                            // clear form
                            $(document).find('.el-sendmail-author input[name="subject_customer"]').val("");
                            $(document).find('.el-sendmail-author textarea[name="content"]').val("");
                        } else {
                            $('.info_user .el-notify p.error').css('display', 'block');
                            $('.info_user .el-sendmail-author .submit-load-more').css({'z-index': '-1','opacity':'0'});
                        }
                    });
                }

                if ( $(document).find('.el-sendmail-author #ovaevent-recaptcha-wrapper').length ) {
                    recapcha_send_mail_vendor();
                }  else if ( $(document).find('.el-sendmail-author input[name="g-recaptcha-response"]').length ){
                    recapcha_v3_send_mail_vendor();
                } else {
                    single_send_mail_vendor();
                }
                
            });
        },

        el_single_click_book: function() {
            var h_wpadmin = 0;
            var h_ovamenu = 0;

            if($('#wpadminbar').length){
                h_wpadmin = $('#wpadminbar').outerHeight();
            };

            if( $(document).find('.ovamenu_shrink .elementor-container').length ){
                h_ovamenu = $(document).find('.ovamenu_shrink .elementor-container').height();
            }

            if ($(".title-event-single").hasClass("no-empty-ticket")) {
                $(".booking_event_button").on('click', function(e) {
                    e.preventDefault();
                    var $external_link = $('#booking_event').attr('data-external-link');
                    if ( $(".ticket-calendar").length ) {
                        var calendar_items = $('#booking_event .item-calendar-ticket');

                        // Desktop Screen
                        if ( $(window).width() > 767 ) {

                            if ( calendar_items.length > 1 ) {
                                $('html, body').animate({
                                    scrollTop: ($(".ticket-calendar").offset().top - h_wpadmin - h_ovamenu)
                                }, 500);
                            } else {
                                var url = calendar_items.find('.button-book a').attr('href');

                                if ( url ) {
                                    if ( $external_link == 'ticket_external_link' ) {
                                        window.open( url, '_blank' );
                                    } else {
                                        window.open( url, '_self' );
                                    }
                                    
                                } else {
                                    $('html, body').animate({
                                        scrollTop: ($(".ticket-calendar").offset().top)
                                    }, 500);
                                }
                            }

                        } else {
                            // Mobile Screen
                            if ( calendar_items.length > 1 ) {

                                $('html, body').animate({
                                    scrollTop: ($(".ticket-calendar").offset().top)
                                }, 500);

                            } else {
                                var url = calendar_items.find('.button-book a').attr('href');

                                if ( url ) {
                                    if ( $external_link == 'ticket_external_link' ) {
                                        window.open( url, '_blank' );
                                    } else {
                                        window.open( url, '_self' );
                                    }
                                } else {
                                    $('html, body').animate({
                                        scrollTop: ($(".ticket-calendar").offset().top)
                                    }, 500);
                                }
                            }

                            
                        }
                    }
                });
            } else {
                $(".booking_event_button").css('display', 'none');
            }

            $("#event_booking_single_button").on('click', function(e) {
                // e.preventDefault();
                var url_scroll = $(this).data('url');

                if ( 1 != url_scroll ) {
                    if ($(".ticket-calendar").length) {
                        if ($(window).width() > 767) {
                            $('html, body').animate({
                                scrollTop: ($(".ticket-calendar").offset().top - h_wpadmin - h_ovamenu)
                            }, 500);
                        } else {
                            $('html, body').animate({
                                scrollTop: ($(".ticket-calendar").offset().top)
                            }, 500);
                        }
                    }
                }
            });
        },

        /*** Update Role at My account >> Profile >> Role tab ***/
        update_role: function() {
            $('#author_role input[name="el_update_role"]').off().on('click', function(e) {
                e.preventDefault();
                
                var that = $(this);
                
                var el_update_role_nonce = that.closest('#author_role').find('#el_update_role_nonce').val();
                var role = that.data( 'role' );
                var $approve = that.attr('data-approve');

                
                if ( $approve != 'yes' ) {

                    $.post(ajax_object.ajax_url, {
                        action: 'el_update_role',
                        data: {
                            el_update_role_nonce: el_update_role_nonce,
                            role: role,
                        },
                    }, function(response) {
                        if ( response && response != 'true' ) {
                            window.location.replace( response );
                        } else if ( response == 'true' ) {
                            location.reload();
                        }
                    });

                } else {
                    that.attr("disabled", "disabled");
                    $(document).find('#author_role #el_save_role .loader').addClass('is-active');
                    $(document).find('#author_role #el_save_role .ova_result_update_to_vendor').html('');
                    $(document).find('#author_role #el_save_role .ova_result_update_to_vendor').removeClass('success');
                    $(document).find('#author_role #el_save_role .ova_result_update_to_vendor').removeClass('error');
                    $(document).find('#author_role #el_save_role .ova_result_update_to_vendor').html('');
                    EL_Frontend.el_check_vendor_field_required( el_update_role_nonce );
                }
                
                return false;

            });
        },

        el_check_vendor_field_required: function( $nonce ){
            var data = {
                'action': 'el_check_vendor_field_required',
                'nonce': $nonce,
            };

            $.post(ajax_object.ajax_url, data, function(response) {
                response = JSON.parse( response );
                if ( response.status ) {
                    $(document).find('#author_role #el_save_role .ova_result_update_to_vendor').append('<p class="'+response.status+'">'+response.mess+'</p>');

                    if ( response.send_mail == 'error' ) {
                        $(document).find('#author_role #el_save_role .ova_result_update_to_vendor').append('<p class="error">'+response.mail_mess+'</p>');
                    }

                    if  (response.reload_page == 'yes' ) {
                        window.location.reload();
                    }

                    if ( response.show_vendor_field == 'yes' ) {
                        if ( response.vendor_field ) {
                            $(document).find('#el_save_profile .ova_profile_custom_field_wrapper').html('');
                            $(document).find('#el_save_profile .ova_profile_custom_field_wrapper').addClass('only_show_vendor');
                            $(document).find('#el_save_profile .ova_profile_custom_field_wrapper').html(response.vendor_field);
                            $([document.documentElement, document.body]).animate({
                                scrollTop: $("#el_save_profile .ova_profile_custom_field_wrapper").offset().top
                            }, 2000);
                        }
                        
                    }
                }
                $(document).find('#author_role #el_save_role .loader').removeClass('is-active');
                $(document).find('#author_role input[name="el_update_role"]').removeAttr("disabled");
            });
        },

        el_cancel_booking: function() {
            $('button.cancel-booking').off().on('click', function() {

                var prompt_msg = $(this).data('prompt-msg');
                
                if ( confirm(prompt_msg) == true ) {
                    var id_booking = $(this).attr("data-id-booking");
                    var el_cancel_booking_nonce = $(this).attr('data-nonce');

                    $(this).siblings(".submit-load-more.cancel-booking").css({"z-index":"1"});

                    $.ajax({
                        url: ajax_object.ajax_url,
                        type: 'POST',
                        data: {
                            action: 'el_cancel_booking',
                            el_cancel_booking_nonce: el_cancel_booking_nonce,
                            id_booking: id_booking,
                        },
                        success:function(response) {
                            var data = JSON.parse(response);

                            if (data.status == "error") {
                                alert( data.msg );
                                $('.cancel-booking').siblings(".submit-load-more.cancel-booking").css({"z-index":"-1"});
                            } else {
                                alert( data.msg );
                                $('.cancel-booking').siblings(".submit-load-more.cancel-booking").css({"z-index":"-1"});
                                $('.booking_'+id_booking + ' .wp-button-my-booking').remove();
                                window.location.reload( true );
                            }
                        },
                    });
                }
            });
        },
     
        el_choose_event_type: function(){
            if ( $( "input[name='ova_mb_event_event_type']" ).length > 0 ) {
                var event_type = $("input[name='ova_mb_event_event_type']:checked").val();

                if ( event_type == 'online' ) {
                    $( ".vendor_edit_event .location" ).hide();
                    $( ".vendor_edit_event" ).addClass('online_event');
                } else if ( event_type == 'classic' ) {
                    $( ".vendor_edit_event .location" ).show();
                    $( ".vendor_edit_event" ).removeClass('online_event');
                }

                $( "input[name='ova_mb_event_event_type']" ).on( 'click', function() {
                    event_type = $("input[name='ova_mb_event_event_type']:checked").val();
                    
                    if ( event_type == 'online' ) {
                        $( ".vendor_edit_event .location" ).hide();
                        $( ".vendor_edit_event" ).addClass('online_event');
                    } else if ( event_type == 'classic' ) {
                        $( ".vendor_edit_event .location" ).show();
                        $( ".vendor_edit_event" ).removeClass('online_event');
                    }
                });
            }
        },
  
        el_choose_link_ticket: function(){
            if ( $( "input[name='ova_mb_event_ticket_link']" ).length > 0 ) {
                var ticket_link = $("input[name='ova_mb_event_ticket_link']:checked").val();

                if ( ticket_link == 'ticket_internal_link' ) {
                    $( ".vendor_edit_event .ticket_internal_link" ).show();
                    $( ".vendor_edit_event .ticket_external_link" ).hide();
                } else if ( ticket_link == 'ticket_external_link' ) {
                    $( ".vendor_edit_event .ticket_internal_link" ).hide();
                    $( ".vendor_edit_event .ticket_external_link" ).show();
                }

                $( "input[name='ova_mb_event_ticket_link']" ).on( 'click', function(){
                    ticket_link = $("input[name='ova_mb_event_ticket_link']:checked").val();
                  
                    if ( ticket_link == 'ticket_internal_link' ) {
                        $( ".vendor_edit_event .ticket_internal_link" ).show();
                        $( ".vendor_edit_event .ticket_external_link" ).hide();
                    } else if ( ticket_link == 'ticket_external_link' ) {
                        $( ".vendor_edit_event .ticket_internal_link" ).hide();
                        $( ".vendor_edit_event .ticket_external_link" ).show();
                    }
                });
            }
        },
    
        el_update_ticket_status: function(){
            $('.update_ticket_status').on('click', function(e) {
                e.preventDefault();

                var that = $(this);

                $(this).closest('td').append( '<div class="el_loader"></div>' );
                $(this).hide();
             
                var qr_code = $(this).data('qr_code');

                $.post(ajax_object.ajax_url, {
                    action: 'el_update_ticket_status',
                    data: {
                        qr_code: qr_code,
                    },
                }, function(response) {
                    var res = JSON.parse(response);

                    if (res.status == 'valid' ) {
                        alert( res.msg_show );
                        that.closest('td').append( '<span class="update_ticket_success">'+res.msg_show+'</span>' );
                        that.closest('td').find('.el_loader').remove();
                    } else {
                        alert( res.msg );
                    }
                });
            });
        },  

        el_choose_payout_method: function(){
            if ( $( "input[name='ova_mb_event_payout_method']" ).length > 0 ){
                var payout_method   = $("input[name='ova_mb_event_payout_method']:checked").data('method');
                var list_id         = $('#el_save_bank #el_list_id_payout_method').val();

                if (payout_method == '') {
                    $("input[name='ova_mb_event_payout_method']").closest('form').find( ".bank_method" ).hide();
                    $("input[name='ova_mb_event_payout_method']").closest('form').find( ".paypal_method" ).hide();

                    if(list_id) {
                        list_id = JSON.parse(list_id);

                        for( var key in list_id ) {
                            var id = list_id[key];
                            $("input[name='ova_mb_event_payout_method']").closest('form').find( "."+id).hide();
                        }
                    }
                } else if ( payout_method == 'bank' ) {
                    $("input[name='ova_mb_event_payout_method']").closest('form').find( ".bank_method" ).show();
                    $("input[name='ova_mb_event_payout_method']").closest('form').find( ".paypal_method" ).hide();

                    if(list_id) {
                        list_id = JSON.parse(list_id);

                        for( var key in list_id ) {
                            var id = list_id[key];
                            $("input[name='ova_mb_event_payout_method']").closest('form').find( "."+id).hide();
                        }
                    }
                } else if ( payout_method == 'paypal' ) {
                    $("input[name='ova_mb_event_payout_method']").closest('form').find( ".bank_method" ).hide();
                    $("input[name='ova_mb_event_payout_method']").closest('form').find( ".paypal_method" ).show();

                    if(list_id) {
                        list_id = JSON.parse(list_id);

                        for( var key in list_id ) {
                            var id = list_id[key];
                            $("input[name='ova_mb_event_payout_method']").closest('form').find( "."+id).hide();
                        }
                    }
                } else {
                    if(list_id) {
                        list_id = JSON.parse(list_id);

                        for( var key in list_id ) {
                            var id = list_id[key];

                            if( payout_method == id ){
                                $("input[name='ova_mb_event_payout_method']").closest('form').find( "."+id).show();
                                $("input[name='ova_mb_event_payout_method']").closest('form').find( ".bank_method" ).hide();
                                $("input[name='ova_mb_event_payout_method']").closest('form').find( ".paypal_method" ).hide();
                            }else{
                                $("input[name='ova_mb_event_payout_method']").closest('form').find( "."+id).hide();
                            }
                        }
                    }
                }

                $( "input[name='ova_mb_event_payout_method']" ).on( 'click', function(){
                    var list_id         = $('#el_save_bank #el_list_id_payout_method').val();
                    var payout_method   = $("input[name='ova_mb_event_payout_method']:checked").data('method');
                    
                    if ( payout_method == 'bank' ) {
                        $(this).closest('form').find( ".bank_method" ).show();
                        $(this).closest('form').find( ".paypal_method" ).hide();

                        if(list_id) {
                            list_id = JSON.parse(list_id);
                            for( var key in list_id ) {
                                var id = list_id[key];
                                $(this).closest('form').find( "."+id).hide();
                            }
                        }
                    } else if ( payout_method == 'paypal' ) {
                        $(this).closest('form').find( ".bank_method" ).hide();
                        $(this).closest('form').find( ".paypal_method" ).show();

                        if (list_id) {
                            list_id = JSON.parse(list_id);

                            for( var key in list_id ) {
                                var id = list_id[key];
                                $(this).closest('form').find( "."+id).hide();
                            }
                        }
                    }else {
                        if (list_id) {
                            list_id = JSON.parse(list_id);

                            for( var key in list_id ) {
                                var id = list_id[key];

                                if ( payout_method == id ) {
                                    $(this).closest('form').find( "."+id).show();
                                    $(this).closest('form').find( ".bank_method" ).hide();
                                    $(this).closest('form').find( ".paypal_method" ).hide();
                                }else{
                                    $(this).closest('form').find( "."+id).hide();
                                }
                            }
                        }
                    }
                });
            }
        },
          
        withdraw_modal: function(){
            var modal = document.getElementById("myModal");
            var btn = document.getElementById("Btn_Withdraw");
            var span = document.getElementsByClassName("close")[0];

            if( btn ){
                btn.onclick = function() {
                    modal.style.display = "block";
                }  
            }

            if( modal ){
                span.onclick = function() {
                    modal.style.display = "none";
                }
            }

            window.onclick = function(event) {
                if (event.target == modal) {
                    modal.style.display = "none";
                }
            }
        },

        validateForm_withdraw:function() {
            $("input[name='el_add_withdrawal']").on('click', function(e) {
                e.preventDefault();

                var el_add_withdrawal_nonce = $(this).closest('form').find('input[name="el_add_withdrawal_nonce"]').val();
                var amount = Number($(this).closest('form').find('input[name="amount"]').val());
                var check_withdraw = Number($(this).closest('form').find('input[name="check_withdraw"]').val());
              
                if ( amount == null || amount == "" ) {
                    if ( typeof withdraw_amount_null ) {
                        alert(withdraw_amount_null);  
                    }
                    
                    return false;
                } else if ( isNaN(amount) || amount < 0 ) {
                    if( typeof withdraw_amount_num ){
                        alert(withdraw_amount_num);
                    }
                    return false;
                }else if ( amount > check_withdraw ) {
                    if ( typeof withdraw_amount_validate ) {
                        alert( withdraw_amount_validate+" "+check_withdraw);  
                    }
                    return false;
                }

                if ($(this).closest('form').valid()) {
                    $(".form-Withdraw .submit-load-more").css('z-index', '3');

                    $.post(ajax_object.ajax_url, {
                        action: 'el_add_withdrawal',
                        data: {
                            el_add_withdrawal_nonce: el_add_withdrawal_nonce,
                            amount: amount,
                        },
                    }, function(cons) {
                        var data = JSON.parse(cons);

                        if ( data.status == "error" ) {
                            alert( data.msg );
                            $(".form-Withdraw .submit-load-more").css('z-index', '-1');
                        } else {
                            location.reload();
                            $(".form-Withdraw .submit-load-more").css('z-index', '-1');
                        }
                    });
                }

            });
        },

        /***  update ticket in manage sale***/
        el_update_ticket_max:function(){
            $(document).on('click', '.el_update_ticket_max', function(e) {
                e.preventDefault();

                var el_update_ticket_max_nonce = $(this).parent().find('#el_update_ticket_max_nonce').val();
                var cal_id = $(this).parent().find("input[name='cal_id']").val();
                var id = $(this).parent().find("input[name='eid']").val();
                var list_type_ticket = $(this).parent().find("input[name='list_type_ticket']").val();

                var ticket_max = [];
                list_type_ticket = JSON.parse(list_type_ticket);

                for (var key in list_type_ticket) {
                    var data_ticket_max = {
                        'ticket_id': list_type_ticket[key].ticket_id,
                        'ticket_max':$(this).closest('.content_edit_ticket').find( 'input[name="ova_mb_event_ticket_max['+cal_id+"_"+list_type_ticket[key].ticket_id+']"]').val(),
                    };

                    ticket_max.push(data_ticket_max);
                }

                $(this).parent().find(".submit-load-more").css('z-index', '3');

                $.post(ajax_object.ajax_url, {
                    action: 'el_update_ticket_max',
                    data: {
                        el_update_ticket_max_nonce: el_update_ticket_max_nonce,
                        cal_id: cal_id,
                        id: id,
                        ticket_max: ticket_max,
                    },
                }, function(cons) {
                    var data = JSON.parse(cons);
                    if(data.msg){
                        alert(data.msg);
                    }
                    if(data.message){
                        alert(data.message);
                    }
                    $(".submit-load-more").css('z-index', '-1');
                    return true;
                });
            });
        },

        /***  edit ticket in manage ticket***/
        el_edit_ticket_calendar: function() {
            $(document).on('click', "[name='edit_ticket_calendar']", function(e) {
                e.preventDefault();
                var eid     = $(this).data('eid');
                var cal_id  = $(this).data('cal_id');

                $('.content_edit_ticket').hide("slow");
                $('[data-name="'+cal_id+'"]').show("slow");

                $(this).closest('.button_ticket').find(".submit-load-more").css('z-index', '3');

                $.post(ajax_object.ajax_url, {
                    action: 'el_load_edit_ticket_calendar',
                    data: {
                        cal_id: cal_id,
                        eid: eid,
                    },
                }, function(response) {
                    $('[data-name="'+cal_id+'"]').html('').append(response);
                    $(".submit-load-more").css('z-index', '-1');
                    $(".close_edit_ticket").on('click', function() {
                        $('[data-name="'+cal_id+'"]').hide("slow");
                    });
                });
            });
        },

        /***  choose calendar in manage ticket ***/
        el_choose_calendar: function() {
            $(document).find("[name='manage_sale_calendar']").on('change', function(e) {
                e.preventDefault();

                var cal_id  = $(this).val();
                var eid     = $(this).closest(".manage_tickets").find("input[name='eid']").val();

                $(".manage_tickets .submit-load-more.load").css('z-index', '3');

                $.post(ajax_object.ajax_url, {
                    action: 'el_choose_calendar',
                    data: {
                        cal_id: cal_id,
                        eid: eid,
                   },
                }, function(response) {
                   $('.manage_tickets .desc_calendar_sale').html('').append(response);
                   $(".manage_tickets .submit-load-more.load").css('z-index', '-1');
                });
            });
        },

        el_check_date_search_ticket: function(){
            $( "[name ='check_date_search_ticket']").on( 'click', function(e){
                e.preventDefault();

                var start_time  = $(this).closest('.form_date_time_search_ticket').find("input[name ='start_date_2']").val();
                var end_time    = $(this).closest('.form_date_time_search_ticket').find("input[name ='end_date_2']").val();
                var eid         = $(this).closest('.form_date_time_search_ticket').find("input[name ='eid']").val();

                $.ajax({
                    url: ajax_object.ajax_url,
                    type: 'POST',
                    data: {
                        action: 'el_check_date_search_ticket',
                        start_time: start_time,
                        end_time: end_time,
                        eid:eid,
                    },
                    success: function(response) {
                        var data = JSON.parse(response);
                        if(data.msg){
                            alert(data.msg);
                            return false;
                        }
                        window.location.href = data.url;
                    }
                });
            });
        },

        el_show_chart: function(){
            if ( $('#main_chart').length ) {
                var data_chart = $('#main_chart').data('chart');
                var currency = $('#main_chart').data('currency');
                var currency_position = $('#main_chart').data('currency_position');
                var name_month = $('#main_chart').data('name_month');
                var chart_groupby = $('#main_chart').data('chart_groupby');
                var chart_color = $('#main_chart').data('chart_color');
                var timeformat = $('#main_chart').data('timeformat');
                var monthnames = $('#main_chart').data('monthnames');


                var options = {
                    lines: { show: true, lineWidth: 2, fill: false },
                    points: { show: true, radius: 5, lineWidth: 2, fillColor: '#fff', fill: true },
                    legend: { show: false },
                    colors: [ chart_color ],
                    grid: {
                    color: '#aaa',
                    borderColor: 'transparent',
                    borderWidth: 0,
                    hoverable: true
                    },
                    xaxes: [{
                        color: '#aaa',
                        position: "bottom",
                        tickColor: 'transparent',
                        mode: "time",
                        // timeformat: timeformat,
                        monthNames: JSON.parse( decodeURIComponent( monthnames ) ),
                        minTickSize: [1, chart_groupby],
                        tickLength: 1,
                        font: { color: "#aaa" }
                    }],
                    yaxes:[
                        {
                            min: 0,
                            minTickSize: 1,
                            tickDecimals: 0,
                            color: '#d4d9dc',
                            font: { color: "#aaa" }
                        },
                        {
                            position: "right",
                            min: 0,
                            tickDecimals: 2,
                            alignTicksWithAxis: 1,
                            color: 'transparent',
                            font: { color: "#aaa" }
                        }
                    ],
                    yaxis: {
                        axisLabel: '%',
                        axisLabelFontSizePixels: 12,
                        tickFormatter: function (val, axis) {
                            if (val != 0 ) {
                                if ( currency_position == 'left' ) {
                                    return currency + val;
                                } else if ( currency_position == 'left_space' ) {
                                    return currency + ' ' + val;
                                } else if ( currency_position == 'right_space' ) {
                                    return val + ' ' + currency;
                                } else {
                                    return val + currency;
                                }
                            } else {
                                return val;
                            }
                        },
                    }
                };

                if ($('#main_chart').length > 0) {
                    $.plot("#main_chart", data_chart, options);
                }

                $("<div id='tooltip'></div>").css({
                position: "absolute",
                display: "none",
                border: "1px solid #eee",
                'border-radius': "5px",
                padding: "2px 0",
                "background-color": "#eee",
                opacity: 0.80,
                width: '150px',
                'text-align': 'center'
                }).appendTo("body");

                $("#main_chart").bind("plothover", function (event, pos, item) {
                    if ( !pos.x || !pos.y ) {
                        return;
                    }

                    if (item) {
                        var x = item.datapoint[0].toFixed(0);
                        var y = item.datapoint[1].toFixed(2);
                        let date = new Date( parseInt(x) );

                        var data_month_php = JSON.parse( decodeURIComponent( monthnames ) );

                        var monthName = data_month_php[date.getMonth()];

                        var dayName = date.getDate();

                        if ( currency_position == 'left' ) {
                            $("#tooltip").html( dayName + " " + monthName + ": " + currency + y ).css({top: item.pageY-40, left: item.pageX-75}).fadeIn(200);
                        } else if ( currency_position == 'left_space' ) {
                            $("#tooltip").html( dayName + " " + monthName + ": " + currency + ' ' + y ).css({top: item.pageY-40, left: item.pageX-75}).fadeIn(200);
                        } else if ( currency_position == 'right_space' ) {
                            $("#tooltip").html( dayName + " " + monthName + ": " + y + ' ' + currency ).css({top: item.pageY-40, left: item.pageX-75}).fadeIn(200);
                        } else {
                            $("#tooltip").html( dayName + " " + monthName + ": " + y + currency ).css({top: item.pageY-40, left: item.pageX-75}).fadeIn(200);
                        }
                    } else {
                        $("#tooltip").hide();
                    }
                });
            }
        },

        /* User Profile */
        ova_upload_file: function() {
            var ova_upload_file;
            $(document).on('click', '.vendor_profile .ova_upload_file', function(e) {
                e.preventDefault();
                if (typeof ova_upload_file != 'undefined') {
                    ova_upload_file.close();
                }

                var that = $(this);

                var $wrapper = that.closest(".vendor_field");

                ova_upload_file = wp.media({
                    title: $(this).data('uploader-title'),
                    button: {
                        text: $(this).data('uploader-button-text'),
                    },
                    multiple: false,
                });

                ova_upload_file.on('select', function() {
                    var selection = ova_upload_file.state().get('selection');

                    selection.map(function(attachment, i) {
                        attachment = attachment.toJSON();
                        if ( attachment.type !== 'image' ) {
                            $wrapper.find(".file__wrap").html(`<span class="file-name"><a href="${attachment.url}" target="_blank">${attachment.filename}</a></span><a class="ova_remove_file" href="#"><i class="far fa-trash-alt"></i></a>`);
                        } else {
                            $wrapper.find(".file__wrap").html(`<img class="ova__thumbnail" src="${attachment.url}" alt="${attachment.filename}"><a class="ova_remove_file" href="#"><i class="far fa-trash-alt"></i></a>`);
                        }
                        $wrapper.find("input").val(attachment.id);
                    });
                });

                ova_upload_file.open();
            });

            /* Remove File */
            $(document).on('click', '.vendor_profile .ova_remove_file', function(e) {
                e.preventDefault();
                var $wrapper = $(this).closest(".vendor_field");
                $wrapper.find('input').val('');
                $wrapper.find('.file__wrap').html('');
            });
        },

        el_ticket_received_download: function(){
            if ( $(document).find('.button-download-ticket-received').length ) {
                $(document).find('.button-download-ticket-received').click(function(){

                    var loader          = $(this).find('.submit-load-more');
                    var download_btn    = $(this).find('button.download-ticket-received');
                    var $nonce          = download_btn.data('nonce');
                    var $id             = download_btn.data('id');

                    loader.css('z-index','1');
                    var data = {
                        'action': 'el_ticket_received_download',
                        'nonce': $nonce,
                        'id': $id,
                    };
                    $.post(ajax_object.ajax_url, data, function(response){

                        var data = JSON.parse(response);
                        var data_url = data.ticket_url;

                        loader.css('z-index','1');

                        if ( data.status == "error" ) {
                            alert( data.mess );
                            loader.css('z-index','-1');
                        } else {

                            var link    = document.createElement('a');
                            link.href   = data_url;
                            let name_ticket = data_url.slice(data_url.lastIndexOf("/") + 1);
                            link.download   = name_ticket;
                            link.dispatchEvent(new MouseEvent('click'));
                            loader.css('z-index','-1');

                            /* delete file */
                            $.ajax({
                                url: ajax_object.ajax_url,
                                type: 'POST',
                                data: {
                                    action: 'el_fe_unlink_download_ticket',
                                    data_url: data_url,
                                },
                                success:function(response) {},
                            });
                        }
                    });
                });
            }
        },
    };

    /* ready */
    $(document).ready(function() {
        window.EL_Frontend = EL_Frontend;
        EL_Frontend.init();
    });

})(jQuery);